import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../../Styles/Colors'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import { Close } from '@material-ui/icons'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Demo_Layout_Default_JSON } from '../../../../../Utility/Constants'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CommonValidation from '../../../../../Components/CommonFunctions/CommonValidation'
import { DateTime } from 'luxon'
import moment from 'moment'
import Loader from '../../../../../Components/Loader'
import Webcam from 'react-webcam'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { City, Country, State } from 'country-state-city';


export default class RadDemographics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      layoutData: [],
      mandotory_count: '03',
      refDoctorData: [],
      refSourceData: [],
      CorporateList: [],
      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: "",
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",
      docDocument: null,
      aadharProof: null,
      panProof: null,
      passportProof: null,
      rationProof: null,
      dlProof: null,
      doctorList: [],
      filteredDocList: [],
      spcInfo: [],
      tagDetails: [],
      AppointmentTypeList: [],
      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      selectedAppointTypeId: null,
      appointmentDateTime: new Date(),
      purposeOfVisit: "",
      MandatoryLabels: [],
      CorporateList: [],
      patient_img: null,
      temp_patient_img: null,
      ProfilePicClicked: false,
      pancard_number: "",
      ration_card_number: "",
      dl_number: "",
      passport_number: "",
      aadhar_card_number: "",
      SelectedPatientID: "",
      SelectedDocSRC: null,
      SameMobileNumPatients: [],
      isForIPUpdate: false,
      receive_email: false,
      receive_whatsapp: false,
      receive_sms: true,
      refDocumentSelected: null,
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      DateOpen: false,
      isPatientCreated: false,
      CreatedPatientId: null,
      createdBy: '',
      modifiedBy: '',
      NurseList: [],
      selectedNurseId: null,
      mother_name: "",
      mother_age: "",
      POVList: [],
      InsuranceList: [],
      NewInsurance: false,
      POVList: [],
      RequestedPatientData: {},
      slotList: [],
      selectedSlot: "",
      old_referral_no: "",
      patientDetails: {},
      selectedPatientData: {},
      patient_id: '',
      clinicData: {}
    }
    this.uploadImage = React.createRef();
    this.webcamRef = React.createRef();
  }

  componentDidMount() {
    this.getDemographicsLayout()
    let clinicData = JSON.parse(localGetItem('loggedInUserInfo'))
    let details = getCachevalue('RadPatientData')
    let patientDetails = JSON.parse(details)
    this.setState({
      patientDetails: patientDetails,
      clinicData: clinicData
    }, () => {
      this.getPatientData()
      this.getInsuranceList()
      this.GetCorporateList()
      this.getRefSourceData()
      this.getReferralDctorData()
    })
  }

  getPatientData = () => {
    try {
      let { history } = this.props
      let patientId = this.state.patientDetails?.patient_id ? this.state.patientDetails?.patient_id : this.state.patientDetails?.id
      RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + '?patient_id=' + patientId)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              selectedPatientData: response.data.data
            }, () => {
              this.assignPatientDatas()
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  assignPatientDatas = () => {
    try {
      let { selectedPatientData } = this.state
      this.setState({
        referredBy: selectedPatientData?.reffered_by ? selectedPatientData?.reffered_by : '',
        referralSource: selectedPatientData?.referral_source ? selectedPatientData?.referral_source : '',
        mobile_no: selectedPatientData?.mobile_number ? selectedPatientData?.mobile_number : '',
        first_name: selectedPatientData?.first_name ? selectedPatientData?.first_name : '',
        age: selectedPatientData?.patient_age ? selectedPatientData?.patient_age : '',
        dob: selectedPatientData?.dob ? selectedPatientData?.dob : '',
        gender: selectedPatientData?.gender ? selectedPatientData?.gender : '',
        title: selectedPatientData?.title ? selectedPatientData?.title : '',
        middle_name: selectedPatientData?.middle_name ? selectedPatientData?.middle_name : '',
        surname: selectedPatientData?.last_name ? selectedPatientData?.last_name : '',
        address_1: selectedPatientData?.address_line_1 ? selectedPatientData?.address_line_1 : '',
        address_2: selectedPatientData?.address_line_2 ? selectedPatientData?.address_line_2 : '',
        country_code: this.state.AllCountry.find((item) => (item.name === selectedPatientData?.country))?.isoCode,
        AllStates: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === selectedPatientData?.country))?.isoCode),
        state_code: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === selectedPatientData?.country))?.isoCode).find((item) => (item.name === selectedPatientData?.state_name))?.isoCode,
        pin_code: selectedPatientData?.pincode ? selectedPatientData?.pincode : '',
        city: selectedPatientData?.city_name ? selectedPatientData?.city_name : '',
        employer_id: selectedPatientData?.employer_id ? selectedPatientData?.employer_id : '',
        employee_id: selectedPatientData?.id_no ? selectedPatientData?.id_no : '',
        email_id: selectedPatientData?.email ? selectedPatientData?.email : '',
        marital_status: selectedPatientData?.marital_status ? selectedPatientData?.marital_status : '',
        father_husband_name: selectedPatientData?.father_husband_name ? selectedPatientData?.father_husband_name : '',
        father_husband_age: selectedPatientData?.father_husband_age ? selectedPatientData?.father_husband_age : '',
        occupation: selectedPatientData?.occupation ? selectedPatientData?.occupation : '',
        emergency_contact_person: selectedPatientData?.caretaker_name ? selectedPatientData?.caretaker_name : '',
        emergency_contact_mobile: selectedPatientData?.caretaker_mobile ? selectedPatientData?.caretaker_mobile : '',
        relationship: selectedPatientData?.relationship ? selectedPatientData?.relationship : '',
        docDocument: selectedPatientData?.document_key ? selectedPatientData?.document_key : null,
        aadharProof: selectedPatientData?.aadhar ? selectedPatientData?.aadhar : null,
        panProof: selectedPatientData?.pan ? selectedPatientData?.pan : null,
        passportProof: selectedPatientData?.passport ? selectedPatientData?.passport : null,
        rationProof: selectedPatientData?.ration_card ? selectedPatientData?.ration_card : null,
        dlProof: selectedPatientData?.dl ? selectedPatientData?.dl : null,
        aadhar_card_number: selectedPatientData?.aadhar_card_number ? selectedPatientData?.aadhar_card_number : '',
        ration_card_number: selectedPatientData?.ration_card_number ? selectedPatientData?.ration_card_number : '',
        pancard_number: selectedPatientData?.pancard_number ? selectedPatientData?.pancard_number : '',
        passport_number: selectedPatientData?.passport_number ? selectedPatientData?.passport_number : '',
        dl_number: selectedPatientData?.dl_number ? selectedPatientData?.dl_number : '',
        patient_id: selectedPatientData?.patient_id ? selectedPatientData?.patient_id : '',
        patient_account_number: selectedPatientData?.patient_account_number ? selectedPatientData?.patient_account_number : '',
        patient_img: selectedPatientData?.photo_url === "" ? null : selectedPatientData.photo_url ? selectedPatientData.photo_url : null,
        temp_patient_img: selectedPatientData?.photo_url === "" ? null : selectedPatientData.photo_url ? selectedPatientData.photo_url : null,
      }, () => {
        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code) })
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getReferralDctorData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_DOCTOR + '?clinic_id=' + this.state.clinicData?.clinic_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              refDoctorData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRefSourceData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_SOURCE + '?clinic_id=' + this.state.clinicData?.clinic_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              refSourceData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetCorporateList() {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE + '?clinic_id=' + this.state.clinicData?.clinic_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              CorporateList: response.data.data
            })
          }
          else {
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getInsuranceList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST + '?clinic_id=' + this.state.clinicData?.clinic_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              InsuranceList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDemographicsLayout = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.FODemographicsLayout)
        .then((response) => {
          if (response.data.status === "success") {
            var Data = response.data.data
            var len = Data?.length
            this.setState({
              layoutData: Data[len - 1] ? Data[len - 1]?.field_order : Demo_Layout_Default_JSON,
              mandotory_count: Data[len - 1] ? ("0" + Data[len - 1]?.mandatory_field_count) : '04',
            }, () => {
              let MandLabels = []
              for (let i = 0; i < +this.state.mandotory_count; i++) {
                MandLabels.push(this.state.layoutData[i].label)
              }
              this.setState({ MandatoryLabels: MandLabels }, () => { this.LoaderFunction(false) })
            })
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  sendDocuments(key) {
    let states = this.state;
    if (states[key] === "" || states[key] === null) {
      return "None"
    }
    else if (states[key] === "delete") {
      return "delete"
    }
    else {
      if (typeof (states[key]) === "string") {
        if (key === "patient_img") {
          if (states[key].includes("https://s3-ap-south-1.amazonaws.com")) {
            return "None"
          } else {
            return this.dataURItoBlob(states[key])
          }
        } else {
          return "None"
        }
      }
      else {
        return states[key]
      }
    }
  }

  postNewInsurance = () => {
    var states = this.state.DemographicData

    var data = {
      "insurance_name": states.insurance_company_name
    }

    RestAPIService.create(data, Serviceurls.FO_INSURANCE_LIST)
      .then((response) => {
        if (response.data.status === 'success') {
        }
      }).catch((e) => {
        if (e?.response?.data?.message) {
          this.errorMessage(e.response.data.message)
        } else {
          this.errorMessage(e.message)
        }
      })
  }

  updatePatientData() {
    try {
      let { history } = this.props;
      let states = this.state;

      var date = new Date(states.dob)

      var Dob = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      const dataForm = new FormData();
      let clinicId = JSON.parse(localGetItem('loggedInUserInfo'))

      dataForm.append("first_name", states.first_name === null ? "" : CommonValidation.removeSpace(states.first_name))
      dataForm.append("last_name", states.surname === null ? "" : CommonValidation.removeSpace(states.surname))
      dataForm.append("middle_name", states.middle_name === null ? "" : CommonValidation.removeSpace(states.middle_name))
      dataForm.append("father_husband_name", states.father_husband_name === null ? "" : CommonValidation.removeSpace(states.father_husband_name))
      dataForm.append("father_husband_age", states.father_husband_age === null ? "" : CommonValidation.removeSpace(states.father_husband_age))
      dataForm.append("gender", states.gender === null ? "" : states.gender)
      dataForm.append("title", states.title === null ? "" : states.title)
      dataForm.append("occupation", states.occupation === null ? "" : CommonValidation.removeSpace(states.occupation))
      dataForm.append("dob", Dob === null ? "" : Dob)
      dataForm.append("approx_dob", Dob === null ? "" : Dob)
      dataForm.append("marital_status", states.marital_status === null ? "" : CommonValidation.removeSpace(states.marital_status))
      dataForm.append("mobile_number", states.mobile_no === null ? "" : CommonValidation.removeSpace(states.mobile_no))
      dataForm.append("mobile_number_2", "")
      dataForm.append("email", states.email_id === null ? "" : CommonValidation.removeSpace(states.email_id))
      dataForm.append("address_line_1", states.address_1 === null ? "" : CommonValidation.removeSpace(states.address_1))
      dataForm.append("address_line_2", states.address_2 === null ? "" : CommonValidation.removeSpace(states.address_2))
      dataForm.append("pincode", states.pin_code === null ? "" : CommonValidation.removeSpace(states.pin_code))
      dataForm.append("city_name", states.city === null ? "" : CommonValidation.removeSpace(states.city))
      dataForm.append("state_name", states.state_code === null ? "" : CommonValidation.removeSpace(states.AllStates.find((item) => (item.isoCode === states.state_code))?.name))
      dataForm.append("country", states.country_code === null ? "" : CommonValidation.removeSpace(states.AllCountry.find((item) => (item.isoCode === states.country_code))?.name))
      dataForm.append("reffered_by", states.referredBy === null ? "" : CommonValidation.removeSpace(states.referredBy))
      dataForm.append("referral_source", states.referralSource === null ? "" : CommonValidation.removeSpace(states.referralSource))
      dataForm.append("id_no", states.employee_id === null ? "" : CommonValidation.removeSpace(states.employee_id))
      dataForm.append("employer_id", states.employer_id === null ? "" : CommonValidation.removeSpace(states.employer_id))
      dataForm.append("document", states.docDocument === null ? "" : this.sendDocuments("docDocument"))
      dataForm.append("aadhar", states.aadharProof === null ? "" : this.sendDocuments("aadharProof"))
      dataForm.append("pan", states.panProof === null ? "" : this.sendDocuments("panProof"))
      dataForm.append("passport", states.passportProof === null ? "" : this.sendDocuments("passportProof"))
      dataForm.append("ration_card", states.rationProof === null ? "" : this.sendDocuments("rationProof"))
      dataForm.append("dl", states.dlProof === null ? "" : this.sendDocuments("dlProof"))
      dataForm.append("photo", states.patient_img === null ? "" : this.sendDocuments("patient_img"))
      dataForm.append("face_data", states.patient_img === null ? "" : this.sendDocuments("patient_img"))
      dataForm.append("pancard_number", states.pancard_number === null ? "" : CommonValidation.removeSpace(states.pancard_number))
      dataForm.append("ration_card_number", states.ration_card_number === null ? "" : CommonValidation.removeSpace(states.ration_card_number))
      dataForm.append("dl_number", states.dl_number === null ? "" : CommonValidation.removeSpace(states.dl_number))
      dataForm.append("passport_number", states.passport_number === null ? "" : CommonValidation.removeSpace(states.passport_number))
      dataForm.append("aadhar_card_number", states.aadhar_card_number === null ? "" : CommonValidation.removeSpace(states.aadhar_card_number))
      dataForm.append("caretaker_name", states.emergency_contact_person === null ? "" : CommonValidation.removeSpace(states.emergency_contact_person))
      dataForm.append("caretaker_mobile", states.emergency_contact_mobile === null ? "" : CommonValidation.removeSpace(states.emergency_contact_mobile))
      dataForm.append("caretaker_relationship", states.relationship === null ? "" : CommonValidation.removeSpace(states.relationship))
      dataForm.append("receive_email", states.receive_email === null ? "" : states.receive_email)
      dataForm.append("receive_whatsapp", states.receive_whatsapp === null ? "" : CommonValidation.removeSpace(states.receive_whatsapp))
      dataForm.append("receive_sms", states.receive_sms === null ? "" : states.receive_sms)
      dataForm.append("insurance_company_name", states.insurance_company_name === null ? "" : CommonValidation.removeSpace(states.insurance_company_name))
      dataForm.append("policy_number", states.policy_number === null ? "" : CommonValidation.removeSpace(states.policy_number))
      dataForm.append("guardian", states.guardian === null ? "" : CommonValidation.removeSpace(states.guardian))
      dataForm.append("mother_name", states.mother_name === null ? "" : CommonValidation.removeSpace(states.mother_name))
      dataForm.append("mother_age", states.mother_age === null ? "" : CommonValidation.removeSpace(states.mother_age))
      dataForm.append("old_referral_no", states.old_referral_no === "" ? "" : CommonValidation.removeSpace(states.old_referral_no))
      dataForm.append('patient_id', states.patient_id)

      let ApiUrl = "";
      ApiUrl = Serviceurls.FO_PATIENT_UPDATE
      RestAPIService.create(dataForm, ApiUrl)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if ((states.insurance_company_name != "" && states.insurance_company_name != null) && states.NewInsurance) {
              this.postNewInsurance()
            }
            this.successMessage(response.data.message)
          }
        }).catch((e) => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  alphaValidation = (num) => {
    return !/[^A-Z a-z]+/.test(num)
  }
  numberValidation(num) {
    return /^\d+$/.test(num);
  }

  handleAgeCalculation = date => {
    // let age = "";
    // let dob = DateTime.fromJSDate(date);
    // var year_age = DateTime.now().diff(dob, 'years');
    // age = Math.floor(year_age.years) || 0;
    // return (date < new Date() && date > new Date("1872-01-01"))?age:"";
    let age = "";
    let dob = new Date(date);
    let m = moment(dob, "DD-MM-YYYY");
    var end_date = moment();

    var year_age = end_date.diff(m, 'year') || 0;
    // reset(year) year with difference year
    m = moment(m).add(year_age, 'years');

    var month_age = end_date.diff(m, 'month') || 0;
    // reset(month) month with difference month
    m = moment(m).add(month_age, 'months');

    var day_age = end_date.diff(m, 'days') || 0;

    if (year_age > 0) {
      age = year_age || 0;
    } else {
      age = year_age + "Y/" + month_age + "M/" + day_age + "D";
    }
    return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
  }

  handleFindApproxDob = ageEntered => {

    var year = 0;
    var month = 0;
    var days = 0;
    var tempDate;
    year = +(ageEntered)
    if (!year) {
      tempDate = "";
    } else {
      tempDate = DateTime.now().minus({ years: year }).toBSON();
    }

    return tempDate;
  }

  getPincodeToCity = (pincode) => {
    fetch("https://api.postalpincode.in/pincode/" + pincode)
      .then((res) => res.json())
      .then(response => {
        if (response && response[0].Status == "Success") {
          var data = response[0].PostOffice
          if (data && data.length > 0) {
            this.setState({
              city: this.state.city ? this.state.city : data[0].District,
              AllStates: this.state.state_code ? this.state.AllStates : State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === data[0].Country))?.isoCode),
              country_code: this.state.country_code ? this.state.country_code : this.state.AllCountry.find((item) => (item.name === data[0].Country))?.isoCode,
            }, () => {
              this.setState({
                state_code: this.state.state_code ? this.state.state_code : this.state.AllStates.find((item) => (item.name === data[0].State))?.isoCode,
                cityName: this.state.city ? this.state.cityName : City.getCitiesOfState(this.state.country_code, this.state.AllStates.find((item) => (item.name === data[0].State))?.isoCode)
              })
            })
          }
        } else if (response.data.status == "fail") {
          this.errorMessage(response.data.message)
        }
      })
      .catch(e => {
        this.errorMessage(e.message)
      });
  }

  renderInput = (data, type) => {
    var states = this.state
    const { t } = this.props
    return (
      <form>
        <Box sx={{ marginTop: '2.5vh', marginLeft: '1vw', width: (data.label == "Age" || data.label == "Title") ? '7vw' : data.label == "Gender" ? '10vw' : '12vw' }}>
          {data.label == "Date of Birth" ?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                inputFormat={"dd-MM-yyyy"}
                autoComplete="off"
                value={states[data.field_name]}
                minDate={new Date("1872-01-01")}
                maxDate={DateTime.now()}
                onChange={(newDate) => {
                  var age = this.handleAgeCalculation(newDate)
                  this.setState({
                    dob: newDate,
                    age: age
                  })
                }}
                renderInput={(params) => <TextField {...params} size='small' autoComplete="off"
                  variant="outlined" fullWidth />}
              />
            </LocalizationProvider>
            :
            data.label == "Title" ?
              <FormControl size='small' fullWidth sx={{ textAlign: 'center', height: '1.3vw' }}>
                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                <Select
                  labelId="title-select-label"
                  MenuProps={{ style: { maxHeight: 250 } }}
                  sx={{ fontSize: '0.8vw', }}
                  size='small'
                  value={states[data.field_name]}
                  label={(data.label)}
                  onChange={(e) => {
                    states[data.field_name] = e.target.value

                    this.setState({
                      states
                    }, () => {
                      var states = this.state
                      if (states.title == "Mr") {
                        states["gender"] = "m";
                      } else if (states.title == "Ms" || states.title == "Mrs") {
                        states["gender"] = "f";
                      }
                      this.setState({ states })
                    })
                  }}
                >
                  <MenuItem value={""}>{"Title"}</MenuItem>
                  <MenuItem value={"Dr"}>{"Dr"}</MenuItem>
                  <MenuItem value={"Mr"}>{"Mr"}</MenuItem>
                  <MenuItem value={"Ms"}>{"Ms"}</MenuItem>
                  <MenuItem value={"Mrs"}>{"Mrs"}</MenuItem>
                  <MenuItem value={"Master"}>{"Master"}</MenuItem>
                  <MenuItem value={"Baby"}>{"Baby"}</MenuItem>
                  <MenuItem value={"Baby of"}>{"Baby of"}</MenuItem>
                </Select>
              </FormControl>
              : data.label == "Gender" ?
                <FormControl size='small' sx={{ width: '10vw' }}>
                  <InputLabel id="gender-select-label">{"Gender *"}</InputLabel>
                  <Select
                    sx={{ width: '10vw' }}
                    labelId='gender-select-label'
                    size='small'
                    label={(data.label)}
                    value={states[data.field_name]}
                    onChange={(e) => {
                      states[data.field_name] = e.target.value
                      this.setState({
                        states
                      })
                    }}
                  >
                    <MenuItem key={1} value={"m"}>{("Male")}</MenuItem>
                    <MenuItem key={2} value={"f"}>{("Female")}</MenuItem>
                    <MenuItem key={2} value={"t"}>{("TransGender")}</MenuItem>
                  </Select>
                </FormControl>
                : data.label === "Insurance Company Name" ?
                  <Autocomplete
                    sx={{ width: "12vw" }}
                    size="small"
                    options={states.InsuranceList}
                    getOptionLabel={(option) => (typeof (option) === "string" ? option : option.insurance_name)}
                    onChange={(event, value) => {
                      states["insurance_company_name"] = value?.insurance_name ? value?.insurance_name : ""
                      states["NewInsurance"] = false
                      this.setState({
                        states
                      })
                    }}
                    clearIcon={false}
                    value={states.insurance_company_name}
                    renderInput={(params) => (
                      <TextField
                        label={("Insurance Company Name")} InputProps={{ ...params.InputProps }}
                        onChange={(event) => {
                          states["insurance_company_name"] = event.target.value
                          states["NewInsurance"] = true
                          this.setState({
                            states
                          })
                        }}
                        {...params}
                      />
                    )}
                  />
                  : data.label == "Mobile no" ?
                    <TextField
                      disabled={Object.keys(this.state.RequestedPatientData).length > 0}
                      label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                      autoComplete="off"
                      size="small"
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{"+91"}</InputAdornment>
                      }}
                      value={states[data.field_name]}
                      onChange={(e) => {
                        var states = this.state
                        var isMobile = this.numberValidation(e.target.value)
                        if (data.label == "Mobile no") {
                          let mobileNum = e.target.value
                          if ((isMobile && mobileNum.length < 11) || e.target.value == "") {
                            states[data.field_name] = e.target.value
                            if (e.target.value.length === 10) { }
                          }
                        }
                        this.setState({ states })
                      }}
                      error={states.mobile_no !== "" && states.mobile_no.length !== 10}
                    />
                    : data.label == "Corporate Name" ?
                      <FormControl size='small' sx={{ width: '12vw' }}>
                        <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                        <Select
                          sx={{ width: '12vw' }}
                          MenuProps={{ style: { maxHeight: 250 } }}
                          labelId='gender-select-label'
                          size='small'
                          label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                          value={states[data.field_name]}
                          onChange={(e) => {
                            states[data.field_name] = e.target.value
                            if (e.target.value === "") { states.employee_id = "" }
                            this.setState({
                              states
                            })
                          }}
                        >
                          <MenuItem value={""}>{"None"}</MenuItem>
                          {
                            states.CorporateList.length > 0 ?
                              states.CorporateList?.map((list, index) => (
                                <MenuItem key={index} value={list.id}>{list.company_name}</MenuItem>
                              )) : null
                          }
                        </Select>
                      </FormControl>
                      :
                      data.label === "City" ?
                        <Autocomplete
                          sx={{ width: "12vw" }}
                          size="small"
                          options={states.cityName ? states.cityName : []}
                          getOptionLabel={(option) => typeof (option) === "string" ? option : option.name}
                          onChange={(e, value) => {
                            this.setState({
                              city: value?.name ? value?.name : '',
                              // pin_code: '',
                            })
                          }}
                          clearIcon={false}
                          value={states.city}
                          renderInput={(params) => (
                            <TextField
                              label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                              InputProps={{ ...params.InputProps }}
                              onChange={(e) => this.setState({
                                city: e.target.value,
                                // pin_code: ''
                              })}
                              {...params}
                            />)}
                          onKeyDown={(e) => e.key === "Enter" ? e.preventDefault() : null}
                        /> :
                        data.label == "Marital Status" ?
                          <FormControl size='small' sx={{ width: '12vw' }}>
                            <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                            <Select
                              sx={{ width: '12vw' }}
                              labelId='gender-select-label'
                              size='small'
                              label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                              value={states[data.field_name]}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value
                                this.setState({
                                  states
                                })
                              }}
                            >
                              <MenuItem key={1} value={"Single"}>{("Single")}</MenuItem>
                              <MenuItem key={2} value={"Married"}>{("Married")}</MenuItem>
                              <MenuItem key={2} value={"Divorced"}>{("Divorced")}</MenuItem>
                            </Select>
                          </FormControl>
                          : data.label == "Employee ID No" ?
                            <TextField
                              autoComplete="off"
                              inputProps={{ maxLength: 30 }}
                              label={(type === 'mandatory' || data.is_default || states.employer_id) ? ((data.label) + "*") : (data.label)}
                              size="small" value={states[data.field_name]}
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value?.toUpperCase()
                                this.setState({ states })
                              }}
                              disabled={states.employer_id === null || states.employer_id === ""}
                            />
                            : data.label == "State" ?
                              // <RegionDropdown className='eMed_demo_dropdown_inputs'
                              //   country={this.state.country}
                              //   value={this.state.state}
                              //   onChange={(val) => {
                              //     states[data.field_name] = val
                              //     this.setState({ states })
                              //   }} />
                              <FormControl size='small' sx={{ width: '12vw' }}>
                                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                                <Select
                                  sx={{ width: '12vw' }}
                                  MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                  labelId='gender-select-label'
                                  size='small'
                                  label={data.label}
                                  value={states.state_code}
                                  onChange={(e) => {
                                    this.setState({ state_code: e.target.value },
                                      () => {
                                        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code), city: '', pin_code: '' })
                                      })
                                  }}
                                >
                                  {
                                    states.AllStates?.map((list) => (
                                      <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                              : data.label == "Country" ?
                                <FormControl size='small' sx={{ width: '12vw', maxWidth: '12vw' }}>
                                  <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                                  <Select
                                    sx={{ width: '12vw' }}
                                    MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                    labelId='gender-select-label'
                                    label={data.label}
                                    size='small'
                                    value={states.country_code}
                                    onChange={(e) => {
                                      this.setState({ country_code: e.target.value, state_code: "", city: '' }, () => {
                                        this.setState({ AllStates: State.getStatesOfCountry(this.state.country_code), cityName: '', pin_code: '' })
                                      })
                                    }}
                                  >
                                    {
                                      states.AllCountry.map((list) => (
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                                // <CountryDropdown className='eMed_demo_dropdown_inputs'
                                //   value={this.state.country}
                                //   onChange={(val) => this.setState({ country: val })}
                                //   priorityOptions={["IN"]}
                                //   whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                                // />
                                :
                                <TextField
                                  // disabled={(data.label === "First Name") ? Object.keys(this.state.RequestedPatientData).length > 0 : false}
                                  autoComplete="off"
                                  inputProps={{ maxLength: (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Referred By" || data.label === "Referral Source" || data.label === "Mother's Name" || data.label === "Father/Husband Name" || data.label === "Emergency Contact Person" || data.label === "Relationship") ? 32 : ( data.label === "Occupation" || data.label === "'Insurance Company Name'" || data.label === "Insurance No") ? 45 : ( data.label === "Email ID") ? 50 : 100 }}
                                  label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                                  variant="outlined"
                                  value={states[data.field_name]}
                                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                  onChange={(e) => {
                                    var isAlpha = this.alphaValidation(e.target.value)
                                    var isNum = this.numberValidation(e.target.value)
                                    var isAlphaNum = CommonValidation.alphanumValidation(e.target.value)
                                    var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)

                                    if (data.label === "Age" || data.label === "Father/Husband Age" || data.label === "Mother's Age") {
                                      var age = e.target.value
                                      if ((isNum && +(age) <= 150) || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        states['dob'] = data.label === "Age" ? null : states.dob
                                        this.setState({
                                          states,
                                        }, () => {
                                          if (data.label === "Age") {
                                            var approxDob = this.handleFindApproxDob(this.state.age);
                                            this.setState({
                                              dob: approxDob
                                            })
                                          }
                                        })
                                      }
                                      else if (e.target.value?.includes("/")) {
                                        this.setState({ age: "", dob: null })
                                      }
                                    }
                                    else if (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Emergency Contact Person" || data.label === "Occupation" || data.label === "Relationship" || data.label === "City" || data.label === "Guardian" || data.label === "Mother's Name") {
                                      if (isAlpha || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Father/Husband Name") {
                                      if (isSpecial || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Pin Code") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 6) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        }, () => {
                                          if (this.state.pin_code && this.state.pin_code.length === 6) {
                                            this.getPincodeToCity(this.state.pin_code)
                                          }
                                        })
                                      }
                                    }
                                    else if (data.label === "Emergency Mobile No") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 10) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Insurance No") {
                                      if ((isAlphaNum || e.target.value == "")) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else {
                                      states[data.field_name] = e.target.value
                                      this.setState({
                                        states
                                      })
                                    }
                                  }}
                                  size="small"
                                />
          }
        </Box>
      </form>
    )
  }

  OpenWebCamPopUP = () => {
    try {
      return (
        <Box>
          <Modal open={true}>
            <Box className="eMed_WebCamPop_Main">
              <Box component={'div'} className="eMed_WebCam_Header">
                <Typography variant='h6'>{"Patient Profile "}</Typography>
                <Button onClick={() => { this.setState({ temp_patient_img: this.state.patient_img, ProfilePicClicked: false }) }} >
                  <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                </Button>
              </Box>
              <Box component={'div'}>
                {
                  this.state.temp_patient_img === null ?
                    <Webcam
                      className='eMed_WebCam_Div'
                      audio={false}
                      mirrored={true}
                      ref={this.webcamRef}
                      screenshotFormat="image/jpeg"
                    // videoConstraints={videoConstraints}
                    /> :
                    <img className='eMed_WebCam_Img_Div' src={this.state.temp_patient_img} />
                }
              </Box>
              <Box component={'div'} className="eMed_WebCam_Btn_div">
                <Button disabled={this.state.temp_patient_img !== null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: this.webcamRef.current.getScreenshot() }) }}>Capture</Button>
                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: null }) }}>Retake</Button>
                <Button disabled={this.state.temp_patient_img === null} variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ patient_img: this.state.temp_patient_img, ProfilePicClicked: false }) }}>Set Profile</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      );
    } catch (e) {
      this.errorMessage(e.message)
    }
  };

  ClearData() {
    this.setState({
      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: null,
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",
      docDocument: null,
      aadharProof: null,
      panProof: null,
      passportProof: null,
      rationProof: null,
      dlProof: null,
      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      appointmentDateTime: new Date(),
      purposeOfVisit: null,
      pancard_number: "",
      ration_card_number: "",
      dl_number: "",
      passport_number: "",
      aadhar_card_number: "",
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      patient_img: null,
      temp_patient_img: null,
      selectedAppointTypeId: this.state.AppointmentTypeList?.find((item) => (item.appoinment_type === "Walk in Appointment")),
      filteredDocList: [],
      isPatientCreated: false,
      SelectedPatientID: "",
      mother_name: "",
      mother_age: "",
      RequestedPatientData: {},
      old_referral_no: ""
    })
  }

  getPatientCardPrint = () => {
    try {
      this.setState({ disableBtn: true })
      RestAPIService.getAll(Serviceurls.OP_CARD_PRINT + `?patient_id=${this.state.patientDetails?.patient_id}&header=false&clinic_id=${this.state.clinicData?.clinic_id}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ disableBtn: false })
        }).catch((error) => {
          this.setState({ disableBtn: false })
          if (error.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMessage(error.message)
    }
  }

  MandatoryCheck() {
    let states = this.state;
    let MandatoryCount = +(states.mandotory_count)
    let flag = false;
    for (let i = 0; i < MandatoryCount; i++) {
      if (CommonValidation.removeSpace(states[states.layoutData[i].field_name]) === "" || states[states.layoutData[i].field_name] === null) {
        flag = false
        this.errorMessage(`${states.layoutData[i].label} is Mandatory !`)
        break
      } else {
        flag = true
      }
    }
    return flag
  }

  Validation() {
    let states = this.state;
    let Email = (states.MandatoryLabels.includes("Email ID") || states.email_id !== "") ? CommonValidation.emailValidation(states.email_id) : true;
    Email = states.email_id == null ? true : true;
    let Corporate = (states.employer_id === "" || states.employer_id === null) ? true : (states.employee_id !== "" && states.employee_id !== null)
    let Insurance = (states.insurance_company_name === "" || states.insurance_company_name === null) ? true : (states.policy_number !== "" && states.policy_number !== null)
    if (this.MandatoryCheck()) {
      if ((states.mobile_no.length === 10 && Email && Corporate && Insurance)) {
        this.updatePatientData()
      }
    }
  }

  renderLeftContent = () => {
    try {
      var states = this.state
      var Mandatory_Array = []
      var Non_Mandatory_Array = []
      const SplitCount = +(states.mandotory_count)
      const Total_Len = (states.layoutData)?.length

      var Active_Array = (states.layoutData)?.filter((item) => {
        return item.is_active == true
      })

      Mandatory_Array = (Active_Array)?.slice(0, SplitCount)
      Non_Mandatory_Array = (Active_Array)?.slice(SplitCount, Total_Len)
      return (
        <Box component={'div'}>
          <Box className="rad_demo_header_card">
            <Paper className='rad_demo_header_one'>
              <Typography className='eMed_refdoc_title'>{("Referral Information")}</Typography>
              <Box component={'div'} className="rad_demo_header_two">
                <FormControl size='small' sx={{ width: '15vw' }}>
                  <InputLabel id="referredby-select-label">{"Referred By"}</InputLabel>
                  <Select
                    labelId="referredby-select-label"
                    MenuProps={{ style: { maxHeight: 250 } }}
                    size='small'
                    value={states.referredBy}
                    label={"Referred By"}
                    onChange={(e) => {
                      this.setState({
                        referredBy: e.target.value
                      })
                    }}
                  >
                    <MenuItem value={""}>{"Select Doctor"}</MenuItem>
                    {states.refDoctorData?.map((item, index) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
                <FormControl size='small' sx={{ width: '15vw' }}>
                  <InputLabel id="referralSrc-select-label">{"Referral Source"}</InputLabel>
                  <Select
                    labelId="referralSrc-select-label"
                    MenuProps={{ style: { maxHeight: 250 } }}
                    size='small'
                    value={states.referralSource}
                    label={"Referral Source"}
                    onChange={(e) => {
                      this.setState({
                        referralSource: e.target.value
                      })
                    }}
                  >
                    <MenuItem value={""}>{"Select Source"}</MenuItem>
                    {states.refSourceData?.map((item, index) => (
                      <MenuItem value={item.source_name}>{item.source_name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
                <input
                  type="file"
                  accept="application/pdf, image/jpg, image/jpeg, image/png"
                  onChange={(e) => this.UploadImage(e, "docDocument")}
                  ref={this.uploadImage}
                  style={{ display: "none" }}
                />
                {
                  (this.state.docDocument === null || this.state.docDocument === "delete") ?
                    <Button
                      size='small'
                      variant="contained"
                      endIcon={
                        <FileUploadOutlinedIcon />
                      }
                      onClick={() => this.uploadImage.current.click()}
                    >
                      Upload Document
                    </Button>
                    :
                    <Box>
                      <div className='emedhub_doc_image_size'>
                        <Tooltip title={typeof (this.state.docDocument) === "string" ? null : this.state.docDocument?.name} arrow placement='top'>
                          <img
                            onClick={() => {
                              if (typeof (this.state.docDocument) === "string") {
                                if (this.state.docDocument.split('.').pop() === "jpg" || this.state.docDocument.split('.').pop() === "jpeg" || this.state.docDocument.split('.').pop() === "JPG" || this.state.docDocument.split('.').pop() === "png") {
                                  this.setState({ SelectedDocSRC: this.state.docDocument })
                                }
                                else {
                                  window.open(this.state.docDocument)
                                }
                              }
                            }}
                            src={typeof (this.state.docDocument) === "string" ?
                              (this.state.docDocument.split('.').pop() === "jpg" || this.state.docDocument.split('.').pop() === "jpeg" || this.state.docDocument.split('.').pop() === "JPG" || this.state.docDocument.split('.').pop() === "png" ?
                                this.state.docDocument : ImagePaths.DocumentPreview.default) : ((this.state.docDocument?.type === "image/jpeg" || this.state.docDocument?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                            className='eMed_createapt_right2_field_img_inv' />
                        </Tooltip>
                        <div>
                          <IconButton onClick={() => {
                            this.state.docDocument = "delete"
                            this.setState({ states })
                          }}
                            size='small'
                            className='emedhub_icon_img_close'
                          ><Close fontSize='small' /></IconButton>
                        </div>
                      </div>
                    </Box>
                }
              </Box>
            </Paper>
            <Paper className='rad_demo_header_lft' sx={{ width: '15vw' }}>
              <IconButton className='eMed_createapt_left1_paper2_icon' onClick={() => { this.setState({ ProfilePicClicked: true }) }}>
                <img className='eMed_createapt_left1_paper2_img' src={this.state.patient_img === null ? ImagePaths.UploadPatientDP.default : this.state.patient_img} alt="ProfileImage" />
              </IconButton>
            </Paper>
          </Box>
          <Paper component={'div'} elevation={1} sx={{ backgroundColor: Colors.white, height: '61vh' }}>
            <Box component={'div'} height={'6vh'} display={'flex'} alignItems={'center'} pl={'0.5vw'} sx={{ backgroundColor: Colors.Background }} borderBottom={'1px solid lightgray'}>
              <Typography fontWeight={'600'} fontSize={'0.9vw'}>Patient Details</Typography>
              <Box component={'div'} display={'flex'} alignItems={'center'} ml={'1vw'} sx={{ cursor: 'pointer' }} onClick={() => this.getPatientCardPrint()}>
                <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
              </Box>
              <Box className="emed_createAppoint_Checkbox">
                {/* <FormControlLabel control={<Checkbox checked={this.state.receive_email} onChange={(e) => { this.setState({ receive_email: e.target.checked }) }} size='small' />} label="Receive Email" /> */}
                <FormControlLabel control={<Checkbox checked={this.state.receive_sms} onChange={(e) => { this.setState({ receive_sms: e.target.checked }) }} size='small' />} label="Receive SMS" />
                {/* <FormControlLabel control={<Checkbox disabled checked={this.state.receive_whatsapp} onChange={(e) => { this.setState({ receive_whatsapp: e.target.checked }) }} size='small' />} label="WhatsApp" /> */}
              </Box>
            </Box>
            <Box component={'div'} overflow={'auto'} height={'48vh'}>
              <Box className="eMed_demolayout_left_TextInput">
                {Mandatory_Array?.map((item, index) => (
                  this.renderInput(item, 'mandatory')
                ))}
              </Box>
              <Divider />
              <Box className="eMed_demolayout_left_TextInput" p={'0.5vw'}>
                {Non_Mandatory_Array?.map((item, index) => (
                  this.renderInput(item, 'non-mandatory')
                ))}
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'6vh'} borderTop={'1px solid lightgray'}>
              <Button variant='outlined' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                onClick={() => this.ClearData()}
              >Clear</Button>
              <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw' }}
                onClick={() => this.Validation()}
              >Update</Button>
            </Box>

          </Paper>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  UploadImage = (e, key) => {
    let states = this.state
    states[key] = e.target.files[0]
    this.setState({ states })
    e.target.value = ''
  }

  renderIdentityProof = (Label, key, NumberKey) => {
    let states = this.state;
    return (
      <Box className="eMed_createapt_right2_box">
        <TextField
          label={Label}
          sx={{ width: '15vw' }}
          size="small"
          value={states[NumberKey]}
          onChange={(e) => {
            let value = e.target.value
            if (NumberKey === "aadhar_card_number") {
              let Valid = CommonValidation.numberOnly(value)
              if ((Valid || value === "") && value.length <= 12) {
                states[NumberKey] = value
              }
            }
            else if (NumberKey === "passport_number") {
              let Valid = CommonValidation.ALPHA_NUMARIC(value)
              if ((Valid || value === "") && value.length <= 8) {
                states[NumberKey] = value.toUpperCase()
              }
            }
            else if (NumberKey === "pancard_number") {
              let Valid = CommonValidation.ALPHA_NUMARIC(value)
              if ((Valid || value === "") && value.length <= 10) {
                states[NumberKey] = value.toUpperCase()
              }
            }
            else {
              let Valid = CommonValidation.ALPHA_NUMARIC(value)
              if ((Valid || value === "") && value.length <= 15) {
                states[NumberKey] = value.toUpperCase()
              }
            }
            this.setState({ states })
          }}
        />
        <IconButton size='small' component="label">
          <input onChange={(e) => this.UploadImage(e, key)} hidden accept="application/pdf, image/jpg, image/jpeg, image/png" type="file" />
          <img className='eMed_createapt_right2_field_img' src={ImagePaths.UploadBtnTheme.default} alt="" />
        </IconButton>
        {(states[key] !== null && states[key] !== "delete") ?
          <div className='emedhub_doc_image_size'>
            <Tooltip title={typeof (states[key]) === "string" ? null : states[key]?.name} arrow placement='top'>
              <img
                onClick={() => {
                  if (typeof (states[key]) === "string") {
                    if (states[key].split('.').pop() === "jpg" || states[key].split('.').pop() === "jpeg" || states[key].split('.').pop() === "JPG" || states[key].split('.').pop() === "png") {
                      this.setState({ SelectedDocSRC: states[key] })
                    }
                    else {
                      window.open(states[key])
                    }
                  }
                }}
                src={typeof (states[key]) === "string" ?
                  (states[key].split('.').pop() === "jpg" || states[key].split('.').pop() === "jpeg" || states[key].split('.').pop() === "JPG" || states[key].split('.').pop() === "png" ?
                    states[key] : ImagePaths.DocumentPreview.default) : ((states[key]?.type === "image/jpeg" || states[key]?.type === "image/png") ? ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default)}
                className='eMed_createapt_right2_field_img_inv' />
            </Tooltip>
            <div>
              <IconButton onClick={() => {
                states[key] = "delete"
                this.setState({ states })
              }}
                size='small'
                className='emedhub_icon_img_close'
              ><Close fontSize='small' /></IconButton>
            </div>
          </div> : null}
      </Box>
    )
  }

  renderRightContent = () => {
    try {
      return (
        <Paper component={'div'} elevation={1} sx={{ width: '25vw' }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} height={'77vh'}>
            <Typography className='eMed_refdoc_title'>{("Identity Proof")}</Typography>
            {this.renderIdentityProof("Aadhaar", "aadharProof", "aadhar_card_number")}
            {this.renderIdentityProof("PAN", "panProof", "pancard_number")}
            {this.renderIdentityProof("Passport", "passportProof", "passport_number")}
            {this.renderIdentityProof("Ration", "rationProof", "ration_card_number")}
            {this.renderIdentityProof("DL", "dlProof", "dl_number")}
          </Box>
        </Paper>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <Box component={'div'}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '79vh' }} display={'flex'} p={'0.5vw'}>
          <Box component={'div'} flex={0.7} mr={'0.5vw'}>
            {this.renderLeftContent()}
          </Box>
          <Box component={'div'} flex={0.3}>
            {this.renderRightContent()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.ProfilePicClicked ? this.OpenWebCamPopUP() : null}
      </Box>
    )
  }
}
