import React, { Component } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Popover from '@mui/material/Popover';
import { DateTime } from "luxon";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import './DatePicker.css';
import { withTranslation } from 'react-i18next';
import { localGetItem } from '../../Utility/Services/CacheProviderService';

class DatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FrmDate: new Date(),
            ToDate: new Date()
        }
    }

    HandleFromDate = (newvalue) => {
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"));
        let howManyDays = RoleData.module_name === "Pharmacy" ? (this.props.days ? this.props.days : 90) :  (this.props.MaxDaysCount ? this.props.MaxDaysCount : 30);
        let toDate = new Date(newvalue.getTime() + howManyDays * 24 * 60 * 60 * 1000);
        const toDay = toDate.getDate();
        const getLastDayOfMonth = (date) => {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        };

        if(this.props.fromStockValue){
            this.setState({
                FrmDate: newvalue === null ? null : newvalue,
                ToDate: (newvalue != null && newvalue != new Date().getDate()) ?
                    (newvalue !== null && toDay === 30 && getLastDayOfMonth(toDate) === 31 && (toDate < new Date().getTime())) ?
                        new Date(toDate.getFullYear(), toDate.getMonth(), getLastDayOfMonth(toDate)) :
                        new Date(newvalue.getTime() + howManyDays * 24 * 60 * 60 * 1000) < new Date().getTime() ?
                            new Date(newvalue.getTime() + howManyDays * 24 * 60 * 60 * 1000) : new Date(Date.now() - 864e5) : new Date()
        })
        }else{
            this.setState({
            FrmDate: newvalue === null ? null : newvalue,
                ToDate: (newvalue !== null && toDay === 30 && getLastDayOfMonth(toDate) === 31 && (toDate < new Date().getTime())) ?
                    new Date(toDate.getFullYear(), toDate.getMonth(), getLastDayOfMonth(toDate)) :
                    new Date(newvalue.getTime() + howManyDays * 24 * 60 * 60 * 1000) < new Date().getTime() ?
                        new Date(newvalue.getTime() + howManyDays * 24 * 60 * 60 * 1000) : new Date()
            });
        }
    };


    HandleToDate = (newvalue) => {
        this.setState({
            ToDate: newvalue === null ? null : newvalue,
        });
    };

    HandleCustomDate = () => {
        this.props.CustomDates(
            DateTime.fromJSDate(this.state.FrmDate).toFormat('yyyy-MM-dd'),
            DateTime.fromJSDate(this.state.ToDate).toFormat('yyyy-MM-dd'))
    }

    PopClose = () => {
        this.props.PopClose()
    }

    render() {
        const { t } = this.props;
        let Settings = localGetItem("PharmaSettings") === null ? {} : JSON.parse(localGetItem("PharmaSettings"))
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let PharmaRestirictionWeek = RoleData.is_user ? (this.props.PharmaDateFilterRestriction === true && (Settings?.report_date_config === "last7days")) : false
        let PharmaRestirictionMonth = RoleData.is_user ? (this.props.PharmaDateFilterRestriction === true && (Settings?.report_date_config === "last30days" )) : false
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser;
        let howManyDays = RoleData.module_name === "Pharmacy" ? (this.props.days ? this.props.days : 90) : (this.props.MaxDaysCount ? this.props.MaxDaysCount : 30);
        let EndDate = new Date(this.state.FrmDate.getTime() + howManyDays * 24 * 60 * 60 * 1000) < new Date().getTime() ? new Date(this.state.FrmDate.getTime() + howManyDays * 24 * 60 * 60 * 1000) : new Date()
        let FromDateRestriction =  new Date()
        if(window.location.pathname === "/ChartDashboard"){
            FromDateRestriction =  new Date("2024-02-01")
        }
        else if(window.location.pathname === "/FOReports/PatientReport/CreditSettlementReport"){
            FromDateRestriction =  new Date("2024-04-01")
        }
        else{
             FromDateRestriction =  new Date("2017-01-01")
        }
        if(PharmaRestirictionWeek){
            FromDateRestriction =  new Date(this.state.FrmDate.getTime() - 7 * 24 * 60 * 60 * 1000)
        }
        else if(PharmaRestirictionMonth){
            FromDateRestriction = new Date(this.state.FrmDate.getTime() - 30 * 24 * 60 * 60 * 1000)
        }
        return (
            <div>
                <Popover
                    id="emed_calender_popover"
                    open={true}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 190, left: window.location.pathname === "/PharmacyReports/PurchaseWiseEnquiry" ? 200 : 800 }}>
                    <div className='emed_Calender_Pop' style={{ overflow: 'auto', height: '66.5vh' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginLeft: "20%" }}> <h5 id="emed_CalenderHead">{t("FromDate")}</h5></div>
                            <div style={{ marginLeft: "35%" }}>  <h5 id="emed_CalenderHead">{t("ToDate")}</h5></div>
                            <IconButton id="emed_CalenderClose_Icon" onClick={this.PopClose}>
                                <CloseIcon color='primary' fontSize='large' />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex' }} >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDatePicker
                                    id="emed_calender_"
                                    displayStaticWrapperAs="desktop"
                                    value={this.state.FrmDate}
                                    maxDate={this.state.ToDate}
                                    // minDate={ UserData?.is_user === true ? new Date(new Date(new Date().getFullYear(), new Date().getMonth()-3, 1)) : new Date("2017-01-01")}
                                    minDate={ FromDateRestriction }
                                    onChange={this.HandleFromDate}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    value={this.state.ToDate}
                                    maxDate={this.props.fromStockValue ? this.state.ToDate : (window.location.pathname === "/IPHome" ? new Date(Date.now() + 1 * 24 * 60 * 60 * 1000) : EndDate)}
                                    minDate={this.state.FrmDate}
                                    onChange={this.HandleToDate}
                                />
                            </LocalizationProvider >
                        </div>
                        <div className='eMed_Custum_pop_buttons'>
                        <Button id="emed_Calender_TodayButton" 
                        size='small'
                        variant="text"
                            onClick={() => {
                                this.setState({
                                    FrmDate: new Date(),
                                    ToDate: new Date()
                                })
                            }}>{t("Today")}</Button>
                        <Button size='small' id="emed_Calender_SearchIcon" variant="contained" onClick={this.HandleCustomDate}>{t("Search")}</Button>
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}

export default withTranslation()(DatePicker);