import React, { Component } from 'react'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from '../../../../Styles/Colors'
import { DOC_PRINT_DEFAULT } from '../../../../Utility/Constants'
import { localGetItem, localSetItem } from '../../../../Utility/Services/CacheProviderService'

const SEARCH_DRUG_NAME = [
    { label: 'Brand Name', value: 1 },
    { label: 'Generic Name', value: 2 },
    { label: 'Both', value: 3 },
]

export default class DocNotesConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOPT: false,
            lockPeriod: '30',
            transmitSms: null,
            summarySms: null,
            doctorPrintList: {},
            loginInfo: '{}',
            dupDocPrintList: '{}',
            auto_fetch_prescription_drug: false,
            drug_search:1
        }
    }

    componentDidMount() {
        let loginInfo = localGetItem("loggedInUserInfo")
        let loginData = JSON.parse(loginInfo)
        this.setState({ loginInfo: loginData })
        this.getDoctorNotesConfigList()
    }

    getDoctorNotesConfigList = () => {
        try {
            RestAPIService.getAllTwo(Serviceurls.DOC_OPT_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            lockPeriod: response.data.data.doctor_notes_lock_period,
                            transmitSms: response.data.data.late_transmit_summary_sms,
                            summarySms: response.data.data.append_summary_sms,
                            doctorPrintList: response.data.data.doc_notes_print_template ? response.data.data.doc_notes_print_template : DOC_PRINT_DEFAULT,
                            dupDocPrintList: JSON.stringify(DOC_PRINT_DEFAULT),
                            auto_fetch_prescription_drug: response?.data?.data?.auto_fetch_prescription_drug,
                            drug_search: response.data.data.drug_search ? response.data.data.drug_search : 1
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postDoctorConfigList = () => {
        try {
            let data = {
                doctor_notes_lock_period: +this.state.lockPeriod,
                late_transmit_summary_sms: Boolean(this.state.transmitSms),
                append_summary_sms: Boolean(this.state.summarySms),
                doc_notes_print_template: this.state.doctorPrintList,
                auto_fetch_prescription_drug: this.state.auto_fetch_prescription_drug,
                drug_search:this.state.drug_search ? this.state.drug_search : 1
            }
            RestAPIService.create(data, Serviceurls.DOC_OPT_CONFIG)
                .then((response) => {
                    if (response?.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getDoctorNotesConfigList()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onChangeHandler = (e, names) => {
        try {
            let value = e.target.value
            switch (names) {
                case 'lockPeriod':
                    if ((CommonValidation.numberOnly(value) && +value <= 90) || value === '') {
                        this.setState({ lockPeriod: value })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (names, value, label, endAdornment, text, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    label={label}
                    autoComplete='off'
                    InputProps={endAdornment ? {
                        endAdornment: <Box component={'div'}><Typography fontWeight={600}>{text}</Typography></Box>
                    } : null}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeHandler(e, names)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                lockPeriod: '30',
                transmitSms: false,
                summarySms: false,
                drug_search:1,
                doctorPrintList: DOC_PRINT_DEFAULT
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeCheckBox = (e, index, key) => {
        try {
            let value = e.target.checked
            if (this.state.loginInfo?.service_type === 'OPT') {
                if (this.state.doctorPrintList['opt_doctor'][index]['label'] === 'Full Report') {
                    this.state.doctorPrintList['opt_doctor'][index]['is_checked'] = value
                    this.setState({ doctorPrintList: this.state.doctorPrintList }, () => {
                        if (this.state.doctorPrintList['opt_doctor'][index]['label'] === 'Full Report' && this.state.doctorPrintList['opt_doctor'][index]['is_checked']) {
                            this.state.doctorPrintList?.opt_doctor?.forEach(element => element.is_checked = true)
                            this.setState({ doctorPrintList: this.state.doctorPrintList })
                        } else {
                            this.state.doctorPrintList?.opt_doctor?.forEach(element => element.is_checked = false)
                            this.setState({ doctorPrintList: this.state.doctorPrintList })
                        }
                    })
                } else {
                    this.state.doctorPrintList['opt_doctor'][index]['is_checked'] = value
                    this.setState({ doctorPrintList: this.state.doctorPrintList },() => {
                        let filterArray = this.state.doctorPrintList?.opt_doctor?.filter(item => item?.label !== 'Full Report')
                        let checkAll = filterArray?.every(item => item.is_checked)
                        this.state.doctorPrintList?.opt_doctor?.forEach(element => {
                            if(element.label === 'Full Report' && checkAll){
                                element.is_checked = true
                            }else if(!checkAll && element.label === 'Full Report'){
                                element.is_checked = false
                            }
                        })
                        this.setState({ doctorPrintList: this.state.doctorPrintList })
                    })
                }

            } else {
                if (this.state.doctorPrintList['other_doctor'][index]['label'] === 'Full Report') {
                    this.state.doctorPrintList['other_doctor'][index]['is_checked'] = value
                    this.setState({ doctorPrintList: this.state.doctorPrintList }, () => {
                        if (this.state.doctorPrintList['other_doctor'][index]['label'] === 'Full Report' && this.state.doctorPrintList['other_doctor'][index]['is_checked']) {
                            this.state.doctorPrintList?.other_doctor?.forEach(element => element.is_checked = true)
                            this.setState({ doctorPrintList: this.state.doctorPrintList })
                        } else {
                            this.state.doctorPrintList?.other_doctor?.forEach(element => element.is_checked = false)
                            this.setState({ doctorPrintList: this.state.doctorPrintList })
                        }
                    })
                } else {
                    this.state.doctorPrintList['other_doctor'][index]['is_checked'] = value
                    this.setState({ doctorPrintList: this.state.doctorPrintList },() => {
                        let filterArray = this.state.doctorPrintList?.other_doctor?.filter(item => item?.label !== 'Full Report')
                        let checkAll = filterArray?.every(item => item.is_checked)
                        this.state.doctorPrintList?.other_doctor?.forEach(element => {
                            if(element.label === 'Full Report' && checkAll){
                                element.is_checked = true
                            }else if(!checkAll && element.label === 'Full Report'){
                                element.is_checked = false
                            }
                        })
                        this.setState({ doctorPrintList: this.state.doctorPrintList })
                    })
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkData = () => {
        try{
            let message = null;
            if(this.state.loginInfo?.service_type === 'OPT'){
                let check = this.state.doctorPrintList?.opt_doctor?.some(item => item?.is_checked)
                if(!check){
                    message = 'Please Select atleast one for print'
                }
            }else{
                let check = this.state.doctorPrintList?.other_doctor?.some(item => item?.is_checked)
                if(!check){
                    message = 'Please Select atleast one for print'
                }
            }
            return message;
        }catch(error){
            this.errorMessage(error.message)
        }
    }

    renderCheckbox = (key) => {
        try{
            return (
                <Checkbox 
                onClick={(event)=>{
                    switch (key) {
                        case "auto_fetch_prescription_drug": this.setState({ auto_fetch_prescription_drug: event.target.checked })
                    }
                }}  
                checked={key === "auto_fetch_prescription_drug" ? this.state.auto_fetch_prescription_drug : false } /> 
            )
        }
        catch(e){
            this.errorMessage(e.message)
        }
    }

    render() {
        return (
            <Box component={"div"} className='eMed_refdoc_container' style={{ backgroundColor: "white" }} p={'0.5vw'}>
                <Box component={'div'} border={'1px solid lightgray'} borderRadius={'10px'} height={'68vh'}>
                    <Box component={'div'} height={'3vw'} borderBottom={'1px solid lightgray'} display={'flex'} alignItems={'center'} pl={'0.5vw'}>
                        <Typography fontWeight={'600'}>Doctor Notes Configuration</Typography>
                    </Box>
                    <Box component={'div'} height={'60vh'} overflow={'auto'} p={'0.5vw'}>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ padding: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Duration for Doctor Notes Lock Period ?</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                {this.inputTextBox('lockPeriod', this.state.lockPeriod, '', true, 'Days', '10vw')}
                                <Typography mt={"0.25vw"} fontSize={'0.8vw'} color={'gray'}>*3 days is the default lock period</Typography>
                            </Box>
                        </Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={'.5vw'} style={{ padding: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>{this.state.isOPT ? 'Medication Search Based On' : 'Prescription Search Based On'}</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                <Select
                                    size='small'
                                    value={this.state.drug_search}
                                    onChange={(e) => this.setState({ drug_search: e.target.value })}
                                    sx={{ width: '10vw' }}
                                >
                                    {SEARCH_DRUG_NAME?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </Box>
                        </Box>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={'.5vw'} style={{ padding: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Prescription - Auto-fetch Previous Drugs</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                {this.renderCheckbox("auto_fetch_prescription_drug")}
                            </Box>
                        </Box>
                        <Box component={'div'} mt={'.5vw'}>
                            <Accordion elevation={1}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={"100%"}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>Select default option for Print</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box component={'div'} display={'flex'} flexWrap={'wrap'} >
                                        {this.state.loginInfo?.service_type === 'OPT' ?
                                            this.state.doctorPrintList?.opt_doctor?.map((item, index) => {
                                                return (
                                                    <Box component={'div'} key={index} width={'15vw'}>
                                                        <FormControlLabel checked={item.is_checked} onChange={(e) => this.onChangeCheckBox(e, index, item?.label)} control={<Checkbox size='small' />} label={item.label === 'Full Report' ? <Typography fontWeight={600}>{item?.label}</Typography> : item?.label} />
                                                    </Box>
                                                )
                                            }) :
                                            this.state.doctorPrintList?.other_doctor?.map((item, index) => {
                                                return (
                                                    <Box component={'div'} key={index} width={'15vw'}>
                                                        <FormControlLabel checked={item.is_checked} onChange={(e) => this.onChangeCheckBox(e, index, item?.label)} control={<Checkbox size='small' />} label={item.label === 'Full Report' ? <Typography fontWeight={600}>{item?.label}</Typography> : item?.label} />
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        {/* <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ paddingLeft: "0.5vw", marginBottom: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Late Transmit Summary SMS</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <RadioGroup
                                    row
                                    value={this.state.transmitSms}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ transmitSms: value })
                                    }}
                                >
                                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Box> */}

                        {/* <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ paddingLeft: "0.5vw", marginTop: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Append Summary SMS</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <RadioGroup
                                    value={this.state.summarySms}
                                    row
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ summarySms: value })
                                    }}
                                >
                                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
                <Box component={'div'} height={'3vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                        variant='outlined'
                        onClick={() => this.onClearHandler()}
                    >Reset</Button>
                    <Button
                        sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                        variant='contained'
                        onClick={() => {
                            let checkValues = this.checkData()
                            if(checkValues === null){
                                this.postDoctorConfigList()
                            }else{
                                this.errorMessage(checkValues)
                            }
                        }}
                    >Save</Button>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}