import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Modal, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ShowComponents from './ShowComponent';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default class DocOtherInvestigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: this.props.clinicId,
      patientId: this.props.patientId,
      appointmentId: this.props.appointmentId,
      appointmentDate: this.props.appointmentDate,
      openPopUp: false,
      postLoading: false,

      rightEye: false,
      lefytEye: false,
      normal: false,

      WFDTRightEye: "",
      EOMRightEye: "",
      ColorVisionRightEye: "",
      ConfrontationRightEye: "",
      PupilRightEye: "",
      PachymentryRightEye: "",
      SchirmerRightEye: "",
      ContrastRightEye: "",
      SyringeRightEye:"",
      AdditionalRightEye:"",
      DuctRightEye:"",

      WFDTLeftEye: "",
      EOMLeftEye: "",
      ColorVisionLeftEye: "",
      ConfrontationLeftEye: "",
      PupilLeftEye: "",
      PachymentryLeftEye: "",
      SchirmerLeftEye: "",
      ContrastLeftEye: "",
      SyringeLeftEye: "",
      AdditionalLeftEye:"",
      DuctLeftEye:"",

      PreviousData: [],
      currentData: [],

      WFDTSuggRE: [],
      WFDTSuggLE: [],
      EOMSuggRE: [],
      EOMSuggLE: [],
      ColorVisionSuggRE: [],
      ColorVisionSuggLE: [],
      ConfrontationSuggRE: [],
      ConfrontationSuggLE: [],
      PupilSuggRE: [],
      PupilSuggLE: [],
      PachymentrySuggRE: [],
      PachymentrySuggLE: [],
      SchirmerSuggRE: [],
      SchirmerSuggLE: [],
      ContrastSuggRE: [],
      ContrastSuggLE: [],
      SyringeRE: [],
      SyringeLE: [],
      AdditionalLE:[],
      AdditionalRE:[],
      DuctLE:[],
      DuctRE:[],
      addCustomBox : []
    }
  }

  apiCalls = () => {
    this.getOtherInvestigation()
    this.getPreviousInvestigation()
    this.getInvestPerviousValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  getOtherInvestigation = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OTHER_INVESTIGATION + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
        .then((response) => {
          if (response.data.status === 'success') {
            let Investigation = response.data?.data ? response.data?.data : []
            if (Investigation.length > 0) {
              for (let i = 0; i < Investigation.length; i++) {
                if (Investigation[i].eye_type == "RE") {
                  var states = this.state
                  states["WFDTRightEye"] = Investigation[i].wfdt;
                  states["EOMRightEye"] = Investigation[i].eom;
                  states["ColorVisionRightEye"] = Investigation[i].colour_vision;
                  states["ConfrontationRightEye"] = Investigation[i].confrontation;
                  states["PupilRightEye"] = Investigation[i].pupil;
                  states["PachymentryRightEye"] = Investigation[i].pachymentry;
                  states["SchirmerRightEye"] = Investigation[i].schirmers_test;
                  states["ContrastRightEye"] = Investigation[i].contrast_sensitivity;
                  states['SyringeRightEye'] = Investigation[i].syringe;
                  states['AdditionalRightEye'] = Investigation[i].additional_details;
                  states['DuctRightEye'] = Investigation[i].duct;
                  this.setState({ states })
                } else if (Investigation[i].eye_type == "LE") {
                  var states = this.state
                  states["WFDTLeftEye"] = Investigation[i].wfdt;
                  states["EOMLeftEye"] = Investigation[i].eom;
                  states["ColorVisionLeftEye"] = Investigation[i].colour_vision;
                  states["ConfrontationLeftEye"] = Investigation[i].confrontation;
                  states["PupilLeftEye"] = Investigation[i].pupil;
                  states["PachymentryLeftEye"] = Investigation[i].pachymentry;
                  states["SchirmerLeftEye"] = Investigation[i].schirmers_test;
                  states["ContrastLeftEye"] = Investigation[i].contrast_sensitivity;
                  states['SyringeLeftEye'] = Investigation[i].syringe;
                  states['AdditionalLeftEye'] = Investigation[i].additional_details;
                  states['DuctLeftEye'] = Investigation[i].duct;
                  this.setState({ states })
                }
              }
              this.setState({
                currentData: Investigation,
                addCustomBox: Investigation[0].custom_column || [],
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPreviousInvestigation = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PREVIOUS_INVESTIGATION + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}&doctor_id=${this.props.doctorID}`)
        .then((response) => {
          if (response.data.status === 'success') {
            let Investigation = response.data?.data ? response.data?.data : []
            if (Investigation.length > 0) {
              for (let i = 0; i < Investigation.length; i++) {
                if (Investigation[i].eye_type == "RE") {
                  var states = this.state
                  states["PreWFDTRightEye"] = Investigation[i].wfdt;
                  states["PreEOMRightEye"] = Investigation[i].eom;
                  states["PreColorVisionRightEye"] = Investigation[i].colour_vision;
                  states["PreConfrontationRightEye"] = Investigation[i].confrontation;
                  states["PrePupilRightEye"] = Investigation[i].pupil;
                  states["PrePachymentryRightEye"] = Investigation[i].pachymentry;
                  states["PreSchirmerRightEye"] = Investigation[i].schirmers_test;
                  states["PreContrastRightEye"] = Investigation[i].contrast_sensitivity;
                  states['PreSyringeRightEye'] = Investigation[i].syringe;
                  states['PreAdditionalRightEye'] = Investigation[i].additional_details;
                  states['PreDuctRightEye'] = Investigation[i].duct;
                  this.setState({ states })
                } else if (Investigation[i].eye_type == "LE") {
                  var states = this.state
                  states["PreWFDTLeftEye"] = Investigation[i].wfdt;
                  states["PreEOMLeftEye"] = Investigation[i].eom;
                  states["PreColorVisionLeftEye"] = Investigation[i].colour_vision;
                  states["PreConfrontationLeftEye"] = Investigation[i].confrontation;
                  states["PrePupilLeftEye"] = Investigation[i].pupil;
                  states["PrePachymentryLeftEye"] = Investigation[i].pachymentry;
                  states["PreSchirmerLeftEye"] = Investigation[i].schirmers_test;
                  states["PreContrastLeftEye"] = Investigation[i].contrast_sensitivity;
                  states['PreSyringeLeftEye'] = Investigation[i].syringe;
                  states['PreAdditionalLeftEye'] = Investigation[i].additional_details;
                  states['PreDuctLeftEye'] = Investigation[i].duct;
                  this.setState({ states })
                }
              }
              this.setState({
                PreviousData: Investigation
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getInvestPerviousValue = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OTHER_INVESTIGATION_SUGGESTION)
        .then((response) => {
          if (response.data.status === 'success') {
            const Data = response.data?.data;
            const newState = {
              WFDTSuggLE: new Set(),
              WFDTSuggRE: new Set(),
              EOMSuggRE: new Set(),
              EOMSuggLE: new Set(),
              ColorVisionSuggRE: new Set(),
              ColorVisionSuggLE: new Set(),
              ConfrontationSuggRE: new Set(),
              ConfrontationSuggLE: new Set(),
              PupilSuggRE: new Set(),
              PupilSuggLE: new Set(),
              PachymentrySuggRE: new Set(),
              PachymentrySuggLE: new Set(),
              SchirmerSuggRE: new Set(),
              SchirmerSuggLE: new Set(),
              ContrastSuggRE: new Set(),
              ContrastSuggLE: new Set(),
              SyringeLE: new Set(),
              SyringeRE: new Set(),
              AdditionalLE: new Set(),
              AdditionalRE: new Set(),
              DuctLE: new Set(),
              DuctRE: new Set()
            };

            Data.forEach(item => {
              if (item.eye_type === 'RE') {
                newState.WFDTSuggRE.add(item.wfdt);
                newState.EOMSuggRE.add(item.eom);
                newState.ColorVisionSuggRE.add(item.colour_vision);
                newState.ConfrontationSuggRE.add(item.confrontation);
                newState.PupilSuggRE.add(item.pupil);
                newState.PachymentrySuggRE.add(item.pachymentry);
                newState.SchirmerSuggRE.add(item.schirmers_test);
                newState.ContrastSuggRE.add(item.contrast_sensitivity);
                newState.SyringeRE.add((item.syringe !== null && item.syringe !== '') ? item?.syringe : '');
                newState.AdditionalRE.add((item.additional_details !== null && item.additional_details !== '') ? item?.additional_details : '');
                newState.DuctRE.add((item.duct !== null && item.duct !== '') ? item?.duct : '');
              } else if (item.eye_type === 'LE') {
                newState.WFDTSuggLE.add(item.wfdt);
                newState.EOMSuggLE.add(item.eom);
                newState.ColorVisionSuggLE.add(item.colour_vision);
                newState.ConfrontationSuggLE.add(item.confrontation);
                newState.PupilSuggLE.add(item.pupil);
                newState.PachymentrySuggLE.add(item.pachymentry);
                newState.SchirmerSuggLE.add(item.schirmers_test);
                newState.ContrastSuggLE.add(item.contrast_sensitivity);
                newState.SyringeLE.add((item.syringe !== null && item.syringe !== '') ? item?.syringe : '');
                newState.AdditionalLE.add((item.additional_details !== null && item.additional_details !== '') ? item?.additional_details : '');
                newState.DuctLE.add((item.duct !== null && item.duct !== '') ? item?.duct : '');
              }
            });

            Object.keys(newState).forEach(key => {
              newState[key] = Array.from(newState[key]);
            });

            this.setState(newState);
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PostPosteriorSegement = () => {
    try {
      const data = new FormData();
      var states = this.state
      let lastItem = states.addCustomBox[states.addCustomBox.length - 1]; 
      let validate = states.addCustomBox.length > 0 && (!lastItem.le && !lastItem.re);
      if (validate) {
          this.errorMessage('Please add or remove the extra column.')
      } else {
        data.append('appointment_id', states.appointmentId);
        data.append('patient_id', states.patientId);
        data.append('doctor_id', this.props.doctorID);
        data.append('le_wfdt', states.WFDTLeftEye);
        data.append('le_eom', states.EOMLeftEye);
        data.append('le_colour_vision', states.ColorVisionLeftEye);
        data.append('le_confrontation', states.ConfrontationLeftEye);
        data.append('le_pupil', states.PupilLeftEye);
        data.append('le_pachymentry', states.PachymentryLeftEye);
        data.append('le_schirmers_test', states.SchirmerLeftEye);
        data.append('le_contrast_sensitivity', states.ContrastLeftEye);
        data.append('le_anterior_image_key', "");
        data.append('le_image_json', "");
        data.append('le_syringe', states.SyringeLeftEye);
        data.append('le_additional_details', states.AdditionalLeftEye);
        data.append('le_duct', states.DuctLeftEye);
        data.append('re_wfdt', states.WFDTRightEye);
        data.append('re_eom', states.EOMRightEye);
        data.append('re_colour_vision', states.ColorVisionRightEye);
        data.append('re_confrontation', states.ConfrontationRightEye);
        data.append('re_pupil', states.PupilRightEye);
        data.append('re_pachymentry', states.PachymentryRightEye);
        data.append('re_schirmers_test', states.SchirmerRightEye);
        data.append('re_contrast_sensitivity', states.ContrastRightEye);
        data.append('re_syringe', states.SyringeRightEye);
        data.append('re_additional_details', states.AdditionalRightEye);
        data.append('re_duct', states.DuctRightEye);
        data.append('custom_column', JSON.stringify(states.addCustomBox));
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_OTHER_INVESTIGATION)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.getOtherInvestigation()
              this.getInvestPerviousValue()
              this.setState({ postLoading: false, openPopUp: false })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  CopyPreviousData = () => {
    var states = this.state
    states["WFDTLeftEye"] = states.PreWFDTLeftEye ? states.PreWFDTLeftEye : "";
    states["EOMLeftEye"] = states.PreEOMLeftEye ? states.PreEOMLeftEye : "";
    states["ColorVisionLeftEye"] = states.PreColorVisionLeftEye ? states.PreColorVisionLeftEye : "";
    states["ConfrontationLeftEye"] = states.PreConfrontationLeftEye ? states.PreConfrontationLeftEye : "";
    states["PupilLeftEye"] = states.PrePupilLeftEye ? states.PrePupilLeftEye : "";
    states["PachymentryLeftEye"] = states.PrePachymentryLeftEye ? states.PrePachymentryLeftEye : "";
    states["SchirmerLeftEye"] = states.PreSchirmerLeftEye ? states.PreSchirmerLeftEye : "";
    states["ContrastLeftEye"] = states.PreContrastLeftEye ? states.PreContrastLeftEye : "";
    states["WFDTRightEye"] = states.PreWFDTRightEye ? states.PreWFDTRightEye : "";
    states["EOMRightEye"] = states.PreEOMRightEye ? states.PreEOMRightEye : "";
    states["ColorVisionRightEye"] = states.PreColorVisionRightEye ? states.PreColorVisionRightEye : "";
    states["ConfrontationRightEye"] = states.PreConfrontationRightEye ? states.PreConfrontationRightEye : "";
    states["PupilRightEye"] = states.PrePupilRightEye ? states.PrePupilRightEye : "";
    states["PachymentryRightEye"] = states.PrePachymentryRightEye ? states.PrePachymentryRightEye : "";
    states["SchirmerRightEye"] = states.PreSchirmerRightEye ? states.PreSchirmerRightEye : "";
    states["ContrastRightEye"] = states.PreContrastRightEye ? states.PreContrastRightEye : "";
    states['SyringeLeftEye'] = states.PreSyringeLeftEye ? states.PreSyringeLeftEye : "";
    states['SyringeRightEye'] = states.PreSyringeRightEye ? states.PreSyringeRightEye : "";
    states['AdditionalLeftEye'] = states.PreAdditionalLeftEye ? states.PreAdditionalLeftEye : "";
    states['AdditionalRightEye'] = states.PreAdditionalRightEye ? states.PreAdditionalRightEye : "";
    states['DuctLeftEye'] = states.PreDuctLeftEye ? states.PreDuctLeftEye : "";
    states['DuctRightEye'] = states.PreDuctRightEye ? states.PreDuctRightEye : "";
    states['addCustomBox'] = states.PreviousData[0]?.custom_column?.length > 0 ? states.PreviousData[0].custom_column : []
    this.setState({ states }, () => { this.PostPosteriorSegement() })
  }

  copyCheckBoxValues(key) {
    if (key == "rightEye") {
      if (this.state.lefytEye) {
        this.setState({ rightEye: !this.state.rightEye, normal: true }, () => {
          if (!this.state.rightEye) {
            this.setState({ normal: false }, () => { this.copyValues("rightEye") })
          } else {
            this.copyValues("rightEye")
          }
        })
      } else {
        this.setState({ rightEye: !this.state.rightEye }, () => { this.copyValues("rightEye") })
      }
    } else if (key == "leftEye") {
      if (this.state.rightEye) {
        this.setState({ lefytEye: !this.state.lefytEye, normal: true }, () => {
          if (!this.state.lefytEye) {
            this.setState({ normal: false }, () => { this.copyValues("leftEye") })
          } else {
            this.copyValues("leftEye")
          }
        })
      } else {
        this.setState({ lefytEye: !this.state.lefytEye }, () => { this.copyValues("leftEye") })
      }
    } else if (key == "normal") {
      if (this.state.rightEye && this.state.lefytEye) {
        this.setState({
          rightEye: false,
          lefytEye: false,
          normal: false
        }, () => { this.copyValues("normal") })
      } else {
        this.setState({
          rightEye: true,
          lefytEye: true,
          normal: true
        }, () => { this.copyValues("normal") })
      }
    }
  }

  copyValues = (type) => {
    if (type == "leftEye") {
      if (this.state.lefytEye) {
        var states = this.state
        states["WFDTLeftEye"] = "Normal";
        states["EOMLeftEye"] = "Normal";
        states["ColorVisionLeftEye"] = "Normal";
        states["ConfrontationLeftEye"] = "Normal";
        states["PupilLeftEye"] = "Normal";
        states["PachymentryLeftEye"] = "Normal";
        states["SchirmerLeftEye"] = "Normal";
        states["ContrastLeftEye"] = "Normal";
        states["SyringeLeftEye"] = "Normal";
        states["AdditionalLeftEye"] = "Normal";
        states["DuctLeftEye"] = "Normal";
        this.setState({ states })
      } else {
        var states = this.state
        states["WFDTLeftEye"] = "";
        states["EOMLeftEye"] = "";
        states["ColorVisionLeftEye"] = "";
        states["ConfrontationLeftEye"] = "";
        states["PupilLeftEye"] = "";
        states["PachymentryLeftEye"] = "";
        states["SchirmerLeftEye"] = "";
        states["ContrastLeftEye"] = "";
        states["SyringeLeftEye"] = "";
        states["AdditionalLeftEye"] = "";
        states["DuctLeftEye"] = "";
        this.setState({ states })
      }
    } else if (type == "rightEye") {
      if (this.state.rightEye) {
        var states = this.state
        states["WFDTRightEye"] = "Normal";
        states["EOMRightEye"] = "Normal";
        states["ColorVisionRightEye"] = "Normal";
        states["ConfrontationRightEye"] = "Normal";
        states["PupilRightEye"] = "Normal";
        states["PachymentryRightEye"] = "Normal";
        states["SchirmerRightEye"] = "Normal";
        states["ContrastRightEye"] = "Normal";
        states["SyringeRightEye"] = "Normal";
        states["AdditionalRightEye"] = "Normal"
        states["DuctRightEye"] = "Normal"
        this.setState({ states })
      } else {
        var states = this.state
        states["WFDTRightEye"] = "";
        states["EOMRightEye"] = "";
        states["ColorVisionRightEye"] = "";
        states["ConfrontationRightEye"] = "";
        states["PupilRightEye"] = "";
        states["PachymentryRightEye"] = "";
        states["SchirmerRightEye"] = "";
        states["ContrastRightEye"] = "";
        states["SyringeRightEye"] = "";
        states["AdditionalRightEye"] = ""
        states["DuctRightEye"] = ""
        this.setState({ states })
      }
    } else if (type == "normal") {
      if (this.state.normal) {
        var states = this.state;
        states["WFDTRightEye"] = "Normal";
        states["EOMRightEye"] = "Normal";
        states["ColorVisionRightEye"] = "Normal";
        states["ConfrontationRightEye"] = "Normal";
        states["PupilRightEye"] = "Normal";
        states["PachymentryRightEye"] = "Normal";
        states["SchirmerRightEye"] = "Normal";
        states["ContrastRightEye"] = "Normal";
        states["SyringeRightEye"] = "Normal";
        states["AdditionalRightEye"] = "Normal"
        states["DuctRightEye"] = "Normal"

        states["WFDTLeftEye"] = "Normal";
        states["EOMLeftEye"] = "Normal";
        states["ColorVisionLeftEye"] = "Normal";
        states["ConfrontationLeftEye"] = "Normal";
        states["PupilLeftEye"] = "Normal";
        states["PachymentryLeftEye"] = "Normal";
        states["SchirmerLeftEye"] = "Normal";
        states["ContrastLeftEye"] = "Normal";
        states["SyringeLeftEye"] = "Normal";
        states["AdditionalLeftEye"] = "Normal";
        states["DuctLeftEye"] = "Normal";
        this.setState({ states })
      } else {
        var states = this.state;
        states["WFDTRightEye"] = "";
        states["EOMRightEye"] = "";
        states["ColorVisionRightEye"] = "";
        states["ConfrontationRightEye"] = "";
        states["PupilRightEye"] = "";
        states["PachymentryRightEye"] = "";
        states["SchirmerRightEye"] = "";
        states["ContrastRightEye"] = "";
        states["SyringeRightEye"] = "";
        states["AdditionalRightEye"] = ""
        states["DuctRightEye"] = ""

        states["WFDTLeftEye"] = "";
        states["EOMLeftEye"] = "";
        states["ColorVisionLeftEye"] = "";
        states["ConfrontationLeftEye"] = "";
        states["PupilLeftEye"] = "";
        states["PachymentryLeftEye"] = "";
        states["SchirmerLeftEye"] = "";
        states["ContrastLeftEye"] = "";
        states["SyringeLeftEye"] = "";
        states["AdditionalLeftEye"] = "";
        states["DuctLeftEye"] = "";
        this.setState({ states, addCustomBox: [], })
      }
    }
  }

  renderPosteriorSegmentTable = () => {    
    let states = this.state
    let lastItem = states.addCustomBox[states.addCustomBox.length - 1]; 
    let dis = states.addCustomBox.length > 0 && (!lastItem.le && !lastItem.re); 
    return (
      <Box width={"50vw"} marginLeft={"1.5vw"}>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontWeight={600} flex={0.2}></Typography>
          <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Right Eye</Typography>
          <Typography fontWeight={600} flex={0.4} paddingLeft={"7vw"}>Left Eye</Typography>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>WFDT</Typography>
          <Box flex={0.4}>{this.renderTextBox("WFDTRightEye", this.state.WFDTSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("WFDTLeftEye", this.state.WFDTSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>EOM</Typography>
          <Box flex={0.4}>{this.renderTextBox("EOMRightEye", this.state.EOMSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("EOMLeftEye", this.state.EOMSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Color Vision</Typography>
          <Box flex={0.4}>{this.renderTextBox("ColorVisionRightEye", this.state.ColorVisionSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("ColorVisionLeftEye", this.state.ColorVisionSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Confrontation</Typography>
          <Box flex={0.4}>{this.renderTextBox("ConfrontationRightEye", this.state.ConfrontationSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("ConfrontationLeftEye", this.state.ConfrontationSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Pupil</Typography>
          <Box flex={0.4}>{this.renderTextBox("PupilRightEye", this.state.PupilSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("PupilLeftEye", this.state.PupilSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Pachymetry</Typography>
          <Box flex={0.4}>{this.renderTextBox("PachymentryRightEye", this.state.PachymentrySuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("PachymentryLeftEye", this.state.PachymentrySuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Schirmer's Test</Typography>
          <Box flex={0.4}>{this.renderTextBox("SchirmerRightEye", this.state.SchirmerSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("SchirmerLeftEye", this.state.SchirmerSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Contrast Sensitivity</Typography>
          <Box flex={0.4}>{this.renderTextBox("ContrastRightEye", this.state.ContrastSuggRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("ContrastLeftEye", this.state.ContrastSuggLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Syringe</Typography>
          <Box flex={0.4}>{this.renderTextBox("SyringeRightEye", this.state.SyringeRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("SyringeLeftEye", this.state.SyringeLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Additional Details</Typography>
          <Box flex={0.4}>{this.renderTextBox("AdditionalRightEye", this.state.AdditionalRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox("AdditionalLeftEye", this.state.AdditionalLE)}</Box>
        </Box>
        <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
          <Typography fontWeight={600} flex={0.2}>Duct</Typography>
          <Box flex={0.4}>{this.renderTextBox('DuctRightEye', this.state.DuctRE)}</Box>
          <Box flex={0.4}>{this.renderTextBox('DuctLeftEye', this.state.DuctLE)}</Box>
        </Box>
        {this.renderCustomBox()}
        {states.addCustomBox.length < 5 ?
        <Button size='small' disabled={dis || this.state.isLocked || this.props.isLocked} variant='contained' startIcon={<AddCircleIcon />} sx={{textTransform: 'capitalize'}}
        onClick={()=>{
            let newBox = {
              label: "",
              le: "",
              re: ""
            };
        
            this.setState((prevState) => ({
              addCustomBox: [...prevState.addCustomBox, newBox]
            }));
        }} >Add</Button> : null}
      </Box>
    )
  }

  renderCustomBox = () => {
    return (
      <Box>
        {this.state.addCustomBox?.length > 0 ?
          this.state.addCustomBox.map((item, index) => (
            <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} marginY={"0.5vw"}>
              <Box flex={0.2}>{this.renderAddTextBox('Name', item.label, '90%', false, index, 'label', false)}</Box>
              <Box flex={0.4}>{this.renderAddTextBox('Type Remark', item.le, '64%', false, index, 'le', !item.label ? true: false)}</Box>
              <Box flex={0.4}>{this.renderAddTextBox('Type Remark', item.re, '64%', true, index, 're', !item.label ? true: false)}</Box>
            </Box>
          )) : null}
      </Box>
    )
  }

  renderAddTextBox = (label, val, width, isDel = false, i, key, list = false) => {
    let states = this.state
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <TextField
          size='small'
          variant="outlined"
          placeholder={label}
          value={val}
          disabled={list}
          sx={{ width: width }}
          inputProps={{ maxLength: 50 }}
          onChange={(event) => {
            let updatedBoxes = [...states.addCustomBox];
            updatedBoxes[i][key] = event.target.value; 
            this.setState({
              addCustomBox: updatedBoxes
            });
          }}
  
        />
        {isDel ? <Box flex={0.1} component={'img'} src={ImagePaths.DeleteOutline.default} 
        sx={{ width: '1.2vw', height: '1.2vw', marginLeft: '1vw', display: 'flex', alignSelf: 'center', cursor: 'pointer' }} alt='delete'
        onClick={()=>{
          let updatedBoxes = [...states.addCustomBox];
            updatedBoxes.splice(i, 1);
            this.setState({ addCustomBox: updatedBoxes });
        }}></Box> : null}
      </Box>

    )
  }

  renderTextBox = (key, suggestion) => {
    let states = this.state
    const filteredOptions = suggestion.filter(option => option !== '');
    const autoCompleteOptions = filteredOptions.map((option) => ({ label: option }));
    return (
        <Autocomplete
            freeSolo
            size='small'
            sx={{ width: '80%' }}
            options={autoCompleteOptions}
            getOptionLabel={(option) => typeof (option) === 'string' ? option : option.label}
            value={states[key] ? states[key] : ""}
            onChange={(e, newValue) => {
                states[key] = newValue?.label
                this.setState({
                    states
                })
            }}
            clearIcon={false}
            renderInput={(params) => <TextField
                {...params}
                disabled={this.state.isLocked || this.props.isLocked}
                autoComplete="off"
                sx={{ width: '80%' }}
                size='small'
                placeholder='Type Remark'
                onChange={(e) => {
                    states[key] = e.target.value
                    this.setState({ states })
                }}
            />}
        />
    )
}

  render() {
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.white }} paddingBottom={"1vw"}>
          <Box component={'div'} height={'3vw'} sx={{ backgroundColor: Colors.Background }} display={'flex'} pl={'0.5vw'} alignItems={'center'} flexDirection={'row'}>
            <Typography fontWeight={600}>Other Investigation</Typography>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "1vw" }}>
              <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                <Tooltip placement='top' title='Copy Previous Investigation'>
                  <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                </Tooltip>
              </div>
            </div>
          </Box>
          <Box component={'div'} m={'0.5vw'}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={"space-between"}>
              <Box marginLeft={'2vw'}>
                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="cash_checkbox" checked={this.state.rightEye} onChange={(e) => { this.copyCheckBoxValues("rightEye") }} size='small' />} label={"Right Eye"} />
                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="upi_checkbox" checked={this.state.lefytEye} onChange={(e) => { this.copyCheckBoxValues("leftEye") }} size='small' />} label={"Left Eye"} />
                <FormControlLabel width={"10vw"} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.normal} onChange={(e) => { this.copyCheckBoxValues("normal") }} size='small' />} label={"Normal"} />
              </Box>
              <Box>
                <Button variant='outlined' size='small'
                  sx={{ textTransform: 'capitalize', marginRight: '1vw' }}
                  onClick={() => {
                    this.setState({
                      rightEye: false, lefytEye: false, normal: false
                    }, () => { this.copyValues("normal") })
                  }}>Clear</Button>
                <Button disabled={this.state.isLocked || this.props.isLocked} variant='contained' size='small' sx={{ textTransform: 'capitalize' }} onClick={() => { this.PostPosteriorSegement() }}>Save</Button>
              </Box>
            </Box>
            {this.renderPosteriorSegmentTable()}
          </Box>
          {
            this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null
          }
          {
            this.state.successMsg ?
              <ToastMsg
                severity={'success'}
                msg={this.state.successMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : null
          }
        </Box>
      </ShowComponents>
    )
  }
}