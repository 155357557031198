import { Autocomplete, Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import './AccountsReport.css'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents'
import { DataGrid } from '@mui/x-data-grid'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { CheckBox } from '@material-ui/icons'
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../../Utility/Constants'
import { DateTime } from 'luxon'
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next'
class DoctorShareReports extends Component {
    constructor(props) {
        super(props)
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const currentYear = new Date().getFullYear()
        const minDate = dayjs(`${currentYear}-07-01`)

        this.state = {
            doc_share_amt: 0,
            concession_amt: 0,
            docShareData: [],
            page: 0,
            pageSize: 10,
            FilterOpen: false,
            minDate: minDate,
            datePicker: yesterday,
            doctorName: [],
            serviceDoctorName: [],
            inPatientChecked: true,
            outPatientChecked: true,
            labChecked: true,
            RadiologyChecked: true,
            otChecked: true,
            doctorName: [],
            loading: false,
            moduleType: ["op", "ip"],
            printLoad: false,
            DatePickerOpen: false,
        }
    }

    componentDidMount() {
        this.getDocShareReportData()
        this.getFilterDocList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    handleClear = () => {

        this.setState({
            inPatientChecked: true,
            outPatientChecked: true,
            labChecked: true,
            RadiologyChecked: true,
            FilterOpen: false,
            otChecked: true,
            doctorName: [],
            datePicker: new Date().setDate(new Date().getDate() - 1),
            moduleType: ["ip", "op"]
        }, () => { this.getDocShareReportData() })
    }
    getDocShareReportData = () => {
        try {
            let states = this.state
            let datepic = new Date(states.datePicker)
            let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
            let docName = [];
            this.state.doctorName.forEach((item) => docName.push(item.doctor_id))
            this.setState({ loading: false})
            RestAPIService.getAccReport(Serviceurls.FO_REPORTS_ACCOUNTS_DOCTORSHARE + `?date=${formatDate}&type=${this.state.moduleType}&doctor_id=${docName}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            docShareData: response?.data?.data,
                            doc_share_amt: response.data.doc_share_amt,
                            concession_amt: response.data.concession_amt,
                            loading: false,
                        })
                    }
                }).catch((error) => {
                    this.setState({ loading: false})
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false})
            this.errorMessage(error.message)
        }
    }

    getDocShareReportDataPrint = () => {
        try {
            let states = this.state
            let datepic = new Date(states.datePicker)
            let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
            let docName = [];
            this.state.doctorName.forEach((item) => docName.push(item.doctor_id))

            RestAPIService.getAccReport(Serviceurls.FO_REPORTS_ACCOUNTS_DOCTORSHARE + `?date=${formatDate}&type=${this.state.moduleType}&doctor_id=${docName}&export_type=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ printLoad: false })
                }).catch((error) => {
                    this.setState({ printLoad: false })
                    this.errorMessage(error.response?.data?.message)
                })
        }
        catch (error) {
            this.setState({ printLoad: false })
            this.errorMessage(error.message)
        }
    }

    getFilterDocList = () => {
        try {
            this.setState({ loading: true})
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceDoctorName: response?.data?.data ? response?.data?.data : [],
                            loading: false,
                        })
                    }

                }).catch((error) => {
                    this.setState({ loading: false})
                    if (error?.response.data.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    }
                    else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            this.setState({ loading: false})
            this.errorMessage(error.message)
        }
    }
    gridToolBar = () => {
        var { t } = this.props
        var states = this.state
        let datepic = new Date(states.datePicker)
        let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
        let TextArray = [
            { label: "Date", value: formatDate }
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    handleCheckBox = (e, key) => {
        let states = this.state
        states[key] = e.target.checked
        let checked = []
        if (states.inPatientChecked) {
            checked.push('ip')
        }
        if (states.outPatientChecked) {
            checked.push('op')
        }
        this.state.moduleType = checked
        this.setState({
            inPatientChecked: this.state.inPatientChecked,
            outPatientChecked: this.state.outPatientChecked,
            moduleType: this.state.moduleType
        })

    }
    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button emed_tid={"FilterOpen_TestID01"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box component={'div'} p={'0.5vw'} maxHeight={'75dvh'} overflow={'auto'} >
                    <Box component={'div'} mb={'1vw'} display={'flex'} justifyContent={'flex-end'} mr={"0.5vw"}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                open={this.state.DatePickerOpen}
                                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                                value={this.state.datePicker}
                                minDate={this.state.minDate}
                                name={"datePicker"}
                                inputFormat='DD-MM-YYYY'
                                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                onChange={(value) => this.setState({ datePicker: value })}
                                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} inputProps={{ ...params.inputProps, 'emed_tid': 'datePicker_TestID_DocShare' }} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box component={'div'}>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            sx={{ width: "23.5vw" }}
                            options={this.state.serviceDoctorName}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                    {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'selectDoctor_TestID', }}
                                    label={t('select Doctor Name')}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_Filter_Header' >
                        <Typography>{t("Module Type")}</Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'}>
                        <FormControlLabel control={<Checkbox emed_tid={"inPatientChecked_TestID"} size="small" checked={this.state.inPatientChecked} onChange={(e) => { this.handleCheckBox(e, "inPatientChecked") }} />} label={t("In Patient")} />
                        <FormControlLabel control={<Checkbox emed_tid={"outPatientChecked_TestID"} size="small" checked={this.state.outPatientChecked} onChange={(e) => { this.handleCheckBox(e, "outPatientChecked") }} />} label={t("Out Patient")} />
                    </Box>

                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" mt={'50vh'} >
                    <Button emed_tid={"clear_TestID01"} variant='outlined' size="small"
                        onClick={() => {
                            this.handleClear()
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid={"search_TestID01"}
                        onClick={() => { this.setState({ FilterOpen: false }, () => this.getDocShareReportData()) }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    render() {
        const { t } = this.props
        this.state.docShareData?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: "doctor_name", headerName: "Doctor Name", flex: 0.166,
                renerCell: (params) => (<Box component={'div'} >{params?.row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params.row.doctor_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.doctor_name ? params?.row?.doctor_name : '-'}</Box>)
            },
            {
                field: "module_type", headerName: "Module Type", flex: 0.166,
                renderCell: (params) => (<Box component={'div'}>{params.row.module_type ? params.row.module_type : "-"}</Box>)
            },
            {
                field: "service_type", headerName: "Service Type", flex: 0.166,
                renderCell: (params) => (<Box component={'div'}>{params.row.service_type.length > 20 ?
                    <Tooltip placement='top' title={params.row.service_type}><div>{params.row.service_type.slice(0, 20) + "..."}</div></Tooltip>
                    : params.row.service_type ? params.row.service_type : "-"}</Box>)

            },
            {
                field: "count", headerName: "Count", flex: 0.166, type: 'number',
                renderCell: (params) => (<Box component={'div'}>{params.row.count.length > 20 ?
                    <Tooltip placement='top' title={params.row.count}><div>{params.row.count.slice(0, 20) + "..."}</div></Tooltip>
                    : params.row.count ? params.row.count : "-"}</Box>)

            },
            {
                field: "doctor_share", headerName: "Doctor Share", flex: 0.166, type: 'number',
                renderCell: (params) => (<Box component={'div'}>{params.row.doctor_share ? AmountFormat(params.row.doctor_share).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "concession", headerName: "Concession", flex: 0.166, type: 'number',
                renerCell: (params) => (<Box component={'div'}>{params.row.concession ? AmountFormat(params.row.concession).replace(`${CurrencySymbol}`, '') : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_Main_Container'>
                <Box component={'div'} className='eMed_Header_Container'>
                    <Box component={'div'} className='eMed_Header_Rgt_Content'>
                        {AmountsCard(t('Doctor Share'), this.state.doc_share_amt, false, 'white', false, 'white', 'DocShareTestID')}
                        {AmountsCard(t('Concession Amount'), this.state.concession_amt, false, 'white', false, 'white', 'concessionTestID')}
                    </Box>
                    <Box component={'div'} className='eMed_Header_Lft_Content'>
                        <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='flt_btn'
                            onClick={() => this.setState({ FilterOpen: true })}
                        >
                            <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                        </Button>
                        <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='prt_btn'
                            disabled={this.state.printLoad}
                            onClick={() => { this.setState({ printLoad: true }, () => this.getDocShareReportDataPrint()) }}
                        >
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Table_Content'>
                    <DataGrid
                        sx={{ backgroundColor: 'white' }}
                        columns={columns}
                        rows={this.state.docShareData}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Box className='eMed_conf_nodata'>
                                    {'No Records To Be Shown'}
                                </Box>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.loading}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(DoctorShareReports)
