import { Height } from '@material-ui/icons'
import { Box, Button, Drawer, TextField, Typography, Autocomplete, FormControlLabel, Checkbox, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { CheckBox } from '@mui/icons-material'
import { DateTime } from 'luxon'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions'
import { CurrencySymbol } from '../../../../Utility/Constants'
import { Colors } from '../../../../Styles/Colors'
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next'

class AccountsCreditSettlementReport extends Component {
  constructor(props) {
    super(props)
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentYear = new Date().getFullYear()
    const minDate = dayjs(`${currentYear}-04-01`)
    this.state = {
      total_settlement_amt: null,
      AccCreditSettlementReport: [],
      pageSize: 10,
      page: 0,
      FilterOpen: false,
      loading: false,
      minDate: minDate,
      datePicker: yesterday,
      outPatientCheckbox: true,
      inPatientCheckbox: true,
      labCheckbox: true,
      radiologyCheckbox: true,
      OTCheckbox: true,
      insuranceCreditCheckbox: true,
      corporateCreditCheckbox: true,
      patientCreditCheckbox: true,
      datePicker: DateTime.now().minus({ day: 1 }).toFormat('yyyy-MM-dd'),
      moduleType: ["ip", "op", "lab"],
      insurancetype: ["Insurance", "Corporate", "Patient"],
      printLoad: false,
      isLoader: false,
      DatePickerOpen: false,
    }
  }

  componentDidMount() {
    this.getAccCreditSettlementReport()
  }

  getAccCreditSettlementReport = () => {
    try {
      let states = this.state
      let datepic = new Date(states.datePicker)
      let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
      this.setState({ loading: true })
      RestAPIService.getAccReport(Serviceurls.FO_REPORTS_ACCOUNTS_CREDITSETTLEMENTREPORT + `?date=${formatDate}&dept=${this.state.moduleType ? this.state.moduleType : []}&type=${this.state.insurancetype ? this.state.insurancetype : []}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              AccCreditSettlementReport: response.data.data ? response.data.data : [],
              total_settlement_amt: response.data.total_settlement_amt,
              loading: false,
            })
          }
        }).catch((error) => {
          this.setState({ loading: false})
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })

    }
    catch (error) {
      this.setState({ loading: false})
      this.errorMessage(error.message)
    }
  }

  getAccCreditSettlementReportPrint = () => {
    let states = this.state
    let datepic = new Date(states.datePicker)
    let formatDate = DateTime.fromJSDate(datepic).toFormat('yyyy-MM-dd')
    try {
      RestAPIService.getAccReport(Serviceurls.FO_REPORTS_ACCOUNTS_CREDITSETTLEMENTREPORT + `?date=${formatDate}&dept=${this.state.moduleType ? this.state.moduleType : []}&type=${this.state.insurancetype ? this.state.insurancetype : []}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ printLoad: false })
        }).catch((error) => {
          this.setState({ printLoad: false })
          this.errorMessage(error.response?.data?.message)
        })
    }
    catch (error) {
      this.setState({ printLoad: false })
      this.errorMessage(error.message)
    }
  }

  gridToolBar = () => {
    var { t } = this.props
    var states = this.state
    let datepic = new Date(states.datePicker)
    let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
    let TextArray = [
      { label: "Date", value: formatDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  onClearHandler = () => {
    this.setState({
      outPatientCheckbox: true,
      inPatientCheckbox: true,
      labCheckbox: true,
      radiologyCheckbox: true,
      OTCheckbox: true,
      insuranceCreditCheckbox: true,
      corporateCreditCheckbox: true,
      patientCreditCheckbox: true,
      FilterOpen: false,
      datePicker: new Date().setDate(new Date().getDate() - 1),
      insurancetype: ["Insurance", "Corporate", "Patient"],
      moduleType: ["ip", "op", "lab"],
    }, () => { this.getAccCreditSettlementReport() })
  }

  handleCheckBox = (e, key) => {
    let states = this.state
    states[key] = e.target.checked
    let checkedModule = []
    let insurancetype = []
    if (states.outPatientCheckbox) {
      checkedModule.push('op')
    }
    if (states.inPatientCheckbox) {
      checkedModule.push('ip')
    }
    if (states.labCheckbox) {
      checkedModule.push('lab')
    }
    if (states.insuranceCreditCheckbox) {
      insurancetype.push('Insurance')
    }
    if (states.corporateCreditCheckbox) {
      insurancetype.push('Corporate')
    }
    if (states.patientCreditCheckbox) {
      insurancetype.push('Patient')
    }


    this.state.moduleType = checkedModule
    this.state.insurancetype = insurancetype
    this.setState({
      outPatientCheckbox: this.state.outPatientCheckbox,
      inPatientCheckbox: this.state.inPatientCheckbox,
      labCheckbox: this.state.labCheckbox,
      insuranceCreditCheckbox: this.state.insuranceCreditCheckbox,
      corporateCreditCheckbox: this.state.corporateCreditCheckbox,
      patientCreditCheckbox: this.state.patientCreditCheckbox,
      insurancetype: this.state.insurancetype,
      moduleType: this.state.moduleType
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button emed_tid={"LabCloseButton_TestID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box component={'div'} p={'0.5vw'} maxHeight={'75dvh'} >
          <Box component={'div'} mb={'1vw'} display={'flex'} justifyContent={'flex-end'} mr={'0.5vw'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.datePicker}
                name={"datePicker"}
                inputFormat='DD-MM-YYYY'
                minDate={this.state.minDate}
                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                onChange={(value) => this.setState({ datePicker: value })}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'datePicker_TestID_creditSettlement' }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
              />
            </LocalizationProvider>
          </Box>
          <Box className="eMed_Filter_Header" >
            <Typography>{t("Department")}</Typography>
          </Box>
          <Box  >
            <FormControlLabel control={<Checkbox emed_tid={"outPatientCheckbox_TestID"} checked={this.state.outPatientCheckbox} onChange={(e) => { this.handleCheckBox(e, "outPatientCheckbox") }} size='small' />} label={t("Out Patient")} />
            <FormControlLabel control={<Checkbox emed_tid={"inPatientCheckbox_TestID"} checked={this.state.inPatientCheckbox} onChange={(e) => { this.handleCheckBox(e, "inPatientCheckbox") }} size='small' />} label={t("In Patient")} />
            <FormControlLabel control={<Checkbox emed_tid={"labCheckbox_TestID"} checked={this.state.labCheckbox} onChange={(e) => { this.handleCheckBox(e, "labCheckbox") }} size='small' />} label={t("Lab")} />

          </Box>
          <Box className="eMed_Filter_Header">
            <Typography>{t("Type")}</Typography>
          </Box>
          <Box>
            <FormControlLabel control={<Checkbox emed_tid={"insuranceCreditCheckbox_TestID"} checked={this.state.insuranceCreditCheckbox} onChange={(e) => { this.handleCheckBox(e, "insuranceCreditCheckbox") }} size='small' />} label={t("Insurance Credit")} />
            <FormControlLabel control={<Checkbox emed_tid={"corporateCreditCheckbox_TestID"} checked={this.state.corporateCreditCheckbox} onChange={(e) => { this.handleCheckBox(e, "corporateCreditCheckbox") }} size='small' />} label={t("Corporate Credit")} />
            <FormControlLabel control={<Checkbox emed_tid={"patientCreditCheckbox_TestID"} checked={this.state.patientCreditCheckbox} onChange={(e) => { this.handleCheckBox(e, "patientCreditCheckbox") }} size='small' />} label={t("Patient Credit")} />
          </Box>
        </Box>
        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} mt={'42vh'}>
          <Button variant='outlined'
            sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }} emed_tid={"Clear_TestID"}
            onClick={() => this.onClearHandler()}
          >{t("Clear")}</Button>
          <Button variant='contained'
            sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }} emed_tid={"Search_TestID"}
            onClick={() => { this.setState({ FilterOpen: false }, () => this.getAccCreditSettlementReport()) }}
          >{t("Search")}</Button>
        </Box>
      </Box>
    )
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }


  render() {
    const { t } = this.props
    this.state.AccCreditSettlementReport?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'pt_name', headerName: "Patient Details", flex: 0.25,
        renderCell: (params) => {
          let patientName = params?.row?.pt_name + "/" + params?.row?.patient_age
          let accNumAndMob = params?.row?.uhid + "|" + params?.row?.mob_no
          let gender = params?.row?.pt_gender === 'm' ? "M" : params?.row?.pt_gender === 'f' ? 'F' : params?.row?.pt_gender === 't' ? 'T' : '-'
          return (
            <Box component={'div'} display={'flex'} emed_tid='pat_dtls'>
              {/* <Box component={'div'}>
                <Box component={'img'} mr={'0.5vw'} src={gender === 'M' ? ImagePaths.Male.default : ImagePaths.Female.default} height={'2.5vw'} width={'2.5vw'} borderRadius={'50px'} />
              </Box> */}
              <Box component={'div'}>
                {patientName ? patientName?.length > 15 ?
                  <Tooltip placement='top' title={patientName}><Typography fontSize={'0.9vw'} fontWeight={600}>{patientName?.slice(0, 15) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{patientName}</Typography> : '-'}
                {accNumAndMob ? accNumAndMob?.length > 15 ?
                  <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 15) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
              </Box>
            </Box>
          )
        }
      },
      {
        field: "department", headerName: "Department", flex: 0.25,
        renerCell: (params) => (<Box component={'div'} >{params?.row?.department ? params?.row?.department : '-'}</Box>)
      },
      {
        field: "payment_against", headerName: "Type", flex: 0.25,
        renerCell: (params) => (<Box component={'div'} >{params?.row?.payment_against ? params?.row?.payment_against : '-'}</Box>)
      },
      {
        field: "amount", headerName: "Amount", flex: 0.25, headerAlign: 'right', align: 'right',
        renerCell: (params) => (<Box component={'div'}>{params.row.amount ? AmountFormat(params.row.amount).replace(`${CurrencySymbol}`, '') : "0"}</Box>)
      },
    ]

    return (
      <Box component={'div'} className='eMed_Main_Container'>
        <Box component={'div'} className='eMed_Header_Container'>
          <Box component={'div'} className='eMed_Header_Rgt_Content'>
            {AmountsCard(t("Total Settlement Amount"), this.state.total_settlement_amt, false, 'white', false, 'white', 'TotalSettlementAmount')}
          </Box>
          <Box component={'div'} className='eMed_Header_Lft_Content'>
            <Button emed_tid={"Filter_TestID"} className='Common_Btn_Min_Width eMed_Header_Btn' onClick={() => { this.setState({ FilterOpen: true }) }} >
              <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
            </Button>
            <Button emed_tid={"LabPrintIcon01_TestID"} className='Common_Btn_Min_Width eMed_Header_Btn'
              disabled={this.state.printLoad}
              onClick={() => { this.setState({ printLoad: true }, () => this.getAccCreditSettlementReportPrint()) }}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Table_Content'>
          <DataGrid
            sx={{ backgroundColor: 'white' }}
            columns={columns}
            rows={this.state.AccCreditSettlementReport}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Box className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Box>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.loading}
          />

        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>

    )
  }
}
export default withTranslation()(AccountsCreditSettlementReport)
