import React, { Component } from 'react'
import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { AmountFormat, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import Loader from '../../../../Components/Loader';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Colors } from '../../../../Styles/Colors';

const concessionList = ["Doctor", "Hospital"];
class ConcessionReports extends Component {
    constructor(props) {
        super(props)
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const currentYear = new Date().getFullYear()
        const minDate = dayjs(`${currentYear}-04-01`)
        this.state = {
            page: 0,
            pageSize: 10,
            dateIndex: 1,
            concessionData: [],
            datePicker: yesterday,
            doctorNameList: [],
            doctorName: null,
            minDate: minDate,
            DatePickerOpen: false,
            MobileNumber: "",
            UHID: "",
            Patient_name: "",
            total_concession: 0,
            concession_type: "",
            loading: false,
            printLoad: false
        }
    }

    componentDidMount() {
        this.getConcessionData()
        this.getDoctorNameList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    formatDate(input) {
        let datePart = input.match(/\d+/g),
            year = datePart[0].substring(0),
            month = datePart[1], day = datePart[2];

        return day + '-' + month + '-' + year;
    }

    gridToolBar = () => {
        var { t } = this.props
        var states = this.state
        let datepic = new Date(states.datePicker)
        let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
        let TextArray = [
            { label: "Date", value: formatDate }
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    getDoctorNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            doctorNameList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    getConcessionData = () => {
        try {
            let doctor_id = this.state.doctorName ? this.state.doctorName.doctor_id : "";
            let concession_type = this.state.concession_type ? this.state.concession_type : "";
            let startdate = new Date(this.state.datePicker);
            let date = DateTime.fromJSDate(startdate).toFormat("yyyy-MM-dd");
            this.setState({ loading: true })
            RestAPIService.getAccReport(`${Serviceurls.FO_REPORTS_ACCOUNTS_CONCESSION}?from_date=${date}&to_date=${date}&doctor_name=${doctor_id}&mobile_no=${this.state.MobileNumber}&uhid=${this.state.UHID}&patient_name=${this.state.Patient_name}&department=all&concession_type=${concession_type}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let list = response.data?.data?.length > 0 ? response.data?.data : [];
                        list.forEach((item, index) => {
                            item["concession_amount"] = item.doctor_concession ? item.doctor_concession : item.hosptial_concession;
                            item["sno"] = index + 1
                        })
                        this.setState({
                            concessionData: list,
                            total_concession: response?.data?.total_concession_amount,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    this.setState({ loading: false })
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    printReports = () => {
        let doctor_id = this.state.doctorName ? this.state.doctorName.doctor_id : "";
        let concession_type = this.state.concession_type ? this.state.concession_type : "";
        let startdate = new Date(this.state.datePicker);
        let date = DateTime.fromJSDate(startdate).toFormat("yyyy-MM-dd");
        try {
            RestAPIService.getAccReport(`${Serviceurls.FO_REPORTS_ACCOUNTS_CONCESSION}?from_date=${date}&to_date=${date}&doctor_id=${doctor_id}&mobile_no=${this.state.MobileNumber}&uhid=${this.state.UHID}&Patient_name=${this.state.Patient_name}&department=all&concession_type=${concession_type}&export_type=pdf`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        this.setState({ printLoad: false })
                    }
                }).catch((error) => {
                    this.setState({ printLoad: false })
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ printLoad: false })
            this.errorMessage(error.message)
        }
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button emed_tid={"LabCloseButton_TestID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box component={'div'} maxHeight={'75dvh'} overflow={'auto'} >
                    <Box mt={'1vw'} display={'flex'} justifyContent={'flex-end'} mr={'0.5vw'}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                open={this.state.DatePickerOpen}
                                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                                value={this.state.datePicker}
                                name={"datePicker"}
                                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                minDate={this.state.minDate}
                                inputFormat='DD-MM-YYYY'
                                views={["year", "month", "day"]}
                                onChange={
                                    (newDate) => {
                                        this.setState({
                                            datePicker: newDate,
                                        });
                                    }}
                                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Report_testid', }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            sx={{ width: "23.5vw" }}
                            inputProps={{ maxLength: 30, emed_tid: "Patient_Name_TestID" }}
                            size='small'
                            label={t("Patient Name")}
                            value={this.state.Patient_name}
                            autoComplete='off'
                            onChange={(e) => {
                                let number = CommonValidation.alphabetOnly(e.target.value);
                                if (number || e.target.value === "") {
                                    this.setState({ Patient_name: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            sx={{ width: "23.5vw" }}
                            inputProps={{ maxLength: 20, emed_tid: "UHID_TestID" }}
                            size='small'
                            label={t("UHID")}
                            value={this.state.UHID}
                            autoComplete='off'
                            onChange={(e) => {
                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                if (number || e.target.value === "") {
                                    this.setState({ UHID: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            sx={{ width: "23.5vw" }}
                            inputProps={{ maxLength: 10, emed_tid: "MobileNumber_TestID" }}
                            size='small'
                            label={t("MobileNumber")}
                            value={this.state.MobileNumber}
                            autoComplete='off'
                            onChange={(e) => {
                                let number = CommonValidation.numberOnly(e.target.value);
                                if ((number && e.target.value?.length <= 10) || e.target.value === "") {
                                    this.setState({ MobileNumber: e.target.value })
                                }
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            sx={{ width: "23.5vw" }}
                            disableClearable
                            options={this.state.doctorNameList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'doctor_name_testID', }}
                                    label={t('select Doctor Name')}
                                    autoComplete='off'
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                    {option?.doctor_name}
                                </li>
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            sx={{ width: "23.5vw" }}
                            disableClearable
                            options={concessionList}
                            getOptionLabel={(option) => (option)}
                            value={this.state.concession_type}
                            onChange={(event, newValue) => {
                                this.setState({ concession_type: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'emed_tid': 'concession_Type_TestID', }}
                                    label={t('Select Concession Type')}
                                    autoComplete='off'
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" mt={'31vh'}>
                    <Button variant='outlined' size="small" emed_tid={"Clear_TestID"}
                        onClick={() => {
                            this.setState({
                                datePicker: DateTime.now().minus({ day: 1 }).toFormat("yyyy-MM-dd"),
                                Patient_name: "",
                                MobileNumber: "",
                                UHID: "",
                                doctorName: null,
                                concession_type: "",
                                FilterOpen: false,
                            }, () => this.getConcessionData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid={"Search_TestID"}
                        onClick={() => { this.setState({ FilterOpen: false }, () => this.getConcessionData()) }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    render() {
        const { t } = this.props
        let { concessionData } = this.state;

        const columns = [
            {
                field: 'patient_name', headerName: "Patient Details", flex: 0.125,
                renderCell: ({ row }) => {
                    let patientName = row?.patient_name + "/" + row?.patient_age
                    let accNumAndMob = (row?.patient_uhid ? row?.patient_uhid : "-") + "|" + row?.patient_mobile_number
                    let gender = row?.patient_gender === 'm' ? "M" : row?.patient_gender === 'f' ? 'F' : row?.patient_gender === 't' ? 'T' : '-'
                    return (
                        <Box component={'div'} display={'flex'} emed_tid='pat_dtls'>
                            {/* <Box component={'div'}>
                                <Box component={'img'} mr={'0.5vw'} src={gender === 'M' ? ImagePaths.Male.default : ImagePaths.Female.default} height={'2.5vw'} width={'2.5vw'} borderRadius={'50px'} />
                            </Box> */}
                            <Box component={'div'}>
                                {patientName ? patientName?.length > 15 ?
                                    <Tooltip placement='top' title={patientName}><Typography fontSize={'0.9vw'} fontWeight={600}>{patientName?.slice(0, 15) + "..."}</Typography></Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600}>{patientName}</Typography> : '-'}
                                {accNumAndMob ? accNumAndMob?.length > 15 ?
                                    <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 15) + "..."}</Typography></Tooltip> :
                                    <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
                            </Box>
                        </Box>
                    )
                }
            },
            {
                field: "invoice_number", headerName: t("Bill No"), flex: 0.20, align: "center", headerAlign: "center", density: 'compact',
                renderCell: ({ row }) => {
                    let BillNumber = row?.common_invoice_number ? row?.common_invoice_number : row?.invoice_number ? row?.invoice_number : ""
                    const billArr = BillNumber;
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box>
                                <Box component={'div'} sx={{ fontSize: "0.90vw", fontWeight: "600" }}>{billArr}</Box>
                                <Box component={'div'} sx={{ fontSize: "0.85vw" }}>{formatDate(row?.invoice_date.slice(0, 10))}</Box>
                            </Box>
                        </Tooltip>
                    )

                }
            },
            {
                field: "concession_type", headerName: t("Concession Type"), flex: 0.10, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.concession_type?.length > 15 ?
                    <Tooltip placement='top' title={row?.concession_type} arrow><div>{row?.concession_type.slice(0, 13) + "..."}</div></Tooltip>
                    : row?.concession_type ? row?.concession_type : "-"}</Box>)
            },
            {
                field: "concession_amount", headerName: t("Concession Amount"), flex: 0.12, type: "number", density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}>{row?.concession_amount ? AmountFormat(row?.concession_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "doctor_name", headerName: t("Doctor Name"), flex: 0.15, density: 'compact', headerAlign: "center", align: "center",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={row?.doctor_name} arrow><div>{row?.doctor_name.slice(0, 18) + "..."}</div></Tooltip>
                    : row?.doctor_name ? row?.doctor_name : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_Main_Container'>
                <Box component={'div'} className='eMed_Header_Container'>
                    <Box component={'div'} className='eMed_Header_Rgt_Content'>
                        {AmountsCard(t("Total Concession"), this.state.total_concession)}
                    </Box>
                    <Box component={'div'} className='eMed_Header_Lft_Content'>
                        <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='flt_btn'
                            onClick={() => this.setState({ FilterOpen: true })}
                        >
                            <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                        </Button>
                        <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='prt_btn'
                            disabled={this.state.printLoad}
                            onClick={() => this.setState({ printLoad: true }, () => this.printReports())}
                        >
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Table_Content'>
                    <DataGrid
                        sx={{ bgcolor: 'white' }}
                        rows={concessionData}
                        columns={columns}
                        getRowId={(row) => row.sno}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.loading}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}

export default withTranslation()(ConcessionReports)