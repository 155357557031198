import { Box, Button, Typography, InputLabel, IconButton, InputAdornment, Switch, FormGroup, Checkbox, Modal, Tooltip, Stack, CircularProgress } from "@mui/material";
import React, { Component } from "react";
import '../../Laboratory/Home/home.css'
import TextField from '@mui/material/TextField';
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import RestAPIService from "../../../Utility/Services/RestAPIService";
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ImagePaths } from "../../../Utility/ImagePaths";
import { DateTime } from "luxon";
import ToastMsg from "../../../Components/ToastMsg/ToastMsg";
import Dialog from '@mui/material/Dialog';
import Success from '../../../Assets/Animation/Success.gif';
import { Trans, withTranslation } from 'react-i18next';
import { t } from "i18next";
import { clearCachevalue, getCachevalue, localGetItem, setCachevalue } from "../../../Utility/Services/CacheProviderService";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { CommonPatientDetails, ReasonPopupWithRefund } from "../../../Components/Common Components/CommonComponents";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Loader from '../../../Components/Loader';
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CurrencySymbol } from "../../../Utility/Constants";
import { Colors } from "../../../Styles/Colors";
import AddCircleIcon from '@mui/icons-material/AddCircle';
class LabBilling extends Component {
    constructor(props) {
        super(props);
        this.discountRef = React.createRef()
        this.state = {
            mobileNumber: null,
            title: "",
            patientName: "",
            pincode: "",
            gender: "",
            email: "",
            dateOfBirth: "",
            age: null,
            city: "",
            cityState: "",
            referredSourceList: [],
            referredDoctorList: [],
            referredBy: null,
            referralSource: null,
            selectedBillType: "Regular",
            selectedPaymentType: null,
            cashCollected: 0,
            netAmount: 0.00,
            roundOff: 0.00,
            discountPercentage: 0,
            discountAmount: 0.00,
            discountReason: '',
            isSavedReason: false,
            discountReasonPopup: false,
            totalBillAmount: 0,
            cashAmt: 0,
            cardAmt: 0,
            cardNo: null,
            upiAmt: 0,
            upiNo: null,
            bankTfrAmt: 0,
            bankTfrNo: null,
            chequeAmt: 0,
            chequeNo: null,
            isCash: false,
            isCard: false,
            isUPI: false,
            isCheque: false,
            isBankTranfer: false,
            onlinePaidValue: 0,
            referenceNo: "",
            address: "",
            testRow: {},
            insuranceCompanyName: "",
            policyNo: "",
            validity: "",
            transactionNo: "",
            savedBillDetails: {},
            billSummaryList: [],
            patientDetails: {},
            testConfigData: [],
            testName: null,
            testGroupRow: {},
            testGroup: null,
            testPackage: null,
            testPackageRow: {},
            testPackageDataList:[],
            isErrorMsg: false,
            isErrorMsgText: "",
            isSuccessMsg: false,
            isSuccessMsgText: "",
            invoice_date: new Date(),
            DateTimeChanged: false,
            isFieldError: false,
            isOnFocus: false,
            refDoctorDetails: {},
            referredSourceDetails: {},
            isReceivePayment: false,
            patient_id: null,
            patientAccountNumber: "",
            testGroupDataList: [],
            bill_summary_id: null,
            line_item_id: null,
            searchPatientDetails: {},
            statusDetailList: {},
            isPatientSearch: false,
            refDoctorRegNo: "",
            invoiceNumber: "",
            savedTransmitList: {},
            searchList: [],
            transmitUhid: "",
            ipNumber: "",
            clinic_id: "",
            isTestReport: false,
            labType: "",
            loginType: "",
            bill_summary_id_Print: null,
            country: "",
            smsActive: false,
            clinicId: null,
            newClinicId: null,
            searchPatientList: [],
            selectedLab: null,
            labNameList: [],
            lab_id:null,
            isInsurance:false,
            insuranceAmt:"",
            insuranceTfrNo:"",
            policyNo:"",
            cpyName:"",
            loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
            is_credit:false,
            FoLabPatientSavedBill:{},
            appointmentID: null,
            IPadmissionID: null,
            cancelBillData: {},
            for_cancel_bill: false,
            isForEditBill: false,
            cancelBillClicked: false,
            selectedRefundMode: '',
            upi_ref_num: '',
            selectedBillMode: 'cancel_bill',
            cancelReceipt: true,
            BillRefundData: {},
            laboratory_id: null,
            isLoader: false,
            isSuccess:false,
            is_OPlabBill: false,
            is_IPlabBill: false,
            OutstandingAmount : 0,
            BillReceivedAmount : 0,
            CancelledNetAmount : 0,
            isCredit: false,
            isCurrentIp : false,
            creditPayment:false,
            paymentMode: null,
            previousVisitedDateAndTime:'',
            patientTag:'',
            common_uhid: false,
            testTemplate: null,
            testTempDataList:[],
            ConsultantDataList: [],
            consultantName: '',
            consultantID: null,
            isAppointment: false,
            creditChange: false,
            addInsurance: false,
            addCorporate: false,
            paymentCreditType:'',
            corpName: null,
            employeeNo: '',
            insuranceName: null,
            insuranceNo: '',
            CropName: '',
            CropNo:'',
            InsName:'',
            InsNO:'',
            allowSms: false,
        }
    }
    componentDidMount() {
        
        if (localGetItem("isLoggedIn")) {
            let ProfileName = localGetItem("loggedInUserInfo")
            let smsConfig = JSON.parse(localGetItem("smsConfig"))
            let allowSms = smsConfig?.lab_bill_sms ? smsConfig?.lab_bill_sms : false
            let myUser = JSON.parse(ProfileName)
            this.setState({
                labType: myUser.laboratory_type,
                newClinicId: myUser.clinic_id,
                loginType: myUser?.module_name,
                allowSms: allowSms,
            }, () => {
                if(this.state.loginType != "Laboratory"){
                    this.getLabList()
                }
                this.getConsultantData()
                // this.getReferredSource()
                // this.getReferredDoctor()
                // this.getTestData()
                // if(this.state.loginType != "Laboratory"){
                //     this.getLabList()
                // }
                // this.getTestGroupData()
            })
        }
        if (this.state.statusDetailList != this.props.statusDetailList) {
            var { statusDetailList } = this.props
            if (statusDetailList && Object.keys(statusDetailList).length > 0) {
                this.setState({
                    statusDetailList: statusDetailList,
                    patient_id: statusDetailList.patient,
                    patientAccountNumber: statusDetailList.patient__patient_account_number,
                    bill_summary_id: statusDetailList.lab_bill_summary,
                }, () => {
                    if (this.state.statusDetailList && Object.keys(this.state.statusDetailList).length > 0) {
                        this.getSavedBillSummaryDetails(this.state.statusDetailList)
                    }
                })
            }
        }
        if(this.props?.history?.location?.state?.ForCancel && this.props?.history?.location?.state?.cancelBillData != this.state.cancelBillData){
            let billID = this.props?.history?.location?.state?.cancelBillData?.id ? this.props?.history?.location?.state?.cancelBillData?.id : null
            let patientID = this.props?.history?.location?.state?.cancelBillData?.patient_id ? this.props?.history?.location?.state?.cancelBillData?.patient_id : null
            let Data = {
                bill_summary_id: billID,
                patient_id: patientID,
                laboratory_id: this.props?.history?.location?.state?.cancelBillData?.laboratory_id ? this.props?.history?.location?.state?.cancelBillData?.laboratory_id : null,
                cancelBillData: this.props?.history?.location?.state?.cancelBillData,
                ForCancel: this.props?.history?.location?.state?.ForCancel && this.props?.history?.location?.state?.isForEditBill !== true,
            }
            this.setState({
                cancelBillData: Data.cancelBillData,
                bill_summary_id: billID,
                patient_id: patientID,
                for_cancel_bill: Data.ForCancel,
                lab_id: Data.laboratory_id,
                isForEditBill : this.props?.history?.location?.state?.isForEditBill ? true : false
            }, () => {
                this.getSavedBillSummaryDetails(Data)
                // this.getCancelRefundDetails(billID, Data.laboratory_id)
            })
        }

        if (this.state.isTestReport != this.props.isTestReport) {
            this.setState({
                isTestReport: this.props.isTestReport
            })
        }
        setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
            if (!this.state.DateTimeChanged) {
                const newMinute = new Date().getMinutes();
                if (this.state.invoice_date && this.state.invoice_date?.getMinutes() !== newMinute) {
                    this.setState({
                        invoice_date: new Date(),
                    });
                }
            }
        }, 1000);
    }
    componentDidUpdate(PrevProps) {
        if (this.props.savedBillDetails && Object.keys(this.props.savedBillDetails).length > 0 && this.props.savedBillDetails != PrevProps.savedBillDetails) {
            var { savedBillDetails } = this.props
            this.setState({
                savedBillDetails: savedBillDetails,
                patient_id: savedBillDetails.patient_id,
                patientAccountNumber: savedBillDetails.patient_accout_number,
                bill_summary_id: savedBillDetails.bill_summary_id,
            }, () => {
                if (this.state.savedBillDetails && Object.keys(this.state.savedBillDetails).length > 0) {
                    this.getSavedBillSummaryDetails(this.state.savedBillDetails)
                }
            })
        }

        if(this.props?.history?.location?.state?.ForCancel && this.props?.history?.location?.state?.cancelBillData != this.state.cancelBillData){
            let billID = this.props?.history?.location?.state?.cancelBillData?.id ? this.props?.history?.location?.state?.cancelBillData?.id : null
            let patientID = this.props?.history?.location?.state?.cancelBillData?.patient_id ? this.props?.history?.location?.state?.cancelBillData?.patient_id : null
            let Data = {
                bill_summary_id: billID,
                patient_id: patientID,
                laboratory_id: this.props?.history?.location?.state?.cancelBillData?.laboratory_id ? this.props?.history?.location?.state?.cancelBillData?.laboratory_id : null,
                cancelBillData: this.props?.history?.location?.state?.cancelBillData,
                ForCancel: this.props?.history?.location?.state?.ForCancel && this.props?.history?.location?.state?.isForEditBill !== true,
            }
            this.setState({
                cancelBillData: Data.cancelBillData,
                bill_summary_id: billID,
                patient_id: patientID,
                for_cancel_bill: Data.ForCancel,
                lab_id: Data.laboratory_id,
                isForEditBill : this.props?.history?.location?.state?.isForEditBill ? true : false
            }, () => {
                this.getSavedBillSummaryDetails(Data)
                // this.getCancelRefundDetails(billID, Data.laboratory_id)
            })
        }

        
        if(this.props?.history?.location?.state?.labPatientSavedBillData && Object.keys(this.props?.history?.location?.state?.labPatientSavedBillData).length > 0 && this.props?.history?.location?.state?.labPatientSavedBillData?.id !== this.state.FoLabPatientSavedBill?.id){
            let savedBillData = this.props?.history?.location?.state?.labPatientSavedBillData
            this.setState({
                FoLabPatientSavedBill : savedBillData
            },()=>{
                setTimeout(()=>{
                    if(this.state.FoLabPatientSavedBill && Object.keys(this.state.FoLabPatientSavedBill)?.length > 0){
                        this.getSavedBillSummaryDetails(this.state.FoLabPatientSavedBill)
                    }
                },300)
            })
        }

        if (this.props.savedTransmitList && Object.keys(this.props.savedTransmitList).length > 0 && this.props.savedTransmitList !== PrevProps.savedTransmitList) {
            let { savedTransmitList } = this.props
            this.setState({
                savedTransmitList: savedTransmitList,
                transmitUhid: savedTransmitList.patient_account_number,
                patientAccountNumber: savedTransmitList.patient_account_number,
                billSummaryList: savedTransmitList?.line_items ? savedTransmitList?.line_items : []
            }, () => {
                let { billSummaryList } = this.state
                for (let i = 0; i < billSummaryList.length; i++) {
                    if (billSummaryList[i].special_test_name) {
                        billSummaryList.splice(i, 1)
                        this.setState({
                            billSummaryList
                        })
                    }

                }
                this.getSearchList(savedTransmitList)
                const totalBillAmount = this.state.billSummaryList && this.state.billSummaryList.length > 0 && this.state.billSummaryList.map(item => item.amount).reduce((prev, curr) => (prev + curr));
                this.setState({
                    netAmount: totalBillAmount,
                    totalBillAmount: totalBillAmount,
                }, () => {
                    this.payableAmountCalculation()
                })
            })
        }

        if (this.props?.clinic_id !== this.state.newClinicId) {
            this.setState({
                newClinicId: this.props.clinic_id
            }, () => {
                if(!this.state.for_cancel_bill){this.getReferredSource()}
            })

        }
        if (PrevProps.history?.location?.state?.selectedPatientDetails && Object.keys(this.props.history?.location?.state?.selectedPatientDetails).length !== 0) {
            var selectedPatientDetails = this.props.history?.location?.state?.selectedPatientDetails
            this.setState({
                patient_id: selectedPatientDetails?.patient_id
            },()=>{
                this.getSearchPatient()
            })
            this.props.history.push({ state: { selectedPatientDetails: {} } })
        }

        if(this.props?.history?.location?.state?.patientData && Object.keys(this.props?.history?.location?.state?.patientData).length !== 0 && this.props != PrevProps){
            var labBillPatient = this.props.history?.location?.state?.patientData
            this.setState({
                mobileNumber: labBillPatient.patient_mobile_number ? labBillPatient.patient_mobile_number : '',
                title: labBillPatient.patient__title ? labBillPatient.patient__title : '',
                patientName: labBillPatient.patient_name ? labBillPatient.patient_name : '',
                pincode: labBillPatient.pincode ? labBillPatient.pincode : '',
                gender: labBillPatient.patient_gender == "m" ? "Male" : labBillPatient.patient_gender == "f" ? "Female" : labBillPatient.patient_gender == "t" ? "Trans Gender" : labBillPatient.patient_gender,
                email: labBillPatient.email ? labBillPatient.email : '',
                dateOfBirth: labBillPatient.dob ? labBillPatient.dob : '',
                age: labBillPatient.patient_age ? labBillPatient.patient_age : '',
                city: labBillPatient.patient_city ? labBillPatient.patient_city : '',
                cityState: labBillPatient.patient_state ? labBillPatient.patient_state : '',
                referredBy: labBillPatient.reffered_by ? labBillPatient.reffered_by : null,
                referralSource: labBillPatient.referral_source ? labBillPatient.referral_source : null,
                address: labBillPatient.patient_address_1 ? labBillPatient.patient_address_1 : "",
                country: labBillPatient.patient_country ? labBillPatient.patient_country : '',
                patient_id: labBillPatient.patient_id ? labBillPatient.patient_id : null,
                ipNumber:labBillPatient.ip_number ? labBillPatient.ip_number : '',
                patientAccountNumber:labBillPatient.patient_account_numbers ? labBillPatient.patient_account_numbers : '',
                IPadmissionID: labBillPatient?.ip_admission_id ? labBillPatient.ip_admission_id : '',
                is_OPlabBill: true,
                appointmentID: labBillPatient?.appointment_id ? labBillPatient?.appointment_id : '',
                previousVisitedDateAndTime:labBillPatient?.last_visit_date ? labBillPatient?.last_visit_date : '',
                patientData: labBillPatient,
                patientTag:(labBillPatient?.patient_tag || labBillPatient?.patient_tag_name || labBillPatient?.patient_tags__patient_tag_name) ? (labBillPatient?.patient_tag || labBillPatient?.patient_tag_name || labBillPatient?.patient_tags__patient_tag_name)  : '',
                consultantID: labBillPatient?.doctor_id ? labBillPatient?.doctor_id : null,
                consultantName: labBillPatient?.doctor_name ? labBillPatient?.doctor_name : '',
                isAppointment: true,
              })
            //   if (labBillPatient?.primary_payment_type == "Insurance credit") {
            //     this.setState({
            //       InsName: labBillPatient?.insurance_name,
            //       InsNO: labBillPatient?.policy_no
            //     })
            //   } else if (labBillPatient?.primary_payment_type == "Corprate credit") {
            //     this.setState({
            //       CropName: labBillPatient?.company_name,
            //       CropNo: labBillPatient?.employee_id
            //     })
            //   }
        }
        
        if(this.props?.history?.location?.state?.IP_patientData && Object.keys(this.props?.history?.location?.state?.IP_patientData).length !== 0 && this.props != PrevProps){
            var labBill_IPPatient = this.props.history?.location?.state?.IP_patientData
            this.setState({
                mobileNumber: labBill_IPPatient.patient__mobile_number ? labBill_IPPatient.patient__mobile_number : '',
                title: labBill_IPPatient.patient__title ? labBill_IPPatient.patient__title : '',
                patientName: labBill_IPPatient.patient_name ? labBill_IPPatient.patient_name : '',
                pincode: labBill_IPPatient.pincode ? labBill_IPPatient.pincode : '',
                gender: labBill_IPPatient.patient_gender == "m" ? "Male" : labBill_IPPatient.patient_gender == "f" ? "Female" : labBill_IPPatient.patient_gender == "t" ? "Trans Gender" : labBill_IPPatient.patient_gender,
                email: labBill_IPPatient.email ? labBill_IPPatient.email : '',
                dateOfBirth: labBill_IPPatient.patient__dob ? labBill_IPPatient.patient__dob : '',
                age: labBill_IPPatient.patient_age ? labBill_IPPatient.patient_age : '',
                city: labBill_IPPatient.patient_city ? labBill_IPPatient.patient_city : '',
                cityState: labBill_IPPatient.patient_state ? labBill_IPPatient.patient_state : '',
                referredBy: labBill_IPPatient.reffered_by ? labBill_IPPatient.reffered_by : null,
                referralSource: labBill_IPPatient.referral_source ? labBill_IPPatient.referral_source : null,
                address: labBill_IPPatient.patient_address_1 ? labBill_IPPatient.patient_address_1 : "",
                country: labBill_IPPatient.patient_country ? labBill_IPPatient.patient_country : '',
                patient_id: labBill_IPPatient.patient_id ? labBill_IPPatient.patient_id : null,
                ipNumber:labBill_IPPatient.ip_number ? labBill_IPPatient.ip_number : '',
                patientAccountNumber:labBill_IPPatient.patient_account_number ? labBill_IPPatient.patient_account_number : '',
                IPadmissionID: labBill_IPPatient?.id ? labBill_IPPatient.id : '',
                is_IPlabBill: true,
                appointmentID: labBill_IPPatient?.appointment_id ? labBill_IPPatient?.appointment_id : '',
                previousVisitedDateAndTime:labBill_IPPatient?.last_visit_date ? labBill_IPPatient?.last_visit_date : '',
                patientData: labBill_IPPatient,
                patientTag:(labBill_IPPatient?.patient_tag || labBill_IPPatient?.patient_tag_name || labBill_IPPatient?.patient_tags__patient_tag_name) ? (labBill_IPPatient?.patient_tag || labBill_IPPatient?.patient_tag_name || labBill_IPPatient?.patient_tags__patient_tag_name) : '',
                consultantID: labBill_IPPatient?.doctor_id ? labBill_IPPatient?.doctor_id : null,
                consultantName: labBill_IPPatient?.doctor_name ? labBill_IPPatient?.doctor_name : '',
                isAppointment: true,
              })
            //   if (labBill_IPPatient?.primary_payment_type == "Insurance credit") {
            //     this.setState({
            //       InsName: labBill_IPPatient?.patient_insurance_company,
            //       InsNO: labBill_IPPatient?.policy_number
            //     })
            //   } else if (labBill_IPPatient?.primary_payment_type == "Corprate credit") {
            //     this.setState({
            //       CropName: labBill_IPPatient?.corporate_name,
            //       CropNo: labBill_IPPatient?.employee_no
            //     })
            //   }
        }
    }

    getSearchPatient = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_GET + `?patient_id=${this.state.patient_id}&laboratory_id=${id ? id : this.state.lab_id}`)
                .then(response => {
                    if (response.data.status == "success") {
                        let item = response.data.data
                        this.setState({
                            patientData: item,
                            paymentCreditType:item?.credit_type,
                            mobileNumber: item?.mobile_number ? item?.mobile_number : '',
                            title: item?.patient_title ? item?.patient_title : '',
                            patientName: item?.patient_name ? item?.patient_name : '',
                            pincode: item?.pincode ? item?.pincode : '',
                            gender: item.gender == "m" ? "Male" : item.gender == "f" ? "Female" : item.gender == "t" ? "Trans Gender" : item.gender,
                            email: item?.email ? item?.email : '',
                            dateOfBirth: item.dob ? item.dob : '',
                            age: item.patient_age ? item.patient_age : '',
                            city: item.city_name ? item.city_name : '',
                            cityState: item.state_name ? item.state_name : '',
                            referredBy: item.reffered_by ? item.reffered_by : null,
                            referralSource: item.referral_source ? item.referral_source : null,
                            address: item.address_line_1 ? item.address_line_1 : "",
                            country: item.country ? item.country : '',
                            ipNumber: item.ip_number ? item.ip_number : '',
                            patientAccountNumber: item.patient_account_number ? item.patient_account_number : '',
                            IPadmissionID: item?.ip_admission_id ? item.ip_admission_id : '',
                            appointmentID: item?.appointment_id ? item?.appointment_id : '',
                            previousVisitedDateAndTime:item?.last_visit_date ? item?.last_visit_date : '',
                            patientTag:(item?.patient_tag || item?.patient_tag_name || item?.patient_tags__patient_tag_name) ? (item?.patient_tag || item?.patient_tag_name || item?.patient_tags__patient_tag_name) : '',
                            consultantName: item?.doctor_name ? item?.doctor_name : '',
                            consultantID: item?.doctor_id ? item?.doctor_id : null,
                            isAppointment: item?.is_appointment ? item?.is_appointment : false,
                        })
                        if (item?.credit_type == "Insurance credit") {
                            this.setState({
                                InsName: item?.insurance_name ? item?.insurance_name : '',
                                InsNO: item?.policy_no ? item?.policy_no : '',
                              })
                            } else if (item?.credit_type == "Corprate credit") {
                              this.setState({
                                CropName: item?.corporate_name ? item?.corporate_name : '',
                                CropNo: item?.employee_no ? item?.employee_no : '',
                                CompanyID: item?.employer_id ? item.employer_id : null,
                              })
                          }
                    }
                })
                .catch(e => {
                    if (e?.response?.data?.status === 'fail') {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e?.response?.data?.message
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.message
                        })
                    }
                });
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    getConsultantData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            ConsultantDataList: response.data.data
                        })
                    }
                }).catch(e => {
                    if(e.response.data.status === 'fail'){
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.response.data.message
                        })
                    }else{
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.message
                        })
                    }
                    
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    lodaerFunction = (key) => {
        this.setState({
          isLoader: key
        }, () => {
          setTimeout(() => {
            this.setState({
              isLoader: false
            })
          }, 2000);
        })
    }

    getCancelRefundDetails = (id, LabID) => {
        try {
            let billAmt = this.state.cancelBillData?.amount_net ? this.state.cancelBillData?.amount_net : 0
            var patientModuleId
            var BillType
            if(this.state.cancelBillData.ip_admission_id){
                patientModuleId = this.state.cancelBillData.ip_admission_id
                BillType = 'IP'
            }else if(this.state.cancelBillData.appointment_id){
                patientModuleId = this.state.cancelBillData.appointment_id
                BillType = 'OP'
            }else{
                patientModuleId = this.state.cancelBillData.id
                BillType = 'LAB'
            }
            let LabIDQuery = ""
            if(this.state.loginType != "Laboratory"){
                LabIDQuery = '&laboratory_id=' + LabID
            }
            RestAPIService.getAll(Serviceurls.LAB_CANCEL_BILL_REFUND + '?bill_id=' + id + LabIDQuery + "&bill_amount=" + billAmt + "&patient_module_id=" + patientModuleId + "&bill_type=" + BillType)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            BillRefundData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }catch (error) {
            this.errorMessage(error.message)
        }
    }

    getLabList = () => {
        this.setState({ labNameList: [] });
        try {
            RestAPIService.getAll(`${Serviceurls.FO_IP_LABLIST}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            labNameList: response.data.data,
                            lab_name: (this.state.for_cancel_bill || this.state.isForEditBill) ? response.data.data.find((list)=>(list?.laboratory_id === this.state.lab_id))?.laboratory_name : response.data.data[0].laboratory_name,
                            lab_id: (this.state.for_cancel_bill || this.state.isForEditBill) ? this.state.lab_id :  response.data.data[0].laboratory_id ,
                        }, () => {
                            if(!this.state.for_cancel_bill){
                                this.getReferredDoctor(this.state.lab_id);
                                this.getTestData(this.state.lab_id);
                                this.getTestGroupData(this.state.lab_id)
                                this.getPackageData(this.state.lab_id) 
                                this.getTemplateData(this.state.lab_id)
                            }
                            if(this.state.patient_id){
                                this.getSearchPatient((this.state.for_cancel_bill || this.state.isForEditBill) ? this.state.lab_id :  response.data.data[0].laboratory_id ,)
                            }
                            setCachevalue(this.state.lab_id,"Laboratory_id")
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: "",
            isSuccessMsg: false,
            isSuccessMsgText: "",
        })
    }

    patientDetailsPost = (paymentMode) => {
        const { t } = this.props
        try {
            var dateOfBirth = DateTime.fromJSDate(this.state.dateOfBirth).toFormat('yyyy-MM-dd')
            let docName = ''; let regNo = ''
            if (this.state.referredBy?.name && this.state.referredBy?.qualification) {
                docName = `${this.state.referredBy.name} ${this.state.referredBy.qualification}`
                regNo = this.state.refDoctorDetails.reg_no ? this.state.refDoctorDetails.reg_no : this.state.refDoctorDetails
            } else if (this.state.referredBy?.name) {
                docName = this.state.referredBy.name
                regNo = this.state.refDoctorDetails.reg_no ? this.state.refDoctorDetails.reg_no : this.state.refDoctorDetails
            } else {
                docName = this.state.referredBy ? this.state.referredBy : ''
                regNo = ""
            }
            var data = {}
            data = {
                "first_name": this.state.patientName,
                "last_name": "",
                "father_husband_name": "",
                "gender": this.state.gender === "Female" ? "f" : this.state.gender === "Male" ? "m" : this.state.gender === "Trans Gender" ? "t" : "",
                "title": this.state.title,
                "occupation": "",
                "dob": dateOfBirth,
                "approx_dob": null,
                "marital_status": "",
                "mobile_number": this.state.mobileNumber,
                "mobile_number_2": "",
                "email": this.state.email,
                "address_line_1": this.state.address,
                "address_line_2": "",
                "area_name": "",
                "pincode": this.state.pincode,
                "city_name": this.state.city,
                "state_name": this.state.cityState,
                "country": this.state.country,
                "receive_sms": true,
                "reffered_by": docName,
                "referral_source": this.state.referredSourceDetails?.source_name ? this.state.referredSourceDetails?.source_name : this.state.referralSource ? this.state.referralSource : '',
                "id_no": "",
                "employer_id": null,
                "laboratory_id":this.state.lab_id,
                'patient_tag':this.state.patientTag
            }
            if (this.state.labType === "Inhouse Laboratory") {
                data["clinic_id"] = +this.props.clinic_id
            }
            if (!this.state.mobileNumber || !this.state.patientName || !this.state.age || !this.state.gender) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("ManitoryFields")
                })
            } else if (+this.props.clinic_id == null && this.state.labType === "Inhouse Laboratory") {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Please Select the Clinic"
                })
            } else if(this.state.discountAmount != 0 && this.state.discountReason === ""){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Reason is mandatory.Click Note icon to enter reason"
                })
            } else {
                RestAPIService.create(data, Serviceurls.LAB_CREATE_PATIENT_DETAILS)
                    .then(response => {
                        if (response.data.status == "success") {
                            var data = response.data.data

                            this.setState({
                                patient_id: data.patient_id,
                                patientAccountNumber: data.patient_account_number,
                                // isSuccessMsg: true,
                                // isSuccessMsgText: response.data.message
                            })
                            if (paymentMode == "Partial") {
                                    this.onPressSavedBill(data.patient_id, data.patient_account_number)
                            } else {
                                this.onPressPaymentReceived()
                            }
                        } else {
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: response.data.message
                            })
                        }
                    })
                    .catch(e => {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.response.data.message
                        })
                    })
            }
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }


    }
    getMobileNumSearchList = () => {
        var search_key = this.state.mobileNumber ? this.state.mobileNumber : null
        let serviceUrl = ""
        if (search_key) {
            if (this.state.labType == "Standalone Laboratory") {
                serviceUrl = Serviceurls.LAB_SEARCH_PATIENT + "?search_key=" + search_key
            } else {
                serviceUrl = Serviceurls.LAB_PATIENT_TRANSMIT_SEARCH + "?search_key=" + search_key + `&laboratory_id=${this.state.lab_id}`
            }
        }
        RestAPIService.getAll(serviceUrl)
            .then(response => {
                if (response.data.status == "success") {
                    var data = response.data.data
                    this.setState({
                        searchPatientList: data,
                        isPatientSearch: true,
                        common_uhid: response?.data?.common_uhid ? response?.data?.common_uhid : false,
                    });
                } else if (response.data.status == "fail") {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: data.message
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                })
            });
    }
    getSearchList = (savedTransmitList) => {
        RestAPIService.getAll(Serviceurls.LAB_PATIENT_TRANSMIT_SEARCH + `?search_key=${this.state.transmitUhid}` + `&laboratory_id=${this.state.lab_id}`).
            then((response) => {
                if (response.data.status === "success") {
                    let searchList = response.data.data

                    searchList && searchList.length > 0 && searchList.forEach(element => {
                        this.setState({
                            patient_id: element?.patient_id,
                            patientAccountNumber: element?.patient_account_number,
                            mobileNumber: element?.patient_mobile_number,
                            patientName: element?.patient_name,
                            gender: element.gender == "f" ? "Female" : element.gender == "m" ? "Male" : "Trans Gender",
                            dateOfBirth: element.dob,
                            age: element.patient_age,
                            city: element.city__city_name,
                            cityState: element.state__state_name,
                            referredBy: element?.reffered_by,
                            referralSource: element?.referral_source,
                            title: element?.patient_title,
                            ipNumber: element?.ip_number,
                            country: element?.country,
                            previousVisitedDateAndTime:element?.last_visit_date,
                            patientTag:(element?.patient_tag || element?.patient_tag_name || element?.patient_tags__patient_tag_name)
                        })
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                })
            })
    }
    isClearAllData = () => {
        if(this.state.is_IPlabBill === false && this.state.is_OPlabBill === false){
            this.setState({
                mobileNumber: null,
                title: "",
                patientName: "",
                pincode: "",
                gender: "",
                email: "",
                dateOfBirth: null,
                age: "",
                city: "",
                cityState: "",
                referralSource: null,
                selectedBillType: "Regular",
                selectedPaymentType: null,
                cashCollected: 0,
                netAmount: 0.00,
                roundOff: 0.00,
                cashAmt: 0,
                cardAmt: 0,
                cardNo: null,
                upiAmt: 0,
                upiNo: null,
                bankTfrAmt: 0,
                bankTfrNo: null,
                chequeAmt: 0,
                chequeNo: null,
                isCash: false,
                isCard: false,
                isUPI: false,
                isCheque: false,
                isBankTranfer: false,
                discountPercentage: 0,
                discountAmount: 0.00,
                discountReason: '',
                isSavedReason: false,
                totalBillAmount: 0,
                onlinePaidValue: 0,
                referenceNo: "",
                address: "",
                testRow: {},
                testGroupRow: {},
                insuranceCompanyName: "",
                policyNo: "",
                validity: "",
                transactionNo: "",
                billSummaryList: [],
                ipNumber: "",
                isReceivePayment: false,
                refDoctorDetails: {},
                referredSourceDetails: {},
                referredDoctor: null,
                patientAccountNumber: "",
                // patient_id: "",
                referredBy: null,
                referralSource: null,
                invoiceNumber: "",
                isTestReport: false,
                testGroup: null,
                testName: null,
                savedTransmitList: {},
                country: "",
                smsActive: false,
                isInsurance:false,
                insuranceAmt:"",
                insuranceTfrNo:"",
                cpyName:"",
                isCredit: false,
                previousVisitedDateAndTime:'',
                patientData: {},
                InsName: '',
                InsNO: '',
                CropName: '',
                CropNo: '',
                patientTag: '',
                invoice_date: new Date(),
                DateTimeChanged: false,
                consultantName: '',
                consultantID: null,
                isAppointment: false,
                creditChange: false,
                isSMSText: '',
            })
        }else{
            this.setState({
                selectedBillType: "Regular",
                selectedPaymentType: null,
                cashCollected: 0,
                netAmount: 0.00,
                roundOff: 0.00,
                cashAmt: 0,
                cardAmt: 0,
                cardNo: null,
                upiAmt: 0,
                upiNo: null,
                bankTfrAmt: 0,
                bankTfrNo: null,
                chequeAmt: 0,
                chequeNo: null,
                isCash: false,
                isCard: false,
                isUPI: false,
                isCheque: false,
                isBankTranfer: false,
                discountPercentage: 0,
                discountAmount: 0.00,
                discountReason: '',
                isSavedReason: false,
                totalBillAmount: 0,
                onlinePaidValue: 0,
                referenceNo: "",
                testRow: {},
                testGroupRow: {},
                insuranceCompanyName: "",
                policyNo: "",
                validity: "",
                transactionNo: "",
                billSummaryList: [],
                isReceivePayment: false,
                refDoctorDetails: {},
                referredSourceDetails: {},
                referredDoctor: null,
                invoiceNumber: "",
                isTestReport: false,
                testGroup: null,
                testName: null,
                savedTransmitList: {},
                smsActive: false,
                isInsurance:false,
                insuranceAmt:"",
                insuranceTfrNo:"",
                cpyName:"",
                referredBy: null,
                referralSource: null,
                CancelledBillNumber : "",
                patientData: {},
                // InsName: '',
                // InsNO: '',
                // CropName: '',
                // CropNo: '',
                // CompanyID: '',  
                // addInsurance: false,
                // addCorporate: false,
                // paymentCreditType:'',
                // corpName: null,
                // employeeNo: '',
                // insuranceName: null,
                // insuranceNo: '',
                invoice_date: new Date(),
                DateTimeChanged: false,
            })
        }
        
    }
    onPressSavedBill = (from_credit) => {
        const { t } = this.props;
        let states = this.state;
        let paymentMode = [];
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: "",
            isSuccessMsg: false,
            isSuccessMsgText: ""
        })
        try {
            var { billSummaryList } = this.state
            let startDate = new Date(this.state.invoice_date)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let date = this.state.invoice_date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : ""
            let total = (+this.state.cashAmt) + (+this.state.cardAmt) + (+this.state.upiAmt) + (+this.state.bankTfrAmt) + (+this.state.chequeAmt)
            // let date = this.state.invoice_date && DateTime.fromJSDate(this.state.invoice_date).toFormat('yyyy-MM-dd')
            // let invoice_date = (this.state.savedBillDetails && Object.keys(this.state.savedBillDetails).length > 0) ? this.state.invoice_date : date
            let bill_line_items = [];let noDiscountAmount= 0;
            for (let i = 0; i < billSummaryList.length; i++) {
                var data = { 
                    "result_item_id": billSummaryList[i].result_item_id ? billSummaryList[i].result_item_id : null, 
                    "lab_set_id": billSummaryList[i].lab_set_id ? billSummaryList[i].lab_set_id : null, 
                    "lab_test_id": billSummaryList[i].lab_test_id ? billSummaryList[i].lab_test_id : null, 
                    "package_id": billSummaryList[i].package_id ? billSummaryList[i].package_id : null,
                    "test_name": billSummaryList[i].test_name, 
                    "grp_name": billSummaryList[i].grp_name ? billSummaryList[i].grp_name : null, 
                    "amount": billSummaryList[i].amount ? billSummaryList[i].amount : 0, 
                    "test_units": 1, 
                    "test_rate_per_unit": billSummaryList[i].amount ? billSummaryList[i].amount : 0, 
                    "line_item_id": billSummaryList[i].line_item_id ? billSummaryList[i].line_item_id : null }
                bill_line_items.push(data)
            }
            this.state.billSummaryList?.forEach(elememt => {
                noDiscountAmount = noDiscountAmount + elememt.amount
            })
            if (states.isCash) { paymentMode.push(1) }
            if (states.isCard) { paymentMode.push(2) }
            if (states.isUPI) { paymentMode.push(3) }
            if (states.isBankTranfer) { paymentMode.push(4) }
            if (states.isCheque) { paymentMode.push(5) }
            if (states.isInsurance) {paymentMode.push(6)}
            let transmitted_id = ""
            transmitted_id = this.state.savedTransmitList && Object.keys(this.state.savedTransmitList).length > 0 ? this.state.savedTransmitList.transmit_id : ""
            let docName = ''; let regNo = ''
            if (this.state.referredBy?.name && this.state.referredBy?.qualification) {
                docName = `${this.state.referredBy.name} ${this.state.referredBy.qualification}`
                regNo = this.state.refDoctorDetails.reg_no ? this.state.refDoctorDetails.reg_no : typeof(this.state.refDoctorDetails) === 'string' ? this.state.refDoctorDetails : ''
            } else if (this.state.referredBy?.name) {
                docName = this.state.referredBy.name
                regNo = this.state.refDoctorDetails.reg_no ? this.state.refDoctorDetails.reg_no : typeof(this.state.refDoctorDetails) === 'string' ? this.state.refDoctorDetails : ''
            } else {
                docName = this.state.referredBy ? this.state.referredBy : ''
                regNo = ""
            }
            var data = {
                "is_credit":this.state.is_credit ? true : false,
                "billing_details": {
                    "acc_number": this.state.patientAccountNumber,
                    "patient_id": this.state.patient_id,
                    "reffered_by":  docName,
                    "referral_source": this.state.referredSourceDetails?.source_name ? this.state.referredSourceDetails?.source_name : this.state.referralSource ? this.state.referralSource : '',
                    "reffered_reg_no": regNo,
                    "invoice_date": date === "Invalid DateTime" ? date : date,
                    "total_amount": parseInt(this.state.netAmount),
                    "round_off": parseFloat(this.state.roundOff),
                    "net_amount": this.state.totalBillAmount,
                    "payment_type": "",
                    "cash_amount": states["cashAmt"] ? +(states["cashAmt"]) : 0,
                    "card_amount": states["cardAmt"] ? +(states["cardAmt"]) : 0,
                    "upi_amount": states["upiAmt"] ? +(states["upiAmt"]) : 0,
                    "bank_transfer_amount": states["bankTfrAmt"] ? +(states["bankTfrAmt"]) : 0,
                    "cheque_amount": states["chequeAmt"] ? +(states["chequeAmt"]) : 0,
                    "payment_mode": paymentMode.length > 0 ? paymentMode : [],
                    "card_number": states["cardNo"] ? states["cardNo"] : "",
                    "upi_ref_no": states["upiNo"] ? states["upiNo"] : "",
                    "bank_ref_no": states["bankTfrNo"] ? states["bankTfrNo"] : "",
                    "cheque_ref_no": states["chequeNo"] ? states["chequeNo"] : "",
                    "received_amount": states["received_amount"] ? states["received_amount"] : this.state.creditPayment ? total : 0,
                    "balance_amount": 0,
                    "bill_line_items": bill_line_items,
                    "discount_percentage": parseFloat(this.state.discountPercentage),
                    "discount_amount": parseFloat(this.state.discountAmount),
                    "remarks": +this.state.discountAmount != 0 ? this.state.discountReason : '',
                    "transaction_id": "",
                    "transaction_number": this.state.insuranceTfrNo,
                    "policy_number": states?.paymentCreditType == "Insurance credit" ? states?.InsNO : null,
                    "insurance_company_name": states?.paymentCreditType == "Insurance credit" ? states.InsName : "",
                    "validity": this.state.validity,
                    "bill_type": "Bill",
                    "company_name": states?.paymentCreditType == "Corprate credit" ? states?.CropName : "",
                    "employee_id": states?.paymentCreditType == "Corprate credit" ? states?.CropNo : null,
                    "employer_id": states?.paymentCreditType == "Corprate credit" ? states?.CompanyID : null,
                    "ip_number": this.state.ipNumber ? this.state.ipNumber : "",
                    "bill_summary_id": this.state.bill_summary_id,
                    "test_transmit_summary_id": transmitted_id,
                    "is_transmitted": this.state.savedTransmitList?.transmit_id ? true : false,
                    "insurance_amount":+this.state.insuranceAmt,           
                    "clinic_id": this.state.loggedInuserInfo?.clinic_id ? this.state.loggedInuserInfo?.clinic_id : (localGetItem("clinicId") ? +localGetItem("clinicId") : null),
                    "appointment_id": this.state.IPadmissionID ? null : this.state.appointmentID ? this.state.appointmentID : null,
                    "ip_admission_id": this.state.IPadmissionID ? this.state.IPadmissionID : null,
                    "net_total_amount":noDiscountAmount ? +noDiscountAmount : 0,
                    "last_visit_date":this.state.previousVisitedDateAndTime ? this.state.previousVisitedDateAndTime : null,
                    "doctor_id": this.state.consultantID ? this.state.consultantID : null,
                    "doctor_name": this.state.consultantName ? this.state.consultantName : '',
                    "credit_type": states?.paymentCreditType ? states?.paymentCreditType : null,
                },
                "laboratory_id" : this.state.lab_id,

            }   
            if(from_credit){
                let LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
                let CollectedIn = "";let paidIn = '';
                if(LoggedUserData?.module_name === "front_office"){
                        CollectedIn = "FO LAB"
                        paidIn = 'FRONT OFFICE'
                }else if(LoggedUserData?.module_name === "Laboratory"){
                    CollectedIn = "LAB"
                    paidIn = 'LAB'
                }
                data['is_partial'] = this.state.creditPayment ? true : false
                data['collected_in'] = CollectedIn
                data['paid_in'] = paidIn
            }
            if (this.state.billSummaryList && this.state.billSummaryList.length == 0) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("Please Enter Test Name or Test Group"),
                    isSuccess:false
                })
            }else if(this.state.discountAmount != 0 && this.state.discountReason === ""){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Reason is mandatory.Click Note icon to enter reason",
                    isSuccess:false,
                    isLoader: false,
                })
            }
            // else if(this.state.isCredit && !from_credit){
            //     this.lodaerFunction(false)
            //     let valid = this.verifyAmtNo();
            //     let total = (+this.state.cashAmt) + (+this.state.cardAmt) + (+this.state.bankTfrAmt) + (+this.state.upiAmt) + (+this.state.chequeAmt)
            //     if(valid !== ''){
            //         this.lodaerFunction(false)
            //         this.setState({
            //             isErrorMsg: true,
            //             isErrorMsgText: valid,
            //             isSuccess:false
            //         })
            //     }
            //     if(total > this.state.totalBillAmount){
            //         this.lodaerFunction(false)
            //         this.setState({
            //             isErrorMsg: true,
            //             isErrorMsgText: "Entered Amount Does not Match With Total Amount",
            //             isSuccess:false
            //         })
            //     }
            // }
             else {
                this.lodaerFunction(true)
                RestAPIService.create(data, Serviceurls.LAB_PARTIAL_BILL_POST)
                    .then(response => {
                        if (response.data.status == "success") {
                            if (this.state.isForEditBill) {
                                this.lodaerFunction(false)
                                this.ReasonPopupClose()
                                if (this.state.loginType === "Laboratory") {
                                    clearCachevalue("LabCancelBilldata");
                                    clearCachevalue("isForCancelBill");
                                    this.props.history.push({ pathname: "/MainHome" })
                                } else {
                                    if (this.props?.history?.location?.state?.FromBills) {
                                        clearCachevalue("LabCancelBilldata");
                                        clearCachevalue("isForCancelBill");
                                        if (getCachevalue("patientData")) {
                                            this.props?.history.push({ pathname: '/OPHome/PatientDetails/Billing' })
                                        } else {
                                            this.props?.history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' })
                                        }
                                    } else {
                                        clearCachevalue("LabCancelBilldata");
                                        clearCachevalue("isForCancelBill");
                                        this.props.history.push({ pathname: '/BillingTransaction/OverAllBills' })
                                    }
                                }
                            }
                            if(from_credit){

                            }else{
                                this.isClearAllData()
                                this.setState({patient_id: null})
                            }
                            
                            
                            this.lodaerFunction(false)
                            if(this.state.is_IPlabBill === false && this.state.is_OPlabBill === false){
                                this.setState({
                                    isSuccessMsg: true,
                                    isSuccessMsgText: response.data.message,
                                    patientAccountNumber: "",
                                    savedTransmitList: {},
                                    isTestReport: false,
                                    bill_summary_id: "",
                                    is_credit:false,
                                    FoLabPatientSavedBill: {},
                                    isSuccess:false,
                                    invoice_date:new Date(),
                                    DateTimeChanged : false,
                                    bill_summary_id_Print: from_credit ? response.data?.data?.bill_summary_id : null,
                                    isReceivePayment: from_credit ? true : false,
                                    isCredit: from_credit ? true : false,
                                })
                            }else{
                                this.setState({
                                    isSuccessMsg: true,
                                    isSuccessMsgText: response.data.message,
                                    savedTransmitList: {},
                                    isTestReport: false,
                                    bill_summary_id: "",
                                    is_credit:false,
                                    FoLabPatientSavedBill: {},
                                    isSuccess:false,
                                    invoice_date:new Date(),
                                    DateTimeChanged : false,
                                    bill_summary_id_Print: from_credit ? response.data?.data?.bill_summary_id : null,
                                    isReceivePayment: from_credit ? true : false,
                                    isCredit: from_credit ? true : false,
                                },()=>{
                                    if(!from_credit){
                                        if (this.state?.is_OPlabBill) {
                                            let patientDatas = getCachevalue("patientData")
                                            let patients = JSON.parse(patientDatas)
                                            this.setState({ isReceivePayment : false }, ()=>{
                                                this.isClearAllData()
                                                this.props.history.push({ pathname: "/Billing", state: { patientData: patients } })
                                            })
                                        } else if (this.state?.is_IPlabBill) {
                                            let patientDatas = getCachevalue("IppatientData")
                                            let IPpatients = JSON.parse(patientDatas)
                                            this.setState({ isReceivePayment : false }, ()=>{
                                                this.isClearAllData()
                                                this.props.history.push({ pathname: "/Billing", state: { IP_patientData: IPpatients } })
                                            })
                                        }
                                    }
                                })
                            }
                        } else {
                            this.lodaerFunction(false)
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: response.data.message,
                                isSuccess:false
                            })
                        }
                    }).catch((error) => {
                        this.lodaerFunction(false)
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.response.data.message,
                            isSuccess:false
                        })
                    })
            }
        } catch (e) {
            this.lodaerFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                isSuccess:false
            })
        }
    }
    getTestGroupData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET + '?laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testGroupDataList: response.data.data
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    getPackageData = (id) => {
        try {
          RestAPIService.getAll(Serviceurls.NEW_LAB_PACKAGE_CONFIGURATION + '?laboratory_id=' + id)
            .then(response => {
              if (response.data.status === "success") {
                this.setState({
                    testPackageDataList: response.data.data
                })
              }
            }).catch(e => {
              this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
              })
            })
    
        } catch (e) {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        }
      }
    getTestData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + '?laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testConfigData: response.data.data
                })
              }
            }).catch(e => {
              this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
              })
            })
    
        } catch (e) {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: e.message
          })
        }
      }

      getTemplateData = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEMPLATE_CONFIGURATION + "?for_billing=true" + '&laboratory_id=' + id)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            testTempDataList: response.data.data
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })

        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }


    CalculateCancelBill = () =>{
        // if(this.state.totalBillAmount > this.state.OutstandingAmount){
        //     this.setState({
        //         CancelledNetAmount : this.state.totalBillAmount,
        //         totalBillAmount : this.state.totalBillAmount - this.state.OutstandingAmount
        //     })
        // }else if(this.state.OutstandingAmount >= this.state.totalBillAmount){
        //     this.setState({
        //         CancelledNetAmount : this.state.totalBillAmount,
        //         totalBillAmount : 0
        //     })
        // }else {
            this.setState({
                CancelledNetAmount : this.state.totalBillAmount,
                totalBillAmount : this.state.BillReceivedAmount
            })
        }
    // }
    getSavedBillSummaryDetails = (data) => {
        if (this.state.patient_id) {
            this.getSearchPatient();
        }
        var bill_summary_id = data.bill_summary_id ? data.bill_summary_id : data.lab_bill_summary ? data.lab_bill_summary : data?.id
        var patient_id = data.patient_id ? data.patient_id : data.patient
        let SavedLab = this.state.labNameList?.filter(item => item?.laboratory_id === data?.laboratory_id)
        RestAPIService.getAll(Serviceurls.LAB_PARTIAL_BILL_POST + "?bill_summary_id=" + bill_summary_id + "&patient_id=" + patient_id + "&laboratory_id=" + data?.laboratory_id)
            .then(response => {
                if (response.data.status == "success") {
                    var data = response.data.data
                    // let age_validate = (data.patient_details.age).match(/(\d)/)
                    let paymentModeValue = null;
                    if(data?.bill_details?.payment_mode?.length === 1){
                        if((data?.bill_details?.payment_mode[0] === 1) || (data?.bill_details?.payment_mode[0] === 3) || (data?.bill_details?.payment_mode[0] === 5) ){
                           paymentModeValue = data?.bill_details?.payment_mode[0];
                        }
                    }
                    this.setState({
                        billSummaryList: data.bill_details.bill_line_items ? data.bill_details.bill_line_items : [],
                        netAmount: data.bill_details.total_amount ? data.bill_details.total_amount : "",
                        roundOff: data.bill_details ? data.bill_details.round_off : 0,
                        discountPercentage: data.bill_details ? data.bill_details.discount_percentage : 0,
                        discountAmount: data.bill_details ? data.bill_details.discount_amount : 0,
                        discountReason: data?.bill_details?.remarks ? data?.bill_details?.remarks : '',
                        totalBillAmount: data.bill_details ? data.bill_details.net_amount : 0,
                        invoice_date: this.state.isForEditBill ? data.bill_details ? data.bill_details.invoice_date : "" : this.state.invoice_date, 
                        DateTimeChanged : this.state.isForEditBill ? true : false, 
                        mobileNumber: data.patient_details ? data.patient_details.mobile_number : "",
                        title: data.patient_details ? data.patient_details.title : "",
                        patientName: data.patient_details.patient_name ? data.patient_details.patient_name : "",
                        pincode: data.patient_details.pincode ? data.patient_details.pincode : "",
                        gender: data.patient_details.gender == "f" ? "Female" : data.patient_details.gender == "m" ? "Male" : "Trans Gender",
                        email: data.patient_details.email ? data.patient_details.email : "",
                        dateOfBirth: data.patient_details.dob ? data.patient_details.dob : "",
                        age: data.patient_details.age ? data.patient_details.age : null,
                        city: data.patient_details.city ? data.patient_details.city : "",
                        cityState: data.patient_details.state ? data.patient_details.state : "",
                        bill_summary_id: data.bill_details.bill_summary_id ? data.bill_details.bill_summary_id : "",
                        referredBy: data.bill_details.reffered_by ? data.bill_details.reffered_by : null,
                        referralSource: data.bill_details.referral_source ? data.bill_details.referral_source : null,
                        refDoctorRegNo: data.bill_details.reffered_reg_no ? data.bill_details.reffered_reg_no : "",
                        ipNumber: data.patient_details ? data.patient_details.ip_number : "",
                        address: data.patient_details.address ? data.patient_details.address : "",
                        patient_id: data.patient_details.patient_id ? data.patient_details.patient_id : null,
                        patientAccountNumber: data.patient_details.acc_number ? data.patient_details.acc_number : null,
                        country: data.patient_details.country ? data.patient_details.country : "",
                        lab_id: (this.state.for_cancel_bill || this.state.isForEditBill) ? this.state.lab_id : SavedLab?.length  === 1 ? SavedLab[0]?.laboratory_id : this.state.labNameList[0]?.laboratory_id,
                        lab_name: (this.state.for_cancel_bill || this.state.isForEditBill) ? this.state.lab_name : SavedLab?.length  === 1 ? SavedLab[0]?.laboratory_name : this.state.labNameList[0]?.laboratory_name,
                        BillReceivedAmount : data?.bill_details ? data.bill_details?.received_amount : 0,
                        isCurrentIp : data?.bill_details?.is_current_ip ? data.bill_details?.is_current_ip : false,
                        CancelledBillNumber: data.bill_details?.invoice_number ? data.bill_details?.invoice_number : "",
                        IPadmissionID: data.bill_details?.ip_admission_id ? data.bill_details.ip_admission_id : '',
                        appointmentID: data.bill_details?.ip_admission_id ? "" : (data.bill_details?.appointment_id ? data.bill_details.appointment_id : ''),
                        paymentMode: paymentModeValue,
                        // ipNumber: data.bill_details.ip_number,
                        previousVisitedDateAndTime:data?.patient_details?.last_visit_date ? data?.patient_details?.last_visit_date : '',
                        patientTag:(data?.patient_details?.patient_tag || data?.patient_details?.patient_tag_name || data?.patient_details?.patient_tags__patient_tag_name) ? (data?.patient_details?.patient_tag || data?.patient_details?.patient_tag_name || data?.patient_details?.patient_tags__patient_tag_name) : '',
                        consultantName: data?.bill_details?.doctor_name ? data?.bill_details?.doctor_name : '',
                        consultantID: data?.bill_details?.doctor_id ? data?.bill_details?.doctor_id : null,
                        isAppointment: data?.bill_details?.is_appointment ? data?.bill_details?.is_appointment : false,                        
                    }, () => {
                        if(this.state.discountReason != ""){
                            this.setState({
                                isSavedReason: true,
                            })
                        }
                        if(this.state.for_cancel_bill){
                            this.CalculateCancelBill()
                        }else{
                            this.getReferredDoctor(this.state.lab_id);
                            this.getTestData(this.state.lab_id);
                            this.getTestGroupData(this.state.lab_id)
                            this.getPackageData(this.state.lab_id)
                            this.getTemplateData(this.state.lab_id) 
                        }
                    });
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
    }
    getReferredSource = () => {
        RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_SOURCE + `?clinic_id=${this.props.clinic_id}`)
            .then(response => {
                if (response.data.status == "success") {
                    var data = response.data.data
                    this.setState({
                        referredSourceList: data
                    });
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
    }
    getPincodeToCity = (pincode) => {
        fetch("https://api.postalpincode.in/pincode/" + pincode)
            .then((res) => res.json())
            .then(response => {
                if (response && response[0].Status == "Success") {
                    var data = response[0].PostOffice
                    if (data && data.length > 0) {
                        this.setState({
                            city: this.state?.city ? this.state?.city : data[0].District,
                            cityState: this.state?.cityState ? this.state?.cityState : data[0].State,
                            country: this.state?.country ? this.state?.country : data[0].Country
                        })
                    }
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
    }
    getLabPatientDetails = () => {
        var { savedBillDetails } = this.state
        RestAPIService.getAll(Serviceurls.LAB_PATIENT_DETAILS + "?patient_id=" + savedBillDetails.patient_id)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        patientDetails: response.data.data
                    });
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
    }
    getReferredDoctor = (id) => {
        RestAPIService.getAll(Serviceurls.BILLING_REFERRED_DOCTOR + '?laboratory_id=' + id)
            .then(response => {
                if (response.data.status == "success") {
                    let updatedReferredDoctorList = [...response.data.data];
                    updatedReferredDoctorList.unshift({
                        name: 'self',
                        reg_no: '',
                    });
                    this.setState({
                        referredDoctorList: updatedReferredDoctorList
                    });
                } else if (response.data.status == "fail") {
                    // this.showToastBox(true, response.data.message, Constants.ErrorToast)
                }
            })
            .catch(e => {
                //  this.showToastBox(true, e.response.data.message, Constants.ErrorToast)
            });
    }
    renderSubHeader = (header, value) => {
        const { t } = this.props
        let allow_billing_date_range = localGetItem("allow_billing_date_range");
        let howManyDays = (localGetItem("allow_billing_date_fo") && allow_billing_date_range && allow_billing_date_range !== "Default") ? parseInt(allow_billing_date_range?.split(" ")[1]) : 0;
        return (
            <div>
                {header == "Invoice Date" ?
                    <div className="Lab_home_subHeader">
                        <p className="headerStyle">{header + " :  "}</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {/* <DatePicker
                                className="Lab_home_invoice_date"
                                inputFormat={"dd-MM-yyyy"}
                                autoComplete="off"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                maxDate={DateTime.now()}
                                disabled={this.state.for_cancel_bill}
                                value={this.state.invoice_date}
                                onChange={(newDate) => {
                                    this.setState({
                                        invoice_date: newDate
                                    })
                                }}
                                renderInput={(params) => <TextField variant="standard" {...params} size='small'
                                    error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                            /> */}
                             <DateTimePicker
                                open={this.state.DateOpen}
                                disabled={true} // this.state.for_cancel_bill || this.state.isForEditBill ---> Previous Condition
                                onOpen={() => { this.setState({ DateOpen: true }) }}
                                onClose={() => { this.setState({ DateOpen: false }) }}
                                inputFormat="DD-MM-YYYY & hh:mm A"
                                maxDateTime={DateTime.now()}
                                minDate={ howManyDays ? DateTime.now().minus({ days: howManyDays }) : null}
                                maxDate={DateTime.now()}
                                value={this.state.invoice_date}
                                onChange={(newDate) => this.setState({ invoice_date: newDate, DateTimeChanged : true })}
                                renderInput={(params) => <TextField {...params} size='small' 
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => { this.setState({ DateOpen: true }) }}
                                    error={this.state.expDateerror}
                                    helperText={this.state.expDatehelpertext}
                                    sx={{ width: "15vw" }} />}
                            />
                        </LocalizationProvider>
                    </div> :
                    <div className="Lab_home_subHeader">
                        <p className="headerStyle">{header + " : "}</p>
                        <p className="headerValue">{value}</p>
                    </div>
                }


            </div>
        )
    }
    alphaValidation = (num) => {
        return !/[^A-Z a-z]+/.test(num)
    }
    numberValidation(num) {
        return /^\d+$/.test(num);
    }
    mobileValidation(num) {
        return !/[^0-9+-]+/.test(num);
    }
    renderPatientDetail = (header, key, value) => {
        const { t } = this.props
        return (
            <div>
                {key == "date" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disabled={this.state.patient_id ? true : false}
                            className="Lab_home_dateOfBirth"
                            id="Lab_home_dateOfBirth"
                            label={t("DateofBirth") + "*"}
                            inputFormat={"dd-MM-yyyy"}
                            autoComplete="off"
                            value={this.state.dateOfBirth}
                            maxDate={DateTime.now()}
                            onChange={(newDate) => {
                                var age = this.handleAgeCalculation(newDate)
                                // if(age >= 0){
                                this.setState({
                                    dateOfBirth: newDate,
                                    age: age
                                })
                                // }
                            }}
                            renderInput={(params) => <TextField {...params} size='small' sx={{ marginRight: '0.4vw' }}
                                error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                        />
                    </LocalizationProvider> : key == "gender" ?
                        <FormControl size='small'
                            id="eMed_dropDown_Lab_home">
                            <InputLabel id="demo_simple-select-label">{t("Gender") + "*"}</InputLabel>
                            <Select
                                disabled={this.state.patient_id ? true : false}
                                size='small'
                                label={t("Gender")}
                                value={this.state.gender}
                                onChange={(e) => {
                                    this.setState({
                                        gender: e.target.value
                                    })
                                }}
                            >
                                <MenuItem key={1} value={"Male"}>{t("Male")}</MenuItem>
                                <MenuItem key={2} value={"Female"}>{t("Female")}</MenuItem>
                                <MenuItem key={3} value={"Trans Gender"}>{t("TransGender")}</MenuItem>


                            </Select>
                        </FormControl>
                        : key === "title" ?
                            <FormControl size='small' id="eMed_dropDown_Lab_home_Title">
                                <InputLabel id="demo_simple-select-label">{t("Title")}</InputLabel>
                                <Select
                                    disabled={this.state.patient_id ? true : false}
                                    size='small'
                                    label="Title"
                                    sx={{ width: "7vw" }}
                                    value={this.state.title}
                                    onChange={(e) => {
                                        this.setState({
                                            title: e.target.value
                                        }, () => {
                                            var states = this.state
                                            if (states.title == "Mr") {
                                                states["gender"] = "Male";
                                            } else if (states.title == "Ms" || states.title == "Mrs") {
                                                states["gender"] = "Female";
                                            }
                                            this.setState({ states })
                                        })
                                    }}
                                >
                                    <MenuItem key={1} value={"Dr"}>{t("Dr")}</MenuItem>
                                    <MenuItem key={2} value={"Mr"}>{t("Mr")}</MenuItem>
                                    <MenuItem key={3} value={"Ms"}>{t("Ms")}</MenuItem>
                                    <MenuItem key={4} value={"Mrs"}>{t("Mrs")}</MenuItem>
                                    <MenuItem key={5} value={"Baby of"}>{t("Baby of")}</MenuItem>
                                    <MenuItem key={6} value={"Master"}>{t("Master")}</MenuItem>
                                    <MenuItem key={7} value={"Baby"}>{t("Baby")}</MenuItem>

                                </Select>
                            </FormControl> :
                            key == "mobileNumber" ?
                                <TextField
                                    disabled={this.state.patient_id ? true : false}
                                    required={header == "Mobile Number" ? true : false}
                                    // className="Lab_home_textInput"
                                    // id="Lab_home_textInput"
                                    label={header}
                                    autoComplete="off"
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{"+91"}</InputAdornment>
                                    }}
                                    value={value ? value : ""}
                                    sx={{ width: header == "Mobile Number" ? "11vw" : null, height: '7vh' }}
                                    onChange={(e) => {
                                        var states = this.state
                                        var isMobile = this.mobileValidation(e.target.value)
                                        if (key == "mobileNumber") {
                                            let mobileNum = e.target.value
                                            if( (isMobile && mobileNum.length<11) ||  e.target.value == "" ) {
                                                states[key] = e.target.value
                                                if (e.target.value.length === 10) { this.getMobileNumSearchList() }
                                            }
                                        }
                                        this.setState({ states })
                                    }}

                                /> :
                                key != "" ?
                                    <TextField
                                        disabled={this.state.patient_id || key === 'patientTag' ? true : false}
                                        required={header == "Patient Name" || header == "Age" ? true : false}
                                        className="Lab_home_textInput"
                                        id="Lab_home_textInput"
                                        label={header}
                                        autoComplete="off"
                                        size="small"
                                        value={value ? value : ""}
                                        sx={{ width: (header === "Pincode") ? "7vw" : header == "Age" ? "7vw" : header == "Address" ? "13.5vw" : header == "State" ? "13vw" : null, height: '7vh' }}
                                        onChange={(e) => {
                                            var states = this.state
                                            var isAlpha = this.alphaValidation(e.target.value)
                                            var isNum = this.numberValidation(e.target.value)
                                            var isMobile = this.mobileValidation(e.target.value)
                                            if (key == "patientName") {
                                                if (isAlpha || e.target.value == "") {
                                                    states[key] = e.target.value
                                                }
                                            } else if (key == "pincode") {
                                                var pincode = e.target.value
                                                if ((isNum && pincode.length < 7) || e.target.value == "") {
                                                    states[key] = e.target.value
                                                    states["city"] = states["city"] ? states["city"] : ""
                                                    states["cityState"] = states["cityState"] ? states["cityState"] : ""
                                                    states['country'] = states['country'] ? states['country'] : ""
                                                    this.setState({
                                                        states
                                                    }, () => {
                                                        if (this.state.pincode && this.state.pincode.length === 6) {
                                                            this.getPincodeToCity(this.state.pincode)
                                                        }
                                                    })
                                                }
                                            } else if (key == "age") {
                                                var age = e.target.value
                                                if ((isNum && age >= 0 && age <= 130) || e.target.value == "") {
                                                    if (age >= 0 && age <= 130 || age === "") {
                                                        states[key] = e.target.value
                                                        states["dateOfBirth"] = null
                                                        this.setState({
                                                            states,
                                                        }, () => {
                                                            var approxDob = this.handleFindApproxDob(this.state.age);
                                                            this.setState({
                                                                dateOfBirth: this.state.age ? approxDob : null
                                                            })
                                                        })
                                                    }
                                                }
                                            } else {
                                                states[key] = e.target.value
                                            }
                                            this.setState({ states })
                                        }}

                                    /> : null
                }
            </div>


        )
    }
    renderAutoSuggesstionRefferedSource = (label, list) => {
        const { t } = this.props
        return (
            <div>
                <Autocomplete
                    disabled={this.state.for_cancel_bill}
                    openOnFocus
                    options={this.state.referredSourceList}
                    getOptionLabel={(options) => typeof(options) === 'string' ? options : (options.source_name ? options.source_name : options ? options : this.state.referralSource)}
                    onChange={(e, value) => {
                        this.setState({
                            referralSource: value,
                            referredSourceDetails: value,
                        })
                    }}
                    value={this.state.referralSource ? this.state.referralSource : null}
                    size="small"
                    id="combo-box-demo"
                    sx={{ width: "13.2vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </div>
        )
    }
    renderAutoSuggesstionRefferedDoctor = (label) => {
        const { t } = this.props
        return (
            <div>
                <Autocomplete
                    disabled={this.state.for_cancel_bill}
                    options={this.state.referredDoctorList}
                    openOnFocus
                    getOptionLabel= {(options) => typeof(options) === 'string' ? options : (options.name && options.qualification) ? `${options.name} - ${options.qualification}` : options.name ? options.name : this.state.referredBy }
                    onChange={(e, value) => {

                        this.setState({
                            referredBy: value,
                            refDoctorDetails: value,
                        })
                    }}
                    onInputChange={(event, newvalue) => {
                        this.setState({ referredBy: newvalue })
                    }}
                    value={this.state.referredBy ? this.state.referredBy : null}
                    size="small"
                    id="combo-box-demo"
                    sx={{ width: "13.7vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </div>
        )
    }
    renderTestNameAutoSugg = (label) => {
        const { t } = this.props
        let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
        return (
            <div>
                <Autocomplete
                    disabled={this.state.for_cancel_bill}
                    openOnFocus
                    options={this.state.testConfigData}
                    getOptionLabel={(options) => `${showCode ? options?.test_code ? `${options.test_code} / ` : '' : ''} ${options.test_name} - [${options.grp_name}] - ${CurrencySymbol}${options.test_amt}`}
                    onChange={(e, value) => {

                        this.setState({
                            testRow: value,
                            testName: value
                        })
                    }}
                    value={this.state.testName ? this.state.testName : null}
                    size="small"
                    id="combo-box-demo"
                    sx={{ width: "13vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </div>
        )
    }
    renderTestGroupAutoSugg = (label) => {
        const { t } = this.props
        let showGRPCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
        return (
            <div>
                <Autocomplete
                    disabled={this.state.for_cancel_bill}
                    options={this.state.testGroupDataList}
                    getOptionLabel={(options) => `${showGRPCode ? options?.grp_test_code ? `${options?.grp_test_code} / ` : '' : ''} ${options.name}`}
                    openOnFocus
                    onChange={(e, value) => {

                        this.setState({
                            testGroupRow: value,
                            testGroup: value
                        })
                    }}
                    value={this.state.testGroup ? this.state.testGroup : null}
                    size="small"
                    id="combo-box-demo"
                    sx={{ width: "13vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id}>
                            {`${showGRPCode ? option?.grp_test_code ? `${option?.grp_test_code} / ` : '' : ''} ${option.name}`}
                        </li>
                    )}
                />
            </div>
        )
    }

    renderTestPacakageAutoSugg = () => {
        const {t} = this.props
        let showPAKCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
        return (
            <div>
                <Autocomplete
                    disabled={this.state.for_cancel_bill}
                    options={this.state.testPackageDataList}
                    getOptionLabel= {(options) => `${showPAKCode ? options?.package_test_code ? `${options?.package_test_code} / ` : '' : ''}${options.package_name}`}
                    openOnFocus
                    onChange={(e, value) => {
                        this.setState({
                            testPackage: value,
                            testPackageRow: value
                        })
                    }}
                    value = {this.state.testPackage ? this.state.testPackage : null }
                    size="small"
                    id="combo-box-demo"
                    sx={{ width: "13vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={"Test Package"} />}
                />
            </div>
        )
    }

    renderTestTemplateAutoSugg = () => {
        return (
            <div>
                <Autocomplete
                    options={this.state.testTempDataList}
                    getOptionLabel={(options) => options.template_name}
                    openOnFocus
                    onChange={(e, value) => {
                        this.setState({
                            testTemplate: value,
                        })
                    }}
                    value={this.state.testTemplate ? this.state.testTemplate : null}
                    size="small"
                    id="combo-box-demo"
                    sx={{ width: "13vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={"Test Template"} inputProps={{...params.inputProps, emed_tid:"labBilling_TestTemplate"}} />}
                />
            </div>
        )

    }

    renderConsultantName = () => {
        var states = this.state
        return(
            <Box>
                <Autocomplete
                    disabled={this.state.for_cancel_bill || this.state.isAppointment}
                    options={this.state.ConsultantDataList}
                    openOnFocus
                    getOptionLabel= {(options) => typeof(options) === 'string' ? options : (options.doctor_name) ? `${options.doctor_name} ${options.qualification}` : null  }
                    onChange={(e, value) => {
    
                        this.setState({
                            consultantName: value?.doctor_name,
                            consultantID: value?.doctor_id
                        })
                    }}
                    onInputChange={(event, newvalue) => {
                            this.setState({ consultantName: newvalue })
                    }}
                    value = {this.state.consultantName || null}
                    size="small"
                    id="combo-box-deo"
                    sx={{ width: "13vw", height: "5vh" }}
                    renderInput={(params) => <TextField {...params} label={'Consultant Name'} />}
                />
            </Box>
        )
    }

    renderRadioButton = (label, value) => {
        const { t } = this.props
        return (
            <div className="Lab_home_Radio">
                <Radio
                    checked={value}
                    onChange={(e) => {
                        this.setState({
                            selectedBillType: e.target.value,

                        })
                    }}
                    value={value}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': label }}
                />
                <p>{label}</p>
            </div>
        )
    }

    handleChange = (event) => {
        let states = this.state;
        let name = event.target.name;
        let value = event.target.value.trimStart();
        let isNum = name.includes("No");
        let isValid = false;

        if (isNum) {
            isValid = CommonValidation.ALPHA_NUMARIC(value);
            if (value === "" || isValid && value.length <= 16) {
                states[name] = value;
            }
        } else {
            isValid = this.numberValidation(value);
            if (value === "" || isValid && value > 0 && +(value) <= +(states["totalBillAmount"])) {
                states[name] = value;
            }
        }

        this.setState({
            states
        },() => {
            if (this.state.isCash || this.state.isCard || this.state.isBankTranfer || this.state.isCheque || this.state.isUPI || this.state.isInsurance) {
                let total_amount = (+this.state.cashAmt) + (+this.state.cardAmt) + (+this.state.bankTfrAmt) + (+this.state.upiAmt) + (+this.state.chequeAmt)
                if (total_amount < this.state.totalBillAmount) {
                    this.setState({ creditPayment: true })
                } else {
                    this.setState({ creditPayment: false })
                }
            }
        })
    }

    renderPaymentTextField = (label, key, value, image, isRef) => {
        const { t } = this.props
        return (
            // <TextField
            //     label={t(label)}
            //     disabled={this.state.selectedPaymentType === "1" && (key === "referenceNo" || key === "onlinePaidValue") || ((this.state.selectedPaymentType === "2" || this.state.selectedPaymentType === "4" || this.state.selectedPaymentType === "5") && key === "cashCollected") || !this.state.selectedPaymentType  ? true : false}
            //     size="small"
            //     inputProps={{
            //         style: {
            //             height: "2vh",
            //         },
            //     }}
            //     placeholder={ key == "cashCollected" || key == "onlinePaidValue" ? "0" : "" }
            //     value={value ? value : ""}
            //     onChange={(e) => {
            //         var states = this.state
            //         var value = (e.target.value) ? +(e.target.value) : 0
            //         if(key == "cashCollected" || key == "onlinePaidValue") { 
            //         var isNum = this.numberValidation(value)
            //         if(isNum || e.target.value == ""){
            //             states[key] = e.target.value
            //             this.setState({
            //                 states
            //             }, () => {
            //                 if(this.state.selectedPaymentType == 3) {
            //                     if(key == "cashCollected")  {
            //                        var online_payment = this.state.totalBillAmount - this.state.cashCollected
            //                        this.setState({
            //                            onlinePaidValue: online_payment
            //                        })
            //                     } else if(key == "onlinePaidValue") {
            //                         var cash_collected = this.state.totalBillAmount - this.state.onlinePaidValue
            //                         this.setState({
            //                             cashCollected: cash_collected
            //                         })                    
            //                     }
            //                  } 
            //             })
            //         }
            //       } else {
            //         states[key] = e.target.value
            //         this.setState({
            //             states
            //         })    
            //     }

            //     }}
            // />
            <TextField
                sx={{ marginRight: "0.5vw", width: "10vw" }}
                size="small"
                autoComplete="off"
                label={t(label)}
                name={key}
                value={value ? value : ""}
                InputProps={{
                    startAdornment: isRef ? null : (
                        <InputAdornment position="start">
                            <img src={image} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                onChange={this.handleChange}
            />
        )
    }
    roundOffCalculation = () => {
        var states = this.state;

        var roundOffAmount = states.discountAmount % 1;
        var totalPaymentAmount = Math.round(states.discountAmount)
        // states["totalPayableAmount"] = totalPaymentAmount; 

        if (parseFloat(roundOffAmount) >= 0.5) {
            states["roundOff"] = (1 - parseFloat(roundOffAmount).toFixed(2))
        } else if (parseFloat(roundOffAmount) < 0.5) {
            states["roundOff"] = "-" + parseFloat(roundOffAmount).toFixed(2)
        }
        this.setState({
            states
        })
    }
    payableAmountCalculation = () => {
        let totalPayableAmount = 0
        if (this.state.discountAmount && this.state.netAmount > 0) {
            totalPayableAmount = this.state.netAmount - parseInt(this.state.discountAmount)
            this.setState({
                totalBillAmount: Math.round(totalPayableAmount),
                balance: this.state.isCash ? Math.round(totalPayableAmount) : 0,
                receivedamount: this.state.isCash ? 0 : Math.round(totalPayableAmount),
            }, () => {
                if (this.state.totalBillAmount === 0) {
                    this.setState({
                        cashAmt: 0,
                        cardAmt: 0,
                        cardNo: null,
                        upiAmt: 0,
                        upiNo: null,
                        bankTfrAmt: 0,
                        bankTfrNo: null,
                        chequeAmt: 0,
                        chequeNo: null,
                        isCash: false,
                        isCard: false,
                        isUPI: false,
                        isCheque: false,
                        isBankTranfer: false,
                    })
                }
            })
        } else {
            this.setState({
                totalBillAmount: this.state.discountAmount == 0 ? this.state.netAmount : this.state.totalBillAmount,
                balance: this.state.isCash ? this.state.totalBillAmount : 0,
                receivedamount: this.state.isCash ? 0 : Math.round(this.state.totalBillAmount)
            }, () => {
                if (this.state.totalBillAmount === 0) {
                    this.setState({
                        cashAmt: 0,
                        cardAmt: 0,
                        cardNo: null,
                        upiAmt: 0,
                        upiNo: null,
                        bankTfrAmt: 0,
                        bankTfrNo: null,
                        chequeAmt: 0,
                        chequeNo: null,
                        isCash: false,
                        isCard: false,
                        isUPI: false,
                        isCheque: false,
                        isBankTranfer: false,
                    })
                }
            })
        }
    }
    calculateDiscountAmount = (key) => {

        let { discountAmount, discountPercentage, netAmount } = this.state;

        if (((discountPercentage > 0 && netAmount > 0) || discountPercentage == 0) && key == "discountPercentage") {
            var amount = (netAmount * parseInt(discountPercentage)) / 100
            this.setState({
                discountAmount: parseFloat(amount).toFixed(2)
            }, () => {
                this.roundOffCalculation()
                this.payableAmountCalculation()
            })
        } else if ((netAmount > 0 || discountAmount == 0) && key == "discountAmount") {
            var percentage = discountAmount ? (parseInt(discountAmount) * 100) / netAmount : 0
            this.setState({
                discountPercentage: percentage ? parseInt(percentage).toFixed(2) : 0
            }, () => {
                this.roundOffCalculation()
                this.payableAmountCalculation()
            })
        }
    }

    onChangeDiscount = (event) => {
        var value = (event.target.value) ? +(event.target.value) : 0
        var isNum = this.numberValidation(value)
        if (((event.target.name === "discountPercentage" && value >= 0 && value <= 100 && isNum) || value == 0)) {
            this.setState({
                discountPercentage: value % 1 === 0 ? value : value.toFixed(2) ? value.toFixed(2) : "",
                cashCollected: 0,
                onlinePaidValue: 0
            }, () => {
                this.calculateDiscountAmount("discountPercentage")
            })
        } else if ((event.target.name === "discountAmount" && value >= 0 && isNum && value <= this.state.netAmount) || value == 0) {
            this.setState({
                discountAmount: value % 1 === 0 ? value : value.toFixed(2) ? value.toFixed(2) : "",
                cashCollected: 0,
                onlinePaidValue: 0
            }, () => {
                this.calculateDiscountAmount("discountAmount")
            })

        }
    }

    concessionManditoryPopup = (names) => {
        if ((this.state.discountAmount != "0.00" || this.state.discountPercentage) && (this.state.discountReason === "")) {
            if (names === 'discountAmount' || names === 'discountPercentage') {
                this.setState({ discountReasonPopup: true })
            }
        }
    }

    onFocus = () => {
        this.setState({
            isOnFocus: true
        })
    }
    onBlur = () => {
        this.setState({
            isOnFocus: false
        },()=>{
            this.concessionManditoryPopup("discountPercentage" )
        })
    }
    checkFloat = (num) => {
        return Number(num) === num && num % 1 != 0
    }

    verifyAmtNo() {
        const { t } = this.props;
        let states = this.state;
        let msg = "";
        if (states["isCash"] && !states["cashAmt"]) {
            msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cash")} ${t("Amount")}`
        }

        if (states["isCard"]) {
            if (!states["cardAmt"] && !states["cardNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Card")} ${t("Amount")} ${t("And")} ${t("CardTransactionNumber")}`
            } else if (!states["cardAmt"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Card")} ${t("Amount")}`
            } else if (!states["cardNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("CardTransactionNumber")}`
            }
        }
        if (states["isUPI"]) {
            if (!states["upiAmt"] && !states["upiNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("UPI")} ${t("Amount")} ${t("And")} ${t("UPITransactionNumber")}`
            } else if (!states["upiAmt"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("UPI")} ${t("Amount")}`
            } else if (!states["upiNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("UPITransactionNumber")}`
            }
        }
        if (states["isBankTranfer"]) {
            if (!states["bankTfrAmt"] && !states["bankTfrNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("BankTransfer")} ${t("Amount")} ${t("And")} ${t("BankTransactionNumber")}`
            } else if (!states["bankTfrAmt"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("BankTransfer")} ${t("Amount")}`
            } else if (!states["bankTfrNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("BankTransactionNumber")}`
            }
        }
        if (states["isCheque"]) {
            if (!states["chequeAmt"] && !states["chequeNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cheque")} ${t("Amount")} ${t("And")} ${t("Cheque Number")}`
            } else if (!states["chequeAmt"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cheque")} ${t("Amount")}`
            } else if (states["chequeAmt"] && !states["chequeNo"]) {
                msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cheque Number")}`
            }
        }
        if(states["isInsurance"]){
            if(!states.insuranceAmt && !states.insuranceTfrNo && !states.cpyName && !states.policyNo){
                msg = "Please Enter All Fields"
            } else if(!states.insuranceAmt){
                msg = "Please Enter Insurance Amount"
            } else if(!states.insuranceTfrNo){
                msg = "Please Enter Insurance Transaction Number"
            } else if(!states.cpyName){
                msg = "Please Enter Company Name"
            } else if(!states.policyNo){
                msg = "Please Enter Policy Number"
            }
        }
        return msg
    }


    renderPaymentField = (label, value) => {
        let discountPercentage = (this.checkFloat(parseFloat(this.state.discountPercentage)) && !this.state.isOnFocus) ? parseFloat(this.state.discountPercentage).toFixed(2) : parseFloat(this.state.discountPercentage)
        let discountAmount = (this.checkFloat(parseFloat(this.state.discountAmount)) && !this.state.isOnFocus) ? parseFloat(this.state.discountAmount).toFixed(2) : parseFloat(this.state.discountAmount)

        return (
            <div    >
                {label === "Discount %" ?
                    <div className="Lab_Home_payment_text">
                        <Typography sx={{ flex: 0.3, padding: "0vw" }}  >{label}</Typography>
                        <div className="discountTextField">
                            < TextField
                                onFocus={this.onFocus}
                                onBlur={()=>{this.onBlur(("discountPercentage"))}}
                                sx={{ marginRight: "0.5vw" }}
                                name="discountPercentage"
                                size="small"
                                value={discountPercentage ? discountPercentage : ""}
                                placeholder={"0"}
                                // inputProps={{ textAlign: "right" }}
                                inputProps={{
                                    style: {
                                        textAlign: "right",
                                        height: "2vh"
                                    },
                                }}
                                disabled={((this.state.billSummaryList && this.state.billSummaryList.length > 0) && !this.state.for_cancel_bill )? false : true}
                                onChange={(e) => {

                                    this.onChangeDiscount(e)
                                }}
                                type={"text"}
                            />
                            < TextField
                                inputRef = {label === "Discount %" ? this.discountRef : null}
                                name="discountAmount"
                                sx={{ marginRight: "0.5vw" }}
                                placeholder={CurrencySymbol + 0}
                                size="small"
                                disabled={((this.state.billSummaryList && this.state.billSummaryList.length > 0) && !this.state.for_cancel_bill ) ? false : true}
                                inputProps={{
                                    style: {
                                        textAlign: "right",
                                        height: "2vh"
                                    },
                                }}
                                value={discountAmount ? discountAmount : ""}
                                onChange={this.onChangeDiscount}
                                type={"text"}
                                onBlur={() => { this.concessionManditoryPopup("discountAmount") }}

                            />
                            {(this.state.discountPercentage || this.state.discountAmount != "0.00") && !this.state.for_cancel_bill?
                                <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : ""}>
                                    <Box onClick={() => this.setState({ discountReasonPopup: true, discountReason: this.state.discountReason ? this.state.discountReason : "" })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
                                </Tooltip> :
                                <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
                            }
                        </div>
                    </div> :
                    <div className="Lab_Home_payment_text">
                        <Typography sx={{ flex: 0.5 }} >{label}</Typography>
                        <Typography sx={{ flex: 0.4 }} align="right">{`${CurrencySymbol}` + value}</Typography>
                    </div>
                }
            </div>
        )
    }
    onPressPaymentReceived = () => {
        const { t } = this.props
        let states = this.state;
        let paymentMode = [];
        let valid = this.verifyAmtNo();
        let CashAmt = states.cashAmt === "" ? 0 : +states.cashAmt;
        let CardAmt = states.cardAmt === "" ? 0 : +states.cardAmt;
        let UPIAmt = states.upiAmt === "" ? 0 : +states.upiAmt;
        let bankTransferAmt = states.bankTfrAmt === "" ? 0 : +states.bankTfrAmt;
        let ChequeAmt = states.chequeAmt === "" ? 0 : +states.chequeAmt;
        let insuranceAmt = states.insuranceAmt === "" ? 0 : +states.insuranceAmt
        let totalAmt = (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + insuranceAmt);
        let received_amount = (+states.totalBillAmount === totalAmt) ? totalAmt : 0;

        this.setState({
            isErrorMsg: false,
            isErrorMsgText: "",
            isReceivePayment: false,
            isSuccessMsg: false,
            isSuccessMsgText: ""
        })
        try {
            let startDate = new Date(this.state.invoice_date)
            let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let date = this.state.invoice_date ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}` : ""
            // let date = this.state.invoice_date && DateTime.fromJSDate(this.state.invoice_date).toFormat('yyyy-MM-dd')
            // let invoice_date = (this.state.savedBillDetails && Object.keys(this.state.savedBillDetails).length > 0) || (this.state.statusDetailList && Object.keys(this.state.statusDetailList).length > 0) ? this.state.invoice_date : date
            if (states.isCash) { paymentMode.push(1) }
            if (states.isCard) { paymentMode.push(2) }
            if (states.isUPI) { paymentMode.push(3) }
            if (states.isBankTranfer) { paymentMode.push(4) }
            if (states.isCheque) { paymentMode.push(5) }
            if (states.isInsurance) { paymentMode.push(6)}
            let LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
                let CollectedIn = ""
                if(LoggedUserData?.module_name === "front_office"){
                    // if(this.state.IPadmissionID){
                    //     CollectedIn = "IP"
                    // }else if(this.state.appointmentID){
                    //     CollectedIn = "OP"
                    // }else{
                        CollectedIn = "FO LAB"
                    // }
                }else if(LoggedUserData?.module_name === "Laboratory"){
                    CollectedIn = "LAB"
                }
            let transmitted_id = ""
            transmitted_id = this.state.savedTransmitList && Object.keys(this.state.savedTransmitList).length > 0 ? this.state.savedTransmitList.transmit_id : ""
            let bill_line_items = [];let noDiscountAmount = 0;
            var { billSummaryList } = this.state
            for (let i = 0; i < billSummaryList.length; i++) {
                bill_line_items.push({ 
                    "result_item_id": null, 
                    "lab_set_id": billSummaryList[i].lab_set_id ? billSummaryList[i].lab_set_id : billSummaryList[i].set_id ? billSummaryList[i].set_id : null, 
                    "lab_test_id": billSummaryList[i].lab_test_id ? billSummaryList[i].lab_test_id : billSummaryList[i].test_id ? billSummaryList[i].test_id : null, 
                    "test_name": billSummaryList[i].test_name ? billSummaryList[i].test_name : billSummaryList[i].set_name ? billSummaryList[i].set_name : null, 
                    "grp_name": billSummaryList[i].grp_name ? billSummaryList[i].grp_name : null,
                    "package_id": billSummaryList[i].package_id ? billSummaryList[i].package_id : null,
                    "amount": billSummaryList[i].amount ? billSummaryList[i].amount : 0, 
                    "test_units": 1, 
                    "test_rate_per_unit": billSummaryList[i].amount ? billSummaryList[i].amount : 0, 
                    "line_item_id": billSummaryList[i].line_item_id ? billSummaryList[i].line_item_id : null })
            }
            // let paymentType = parseInt(this.state.selectedPaymentType) == 1 ? "Cash" :  parseInt(this.state.selectedPaymentType) == 2 ? "Card" :  parseInt(this.state.selectedPaymentType) == 3 ? "Cash and Card" :  parseInt(this.state.selectedPaymentType) == 4 ? "UPI" :  parseInt(this.state.selectedPaymentType) == 5 ? "Bank Transfer" :  parseInt(this.state.selectedPaymentType) == 6 ? "Insurance" : ""
            this.state.billSummaryList?.forEach(elememt => {
                noDiscountAmount = noDiscountAmount + elememt.amount
            })
            let docName = ''; let regNo = ''
            if (this.state.referredBy?.name && this.state.referredBy?.qualification) {
                docName = `${this.state.referredBy.name} ${this.state.referredBy.qualification}`
                regNo = this.state.refDoctorDetails.reg_no ? this.state.refDoctorDetails.reg_no : typeof(this.state.refDoctorDetails) === 'string' ? this.state.refDoctorDetails : ''
            } else if (this.state.referredBy?.name) {
                docName = this.state.referredBy.name
                regNo = this.state.refDoctorDetails.reg_no ? this.state.refDoctorDetails.reg_no : typeof(this.state.refDoctorDetails) === 'string' ? this.state.refDoctorDetails : ''
            } else {
                docName = this.state.referredBy ? this.state.referredBy : ''
                regNo = ""
            }
            var data = {
                "billing_details": {
                    "patient_id": this.state.patient_id,
                    "acc_number": this.state.patientAccountNumber,
                    "reffered_by":  docName,
                    "referral_source": this.state.referredSourceDetails?.source_name ? this.state.referredSourceDetails?.source_name : this.state.referralSource,
                    "reffered_reg_no": regNo,
                    "invoice_date": date === "Invalid DateTime" ? date : date,
                    "total_amount": parseInt(this.state.netAmount),
                    "round_off": parseFloat(this.state.roundOff),
                    "net_amount": this.state.totalBillAmount,
                    "payment_type": "",
                    "cash_amount": states["cashAmt"] ? +(states["cashAmt"]) : 0,
                    "card_amount": states["cardAmt"] ? +(states["cardAmt"]) : 0,
                    "upi_amount": states["upiAmt"] ? +(states["upiAmt"]) : 0,
                    "bank_transfer_amount": states["bankTfrAmt"] ? +(states["bankTfrAmt"]) : 0,
                    "cheque_amount": states["chequeAmt"] ? +(states["chequeAmt"]) : 0,
                    "payment_mode": +this.state.discountPercentage === 100 ? [1] : paymentMode.length > 0 ? paymentMode : [],
                    "card_number": states["cardNo"] ? states["cardNo"] : null,
                    "upi_ref_no": states["upiNo"] ? states["upiNo"] : null,
                    "bank_ref_no": states["bankTfrNo"] ? states["bankTfrNo"] : null,
                    "cheque_ref_no": states["chequeNo"] ? states["chequeNo"] : null,
                    "received_amount": received_amount,
                    "balance_amount": 0,
                    "bill_line_items": bill_line_items,
                    "discount_percentage": parseFloat(this.state.discountPercentage),
                    "discount_amount": parseFloat(this.state.discountAmount),
                    "remarks": this.state.discountAmount != 0 ? this.state.discountReason : '',
                    "transaction_id": "",
                    "transaction_number": this.state.insuranceTfrNo,
                    "policy_number": this.state.policyNo,
                    "insurance_company_name": this.state.insuranceCompanyName,
                    "validity": this.state.validity,
                    "bill_type": "Bill",
                    "company_name": "",
                    "employee_id": "",
                    "employer_id": "",
                    "ip_number": this.state.ipNumber ? this.state.ipNumber : "",
                    "bill_summary_id": this.state.bill_summary_id,
                    "test_transmit_summary_id": transmitted_id,
                    "is_transmitted": this.state.savedTransmitList?.transmit_id ? true : false,
                    "insurance_company_name":this.state.cpyName,
                    "policy_number":this.state.policyNo,
                    "insurance_amount":+this.state.insuranceAmt,
                    "clinic_id": this.state.loggedInuserInfo.clinic_id,
                    "appointment_id": this.state.IPadmissionID ? null : this.state.appointmentID ? this.state.appointmentID : null,
                    "ip_admission_id": this.state.IPadmissionID ? this.state.IPadmissionID : null,
                    "collected_in" : CollectedIn,
                    "net_total_amount":noDiscountAmount ? +noDiscountAmount : 0,
                    "last_visit_date":this.state.previousVisitedDateAndTime ? this.state.previousVisitedDateAndTime : null,
                    "doctor_id": this.state.consultantID ? this.state.consultantID : null,
                    "doctor_name": this.state.consultantName ? this.state.consultantName : '', 
                },
                "laboratory_id" : this.state.lab_id,
                "paid_in":"FRONT OFFICE"
            }
            if (this.state.billSummaryList && this.state.billSummaryList.length == 0) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("Please Add Test Name or Test Group"),
                    isSuccess:false
                })
            } else if (valid) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: valid,
                    isSuccess:false
                })
            } else if (totalAmt > +states.totalBillAmount) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: `${t("Amount")} ${t("greaterthan")} ${t("TotalAmount")}`,
                    isSuccess:false
                })
            } else if (+states.totalBillAmount > totalAmt) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: `${t("Amount")} ${t("doesnot")} ${t("Match")} ${t("with")} ${t("TotalAmount")}`,
                    isSuccess:false
                })
            } else if (this.state.discountAmount != 0 && this.state.discountReason === ""){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Reason is mandatory.Click Note icon to enter reason",
                    isSuccess:false,
                    isLoader: false,
                })
            }
            //   else if( this.state.selectedPaymentType == 2 && this.state.onlinePaidValue == 0  ) {
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t("InvalidOnlinePay")
            //     }, () => {
            //         setTimeout(() => {
            //             this.setState({ 
            //                 isErrorMsg: false,
            //             })
            //         }, 1500);
            //     })
            //   }
            //   else if( this.state.selectedPaymentType == 3 && (this.state.cashCollected == 0 || this.state.onlinePaidValue == 0  ) ) {
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t("InvalidCashAndOnlinePay")
            //     }, () => {
            //         setTimeout(() => {
            //             this.setState({ 
            //                 isErrorMsg: false,
            //             })
            //         }, 1500);
            //     })
            //   } else if((this.state.selectedPaymentType == 4 || this.state.selectedPaymentType == 5) && this.state.onlinePaidValue == 0) {
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t("InvalidOnlinePay")
            //     }, () => {
            //         setTimeout(() => {
            //             this.setState({ 
            //                 isErrorMsg: false,
            //             })
            //         }, 1500);
            //     })
            //   } else if(this.state.selectedPaymentType == 1 && this.state.cashCollected != this.state.totalBillAmount){
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t("InvalidCashCollected")
            //     }, () => {
            //         setTimeout(() => {
            //             this.setState({ 
            //                 isErrorMsg: false,
            //             })
            //         }, 1500);
            //     })

            //   } else if((this.state.selectedPaymentType == 2 || this.state.selectedPaymentType == 4 || this.state.selectedPaymentType == 5) && this.state.onlinePaidValue != this.state.totalBillAmount){
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t("InvalidOnlinePay")
            //     }, () => {
            //         setTimeout(() => {
            //             this.setState({ 
            //                 isErrorMsg: false,
            //             })
            //         }, 1500);
            //     })

            //   }else if(this.state.selectedPaymentType == 6 && (!this.state.insuranceCompanyName || !this.state.policyNo || !this.state.transactionNo)){
            //     this.setState({
            //         isErrorMsg: true,
            //         isErrorMsgText: t("InvalidInsuranceDetail")
            //     }, () => {
            //         setTimeout(() => {
            //             this.setState({ 
            //                 isErrorMsg: false,
            //             })
            //         }, 1500);
            //     })
            //   }
            else {
                RestAPIService.create(data, Serviceurls.LAB_COMPLETE_BILL_POST)
                    .then(response => {
                        if (response.data.status == "success") {
                            var data = response.data.data
                            this.lodaerFunction(false)
                            if(this.state.is_IPlabBill === false && this.state.is_OPlabBill === false){
                                this.setState({
                                    isSuccessMsg: true,
                                    isSuccessMsgText: response.data.message,
                                    isReceivePayment: true,
                                    // patient_id: null,
                                    invoiceNumber: data && data.invoice_number ? data.invoice_number : "",
                                    patientAccountNumber: data && data.patient_account_number ? data.patient_account_number : "",
                                    bill_summary_id_Print: data.bill_summary_id,
                                    isTestReport: false,
                                    bill_summary_id: "",
                                    FoLabPatientSavedBill: {},
                                    isSuccess:false,
                                    invoice_date:new Date(),
                                    DateTimeChanged : false,
                                    referredBy: null,
                                    referralSource: null,
                                })
                            }else{
                                this.setState({
                                    isSuccessMsg: true,
                                    isSuccessMsgText: response.data.message,
                                    isReceivePayment: true,
                                    invoiceNumber: data && data.invoice_number ? data.invoice_number : "",
                                    patientAccountNumber: data && data.patient_account_number ? data.patient_account_number : "",
                                    bill_summary_id_Print: data.bill_summary_id,
                                    isTestReport: false,
                                    bill_summary_id: "",
                                    FoLabPatientSavedBill: {},
                                    isSuccess:false,
                                    invoice_date:new Date(),
                                    DateTimeChanged : false,
                                    referredBy: null,
                                    referralSource: null,
                                })
                            }
                            
                        }
                        else {
                            this.lodaerFunction(false)
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: response.data.message,
                                isSuccess:false
                            })
                        }
                    }).catch(e => {
                        this.lodaerFunction(false)
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.response.data.message,
                            isSuccess:false
                        })
                    })
            }
        } catch (e) {
            this.lodaerFunction(false)
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                isSuccess:false
            })
        }
    }
    renderPaymentTypeView = (isSelected) => {
        return (
            <Box component={'div'} display={'flex'} flexDirection={'row'} >
                {/* { this.state.selectedPaymentType != null && this.state.selectedPaymentType == 1 ?
              <div className="Lab_Home_Payment_box">
               {this.renderPaymentTextField(t("Cash Collected"), "cashCollected", this.state.cashCollected)} 
              </div> : this.state.selectedPaymentType == 2 ?
              <div className="Lab_Home_Payment_box">
                {this.renderPaymentTextField(t("ReferenceNo"), "referenceNo", this.state.referenceNo)}
                {this.renderPaymentTextField(t("OnlinePaidValue"), "onlinePaidValue", this.state.onlinePaidValue)} 
              </div> : this.state.selectedPaymentType == 3 ?
              <div className="Lab_Home_Payment_box">
                {this.renderPaymentTextField(t("CashCollected"), "cashCollected", this.state.cashCollected)}
                {this.renderPaymentTextField(t("ReferenceNo"), "referenceNo", this.state.referenceNo)}
                {this.renderPaymentTextField(t("OnlinePaidValue"), "onlinePaidValue", this.state.onlinePaidValue)} 
               </div> : this.state.selectedPaymentType == 4 || this.state.selectedPaymentType == 5 ?
               <div className="Lab_Home_Payment_box">
                 {this.renderPaymentTextField(t("ReferenceNo"), "referenceNo", this.state.referenceNo)}
                 {this.renderPaymentTextField(t("OnlinePaidValue"), "onlinePaidValue", this.state.onlinePaidValue)} 
                </div> : this.state.selectedPaymentType == 6 ?
                <div className="Lab_Home_Payment_box">
                    {this.renderPaymentTextField(t("CompanyName"), "insuranceCompanyName", this.state.insuranceCompanyName)}
                    {this.renderPaymentTextField(t("PolicyNumber"), "policyNo", this.state.policyNo)}
                    {this.renderPaymentTextField(t("Validity"), "validity", this.state.validity)}
                    {this.renderPaymentTextField(t("TransactionNo"), "transactionNo", this.state.transactionNo)}
                </div> : null
              } */}

                {/* Multi payment */}
                {this.state.isCash ?
                    <div className="Lab_Home_Payment_box">
                        {this.renderPaymentTextField(t("CashAmount"), "cashAmt", this.state.cashAmt, ImagePaths.CashIcon.default)}
                    </div> : null}
                {this.state.isCard ?
                    <div className="Lab_Home_Payment_box">
                        {this.renderPaymentTextField(t("CardAmount"), "cardAmt", this.state.cardAmt, ImagePaths.CardIcon.default)}
                        {this.renderPaymentTextField(t("CardTransactionNumber"), "cardNo", this.state.cardNo, "", true)}
                    </div> : null}
                <Box component={'div'} display={"flex"} flexDirection={"row"}>
                    {this.state.isUPI ?
                        <div className="Lab_Home_Payment_box">
                            {this.renderPaymentTextField(t("UPIAmount"), "upiAmt", this.state.upiAmt, ImagePaths.UPIIcon.default)}
                            {this.renderPaymentTextField(t("UPITransactionNumber"), "upiNo", this.state.upiNo, "", true)}
                        </div> : null}
                    {this.state.isBankTranfer ?
                        <div className="Lab_Home_Payment_box">
                            {this.renderPaymentTextField(t("BankTransferAmount"), "bankTfrAmt", this.state.bankTfrAmt, ImagePaths.BankTransferIcon.default)}
                            {this.renderPaymentTextField(t("BankTransactionNumber"), "bankTfrNo", this.state.bankTfrNo, "", true)}
                        </div> : null}
                </Box>
                {this.state.isCheque ?
                    <div className="Lab_Home_Payment_box">
                        {this.renderPaymentTextField(t("ChequeAmount"), "chequeAmt", this.state.chequeAmt, ImagePaths.ChequeIcon.default)}
                        {this.renderPaymentTextField(t("Cheque Number"), "chequeNo", this.state.chequeNo, "", true)}
                    </div> : null}
                    {this.state.isInsurance ?
                    <div className="Lab_Home_Payment_box">
                        <TextField 
                        name="insuranceAmt"
                        value={this.state.insuranceAmt}
                        onChange={(e) => {
                            let value = e.target.value
                            let num = CommonValidation.NumberWithDot(value)
                            if(num || value === ""){
                            this.setState({
                                insuranceAmt:value
                            })
                        }
                        }}
                        size="small"
                        label={t("InsuranceAmt")}
                        sx={{marginRight:"0.5vw",width:"10vw"}}
                        />
                        {this.renderPaymentTextField(t("Insurance Number"), "insuranceTfrNo", this.state.insuranceTfrNo, "", true)}
                        <TextField 
                        sx={{marginRight:"0.5vw",width:"10vw"}}
                        name="cpyName"
                        value={this.state.cpyName}
                        onChange={(e) => {
                            let value = e.target.value
                            let alpha = CommonValidation.alphanumValidation(value)
                            if(alpha || value === ""){
                                this.setState({
                                    cpyName:value
                                })
                            }
                        }}
                        size="small"
                        label={t("CompanyName")}
                        />
                        {this.renderPaymentTextField(t("Policy Number"), "policyNo", this.state.policyNo, "", true)}
                    </div> : null}
            </Box>
        )
    }
    handleAgeCalculation = date => {
        let age = "";
        let dob = DateTime.fromJSDate(date);
        var year_age = DateTime.now().diff(dob, 'years');
        age = Math.floor(year_age.years) || 0;
        return age;
    }
    handleFindApproxDob = ageEntered => {

        var year = 0;
        var month = 0;
        var days = 0;
        var tempDate;
        year = +(ageEntered)

        tempDate = DateTime.now().minus({ years: year }).toBSON();

        return tempDate;
    }
    dialogCloseFunction = () => {
        this.setState({
            isReceivePayment: false
        })
    }

    validCheck = (data) => {
        var {billSummaryList} = this.state
        var duplicate = false 
        if(billSummaryList?.length > 0 ) { 
          for (let i = 0; i < billSummaryList.length; i++){
              if(billSummaryList[i].lab_test_id && billSummaryList[i].lab_test_id == data.id){
                  duplicate = true
              } 
              if(billSummaryList[i].lab_set_id && billSummaryList[i].lab_set_id == data.id) {
                  duplicate = true
              }
              if(billSummaryList[i].package_id && billSummaryList[i].package_id == data.package_id) {
                  duplicate = true
              }
              if (data.template_id) {
                data.test_details?.map((item) => {
                    if (!item.grp_id && billSummaryList[i].lab_test_id == item.test_id) {
                        duplicate = true
                    } else if (!item.test_id && billSummaryList[i].lab_set_id == item.grp_id) {
                        duplicate = true
                    }
                })
            }
          }
        }
          return duplicate
      }
    receivePaymentClose = () => {
        this.setState({
            isReceivePayment: false
        })
        this.isClearAllData()
    }

    discountReasonPopup = () => {
        try {
            return (
                <Modal open={this.state.discountReasonPopup}>
                    <Box className='eMed_Discount_Reason'>
                        <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
                            <Typography color={Colors.SecondaryText} fontWeight={600}>Concession Reason</Typography>
                        </Box>
                        <Box m={'1vw'}>
                            <TextField
                                fullWidth
                                multiline
                                label='Reason *'
                                rows={3}
                                value={this.state.discountReason}
                                onChange={(e) => {
                                    this.setState({ discountReason: e.target.value })
                                }}
                            />
                        </Box>
                        <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant='outlined'
                                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ discountReasonPopup: false })
                                    if(!this.state.isSavedReason){
                                        this.setState({discountReason: ''})
                                    }
                                }}
                            >Close</Button>
                            <Button
                                variant='contained'
                                sx={{ width: '5vw', height: '2vw' }}
                                onClick={() => {
                                    if (this.state.discountReason !== '') {
                                        this.setState({ discountReasonPopup: false,isSavedReason: true })
                                    } else {
                                        this.successErrorMessgae("Reason is mandatory", 'error')
                                    }
                                }}
                            >Save</Button>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.successErrorMessgae(error.message, 'error')
        }
    }

    getCreateBillPrint = () => {
        const { t } = this.props
        try {
            let url;
            if (this.state.is_completed || this.state.isCredit) {
                url = Serviceurls.LAB_CREATE_BILL_COMPLETED_PRINT
            } else {
                url = Serviceurls.LAB_CREATE_BILL_PARTIAL_PRINT
            }
            RestAPIService.getAllPrint(url + "?bill_summary_id=" + this.state.bill_summary_id_Print + "&export_type=pdf" + `&laboratory_id=${this.state.lab_id}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error?.reponse?.data?.message === undefined ? error?.message : error?.reponse?.data?.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError")
            })
        }
    }

    getPatientBarcode = () => {
        try{
            RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT +  "?patient_id=" + this.state.patient_id + "&admission_id=" + this.state.IPadmissionID + "&appointment_id=" + this.state.appointmentID).
            then((response)=>{
                const file = new Blob(
                    [response.data],
                    {type : 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error)=>{
              this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.reponse.data.message
              })
            })
        }catch(error){
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError")
              })
        }
      }

    postSms = () => {
        try {
            let data = {
                "bill_summary_id": this.state.bill_summary_id_Print,
                "is_send_sms": this.state.smsActive
            }
            RestAPIService.create(data, Serviceurls.LAB_SMS_POST)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    handleCheckBox = (e, key) => {
        var states = this.state;
        states[key] = e.target.checked;
        this.setState({ states }, () => {
            if (!states.isCash) { this.setState({ cashAmt: null }) }
            if (!states.isCard) { this.setState({ cardAmt: null, cardNo: null }) }
            if (!states.isUPI) { this.setState({ upiAmt: null, upiNo: null }) }
            if (!states.isCheque) { this.setState({ chequeAmt: null, chequeNo: null }) }
            if (!states.isBankTranfer) { this.setState({ bankTfrAmt: null, bankTfrNo: null }) }
            if (!states.isInsurance) { this.setState({ insuranceAmt:"",insuranceTrfNo:"",companyName:"",policyNo:"" }) }
            if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
                this.setState({ cashAmt: this.state.totalBillAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
            }
        })
    }

    checkAllFields = () => {
        let fields = true;
        let CashAmt = this.state.cashAmt === "" ? 0 : +this.state.cashAmt;
        let CardAmt = this.state.cardAmt === "" ? 0 : +this.state.cardAmt;
        let UPIAmt = this.state.upiAmt === "" ? 0 : +this.state.upiAmt;
        let bankTransferAmt = this.state.bankTfrAmt === "" ? 0 : +this.state.bankTfrAmt;
        let ChequeAmt = this.state.chequeAmt === "" ? 0 : +this.state.chequeAmt;
        let insuranceAmt = this.state.insuranceAmt === "" ? 0 : +this.state.insuranceAmt
        let totalAmt =  (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + insuranceAmt);
        let totBillAmt = this.state.totalBillAmount;
        let valid = this.verifyAmtNo();
        if(+this.state.discountPercentage === 100){
            fields = false
        }
        else if(this.state.totalBillAmount === 0 && this.state.billSummaryList.length > 0){
            fields = false
        }
        else{
         if (this.state.isCash || this.state.isCard || this.state.isBankTranfer || this.state.isCheque || this.state.isUPI || this.state.isInsurance) {
            fields = false
         }
         if(+totalAmt != +totBillAmt || valid!=""){
            fields = true
         }
        }
        return fields
    }

    ReasonPopupClose = () => {
        this.setState({
            cancelBillClicked: false
        })
    }

    RefundMode = (mode) => {
        this.setState({
            selectedRefundMode: mode
        })
    }

    BillMode = (mode) => {
        this.setState({
            selectedBillMode: mode,
            cancelReceipt: true
        })
    }

    changeupiReferNum = (text) => {
        this.setState({
            upi_ref_num : text
        })
    }

    changeReceiptCheck = (check) => {
        this.setState({
            cancelReceipt: check
        })
    }

    addCancelComments = (datas) =>{
        this.CancelBill(datas)
    }

    CancelBill = (datas) => {
        try {
            var { history } = this.props;
            let payment = []
                if (datas.isCash) {
                    payment.push(1)
                }
                if (datas.isUPI) {
                    payment.push(3)
                }
                if (datas.isBankTranfer) {
                    payment.push(5)
                }
                let LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
                let CollectedIn = ""
                if(LoggedUserData?.module_name === "front_office"){
                    // if(this.state.IPadmissionID){
                    //     CollectedIn = "IP"
                    // }else if(this.state.appointmentID){
                    //     CollectedIn = "OP"
                    // }else{
                        CollectedIn = "FO LAB"
                    // }
                }else if(LoggedUserData?.module_name === "Laboratory"){
                    CollectedIn = "LAB"
                }
            let data = {
                bill_summary_id: this.state.bill_summary_id,
                bill_type: 'cancelled',
                cancel_reason: datas?.comments ? datas?.comments : "",
                cash_amount: datas?.CashAmount ? +datas?.CashAmount : 0,
                upi_amount: datas?.UPIAmount ? +datas?.UPIAmount : 0,
                bank_transfer_amount: datas?.BankTfrAmount ? +datas?.BankTfrAmount : 0,
                bank_ref_no: datas?.BankTfrNumber ? datas?.BankTfrNumber : "",
                upi_ref_no: datas?.UPINumber ? datas?.UPINumber : "",
                received_amount: this.state.totalBillAmount ? this.state.totalBillAmount : 0,
                net_amount: this.state.CancelledNetAmount ? this.state.CancelledNetAmount : 0,
                payment_mode: payment,
                laboratory_id: this.state.lab_id,
                add_to_advance: datas?.receiptType === "Advance" ? true : false,
                receipt_type: datas?.receiptType,
                collected_in : CollectedIn,
                clinic_id :  this.state.loggedInuserInfo?.clinic_id ? this.state.loggedInuserInfo?.clinic_id : (localGetItem("clinicId") ? +localGetItem("clinicId") : null)
            }
            let URL = Serviceurls.LAB_CANCEL_BILL 
            if((this.state.selectedBillMode == "refund_bill" && this.state.selectedRefundMode != "") || this.state.selectedBillMode == "cancel_bill"){
            this.lodaerFunction(true)
            RestAPIService.create(data, URL)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.lodaerFunction(false)
                        this.ReasonPopupClose()
                        if (this.state.loginType === "Laboratory") {
                            clearCachevalue("LabCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            this.props.history.push({ pathname: "/MainHome" })
                        } else {
                            if (this.props?.history?.location?.state?.FromBills) {
                                clearCachevalue("LabCancelBilldata");
                                clearCachevalue("isForCancelBill");
                                if (getCachevalue("patientData")) {
                                    this.props?.history.push({ pathname: '/OPHome/PatientDetails/Billing' })
                                } else {
                                    this.props?.history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' })
                                }
                            } else {
                                clearCachevalue("LabCancelBilldata");
                                clearCachevalue("isForCancelBill");
                                this.props.history.push({ pathname: '/BillingTransaction/OverAllBills' })
                            }
                        }
                        
                    }
                }).catch((error) => {
                    this.lodaerFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                      } else {
                        this.errorMessage(error.message)
                      }
                })
            }else{
                this.errorMessage('Please Select Payment Mode')
            }
        }
        catch (e) {

        }
    }
    renderCreditType = () => {
        // let { patientData } = this.state
        let details = this.state.patientData 
        let ins_name = details?.insurance_name ? details?.insurance_name : details?.insurance_company_name ? details?.insurance_company_name : this.state.InsName 
        let ins_no = details?.policy_no ? details?.policy_no : details?.policy_number ? details?.policy_number : this.state.InsNO
        let comp_name = details?.corporate_name ? details?.corporate_name : this.state.CropName
        let employee_no = details?.employee_no ? details?.employee_no : details?.id_no ? details?.id_no : this.state.CropNo
        let paymentCreditType = this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit'
        return (
            <Box>
                <Box component={'div'} display={'flex'} flexDirection={'row'} width={'40vw'} justifyContent={'space-between'}>
                    <Typography fontSize={'1vw'} fontWeight={'bold'}>Select the Credit Type</Typography>
                    <IconButton size='small' onClick={() => {
                        this.setState({
                            creditChange: false,
                            addInsurance: false,
                            addCorporate: false,
                            paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.patientData.credit_type,
                            insuranceName: null,
                            insuranceNo: '',
                            corpName: null,
                            employeeNo: '',
                            InsName: ins_name ? ins_name : this.state.patientData?.insurance_company_name ? this.state.patientData?.insurance_company_name : '',
                            InsNO: ins_no ? ins_no : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                            CropName: comp_name ? comp_name : this.state?.patientData?.company_name ? this.state?.patientData?.company_name : '',
                            CropNo: employee_no ? employee_no : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '',
                            CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : ''
    
                        })
                    }}>
                        <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
                    </IconButton>
                </Box>
                <Box component={'div'} sx={{ height: '5vw', marginTop: '2vw', display: 'flex' }}>
                    <Box component={'div'} sx={{ width: '13vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                        <Typography>Patient Credit</Typography>
                        <FormControlLabel value={'Patient credit'} control={<Radio checked={paymentCreditType === 'Patient credit'} size='small' />}
                            onClick={(e) => {
                                this.setState({
                                    paymentCreditType: e.target.value,
                                    addInsurance: false,
                                    addCorporate: false,
                                    corpName: null,
                                    employeeNo: '',
                                    insuranceName: null,
                                    insuranceNo: ''
                                })
                            }} label="Patient Credit" />
                    </Box>
                    {this.state?.IPadmissionID ?
                    <Box component={'div'} sx={{ width: '15vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Insurace Credit</Typography>
                            {ins_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addInsurance: true,
                                        addCorporate: false,
                                        paymentCreditType: 'Insurance credit',
                                        insuranceName: ins_name,
                                        insuranceNo: ins_no,
                                        corpName: null,
                                        employeeNo: ''
                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {ins_name ?
                            <FormControlLabel value={'Insurance credit'} control={<Radio checked={paymentCreditType === 'Insurance credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false,InsName: ins_name, InsNo: ins_no}) }}
                                label={<Box>
                                    {ins_name?.length > 18 ?
                                        <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                                    {ins_no?.length > 18 ?
                                        <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addInsurance: true,
                                        addCorporate: false,
                                        paymentCreditType: 'Insurance credit',
                                        insuranceName: null,
                                        insuranceNo: ''
                                    })
                                }}
                            >Add Insurance</Button>}
                    </Box>
                    : null }
                    <Box component={'div'} sx={{ width: '15vw', height: '5vw', marginLeft: '1vw' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography>Corporate Credit</Typography>
                            {comp_name ? <Tooltip title={'edit'} placement='top'>
                                <IconButton size='small' onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'Corprate credit',
                                        corpName: comp_name,
                                        employeeNo: employee_no,
                                        insuranceName: null,
                                        insuranceNo: '',
    
                                    })
                                }}>
                                    <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                                </IconButton>
                            </Tooltip> : null}
                        </Box>
                        {comp_name ?
                            <FormControlLabel value={'Corprate credit'} control={<Radio checked={paymentCreditType === 'Corprate credit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false, CropName: comp_name,CropNo: employee_no}) }}
                                label={<Box>
                                    {comp_name?.length > 18 ?
                                        <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                                    {employee_no?.length > 18 ?
                                        <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                                        : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no}</Typography>}
                                </Box>} /> :
                            <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                                onClick={() => {
                                    this.setState({
                                        addCorporate: true,
                                        addInsurance: false,
                                        paymentCreditType: 'Corprate credit',
                                    })
                                }}
                            >
                                Add Corporate</Button>}
                    </Box>
                </Box>
                {this.state.addInsurance ?
                    <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                        <Autocomplete
                            size='small'
                            id="combo-box-demo-ins"
                            options={this.state.insuranceData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
                            onChange={(e, value) => {
                                this.setState({
                                    insuranceName: value,
                                })
                            }}
                            value={this.state.insuranceName}
                            sx={{ width: '13vw', marginRight: '1vw' }}
                            renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
                        />
                        <TextField
                            size='small'
                            label="Insurance No"
                            variant="outlined"
                            sx={{ width: '13vw' }}
                            value={this.state.insuranceNo}
                            onChange={(e) => {
                                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                if (e.target.value === "" || alphanum) {
                                    this.setState({
                                        insuranceNo: e.target.value
                                    })
                                }
                            }}
                        />
                    </Box> :
                    this.state.addCorporate ?
                        <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo-ins"
                                options={this.state.corporateData}
                                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                                onChange={(e, value) => {
                                    this.setState({
                                        corpName: value,
                                    })
                                }}
                                value={this.state.corpName}
                                sx={{ width: '13vw', marginRight: '1vw' }}
                                renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
                            />
                            <TextField
                                size='small'
                                label="Employee ID"
                                variant="outlined"
                                sx={{ width: '13vw' }}
                                value={this.state.employeeNo}
                                onChange={(e) => {
                                    let alphanum = CommonValidation.alphanumValidation(e.target.value)
                                    if (e.target.value === "" || alphanum) {
                                        this.setState({
                                            employeeNo: e.target.value
                                        })
                                    }
                                }} />
                        </Box> : <Box marginTop={'1vw'} display={'flex'} height={'5vw'}></Box>}
                <Box marginTop={'2vw'} display={'flex'} justifyContent={'flex-end'}>
                    <Stack spacing={2} direction="row">
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
                            onClick={() => {
                                this.setState({
                                    creditChange: false,
                                    addInsurance: false,
                                    addCorporate: false,
                                    paymentCreditType: this.state?.selPayemtnCreditType ? this.state?.selPayemtnCreditType : this.state.patientData.credit_type,
                                    insuranceName: null,
                                    insuranceNo: '',
                                    corpName: null,
                                    employeeNo: '',
                                    InsName: ins_name ? ins_name : this.state.patientData?.insurance_company_name ? this.state.patientData?.insurance_company_name : '',
                                    InsNO: ins_no ? ins_no : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                                    CropName: comp_name ? comp_name : this.state?.patientData?.company_name ? this.state?.patientData?.company_name : '',
                                    CropNo: employee_no ? employee_no : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '',
                                    CompanyID: this.state.CompanyID ? this.state.CompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : ''
    
                                })
                            }}>Back</Button>
                        <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
                            if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                                this.errorMessage("Invalid Insurance Details", 'error')
                            } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                                this.errorMessage("Invalid Corporate Details", 'error')
                            } else {
                                this.setState({
                                    creditChange: false,
                                    paymentCreditType:this.state.paymentCreditType == 'Insurance credit' ? 'Insurance credit' : this.state.paymentCreditType == 'Corprate credit' ? 'Corprate credit' : 'Patient credit',      
                                    selPayemtnCreditType: this.state.paymentCreditType,
                                    InsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : ins_name ? ins_name : this.state.patientData?.insurance_company_name ? this.state.patientData?.insurance_company_name : '',
                                    InsNO: this.state.insuranceNo ? this.state.insuranceNo : ins_no ? ins_no : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                                    CropName: this.state.corpName?.company_name ? this.state.corpName?.company_name : comp_name ? comp_name : this.state?.patientData?.company_name ? this.state?.patientData?.company_name : '',
                                    CropNo: this.state.employeeNo ? this.state.employeeNo : employee_no ? employee_no : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '',
                                    CompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.CompanyID ? this.state.CompanyID :  this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : '',
                                    selPayemtnCreditType: this.state.paymentCreditType,
                                })
                            }
                        }}>Submit</Button>
                    </Stack>
                </Box>
            </Box>
        )
    }
    getInsuranceDetails = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            insuranceData: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.errorMessage(error.response.data.message, 'error')
                    } else {
                        this.errorMessage(error.message, 'error')
                    }
                })
        } catch (error) {
            this.errorMessage(error.message, 'error')
        }
    }
    getCorporateDetails = () => {
          try {
              RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                  .then((response) => {
                      if (response.data.status === 'success') {
                          this.setState({
                              corporateData: response.data.data,
                          })
                        }
                  }).catch((error) => {
                      if (error?.response?.data?.status) {
                          this.errorMessage(error.response.data.message, 'error')
                      } else {
                          this.errorMessage(error.message, 'error')
                      }
                  })
          } catch (error) {
              this.errorMessage(error.message, 'error')
          }
    }

    sendeBill() {
        try {
            this.setState({isEbill: true})
            let data = {
                "patient_id": this.state.patient_id,
                "lab_bill_summary_id": this.state.bill_summary_id_Print,
                "is_sms": true,
                "is_email": false
            }
            RestAPIService.create(data, Serviceurls.SMS_SEND).
                then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            isEbill: false,
                            isSMSText: 'SMS Sended Successfully!'
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message,
                        isEbill: false,
                        isSMSText: '',                      
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
                isEbill: false,
                isSMSText: '',
            })
        }
    }

    render() {
        const { t } = this.props
        let states = this.state;
        let isRefundRequired = this.state.BillRefundData?.is_refund ? true : false
        let isSelected = (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance) ;
        if (this.state.isLoader){
            return <Loader loaderOpen={this.state.isLoader} />
        }else{
        return (
            <div className="Lab_homeConatiner">
                <div className="Lab_subcontainer">
                    <div className="Lab_home_emed_mainContainer">
                        <div className="Lab_home_emed_homeheader">
                            {this.renderSubHeader("UHID", this.state.patientAccountNumber)}
                            {this.renderSubHeader("IP No", this.state.ipNumber)}
                            {this.renderSubHeader("Last Visited Date & Time", this.state.previousVisitedDateAndTime)}
                            {/* {this.renderSubHeader("Invoice No", this.state.invoiceNumber)} */}
                            {this.renderSubHeader("Invoice Date", "")}
                            {((this.state.for_cancel_bill || this.state.isForEditBill) && this.state.loginType === "Laboratory") ?  null :
                            <Autocomplete
                                disableClearable
                                disabled={((this.state.FoLabPatientSavedBill && Object.keys(this.state.FoLabPatientSavedBill).length > 0) || this.state.for_cancel_bill || this.state.isForEditBill) ? true : false}
                                value={this.state.lab_name ? this.state.lab_name : ""}
                                sx={{ width: "20vw" }}
                                size="small"
                                options={states.labNameList.length != 0 ? states["labNameList"] : []}
                                getOptionLabel={option => typeof (option) === "string" ? option : option.laboratory_name}
                                onChange={(event, newLab) => {
                                    this.setState({
                                        lab_name: newLab.laboratory_name,
                                        lab_id: newLab.laboratory_id,
                                        focusError: ""
                                    }, () => {
                                        this.getReferredDoctor(this.state.lab_id);
                                        this.getTestData(this.state.lab_id);
                                        this.getTestGroupData(this.state.lab_id)
                                        this.getPackageData(this.state.lab_id)
                                        this.getTemplateData(this.state.lab_id) 
                                        setCachevalue(this.state.lab_id,"Laboratory_id")
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{width:'18.5vw', marginLeft:'1.4vw', marginTop:'0.4vw'}}
                                        {...params}
                                        label={t('selectedLab')}
                                        autoComplete="off"
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        error={this.state.selectedLab === "" && states["focusError"].length > 0}
                                    />
                                )}
                            />
                            }
                        </div>
                    </div>
                    <div className="Lab_home_subContainers">
                        {this.renderPatientDetail(t("MobileNumber"), "mobileNumber", this.state.mobileNumber)}
                        {this.renderPatientDetail(t("Title"), "title", this.state.title)}
                        {this.renderPatientDetail(t("Patient Name"), "patientName", this.state.patientName)}
                        {this.renderPatientDetail(t("Gender"), "gender", this.state.gender)}
                        {this.renderPatientDetail(t("Email"), "email", this.state.email)}   
                        {this.renderPatientDetail(t("Patient Tag"), "patientTag", this.state.patientTag)}   
                        <div className="deteofBirthContainer">
                            {this.renderPatientDetail(t("Date"), "date", this.state.dateOfBirth)}
                            {this.renderPatientDetail(t("Age"), "age", this.state.age)}
                        </div>
                    </div>
                    <div className="Lab_home_subContainer2">
                        {this.renderPatientDetail(t("Address"), "address", this.state.address)}
                        {this.renderPatientDetail(t("Pincode"), "pincode", this.state.pincode)}
                        {this.renderPatientDetail(t("City"), "city", this.state.city)}
                        {this.renderPatientDetail(t("State"), "cityState", this.state.cityState)}
                        {this.renderPatientDetail(t("Country"), "country", this.state.country)}
                        {this.renderAutoSuggesstionRefferedSource(t("ReferralSource"), "referralSource", this.state.referralSource)}
                        {this.renderAutoSuggesstionRefferedDoctor(t("ReferredDoctor"), "referredDoctor", this.state.referredDoctor)}
                    </div>
                    <div className="labhome_table_container">
                        {this.state.for_cancel_bill ?
                            <Box component={'div'} className="Lab_Home_Add" height={"25vh"}>
                                <Box>
                                    {this.renderConsultantName()}
                                </Box>
                                <Box sx={{ borderBottom: '1px solid gray', width: '15vw' }}></Box>
                                <Box>
                                    <Typography ml={'0.5vw'} color='#616161' fontWeight={'600'}>{t("Invoice Number")}</Typography>
                                    <Typography ml={'0.5vw'} mt={'1vw'} fontWeight={'600'}>{this.state.CancelledBillNumber}</Typography>
                                </Box>
                            </Box> :
                            <div className="Lab_Home_Add" style={{gap: '1.2vw'}}>
                                <Box>
                                    {this.renderConsultantName()}
                                </Box>
                                <Box sx={{borderBottom: '1px solid gray', width: '15vw'}}></Box>
                                {this.renderTestNameAutoSugg(t("TestName"))}
                                {this.renderTestGroupAutoSugg(t("TestGroup"))}
                                {this.renderTestPacakageAutoSugg()}
                                {this.renderTestTemplateAutoSugg()}
                                {/* {this.renderTestGroupAutoSugg("Test Package")} */}
                                <Button variant="contained" id="eMed_Btn_Text" sx={{ width: "13vw" }}
                                    disabled={this.state.isTestReport || this.state.for_cancel_bill}
                                    onClick={() => {
                                        var { testRow, testGroupRow, testPackageRow, billSummaryList, testTemplate } = this.state
                                        var isDuplicate = testRow && Object.keys(testRow).length > 0 ? this.validCheck(testRow) : false
                                        var isDuplicateTestGroup = testGroupRow && Object.keys(testGroupRow).length > 0 ? this.validCheck(testGroupRow) : false
                                        var isDuplicateTestPackage = testPackageRow && Object.keys(testPackageRow).length > 0 ? this.validCheck(testPackageRow) : false
                                        var isDuplicateTestTemp = testTemplate && Object.keys(testTemplate).length > 0 ? this.validCheck(testTemplate) : false
                                        if (isDuplicate) {
                                            this.setState({
                                                isErrorMsg: true,
                                                isErrorMsgText: t("Test Already Added")
                                            })
                                        } else if (isDuplicateTestGroup) {
                                            this.setState({
                                                isErrorMsg: true,
                                                isErrorMsgText: t("Group Already Added")
                                            })
                                        } else if (isDuplicateTestPackage) {
                                            this.setState({
                                                isErrorMsg: true,
                                                isErrorMsgText: t("Package Already Added")
                                            })
                                        }else if(isDuplicateTestTemp){
                                            this.setState({
                                                isErrorMsg: true,
                                                isErrorMsgText: t("Test Already Added")
                                            })
                                        }

                                        if (this.state.testRow && Object.keys(this.state.testRow).length > 0 && !isDuplicate) {
                                            billSummaryList.push({ "test_name": testRow.name ? testRow.name : testRow.test_name, "lab_test_id": testRow.id, "amount": testRow.test_amt ? testRow.test_amt : 0, "grp_name": testRow.grp_name ? testRow.grp_name : null })
                                        }
                                        if (this.state.testGroupRow && Object.keys(this.state.testGroupRow).length > 0 && !isDuplicateTestGroup) {
                                            billSummaryList.push({ "test_name": testGroupRow.name, "lab_set_id": testGroupRow.id, "amount": testGroupRow.amount ? testGroupRow.amount : 0 })
                                        }
                                        if (this.state.testPackage && Object.keys(this.state.testPackageRow).length > 0 && !isDuplicateTestPackage) {
                                            billSummaryList.push({ "test_name": testPackageRow.package_name, "package_id": testPackageRow.package_id, "amount": testPackageRow.package_amt ? testPackageRow.package_amt : 0 })
                                        }
                                        if (this.state.testTemplate && Object.keys(this.state.testTemplate).length > 0 && !isDuplicateTestTemp) {
                                            this.state.testTemplate.test_details.map((item) => {
                                                if (item.grp_id) {
                                                    billSummaryList.push({ "test_name": item.grp_name, "lab_set_id": item.grp_id, "amount": item.grp_amt ? item.grp_amt : 0 })
                                                } else {
                                                    billSummaryList.push({ "test_name": item.test_name, "lab_test_id": item.test_id, "amount": item.test_amt ? item.test_amt : 0 })
                                                }
                                            })
                                            let discount_amt = this.state.testTemplate.discount_amt ? this.state.testTemplate.discount_amt : 0
                                            discount_amt += this.state.discountAmount
                                            this.setState({
                                                discountAmount: discount_amt
                                            }, () => { this.discountRef.current.focus() })
                                        }
                                        this.setState({
                                            billSummaryList: this.state.billSummaryList,
                                            testRow: {},
                                            testName: null,
                                            testGroup: null,
                                            testGroupRow: {},
                                            testPackage: null,
                                            testPackageRow: {},
                                            testTemplate: null,
                                        }, () => {
                                            const totalBillAmount = this.state.billSummaryList && this.state.billSummaryList.length > 0 && this.state.billSummaryList.map(item => item.amount).reduce((prev, curr) => (prev + curr));
                                            this.setState({
                                                netAmount: totalBillAmount,
                                                totalBillAmount: totalBillAmount,
                                            }, () => {
                                                this.payableAmountCalculation()
                                                this.calculateDiscountAmount("discountAmount")
                                            })
                                        })
                                    }}>{t('AddToBill')}</Button>

                            </div>

                        }
                        <div className="Lab_Home_Table">
                            <TableContainer className="Lab_Home_Table_Container">
                                <Table stickyHeader size="small">
                                    <TableHead id='Lab_Home_Table_Header' sx={{ height: "4vh" }} >
                                        <TableRow>
                                            <TableCell sx={{ flex: 0.2 }} id="lab_home_table_Head" align="left" >
                                                <IconButton size='small'>
                                                    {this.state.for_cancel_bill ? null :
                                                        <img src={ImagePaths.Delete.default} className='Lab_Home_DeleteIcon' alt='Delete'
                                                            onClick={() => {
                                                                this.setState({
                                                                    billSummaryList: [],
                                                                    netAmount: 0,
                                                                    totalBillAmount: 0,
                                                                    discountAmount: 0,
                                                                    discountPercentage: 0
                                                                })
                                                            }} />
                                                    }
                                                </IconButton>
                                                {t("S.No")}
                                            </TableCell>
                                            <TableCell sx={{ flex: 0.6 }} id="lab_home_table_Head" align="left" >{t("Service Description")}</TableCell>
                                            <TableCell sx={{ flex: 0.2 }} id="lab_home_table_Head" align="right" >{t("GrossValue")}</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="Lab_Home_Table_body">
                                        {this.state.billSummaryList && this.state.billSummaryList.length > 0 ?
                                            this.state.billSummaryList.map((item, index) => {
                                                var sno = index + 1
                                                return (
                                                    <TableRow>
                                                        <TableCell sx={{ flex: 0.2 }} align="left">
                                                            <IconButton size='small'>
                                                                {this.state.for_cancel_bill ? null :
                                                                    <img src={ImagePaths.Delete.default} className='Lab_Home_Delete' alt='Delete'
                                                                        onClick={() => {
                                                                            var { billSummaryList } = this.state
                                                                            billSummaryList.splice(index, 1)
                                                                            this.setState({
                                                                                billSummaryList
                                                                            }, () => {
                                                                                if (this.state.billSummaryList.length == 0) {
                                                                                    this.setState({
                                                                                        totalBillAmount: 0,
                                                                                        netAmount: 0,
                                                                                        roundOff: 0,
                                                                                        discountAmount: 0,
                                                                                        discountPercentage: 0
                                                                                    })
                                                                                } else {
                                                                                    const totalBillAmount = this.state.billSummaryList && this.state.billSummaryList.length > 0 && this.state.billSummaryList.map(item => item.amount).reduce((prev, curr) => (prev + curr));
                                                                                    this.setState({
                                                                                        netAmount: totalBillAmount ? totalBillAmount : 0,
                                                                                        totalBillAmount: totalBillAmount ? totalBillAmount : 0,
                                                                                    }, () => {
                                                                                        this.payableAmountCalculation()
                                                                                        this.calculateDiscountAmount()
                                                                                        this.roundOffCalculation()
                                                                                    })
                                                                                }
                                                                            })
                                                                        }} />
                                                                }
                                                            </IconButton>
                                                            {sno}
                                                        </TableCell>
                                                        <TableCell sx={{ flex: 0.6 }} align="left">{item.test_name || item.set_name}</TableCell>
                                                        <TableCell sx={{ flex: 0.2 }} align="right">{item.amount}</TableCell>
                                                    </TableRow>)
                                            }) :
                                            <TableRow className="Lab_Home_No_record">
                                                <TableCell sx={{ flex: 0.2 }} align="left">{""}</TableCell>
                                                <TableCell sx={{ flex: 0.6 }} align="center">{t("NoRecordsFound")}</TableCell>
                                                <TableCell sx={{ flex: 0.2 }} align="right">{""}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="Lab_Home_Radio_Split">
                        <div className="Lab_Radio_First_Container"></div>
                        <div className="Lab_Home_Payment_container" style={{ height: isSelected ? "20.8vh" : "20vh", overflowX: "scroll" }}>
                            {/* <div className="Lab_home_Radio_Main">
                                <p className="Lab_home_Radio_Text">{t("BillType")} </p>
                                <div className="Lab_Home_Radio_Button">
                                    <Radio
                                        size="small"
                                        checked={this.state.selectedBillType}
                                        // onChange={{}}
                                        value={this.state.selectedBillType}
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': "Regular" }}
                                    />
                                    <Typography className="Lab_Home_Text">{t("Regular")}</Typography>
                                </div>
                            </div> */}
                            {
                                ((this.state.for_cancel_bill) || this.state.isForEditBill) ? null :
                                    <div className="Lab_home_Radio_Main">
                                        <p className="Lab_home_Radio_Text">{t("ModeOfPayment")}</p>
                                        <div className="Lab_Home_Radio_Button1">
                                            {/* <RadioGroup
                                        row
                                        value={this.state.selectedPaymentType}
                                        onClick={(e) => {
                                            if(e.target.value == this.state.selectedPaymentType) {
                                                this.setState({
                                                    selectedPaymentType: "",
                                                    cashCollected: 0,
                                                    referenceNo: "",
                                                    onlinePaidValue: 0,
                                                    insuranceCompanyName:"",
                                                    policyNo:"",
                                                    validity:"",
                                                    transactionNo:""
                                                })
                                            } else { 
                                            this.setState({
                                                selectedPaymentType: e.target.value
                                            }, () => {
                                                this.setState({
                                                     cashCollected: 0,
                                                     referenceNo: "",
                                                     onlinePaidValue: 0,
                                                     insuranceCompanyName:"",
                                                     policyNo:"",
                                                     validity:"",
                                                     transactionNo:""
                                                })
                                            })
                                            }
                                        }}
                                    >
                                        <FormControlLabel value={1} control={<Radio size="small" />} label={t("Cash")} />
                                        <FormControlLabel value={2} control={<Radio size="small" />} label={t("Card")} />
                                        <FormControlLabel value={3} control={<Radio size="small" />} label={t("CashandCard")}/>
                                        <FormControlLabel value={4}control={<Radio size="small" />} label={t("UPI")} />
                                        <FormControlLabel value={5} control={<Radio size="small" />} label={t("BankTransfer")} />
                                        <FormControlLabel value={6} control={<Radio size="small" />} label={t("Insurance")} />
                                    </RadioGroup> */}
                                            <div>
                                                <FormGroup row>
                                                    <FormControlLabel control={<Checkbox size="small" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} disabled={this.state.totalBillAmount === 0 ? true : false} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cash")}</Typography>} />
                                                    {this.state.for_cancel_bill ? null : <FormControlLabel control={<Checkbox size="small" checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} disabled={this.state.totalBillAmount === 0 ? true : false} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Card")}</Typography>} />}
                                                    <FormControlLabel control={<Checkbox size="small" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} disabled={this.state.totalBillAmount === 0 ? true : false} />} label={<Typography id="eMed_recPayment_checkTxt">{t("UPI")}</Typography>} />
                                                    <FormControlLabel control={<Checkbox size="small" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} disabled={this.state.totalBillAmount === 0 ? true : false} />} label={<Typography id="eMed_recPayment_checkTxt">{t("BankTransfer")}</Typography>} />
                                                    {this.state.for_cancel_bill ? null : <FormControlLabel control={<Checkbox size="small" checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} disabled={this.state.totalBillAmount === 0 ? true : false} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cheque")}</Typography>} />}
                                                    {/* <FormControlLabel control={<Checkbox size="small" checked={this.state.isInsurance} onChange={(e) => { this.handleCheckBox(e, "isInsurance") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Insurance")}</Typography>} /> */}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                            }

                            {/* <div>
                                {this.state.selectedPaymentType == 6 ?
                                    <div className="Lab_Home_Payment_box">
                                        {this.renderPaymentTextField(t("CompanyName" + "*"), "insuranceCompanyName", this.state.insuranceCompanyName)}
                                        {this.renderPaymentTextField(t("PolicyNumber") + "*", "policyNo", this.state.policyNo)}
                                        {this.renderPaymentTextField(t("Validity"), "validity", this.state.validity)}
                                        {this.renderPaymentTextField(t("TransactionNo") + "*", "transactionNo", this.state.transactionNo)}
                                    </div> :
                                    <div className="Lab_Home_Payment_box">
                                        {this.renderPaymentTextField(t("CashCollected"), "cashCollected", this.state.cashCollected)}
                                        {this.renderPaymentTextField(t("ReferenceNo"), "referenceNo", this.state.referenceNo)}
                                        {this.renderPaymentTextField(t("OnlinePaidValue"), "onlinePaidValue", this.state.onlinePaidValue)}
                                    </div>

                                }
                            </div> */}
                            {this.renderPaymentTypeView(isSelected)}
                        </div>
                        <div className="Lab_home_Second_Container">
                            {this.renderPaymentField(t("NetTotal"), this.state.netAmount ? this.state.netAmount : 0)}
                            {this.renderPaymentField(t("Discount%"), this.state.discountPercentage)}
                            {this.renderPaymentField(t("RoundOff"), parseFloat(this.state.roundOff).toFixed(2))}
                            {this.renderPaymentField(t("Total"), this.state.totalBillAmount ? this.state.totalBillAmount : 0)}

                        </div>
                    </div>
                    {this.state.for_cancel_bill ? 
                    <Box component={'div'} style={{padding: "0.5vw 0.5vw", alignItems: 'center', display: 'flex', flexDirection:'row-reverse', height: "3.2vw"}}>
                         {/* <Typography>{(+this.state.CancelledNetAmount > 0 && +this.state.OutstandingAmount > 0) ? `₹ ${this.state?.CancelledNetAmount ? (this.state?.CancelledNetAmount < this.state?.OutstandingAmount ? this.state?.CancelledNetAmount : this.state?.OutstandingAmount) : 0} will be deducted from Outstanding Amount. ( Total Oustanding Amount : ₹ ${this.state?.OutstandingAmount} )` : ""}</Typography> */}
                    <Button
                    variant='contained'
                    // disabled={this.checkAllFields() ? true : false}
                    sx={{ textTransform: "capitalize", width: '10vw'}}
                    size='small'
                    onClick={() => {
                        this.setState({
                            cancelBillClicked: true
                        })
                    }}
                    >
                        {t("CancelBill")}
                    </Button>
                        </Box>
                        :
                        this.state.isForEditBill ?
                            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                                <Box component={'div'} style={{ padding: "0.5vw 0.5vw", alignItems: 'center', display: 'flex', justifyContent: 'space-between', height: "3.2vw", width: '70vw' }}>
                                    <Typography fontWeight={600}>{`Invoice Number : ${this.state.CancelledBillNumber}`}</Typography>
                                    <Button
                                        variant='contained'
                                        disabled={this.state.billSummaryList.length === 0 || this.state.isSuccess}
                                        sx={{ textTransform: "capitalize", width: '10vw' }}
                                        size='small'
                                        onClick={() => {
                                            this.setState({
                                                is_completed: false,
                                                is_credit: true,
                                                isSuccess: true
                                            }, () => this.onPressSavedBill(true))
                                        }}
                                    >
                                        {t("Save Changes")}
                                    </Button>
                                </Box>
                            </Box> :
                            <div className="Lab_home_buttom_view">
                                <Box sx={{ ml:'15vw', width: '17vw' }}>
                                    {( this.state.paymentCreditType == "Insurance credit") ?
                                        <div style={{ marginRight: "0.5vw" }}>
                                            {this.state.InsName?.length > 17 ?
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ marginRight: "0.5vw", fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>Insurance Patient : </div>
                                                    <Tooltip placement='top' title={this.state.InsName} arrow>
                                                        <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{this.state.InsName?.slice(0, 17) + "..."}</div>
                                                    </Tooltip>
                                                    {this.state.InsNO?.length > 10 ? 
                                                        <Tooltip placement='top' title={this.state.InsNO} arrow>
                                                            <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>| {this.state.InsNO?.slice(0, 10) + "..."}</div>
                                                        </Tooltip>
                                                    : <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.InsNO}`}</div>}
                                                </div>
                                                :
                                                <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{`Insurance Patient : ${this.state.InsName}  |  ${this.state.InsNO}`}</div>}
                                        </div>
                                        : null}
                                    {( this.state.paymentCreditType == "Corprate credit")?
                                        <div style={{ marginRight: "0.5vw" }}>
                                            {this.state.CropName?.length > 17 ?
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ marginRight: "0.5vw", fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>Corporate Patient : </div>
                                                    <Tooltip placement='top' title={this.state.CropName} arrow>
                                                        <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{this.state.CropName?.slice(0, 17) + "..."}</div>
                                                    </Tooltip>
                                                    {this.state.CropNo?.length > 10 ? 
                                                        <Tooltip placement='top' title={this.state.CropNo} arrow>
                                                            <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>| {this.state.CropNo?.slice(0, 10) + "..."}</div>
                                                        </Tooltip>
                                                    : <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{` | ${this.state.CropNo}`}</div>}
                                                </div>
                                                :
                                                <div style={{ fontSize: '0.6vw', color: '#616161', fontWeight: 'bold' }}>{`Corporate Patient : ${this.state.CropName}  |  ${this.state.CropNo}`}</div>}
                                        </div>
                                        : null}
                        </Box>
                        <Box>
                            {this.state.is_OPlabBill || this.state.is_IPlabBill ? null : <Button variant="outlined" size="small" id="eMed_Btn_Text" sx={{ width: "10vw", marginRight: "1vw" }} onClick={() => { this.isClearAllData() }}>{t("Clear")}</Button>}
                            {this.state.is_OPlabBill || this.state.is_IPlabBill ?
                                <Button
                                    variant="contained"
                                    size="small"
                                    id="eMed_Btn_Text"
                                    sx={{ width: "13vw", marginRight: "0.5vw" }}
                                            onClick={() => {
                                                if (this.state.is_OPlabBill) {
                                                    let AppID = this.state.appointmentID
                                                    clearCachevalue("isInRadiologyBilling");
                                                    this.props.history.push({
                                                        pathname: '/OPHome/PatientDetails/Billing',
                                                        state: { AppointmentID: AppID }
                                                    })
                                                } else if (this.state.is_IPlabBill) {
                                                    let AppID = this.state.appointmentID
                                                    clearCachevalue("isInRadiologyBilling");
                                                    this.props.history.push({
                                                        pathname: '/IPHome/PatientDetails/ServiceBilling',
                                                        state: { AppointmentID: AppID }
                                                    })
                                            this.setState({
                                                is_OPlabBill: false,
                                                is_IPlabBill: false,
                                            })
                                        }
                                     }}>{this.state.is_IPlabBill ? "Back IP Billing" : "Back OP Billing"}</Button> : null
                            }
                        <Button
                         variant="outlined" size="small" 
                         id="eMed_Btn_Text"
                          sx={{ width: "10vw", marginRight: "0.5vw" }}
                           onClick={() => { 
                            if (this.state.patient_id) {
                                this.setState({
                                    is_completed: false,
                                    is_credit:false,
                                    isSuccess:true
                                },() => this.onPressSavedBill())
                               
                            } else {
                                this.errorMessage("Cannot Create Patient Here")
                                // this.patientDetailsPost("Partial")

                            }
                            }}
                           >{t("Save As Draft")}</Button>
                        <Tooltip title={'Change Credit Type'} placement='top'>
                                <IconButton size='small'
                                    disabled={this.state.isSuccess || this.state.billSummaryList?.length === 0 || (!this.state.creditPayment && isSelected) ? true : false} 
                                    onClick={() => {
                                        this.setState({
                                            creditChange: true,
                                        }, () => {
                                            this.getInsuranceDetails()
                                            this.getCorporateDetails()
                                        })
                                    }}>
                                    <img style={{ width: '1.2vw', height: '1.2vw' ,marginRight:'0.5vw'}} src={(this.state.billSummaryList?.length === 0 || this.state.isSuccess) ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                                </IconButton>
                            </Tooltip>
                        <Button variant="contained" id="eMed_Btn_Text" 
                        disabled={this.state.totalBillAmount === 0 ? true : this.state.isSuccess || this.state.billSummaryList?.length === 0 || (!this.state.creditPayment && isSelected) ? true : false} size="small" sx={{ width: "10vw", marginRight: "1vw" }}
                            onClick={() => {
                                if (this.state.patient_id) {
                                    this.lodaerFunction(true)
                                    this.setState({
                                        is_completed: false,
                                        is_credit:true,
                                        isSuccess:true
                                    },()=>this.onPressSavedBill(true))
                                    
                                } else {
                                    this.errorMessage("Cannot Create Patient Here")
                                    // this.patientDetailsPost("Partial")

                                }
                            }}>{t("AddToCredit")}</Button>
                        <Button variant="contained" size="small" sx={{ width: "10vw", marginRight: "0.5vw" }}
                            disabled={this.checkAllFields() || this.state.isSuccess ? true : false}
                            id="eMed_Btn_Text"
                            onClick={() => {
                                this.setState({
                                    is_completed: true,
                                    isSuccess:true
                                })
                                if (this.state.patient_id) {
                                    this.lodaerFunction(true)
                                    this.setState({isSuccess:true},()=>this.onPressPaymentReceived())   
                                } else {
                                    this.errorMessage("Cannot Create Patient Here")
                                    // this.patientDetailsPost("Completed")
                                }
                            }}>{t("ReceivePayment")}</Button>
                        </Box>
                    </div>}
                </div>
                {this.discountReasonPopup()}
                {this.state.isReceivePayment ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.isReceivePayment}
                        onClose={this.receivePaymentClose}
                        maxWidth={"md"}
                    >
                        <div className='Lab_Home_Dialogboxwidth'>
                            <img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />
                            <h3>{this.state.isSMSText ? this.state.isSMSText : this.state.is_completed ? "Payment Received Successfully" : "Credit Added Successfully"}</h3>
                            {/* <FormControlLabel labelPlacement="start" className='eMed_create_switch' control={<Switch onClick={() => { this.setState({ smsActive: true }, () => this.postSms()) }} />} label="Receive SMS" /> */}
                            <div className="Lab_home_pop_buttom_view" style={{width:'22vw', justifyContent: 'center'}}>
                                <Button variant="outlined" size="small" sx={{height:'2vw'}} id="eMed_Btn_Text" 
                                    onClick={() => {
                                        if (this.state?.is_OPlabBill) {
                                            let patientDatas = getCachevalue("patientData")
                                            let patients = JSON.parse(patientDatas)
                                            this.setState({ isReceivePayment : false, isCredit: false, isSMSText: '', }, ()=>{
                                                this.isClearAllData()
                                                this.props.history.push({ pathname: "/Billing", state: { patientData: patients } })
                                            })
                                        } else if (this.state?.is_IPlabBill) {
                                            let patientDatas = getCachevalue("IppatientData")
                                            let IPpatients = JSON.parse(patientDatas)
                                            this.setState({ isReceivePayment : false, isCredit: false, isSMSText: '', }, ()=>{
                                                this.isClearAllData()
                                                this.props.history.push({ pathname: "/Billing", state: { IP_patientData: IPpatients } })
                                            })
                                        }else{
                                            this.setState({ patient_id: null, isCredit: false, isSMSText: '', }, () => {
                                                this.isClearAllData()
                                            })
                                        }
                                    }}>{t('NextBill')
                                    }</Button>
                                <Button variant="contained" size="small" sx={{ marginLeft: "0.5vw", height:'2vw' }}
                                    id="eMed_Btn_Text"
                                    onClick={() => {
                                        this.getCreateBillPrint()
                                        // this.isClearAllData()
                                        this.setState({
                                            isTestReport: false,
                                            // isReceivePayment: false,
                                            smsActive: false,
                                            // isCredit: true,
                                            isSMSText: ''
                                        })
                                    }}
                                >{t("PrintBill")}</Button>
                                { (this.state.allowSms && !this.state.isCredit) ?
                                    <Button variant="contained" disabled={this.state.isSMSText} size="small" sx={{ textTransform: 'none', marginLeft: '.5vw', height: '2vw' }}
                                        onClick={() => {
                                            if (!this.state.isEbill) {
                                                this.sendeBill()
                                            }
                                        }}>
                                        {this.state.isEbill ? <CircularProgress sx={{ color: 'white' }} size={20} /> : "Send eBill"}
                                    </Button>
                                    : null}
                                <Tooltip title={"Print Patient Barcode"} placement="top">
                                <IconButton id="eMed_Btn_Text" sx={{ height: '2vw' }} variant="contained" size="small"
                                    onClick={() => {
                                        this.getPatientBarcode()
                                    }}
                                ><img style={{ width: '1.8vw', height: '1.8vw' }} src={ImagePaths.PrintIcons.default} alt="print" /></IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Dialog>
                    : null}
                {this.state.searchPatientList && this.state.searchPatientList.length > 0 ?
                    <Modal open={true}>
                        <Box className="eMed_MobileNum_Main">
                            <Box className="eMed_MobileNum_Header">
                                <Typography variant='h6'>{"Patient List"}</Typography>
                                <IconButton onClick={() => { this.setState({ searchPatientList: [], common_uhid: false, }) }} marginRight={'4vw'}>
                                    <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                                </IconButton>
                            </Box>
                            <Box component={'div'} className="eMed_MobileNum_wrapper">
                                {this.state.searchPatientList.map((list, index) => {
                                    list["patient_gender"] = list.gender == "m" ? "Male" : list.gender == "f" ? "Female" : "Trans"
                                    return (
                                        <Box className="eMed_Patient_Num_Search_pop">
                                            <CommonPatientDetails data={list} isCommonUhid={this.state.common_uhid} />
                                            <IconButton onClick={() => {
                                                this.setState({
                                                    patient_id: list.patient_id,
                                                }, () => {
                                                    this.getSearchPatient()
                                                    this.setState({
                                                        searchPatientList: [],
                                                        common_uhid: false,
                                                    })
                                                })
                                            }} position="end">
                                                <PersonAddAlt1Icon color='primary' />
                                            </IconButton>
                                        </Box>

                                    )
                                })
                                }
                            </Box>
                        </Box>
                    </Modal> : null
                }
                {this.state.cancelBillClicked ? 
                    <ReasonPopupWithRefund
                        title={"Cancel Bill"}
                        AlertPopupClose={this.ReasonPopupClose.bind(this)}
                        label={"Enter the Cancel Reason"}
                        btntext={'Close'}
                        btnvarient={'outlined'}
                        btncolor={'error'}
                        btntext1={'Confirm'}
                        btnvarient1={'contained'}
                        cancelPost={this.addCancelComments.bind(this)} 
                        BillReceivedAmount={this.state.BillReceivedAmount}
                        ShowAdvance={(this.state.IPadmissionID && this.state.isCurrentIp) ? true : false}
                        payableAmount={this.state.totalBillAmount}
                        isForLab={true}
                        paymentMode={this.state.paymentMode}/>
                : null     
                }
                {this.state.creditChange ?
                <Dialog
                    className="emed_DialogboxOrder"
                    open={this.state.creditChange}
                    maxWidth={"md"}
                >
                    <div className='emed_Dialog_Appoin' style={{ width: '42vw', height: "45vh" }}>
                        {this.renderCreditType()}
                    </div>
                </Dialog> : null}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : this.state.isSuccessMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.isSuccessMsgText}
                            msgPop={this.msgClose.bind(this)}
                        /> : null
                }
            </div>
        )
        }
    }
}

export default withTranslation()(LabBilling);