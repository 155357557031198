import React, { Component } from "react";
import './IpPatientDetails.css';
import {
  Box, Paper, TextField, Button, Stack, Typography, Tooltip, Grid, Checkbox, InputAdornment, RadioGroup,
  FormControlLabel, Radio, FormGroup, Tabs, Tab, FormControl, InputLabel, MenuItem, Select, TableHead, TableCell, Table, TableRow, TableBody, TableContainer, OutlinedInput, Divider, Accordion, AccordionSummary, AccordionDetails,
  FormHelperText
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonPatientDetails, CommonPatientCard, BillSuccessPoPUp, ChecKoutSuceessPop } from "../../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Colors } from "../../../../Styles/Colors";
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import { clearCachevalue, getCachevalue, localGetItem } from "../../../../Utility/Services/CacheProviderService";
import { AmountFormat, CheckAccessFunc, convertDateTime } from "../../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../../Components/Loader';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CreditBillCompletePoP from "../../BillingTransaction/CreditBillCompletePoP";
import { CurrencySymbol } from "../../../../Utility/Constants";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


let today = new Date();
class ReceivePayment extends Component {
  constructor(props) {
    super(props);
    var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
    this.state = {
      branch_id: localGetItem("BranchId"),
      paymentDetails: [],
      selectionModel: [],
      patientData: {},
      attenderData: {},
      amountData: {},
      open: false,
      payAdv: "ReceiveAsAdvance",
      corporatePay: "",
      payable_amt: 0,
      creditAmount: null,
      paymentDate: today,
      paymentmadeBy: "",
      receiptDiscription: "",
      cashAmt: null,
      cardAmt: null,
      cardNo: null,
      upiAmt: null,
      upiNo: null,
      bankTfrAmt: null,
      bankTfrNo: null,
      chequeAmt: null,
      chequeNo: null,
      TwoThounsandCounts: "",
      FiftyCounts: "",
      FiveHundredCounts: "",
      TwentyCounts: "",
      TwoHundredCounts: "",
      TenCounts: "",
      HundredCounts: "",
      isRegular: this.props.history?.location?.state?.isFromCheckOut ? "Credit" : "regular",
      insuranceNo: "",
      isCash: true,
      isCard: false,
      isUPI: false,
      isCheque: false,
      isBankTranfer: false,
      isInsurance: false,
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: "",
      withOutAppointment: {},
      isButtonDisabled: false,
      value: this.props.history?.location?.state?.forRefund ? 'two' : "one",
      refund_amt: null,
      refunDate: today,
      refundPaymentmadeBy: null,
      LogedUserName: null,
      refundCash: true,
      refundUPI: false,
      refundBankTranfer: false,
      refcashAmt: null,
      refupiAmt: null,
      refupiNo: "",
      refupiId: "",
      refbankTfrAmt: null,
      refBankAccNo: null,
      refConbankAccNo: null,
      refbankIFSCNo: "",
      refbankName: "",
      showPassword: false,
      ApproxAmount: 0,
      insuranceAmt: null,
      TransactionNumber: "",
      CompanyName: "",
      PolicyNumber: "",
      corporate_name: "",
      employee_id: "",
      discount_percent: "0",
      serviceAmount: "",
      pendingAmount: "",
      paidAmount: "",
      AdvanceAmount: "",
      attenderMobile: "",
      attenderName: "",
      corporate_amt: "",
      cor_approval_details: "",
      Insurance_amt: "",
      Ins_approval_details: "",
      Insurance: "",
      corporate: "",
      isFromCheckOut: this.props.history?.location?.state?.isFromCheckOut ? true : false,
      CorporateList: [],
      InsuranceList: [],
      employer_id: "",
      PayForIPCredit: false,
      PayForPharmaCredit: false,
      IPPayingAmount: "",
      PharmaPayingAmount: "",
      HeaderDetails: {},
      PatientCredit: false,
      patientCreditAmount: 0,
      receiptID: null,
      pharmareceipt: null,

      //Final Discount
      openFinalDiscount: false,
      finalDiscount: '',
      finalPercentage: '',
      finalSubTotalAmount: '',
      finalTotalAmount: '',
      checkAll: true,
      checkIp: true,
      checkPharmacy: localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? true : false,
      checkRadiology: localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
      checkLab: true,
      dupliPaymentDetails: '[]',
      dupliDiscount: '',
      changed: false,
      customConfigData: {},
      CardPendingAmount: 0,
      CheckoutThePatient: false,
      AllBillDataList: [],
      BillDataList: [],
      ShowOpBills: true,
      ShowIpBills: true,
      ShowPharmaBills: ((localGetItem("Show_Pharmacy_Bills_In_FO") === "true") && localGetItem("multiPharmacy") === "false") ? true : false,
      ShowLabBills: localGetItem("multiLaboratory") === "false" ? true : false,
      ShowOtBills: true,
      ShowRadiologyBills: localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
      TotalPayableAmount: 0,
      PayableAmount: '',
      PayFromAdvance: false,
      AdvanceAmount: 0,
      isCashPayment: false,
      isCardPayment: false,
      isUPIPayment: false,
      isChequePayment: false,
      isBankTranferPayment: false,
      CashAmountPayment: "",
      CardAmountPayment: "",
      CardTransactionNumberPayment: "",
      UPINumberPayment: "",
      UPIAmountPayment: "",
      BankTfrAmountPayment: "",
      BankTfrNumberPayment: "",
      ChequeNumberPayment: "",
      ChequeAmountPayment: "",
      TwoThounsandCountsPayment: "",
      FiftyCountsPayment: "",
      FiveHundredCountsPayment: "",
      TwentyCountsPayment: "",
      TwoHundredCountsPayment: "",
      TenCountsPayment: "",
      HundredCountsPayment: "",
      CoinsCountsPayment: "",
      FiveCountPayment: "",
      is_final_bill_generated: null,
      Refund_Status: "advance",
      BalanceAmt: 0,
      isClimed: false,
      overallConcession: '',
      overallDiscountReason: '',
      concessionChecked: false,
      payableAmt: '',
      dupOverAllConcession: '',
      finalOverAllAmt: 0,
      isRoundOff: true,
      dupFinalAmt: '',
      billingConcessionType: '',
      disableBtn: false,
      overAllPercent: '',
      dupOverAllPercent: '',
      PharmacyData: JSON.parse(JSON.stringify((LoggedUserData?.pharmacy_data))),
      LabData: JSON.parse(JSON.stringify((LoggedUserData?.laboratory_data)))
    }
  }

  componentDidMount() {
    let patientDetails = getCachevalue("IppatientData")
    let patients = JSON.parse(patientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    let LogedUserName = localGetItem("LogedUserName")
    let concessionBill = localGetItem("billing_concession_type")
    this.setState({
      billingConcessionType: concessionBill
    })
    if (details?.patient_id === patients?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => this._getPaymentDetails())
    }
    this.setState({
      patientData: patients,
      LogedUserName: LogedUserName,
      corporatePay: patients?.primary_payment_type ? patients?.primary_payment_type : "",
      PatientCredit: patients?.primary_payment_type === "" || patients?.primary_payment_type === "Patient credit" ? true : false,
      isInsurance: patients?.primary_payment_type === "Insurance" ? false : false,
      CompanyName: patients?.primary_payment?.insurance_detail?.patient_insurance_company ? patients?.primary_payment?.insurance_detail?.patient_insurance_company : "",
      PolicyNumber: patients?.primary_payment?.insurance_detail?.policy_number ? patients?.primary_payment?.insurance_detail?.policy_number : "",
      corporate_name: patients?.primary_payment?.corporate_detail?.company_name ? patients?.primary_payment?.corporate_detail?.company_name : "",
      employee_id: patients?.primary_payment?.corporate_detail?.id_no ? patients?.primary_payment?.corporate_detail?.id_no : "",
      employer_id: patients?.primary_payment?.corporate_detail?.employer_id ? patients?.primary_payment?.corporate_detail?.employer_id : "",
      discount_percent: patients?.primary_payment?.corporate_detail?.discount_percent ? patients?.primary_payment?.corporate_detail?.discount_percent : "0",
      is_final_bill_generated: patients?.is_final_bill_generated
    }, () => {
      this._getPaymentDetails();
      this.getApproxCharges()
      this.getPatientData(true)
      this._getCorpList()
      this._getInsuranceList()
      this.GetBillDataList()
    })
    // if (this.props.history?.location?.state?.fromFinalBill) {
    this.getCustomConfigurationData()
    // }
  }

  _getCorpList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              CorporateList: response.data.data,
            });
          }
        })
        .catch(error => {
          this.responseSuccessErrorMessage(error.response.data.message, false)
        });
    } catch (e) {
    }
  }

  _getInsuranceList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              InsuranceList: response.data.data,
            });
          }
        })
        .catch(error => {
          this.responseSuccessErrorMessage(error.response.data.message, false)
        });
    } catch (e) {
    }
  }

  GetBillDataList = () => {
    try {
      this.LoaderFunction(true)
      var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let labId = []
      let pharmacyId = []
      LoggedUserData?.laboratory_data?.map((item) => {
        labId.push(item?.laboratory_id)
      })
      LoggedUserData?.pharmacy_data?.map((item) => {
        pharmacyId.push(item?.pharmacy_id)
      })
      RestAPIService.getAll(
        Serviceurls.FO_CREDIT_BILLS_GET +
        `?ip_admission_id=${this.state.patientData?.id}` +
        '&module=' + ['ip', 'lab', 'pharmacy', 'ot', 'radiology'] +
        '&laboratory_id=' + labId +
        '&pharmacy_id=' + pharmacyId +
        '&clinic_id=' + (LoggedUserData?.clinic_id ? LoggedUserData?.clinic_id : null)
      ).
        then((response) => {
          if (response.data.status === "success") {
            let Data = []
            if (response?.data?.data?.length > 0) {
              response?.data?.data?.forEach((item) => {
                item.is_checked = item?.module === 'Pharmacy' && !this.state.customConfigData?.allow_fo_pharmacy_bill_complete ? false : this.props?.Bill_Id === item?.id ? true : false
                item.deduct_amount = 0
                Data.push(item)
              })
            }
            this.setState({
              AllBillDataList: Data,
              AdvanceAmount: (LoggedUserData?.module_name === "front_office" && response?.data?.advance_amount) ? response?.data?.advance_amount : 0
            }, () => {
              this.FilterBillList()
              this.LoaderFunction(false)
            })
          }
          else {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(response.data.message, false)
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(error.message, false)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  FilterBillList = () => {
    let AllBillList = JSON.stringify(this.state.AllBillDataList)
    let BillList = []
    JSON.parse(AllBillList).forEach((item) => {
      if (item?.module === "Out Patient" && this.state.ShowOpBills) {
        BillList.push(item)
      }
      else if (item?.module === "In Patient" && this.state.ShowIpBills) {
        BillList.push(item)
      }
      else if (item?.module === "Pharmacy") {
        if (this.state.ShowPharmaBills) {
          BillList.push(item)
        } else {
          this.state.PharmacyData?.map((list) => {
            if (list?.isChecked && list?.pharmacy_id === item?.pharmacy_id) {
              BillList.push(item)
            }
          })
        }
      }
      else if (item?.module === "Laboratory") {
        if (this.state.ShowLabBills) {
          BillList.push(item)
        } else {
          this.state.LabData?.map((list) => {
            if (list?.isChecked && list?.laboratory_id === item?.laboratory_id) {
              BillList.push(item)
            }
          })
        }
      }
      else if (item?.module === "OT" && this.state.ShowOtBills) {
        BillList.push(item)
      }
      else if (item?.module === "Radiology" && this.state.ShowRadiologyBills) {
        BillList.push(item)
      }
    })

    BillList.forEach((list, index) => (
      BillList[index].is_checked = list?.module === 'Pharmacy' && !this.state.customConfigData?.allow_fo_pharmacy_bill_complete ? false : true
    ))
    this.setState({ BillDataList: BillList, PayableAmount: '', PayFromAdvance: false }, () => {
      this.ClearPaymentDetails()
      this.CalculateTotalPayAmt(this.state.isFromCheckOut)
      this.CalculateDeductAmt()
    })
  }

  ClearPaymentDetails() {
    this.setState({
      isCashPayment: false,
      isCardPayment: false,
      isUPIPayment: false,
      isChequePayment: false,
      isBankTranferPayment: false,
      CashAmountPayment: "",
      CardAmountPayment: "",
      CardTransactionNumberPayment: "",
      UPINumberPayment: "",
      UPIAmountPayment: "",
      BankTfrAmountPayment: "",
      BankTfrNumberPayment: "",
      ChequeNumberPayment: "",
      ChequeAmountPayment: "",
      TwoThounsandCountsPayment: "",
      FiftyCountsPayment: "",
      FiveHundredCountsPayment: "",
      TwentyCountsPayment: "",
      TwoHundredCountsPayment: "",
      TenCountsPayment: "",
      HundredCountsPayment: "",
      CoinsCountsPayment: "",
      FiveCountPayment: "",
    })
  }

  CalculateTotalPayAmt = (isFromCheckOut = false) => {
    try {
      let Amount = 0
      this.state.BillDataList.forEach((item) => {
        if (item?.is_checked) {
          Amount += item?.pending_amount
        }
      })
      this.setState({ TotalPayableAmount: Amount }, () => {
        if (this.state.AdvanceAmount) {
          if (this.state.AdvanceAmount < this.state.TotalPayableAmount) {
            this.setState({ PayableAmount: this.state.AdvanceAmount, PayFromAdvance: true }, () => { this.CalculateDeductAmt() })
          } else {
            this.setState({ PayableAmount: this.state.TotalPayableAmount, PayFromAdvance: true }, () => { this.CalculateDeductAmt() })
          }
        } else {
          this.setState({ PayableAmount: this.state.TotalPayableAmount }, () => { this.CalculateDeductAmt() })
        }
      })
    }
    catch (e) {

    }
  }

  CalculateDeductAmt = () => {
    try {
      let Amount = this.state.PayableAmount ? this.state.PayableAmount : 0
      let List = []
      this.state.BillDataList.forEach((item, index) => {
        if (item?.is_checked) {
          if (item?.pending_amount <= Amount) {
            item.deduct_amount = +(+item?.pending_amount)?.toFixed(2)
            Amount = Amount - item?.pending_amount
          } else if (Amount > 0) {
            item.deduct_amount = +(+Amount)?.toFixed(2)
            Amount = 0
          } else {
            item.deduct_amount = 0
          }
        }
        List.push(item)
      })
      this.setState({ BillDataList: List }, () => { this.ClearPaymentDetails() })
    } catch (e) {

    }
  }
  getCorporateID = () => {
    const result = this.state.CorporateList.find(item => item.company_name === this.state.corporate_name);
    if (result) {
      const desiredId = result.id;
      this.setState({
        employer_id: desiredId
      })
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getPatientData = (ChangeTab = false) => {
    try {
      let AdmissionId = this.state.patientData?.id ? this.state.patientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : ""
      RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + AdmissionId)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              // attenderData:response.data.data[0],
              serviceAmount: response.data.data[0].service_amount,
              pendingAmount: response.data.data[0].credit_fetch_amount ? (response.data.data[0].credit_fetch_amount * -1) : 0,
              paidAmount: response.data.data[0].paid_amount,
              AdvanceAmount: response.data.data[0].advance_amount,
              attenderMobile: response.data.data[0].attender_mobile,
              attenderName: response.data.data[0].attender_name,
              HeaderDetails: response.data.data[0],
              CardPendingAmount: response.data.data[0].balance_amount,
              ApprovedAmt: response.data.data[0].claimed_amount,
              isClimed: response.data.data[0].policy_status === "Claimed" ? true : false,
              corporatePay: response.data.data[0].credit_type,
              PatientCredit: response.data.data[0].credit_type === "Patient credit" ? true : false,
              CompanyName: response.data.data[0].insurance_name,
              PolicyNumber: response.data.data[0].policy_number ? response.data.data[0].policy_number : response.data.data[0].policy_no,
            }, () => {
              if (this.state.isRegular === "Credit") {
                this.setState({
                  isCash: false,
                  isCard: false,
                  isCheque: false,
                  isUPI: false,
                  isBankTranfer: false,
                  corporate_amt: +this.state.serviceAmount,
                  Insurance_amt: +this.state.serviceAmount,
                  patientCreditAmount: -(this.state.pendingAmount),
                })
              }
              if (this.props.history?.location?.state?.isFromCheckOut) {
                if (this.state.CardPendingAmount < 0) {
                  this.setState({
                    value: "one"
                  })
                } else if (this.state.AdvanceAmount) {
                  this.setState({
                    value: 'two'
                  })
                }
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          } else {
            this.responseSuccessErrorMessage(error.response.data.message, false)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.response.data.message, false)
    }
  }


  getApproxCharges = () => {
    let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ApproxAmount: response.data.data?.approx_room_charge
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          } else {
            this.responseSuccessErrorMessage(error.message, false);
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false);
    }
  }

  _getPaymentDetails = () => {
    this.setState({
      paymentDetails: [],
      // attenderData: {},
      amountData: {}
    })
    let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(`${Serviceurls.IP_RECEIVE_PAYMENT_GET}?ip_admission_id=${admission_id}`)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              paymentDetails: response.data.data,
              // attenderData: response.data.patient_data,
              // amountData: response.data.amount_data
            }, () => {
              let amount = 0; let concessionAmt = 0;
              this.state.paymentDetails.forEach((item, i) => {
                item.index = i
                item.is_change = false
                item.total_discount_amount = 0
                item.pharmacy_return = item?.pharmacy_return ? item?.pharmacy_return : false
                let outstanding = item?.outstanding_amount !== 0 ? item?.outstanding_amount : item?.sub_total
                let totalAmount = (outstanding)?.toFixed(2)
                let payableAmount = 0;
                if (item?.payment_status === 'paid') {
                  payableAmount = item.outstanding_amount
                } else if (item?.payment_status === 'not paid' && item?.bill_type !== 'Return') {
                  payableAmount = item?.outstanding_amount ? item?.outstanding_amount : 0

                  // if (item?.return_amount) {
                  //   if (item?.total_after_rtn && item?.return_amount && item?.is_double_return) {
                  //     payableAmount = item.total_after_rtn - item?.return_amount
                  //   } else if (item?.total_after_rtn) {
                  //     // payableAmount = (Math.round(item.sub_total) - (item.total_after_rtn + item?.discount_amount))
                  //     payableAmount = item.total_after_rtn
                  //   } else {
                  //     payableAmount = ((item.sub_total) - (item.return_amount + item.discount_amount))
                  //   }
                  // } else {
                  //   payableAmount = ((item.sub_total) - item.discount_amount)
                  // }
                } else if (item?.payment_status === "partial" && item?.bill_type !== 'Return') {
                  payableAmount = item?.outstanding_amount ? item?.outstanding_amount : 0
                  // if (item?.return_amount) {
                  //   if (item?.total_after_rtn && item?.return_amount && item?.is_double_return) {
                  //     payableAmount = item.total_after_rtn - item?.return_amount
                  //   } else if (item?.total_after_rtn) {
                  //     // payableAmount = (Math.round(item.sub_total) - (item.total_after_rtn + item?.discount_amount))
                  //     payableAmount = item.total_after_rtn
                  //   } else {
                  //     payableAmount = ((item.sub_total) - (item.return_amount + item.discount_amount))
                  //   }
                  // } else {
                  //   payableAmount = ((item.sub_total) - item.discount_amount) - (item?.received_amount ? item?.received_amount : 0)
                  // }
                }
                item.payable_amount = Math.round(payableAmount)
                let amt = !item?.pharmacy_return ? item.outstanding_amount + item.discount_amount : 0
                item.bill_amt_const = JSON.parse(JSON.stringify(amt))
                item.is_discount = item?.discount_amount !== 0 ? true : false
                item.discount_man = item?.discount_amount !== 0 ? true : false
                if (item.bill_type !== 'Return' && item.payment_status !== 'partial') {
                  amount = (+amount) + (+item?.discount_amount)
                }
                // item.bill_amt = item?.payment_status === 'not paid' && item?.partial_return ? item?.total_after_rtn : item.outstanding_amount
                item.bill_amt = item.total_amount
                item.from_over_discount = (item.is_overall_concession || (item.payment_status === 'not paid' && !item.discount_amount && item.bill_type !== 'Return' && !item.partial_return)) ? true : false
                if ((item.is_overall_concession || (item.payment_status === 'not paid' && !item.discount_amount && item.bill_type !== 'Return' && !item.partial_return))) {
                  // concessionAmt = concessionAmt + (item?.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total))
                  concessionAmt = concessionAmt + item.bill_amt_const
                }
                // item.hospital_share = item.hospital_share && item.module === 'Inpatient' ? item.hospital_share : 0
              })
              this.setState({
                dupliPaymentDetails: JSON.stringify(this.state.paymentDetails),
                dupliDiscount: amount,
                overallConcession: response.data.over_all_concession ? Math.round(response.data.over_all_concession) : '',
                concessionChecked: response.data.over_all_concession ? true : false,
                overallDiscountReason: response.data.remarks ? response.data.remarks : '',
                dupOverAllConcession: response.data.over_all_concession ? JSON.stringify(response.data.over_all_concession) : '',
                isRoundOff: response.data.over_all_concession ? false : true,
                changed: true,
                overAllPercent: response.data.over_all_concession_percentage ? Math.round(response.data.over_all_concession_percentage) : '',
                dupOverAllPercent: response.data.over_all_concession_percentage ? JSON.stringify(response.data.over_all_concession_percentage) : ''
              })
            });
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.responseSuccessErrorMessage(error.response.data.message, false);
          } else {
            this.responseSuccessErrorMessage(error.message, false);
          }
        });
    } catch (e) {
      this.responseSuccessErrorMessage(e.message, false);
    }
  }
  responseSuccessErrorMessage(message, type) {
    this.setState({
      showSuccessResponse: message ? type : false,
      showErrorResponse: message ? !type : false,
      responseMessage: message
    })
  }

  // CustomFooter = () => {
  //   let states = this.state;
  //   const { t } = this.props;
  //   let outStandingAmt = states["amountData"]["total_pending"] ? states["amountData"]["total_pending"] : 0;
  //   return (
  //     <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={15} p={2}>
  //       <Typography id="eMed_recPayment_Txt">{`${t("TotalUnpaidAmount")}`}</Typography>
  //       <Typography id="eMed_recPayment_Txt">{` ₹ ${outStandingAmt ? AmountFormat(outStandingAmt).replace("₹", "") : "0.00"}`} </Typography>
  //     </Stack>
  //   );
  // }

  onKeyDown = (e) => {
    e.preventDefault();
  };

  handleChange = (event) => {
    let states = this.state;
    let name = event.target.name;
    let value = event.target.value.trimStart();
    let isValid = false;
    let isFromAdvace = (states["payAdv"] === "PayFromAdvance" && +states["amountData"]["advance_amount"] !== 0);
    let balanceAmt = isFromAdvace ? (+states["payable_amt"] - +states["amountData"]["advance_amount"]) : +states["payable_amt"];

    if (name === "cardNo" || name === "upiNo" || name === "bankTfrNo" || name === "chequeNo" || name === "insuranceNo" || name === "refupiNo" || name === "TransactionNumber" || name === "corporate_amt" || name === "Insurance_amt" || name === "patientCreditAmount") {
      isValid = CommonValidation.gstnumberValidation(value);
      if (value === "" || isValid && value.length <= 16) {
        states[name] = value;
      }
    }
    if (name === "refConbankAccNo") {
      states["showPassword"] = false
    }
    switch (name) {
      case "paymentmadeBy":
        isValid = CommonValidation.alphabetOnly(value);
        if (value === "" || isValid) {
          states["paymentmadeBy"] = value;
        }
        break;
      case "refbankName":
        isValid = CommonValidation.alphabetOnly(value);
        if (value === "" || isValid) {
          states["refbankName"] = value;
        }
        break;
      case "payable_amt":
        isValid = CommonValidation.NumberWithDot(value);
        if (value === "" || isValid && value > 0) {
          states["payable_amt"] = value;
          states['cashAmt'] = value;
          if (value !== this.state.creditAmount) {
            states["selectionModel"] = [];
          }
        }
        break;
      case "refBankAccNo":
        isValid = CommonValidation.ALPHA_NUMARIC(value);
        if (value === "" || isValid && value.length < 18) {
          states["refBankAccNo"] = value;
        }
        break;
      case "refConbankAccNo":
        isValid = CommonValidation.ALPHA_NUMARIC(value);
        if (value === "" || isValid && value.length < 18) {
          states["refConbankAccNo"] = value;
        }
        break;
      case "refupiId":
        if (value === "" || value.length < 50) {
          states["refupiId"] = value;
        }
        break;
      case "refbankIFSCNo":
        if (value === "" || value.length < 12) {
          states["refbankIFSCNo"] = value;
        }
        break;
      case "receiptDiscription":
        isValid = CommonValidation.ALPHA_NUM_SPLCHAR(value);
        if (value === "" || (isValid && value.length < 50)) {
          states[name] = value;
        }
        break;
      case "PolicyNumber":
        isValid = CommonValidation.ALPHA_NUM_SPLCHAR(value);
        if (value === "" || (isValid && value.length < 25)) {
          states[name] = value;
        }
        break;
      case "employee_id":
        isValid = CommonValidation.ALPHA_NUM_SPLCHAR(value);
        if (value === "" || (isValid && value.length < 25)) {
          states[name] = value;
        }
        break;
      case "CompanyName":
        isValid = CommonValidation.alphabetOnly(value);
        if (value === "" || isValid) {
          states[name] = value;
        }
        break;
      default:
        isValid = CommonValidation.NumberWithDot(value);
        if (value === "" || isValid && (value > 0 && +(value) <= +(balanceAmt))) {
          states[name] = value;
        }
        break;

    }
    this.setState({
      states
    }, () => {
      if (states["corporatePay"] !== "Insurance") {
        this.setState({
          insuranceNo: ""
        })
      }
    })
  }

  renderTextBox(label, statekey, value, width, image, helperText, amtEdit) {
    const { t } = this.props;
    let { open } = this.state;
    let states = this.state;
    // below condition only allows for pay from advance with out payment method
    // let pay_from_advance = ((states["payAdv"] === "PayFromAdvance" && states["amountData"]["advance_amount"] !== 0) && (helperText === "ReferenceNo" || statekey === "cashAmt" || statekey === "cardAmt") );
    let avoidAdornment = (statekey === "payable_amt" || statekey === "paymentmadeBy" || helperText === "ReferenceNo" || statekey === "refund_amt" || statekey === "refundPaymentmadeBy" || statekey === "refupiId" || statekey === "refbankName" || statekey === "refBankAccNo" || statekey === "refConbankAccNo" || statekey === "refbankIFSCNo" || statekey === "receiptDiscription" || statekey === "TransactionNumber" || statekey === "CompanyName" || statekey === "PolicyNumber" || statekey === "cor_approval_details" || statekey === "corporate_amt" || statekey === "Ins_approval_details" || statekey === "Insurance_amt" || statekey === "CompanyName" || statekey === "PolicyNumber" || statekey === "corporate_name" || statekey === "employee_id" || statekey === "patientCreditAmount");
    return (
      <div id="eMed_IP_recPayment_Div" sx={{ height: this.state.isFromCheckOut ? "64vh" : "69vh" }} style={{ paddingLeft: statekey === "refupiNo" || statekey === "refbankName" || statekey === "refConbankAccNo" ? "0.5vw" : 0, paddingTop: statekey === "corporate_name" || statekey === "CompanyName" ? "0.75vw" : "0vw" }}>
        {statekey === "paymentDate" || statekey === "refunDate" ?
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              open={open}
              onOpen={() => { this.setState({ open: true }) }}
              onClose={() => { this.setState({ open: false }) }}
              disabled={true}
              inputFormat="dd-MM-yyyy"
              label={label ? t(label) + "*" : ""}
              name={statekey}
              value={value ? value : null}
              autoComplete="off"
              maxDate={DateTime.now()}
              onChange={(value) => {
                states["paymentDate"] = value
                this.setState({
                  states
                })
              }
              }
              renderInput={(params) => <TextField onKeyDown={(e) => this.onKeyDown(e)} size="small" sx={{ width: width ? width : "14.5vw" }} {...params} onClick={() => { this.setState({ open: true }) }} />}
            />
          </LocalizationProvider>
          :
          statekey === "corporate_name" ?
            <FormControl size='small' sx={{ width: '13.4vw' }}>
              <InputLabel id="gender-select-label">{t(label)}</InputLabel>
              <Select
                sx={{ width: '13.4vw' }}
                MenuProps={{ style: { maxHeight: 250 } }}
                labelId='gender-select-label'
                size='small'
                label={t(label)}
                value={states["corporate_name"] ? states["corporate_name"] : ""}
                onChange={(e) => {
                  this.setState({
                    corporate_name: e.target.value,
                  }, () => { this.getCorporateID() })
                }}
              >
                <MenuItem value={""}>{"None"}</MenuItem>
                {
                  states.CorporateList.length > 0 ?
                    states.CorporateList.map((list, index) => (
                      <MenuItem key={index} value={list.company_name}>{list.company_name}</MenuItem>
                    )) : null
                }
              </Select>
            </FormControl>
            :
            statekey === "CompanyName" ?
              <FormControl size='small' sx={{ width: '13.4vw' }}>
                <InputLabel id="gender-select-label">{t(label)}</InputLabel>
                <Select
                  sx={{ width: '13.4vw' }}
                  MenuProps={{ style: { maxHeight: 250 } }}
                  labelId='gender-select-label'
                  size='small'
                  label={t(label)}
                  value={states["CompanyName"] ? states["CompanyName"] : ""}
                  onChange={(e) => {
                    this.setState({
                      CompanyName: e.target.value
                    }, () => { this.getCorporateID() })
                  }}
                >
                  <MenuItem value={""}>{"None"}</MenuItem>
                  {
                    states.InsuranceList.length > 0 ?
                      states.InsuranceList.map((list, index) => (
                        <MenuItem key={index} value={list.insurance_name}>{list.insurance_name}</MenuItem>
                      )) : null
                  }
                </Select>
              </FormControl>
              :
              <TextField
                sx={{ width: width ? width : "10vw" }}
                size="small"
                autoComplete="off"
                label={t(label)}
                name={statekey}
                type={(statekey === "refBankAccNo" && !states.showPassword) ? "password" : "text"}
                value={value ? value : statekey === "refund_amt" || statekey === "refcashAmt" || statekey === "refupiAmt" || statekey === "refbankTfrAmt" ? 0 : ""}
                required={statekey === "paymentmadeBy" || statekey === "refundPaymentmadeBy" ? false : true}
                disabled={amtEdit || statekey === "refund_amt" || statekey === "refundPaymentmadeBy" || statekey === "refcashAmt" || statekey === "refupiAmt" || statekey === "refbankTfrAmt" || statekey === "corporate_amt" || statekey === "Insurance_amt" || statekey === "patientCreditAmount" || (statekey === "payable_amt" ? this.state.payAdv === "BillReceipt" : false)}
                variant={statekey === "payable_amt" || statekey === "paymentmadeBy" || statekey === "refund_amt" || statekey === "refundPaymentmadeBy" || statekey === "receiptDiscription" || statekey === "cor_approval_details" || statekey === "corporate_amt" || statekey === "Ins_approval_details" || statekey === "Insurance_amt" || statekey === "patientCreditAmount" || statekey === "CompanyName" || statekey === "PolicyNumber" || statekey === "corporate_name" || statekey === "employee_id" ? "outlined" : "filled"}
                placeholder={avoidAdornment ? "" : "1,00,000.00"}
                InputProps={{
                  startAdornment: avoidAdornment ? null : (
                    <InputAdornment position="start">
                      <img src={image} alt={label} className='eMed_action_img_small' />
                    </InputAdornment>
                  ),
                  endAdornment: statekey === "refBankAccNo" ?
                    <InputAdornment>
                      <IconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                        {this.state.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment> : null
                }}
                onChange={this.handleChange}
                helperText={helperText ? t(helperText) : ""}
              />
        }
      </div>

    )
  }

  renderCountsText = (Amount, Key, Right = false) => {
    var states = this.state
    return (
      <Box component={"div"} marginLeft={Right ? "2vw" : "1.3vw"} className="eMed_recPayment_Denomi_invidual_div">
        <Typography className='eMed_countsAmount_Label'>{Amount}</Typography>
        <Typography>x</Typography>
        <TextField
          placeholder="counts"
          size='small'
          inputProps={{ maxLength: 4 }}
          className='eMed_crdPop_amountTextField'
          variant='standard'
          value={states[Key]}
          onChange={(e) => {
            let number = CommonValidation.numberOnly(e.target.value);
            if (number || e.target.value === "") {
              states[Key] = e.target.value
              this.setState({
                states
              })
            }
          }}
        />
      </Box>
    )
  }
  handleClear = () => {
    this.setState({
      selectionModel: [],
      creditAmount: null,
      open: false,
      payAdv: "ReceiveAsAdvance",
      payable_amt: null,
      paymentDate: new Date(),
      paymentmadeBy: "",
      cashAmt: null,
      cardAmt: null,
      cardNo: null,
      upiAmt: null,
      upiNo: null,
      bankTfrAmt: null,
      bankTfrNo: null,
      chequeAmt: null,
      chequeNo: null,
      TwoThounsandCounts: "",
      FiftyCounts: "",
      FiveHundredCounts: "",
      TwentyCounts: "",
      TwoHundredCounts: "",
      TenCounts: "",
      HundredCounts: "",
      isRegular: this.props.history?.location?.state?.isFromCheckOut ? "Credit" : "regular",
      insuranceNo: "",
      isCash: true,
      isCard: false,
      isUPI: false,
      isCheque: false,
      isBankTranfer: false,
      // isInsurance: false,
      refundCash: true,
      refundUPI: false,
      refundBankTranfer: false,
      refupiNo: null,
      refupiId: null,
      refBankAccNo: null,
      refConbankAccNo: null,
      refbankIFSCNo: null,
      refbankName: null,
      showPassword: false,
      insuranceAmt: null,
      TransactionNumber: "",
      // CompanyName : "",
      // PolicyNumber : "",
      corporatePay: this.state.patientData?.primary_payment_type ? this.state.patientData?.primary_payment_type : "",
      isInsurance: this.state.patientData?.primary_payment_type === "Insurance" ? true : false,
      CompanyName: this.state.patientData?.primary_payment?.insurance_detail?.patient_insurance_company ? this.state.patientData?.primary_payment?.insurance_detail?.patient_insurance_company : "",
      PolicyNumber: this.state.patientData?.primary_payment?.insurance_detail?.policy_number ? this.state.patientData?.primary_payment?.insurance_detail?.policy_number : "",
      receiptDiscription: "",
      PayForIPCredit: false,
      PayForPharmaCredit: false,
      IPPayingAmount: "",
      PharmaPayingAmount: "",
      ShowOpBills: true,
      ShowIpBills: true,
      ShowPharmaBills: true,
      ShowLabBills: true,
      ShowRadiologyBills: localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
      ShowOtBills: true,
      PayableAmount: '',
      PayFromAdvance: false,
    }, () => {
      if (this.state.isRegular === "Credit" && this.state.pendingAmount < 0) {
        this.setState({
          isCash: false,
          isCard: false,
          isCheque: false,
          isUPI: false,
          isBankTranfer: false,
          corporate_amt: +this.state.serviceAmount,
          Insurance_amt: +this.state.serviceAmount,
          patientCreditAmount: -(this.state.pendingAmount),
        })
      }
    })
  }
  returnTotal() {
    try {
      let Amount = 0
      let states = this.state
      if (states.TwoThounsandCounts != "") {
        Amount += +states.TwoThounsandCounts * 2000
      }
      if (states.FiveHundredCounts != "") {
        Amount += +states.FiveHundredCounts * 500
      }
      if (states.TwoHundredCounts != "") {
        Amount += +states.TwoHundredCounts * 200
      }
      if (states.HundredCounts != "") {
        Amount += +states.HundredCounts * 100
      }
      if (states.FiftyCounts != "") {
        Amount += +states.FiftyCounts * 50
      }
      if (states.TwentyCounts != "") {
        Amount += +states.TwentyCounts * 20
      }
      if (states.TenCounts != "") {
        Amount += +states.TenCounts * 10
      }
      return Amount
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  CalculateAmount() {
    const { t } = this.props;
    let states = this.state
    let AdvanceAmount = states["amountData"]["advance_amount"] !== 0 ? +states["amountData"]["advance_amount"] : 0;
    let isFromAdvance = (states["payAdv"] === "PayFromAdvance" && AdvanceAmount);
    let CashAmt = states.cashAmt === "" ? 0 : +states.cashAmt;
    let CardAmt = states.cardAmt === "" ? 0 : +states.cardAmt;
    let UPIAmt = states.upiAmt === "" ? 0 : +states.upiAmt;
    let bankTransferAmt = states.bankTfrAmt === "" ? 0 : +states.bankTfrAmt;
    let ChequeAmt = states.chequeAmt === "" ? 0 : +states.chequeAmt;
    let insuranceAmt = states.insuranceAmt === "" ? 0 : +states.insuranceAmt;
    let totalAmt = isFromAdvance ? (AdvanceAmount + CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + insuranceAmt) : (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + insuranceAmt);
    let msg = "";

    if (totalAmt > +states.payable_amt) {
      msg = `${t("Amount")} ${t("greaterthan")} ${t("PayableAmount")}`
    }
    else if (+states.payable_amt > totalAmt) {
      msg = `${t("Amount")} ${t("doesnot")} ${t("Match")} ${t("with")} ${t("PayableAmount")}`
    }
    else if (+states.payable_amt === totalAmt) {
      msg = ""
    }
    return msg;
  }



  verifyAmtNo() {
    const { t } = this.props;
    let states = this.state;
    let msg = "";

    if (states["isInsurance"]) {
      if (!states["insuranceAmt"]) {
        msg = "Please Enter the Insurance Amount"
      } else if (states.TransactionNumber === "" || states.CompanyName === "" || states.PolicyNumber === "") {
        msg = "Please Enter the all Insurance details"
      }
    }

    if (states["isCheque"]) {
      if (!states["chequeAmt"] && !states["chequeNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cheque")} ${t("Amount")} ${t("And")} ${t("ReferenceNo")}`
      } else if (!states["chequeAmt"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cheque")} ${t("Amount")}`
      } else if (states["chequeAmt"] && !states["chequeNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cheque")} ${t("ReferenceNo")}`
      }
    }

    if (states["isBankTranfer"]) {
      if (!states["bankTfrAmt"] && !states["bankTfrNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("BankTransfer")} ${t("Amount")} ${t("And")} ${t("ReferenceNo")}`
      } else if (!states["bankTfrAmt"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("BankTransfer")} ${t("Amount")}`
      } else if (!states["bankTfrNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("BankTransfer")} ${t("ReferenceNo")}`
      }
    }

    if (states["isUPI"]) {
      if (!states["upiAmt"] && !states["upiNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("UPI")} ${t("Amount")} ${t("And")} ${t("ReferenceNo")}`
      } else if (!states["upiAmt"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("UPI")} ${t("Amount")}`
      } else if (!states["upiNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("UPI")} ${t("ReferenceNo")}`
      }
    }

    if (states["isCard"]) {
      if (!states["cardAmt"] && !states["cardNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Card")} ${t("Amount")} ${t("And")} ${t("ReferenceNo")}`
      } else if (!states["cardAmt"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Card")} ${t("Amount")}`
      } else if (!states["cardNo"]) {
        msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Card")} ${t("ReferenceNo")}`
      }
    }

    if (states["isCash"] && !states["cashAmt"]) {
      msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Cash")} ${t("Amount")}`
    }

    // if(states["isRegular"] === "Insurance"){
    //   if(!states["insuranceNo"]){
    //     msg = `${t("Please")} ${t("Enter")} ${t("The")} ${t("Insurance")} ${t("ReferenceNo")}`
    //   }
    // }
    return msg
  }

  CheckOutPatientPost() {
    let data = {
      "admission_id": this.state.patientData.id ? this.state.patientData.id : null,
      "status": "Checked Out",
      "discharge_date": DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd"),
      "discharge_time": DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
    }
    RestAPIService.create(data, Serviceurls.FO_IP_HOME)
      .then(response => {
        if (response.data.status === "success") {
          this.setState({
            CheckoutPrintPoP: true
          })
        }
      }).catch(e => {
        this.setState({ disable: false })
        if (e.response.data.status === "fail") {
          this.responseSuccessErrorMessage(e.response.data.message, false)
        } else {
          this.responseSuccessErrorMessage(e.message, false)
        }
      })
  }

  handleReceive = (type) => {
    const { t } = this.props;
    let states = this.state;
    let paymentMode = [];
    let allow = false;
    let valid = this.verifyAmtNo();
    let amtValid = this.CalculateAmount();
    let checkIsAdv = (states["payAdv"] === "PayFromAdvance" && states["amountData"]["advance_amount"] !== 0);

    if (states.payable_amt && states.paymentDate !== "Invalid DateTime" && (checkIsAdv && states["amountData"]["advance_amount"] < states.payable_amt &&
      (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance) && amtValid.length === 0 && valid.length === 0)) {
      allow = true;
    } else if (states.payable_amt && states.paymentDate !== "Invalid DateTime" && (checkIsAdv && states["amountData"]["advance_amount"] >= states.payable_amt)) {
      allow = true;
    } else if (states.payable_amt && states.paymentDate !== "Invalid DateTime" &&
      (states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance) && amtValid.length === 0 && valid.length === 0) {
      allow = true;
    } else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance) && states.isRegular === "Credit" && states.pendingAmount !== 0) {
      if (states["corporatePay"] === "Insurance" || states["corporatePay"] === "Insurance credit") {
        if (states["CompanyName"] && states["PolicyNumber"]) {
          allow = true;
        } else {
          this.responseSuccessErrorMessage("Invalid Insurance Details", false);
          this.setState({ disableBtn: false })
        }
      } else if (states["corporatePay"] === "Corporate" || states["corporatePay"] === "Corprate credit") {
        if (states["corporate_name"] && states["employee_id"]) { allow = true; }
        else {
          this.responseSuccessErrorMessage("Invalid Corporate Details", false);
          this.setState({ disableBtn: false })
        }
      } else if (states?.PatientCredit) {
        allow = true;
      }
    }
    try {
      if (allow) {
        // if (!this.state.payAdv) {
        //   if (this.state.pendingAmount + +states.payable_amt > 0) {
        //     this.responseSuccessErrorMessage("Payable Amount Not Matched", false);
        //     return;
        //   }
        //   if (this.state.pendingAmount > 0 && (+states.payable_amt > this.state.pendingAmount)) {
        //     this.responseSuccessErrorMessage("Payable Amount is More than Pending Amount", false);
        //     return;
        //   }
        // }
        if (states.isCash && states.cashAmt) { paymentMode.push(1) }
        if (states.isCard) { paymentMode.push(2) }
        if (states.isUPI) { paymentMode.push(3) }
        if (states.isBankTranfer) { paymentMode.push(5) }
        if (states.isCheque) { paymentMode.push(4) }
        if (states.isInsurance) { paymentMode.push(6) }

        let datas = {
          "branch_id": states["branch_id"] ? +(states["branch_id"]) : null,
          "bill_summary_id": states["selectionModel"].length > 0 ? states["selectionModel"] : [],
          "ip_admission_id": states["patientData"]["id"] ? states["patientData"]["id"] : null,
          "pay_from_advance": states["payAdv"] === "PayFromAdvance" && states["amountData"]["advance_amount"] != 0 ? true : false,
          "payable_amount": states["payable_amt"] ? +(states["payable_amt"]) : 0,
          "advance_amount": states["payAdv"] === "ReceiveAsAdvance" ? +(states["payable_amt"] ? states["payable_amt"] : 0) : states["amountData"]["advance_amount"] ? states["amountData"]["advance_amount"] : 0,
          "payment_date": states["paymentDate"] ? DateTime.fromJSDate(states["paymentDate"]).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(today).toFormat('yyyy-MM-dd'),
          "payment_made_by": states["paymentmadeBy"] ? states["paymentmadeBy"] : "",
          "receive_as_advance": states.isRegular === "Credit" ? false : states["payAdv"] === "ReceiveAsAdvance" ? true : false,
          "patient_id": states["patientData"]["patient_id"] ? +(states["patientData"]["patient_id"]) : null,
          "patient_account_number": states["patientData"]["patient_account_number"] ? states["patientData"]["patient_account_number"] : "",
          "cash_amount": states["cashAmt"] ? +(states["cashAmt"]) : 0,
          "card_amount": states["cardAmt"] ? +(states["cardAmt"]) : 0,
          "upi_amount": states["upiAmt"] ? +(states["upiAmt"]) : 0,
          "bank_transfer_amount": states["bankTfrAmt"] ? +(states["bankTfrAmt"]) : 0,
          "cheque_amount": states["chequeAmt"] ? +(states["chequeAmt"]) : 0,
          "payment_mode": paymentMode.length > 0 ? paymentMode : [],
          "card_ref_no": states["cardNo"] ? states["cardNo"] : null,
          "upi_ref_no": states["upiNo"] ? states["upiNo"] : null,
          "bank_ref_no": states["bankTfrNo"] ? states["bankTfrNo"] : null,
          "cheque_ref_no": states["chequeNo"] ? states["chequeNo"] : null,
          "bill_type": states["isRegular"] ? states["isRegular"] : (states["corporatePay"] === "Insurance" || states["corporatePay"] === "Insurance credit" || states["corporatePay"] === "Corporate" || states["corporatePay"] === "Corprate credit" || states.PatientCredit) ? "credit" : "regular",
          "total_bill_amount": states.pendingAmount,
          "is_credit": states.isRegular === "Credit" ? true : false,
          "pharmacy_paid_amount": states?.PharmaPayingAmount ? +states?.PharmaPayingAmount : 0,
          "fo_paid_amount": states?.IPPayingAmount ? +states?.IPPayingAmount : 0
        }
        if (states.isRegular === "Credit") {
          datas["credit_type"] = states["corporatePay"] === "Insurance" || states["corporatePay"] === "Insurance credit" ? "Insurance credit" : states["corporatePay"] === "Corporate" || states["corporatePay"] === "Corprate credit" ? "Corprate credit" : states.PatientCredit ? "Patient credit" : "";
          datas["policy_amount"] = states["corporatePay"] === "Insurance" || states["corporatePay"] === "Insurance credit" ? +states["Insurance_amt"] : states["corporatePay"] === "Corporate" || states["corporatePay"] === "Corprate credit" ? +states["corporate_amt"] : states.PatientCredit ? +states["patientCreditAmount"] : "";
          if (states["corporatePay"] === "Insurance" || states["corporatePay"] === "Insurance credit") {
            datas["policy_company_name"] = states["CompanyName"]
            datas["policy_number"] = states["PolicyNumber"]
          }
          if (states["corporatePay"] === "Corporate" || states["corporatePay"] === "Corprate credit") {
            datas["employer_id"] = states["employer_id"]
            datas["employee_id"] = states["employee_id"]
          }
        }
        this.LoaderFunction(true)
        RestAPIService.create(datas, Serviceurls.IP_RECEIVE_PAYMENT_GET).
          then((response) => {
            if (response.data.status === "success") {
              if (this.state.isFromCheckOut && this.state.CheckoutThePatient) {
                this.LoaderFunction(false)
                this.responseSuccessErrorMessage(response.data.message, true);
                this.CheckOutPatientPost()
              } else {
                if (type !== "isCredit") {
                  this.setState({
                    receiptID: response.data?.receipt_id?.fo_receipt_id ? response.data?.receipt_id?.fo_receipt_id : response.data?.data?.receipt_id,
                    pharmareceipt: response.data?.receipt_id?.pharmacy_receipt_id ? response.data?.receipt_id?.pharmacy_receipt_id : response.data?.data?.pharmacy_receipt_id,
                  })
                }
                this.handleClear();
                this._getPaymentDetails();
                this.getPatientData();
                this.LoaderFunction(false)
                this.responseSuccessErrorMessage(response.data.message, true);
                this.getApproxCharges()
              }
              this.setState({ disableBtn: false })
            } else {
              this.LoaderFunction(false)
              this.responseSuccessErrorMessage(response.data.message, false)
              this.setState({ disableBtn: false })
            }
          }).catch((error) => {
            this.LoaderFunction(false)
            if (error?.response?.data?.status === "fail") {
              this.responseSuccessErrorMessage(error.response.data.message, false);
            } else {
              this.responseSuccessErrorMessage(error.message, false);
            }
            this.setState({ disableBtn: false })
          })
      }
      else {
        this.setState({ disableBtn: false })
        let check = (states["payAdv"] !== "PayFromAdvance") || (states["payAdv"] === "PayFromAdvance" && states["amountData"]["advance_amount"] !== 0 && states["amountData"]["advance_amount"] < states.payable_amt);
        if (states["isRegular"] === "Credit") {
          if (states["corporatePay"] === "Insurance" || states["corporatePay"] === "Insurance credit") {
            if (states["CompanyName"] && states["PolicyNumber"]) {

            } else {
              this.responseSuccessErrorMessage("Invalid Insurance Details", false);
            }
          } else if (states["corporatePay"] === "Corporate" || states["corporatePay"] === "Corprate credit") {
            if (states["corporate_name"] && states["employee_id"]) {

            }
            else {
              this.responseSuccessErrorMessage("Invalid Corporate Details", false);
            }
          }
        } else {
          if ((!states.payable_amt || states.payable_amt === "0")) {
            this.responseSuccessErrorMessage(`${t("Please")} ${t("Enter")} ${t("the")} ${t("PayableAmount")} `, false)
          } else if (check && !(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque || states.isInsurance)) {
            this.responseSuccessErrorMessage(`${t("Please")} ${t("Select")} ${t("any")} ${t("PaymentMethod")} `, false)
          } else if (check && valid) {
            this.responseSuccessErrorMessage(valid, false)
          } else if (check && amtValid) {
            this.responseSuccessErrorMessage(amtValid, false)
          }
        }

      }
    }
    catch (e) {
      this.responseSuccessErrorMessage(e.message, false)
      this.setState({ disableBtn: false })
    }
  }

  CalculateAmountPayment(RefNumCheck = false) {
    var states = this.state
    let CashAmt = states.CashAmountPayment === "" ? 0 : +states.CashAmountPayment;
    let CardAmt = states.CardAmountPayment === "" ? 0 : +states.CardAmountPayment;
    let UPIAmt = states.UPIAmountPayment === "" ? 0 : +states.UPIAmountPayment;
    let bankTransferAmt = states.BankTfrAmountPayment === "" ? 0 : +states.BankTfrAmountPayment;
    let ChequeAmt = states.ChequeAmountPayment === "" ? 0 : +states.ChequeAmountPayment;
    if (states.PayableAmount === "") {
      return false
    }
    else if (Math.round(+states.PayableAmount) === (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt)) {
      if (RefNumCheck) {
        if ((CardAmt === 0 || states.CardTransactionNumberPayment !== "") &&
          (UPIAmt === 0 || states.UPINumberPayment !== "") &&
          (bankTransferAmt === 0 || states.BankTfrNumberPayment !== "") &&
          (ChequeAmt === 0 || states.ChequeNumberPayment !== "")) {
          return true
        }
      } else {
        return true
      }
    }
  }

  handleRefund = () => {
    const { t } = this.props;
    let states = this.state;
    let paymentMode = [];
    let allow = false;
    let advance_amount = states.AdvanceAmount !== 0 && states.AdvanceAmount !== undefined ? true : false
    let balance_amount = states.BalanceAmt !== 0 && states.BalanceAmt !== undefined ? true : false
    let payment_type = (states.refundCash || states.refundBankTranfer || states.refundUPI) ? true : false
    let UPI_Id = CommonValidation.CheckUPIId(states["refupiId"])
    let IFSC = CommonValidation.CheckIFSC(states["refbankIFSCNo"])
    if (states.refunDate !== "Invalid DateTime" && ((advance_amount && states?.Refund_Status === "advance") || (balance_amount && states?.Refund_Status === "balance")) && payment_type) {
      if (states.refundBankTranfer && (states.refBankAccNo === states.refConbankAccNo) && IFSC && (states.refbankName != "" && states.refbankName != null) && (states.refBankAccNo != null && states.refBankAccNo != "") && (states.refConbankAccNo != null && states.refConbankAccNo != "")) {
        allow = true
      } else if (states.refundUPI && (states.refupiNo != "" && states.refupiNo != null) && (states.refupiId != "" && states.refupiId != null) && UPI_Id) {
        allow = true
      } else if (states.refundCash) {
        allow = true
      }
    }
    try {
      if (allow) {
        if (states.refundCash) { paymentMode.push(1) }
        if (states.refundUPI) { paymentMode.push(3) }
        if (states.refundBankTranfer) { paymentMode.push(5) }

        let datas = {
          "ip_admission_id": states["patientData"]["id"] ? states["patientData"]["id"] : null,
          "refund_amount": states?.Refund_Status === "advance" ? +states.AdvanceAmount : +states.BalanceAmt,
          "advance_amount": states?.Refund_Status === "advance" ? +states.AdvanceAmount : +states.BalanceAmt,
          "cash_amount": states.refundCash ? (states?.Refund_Status === "advance" ? +states.AdvanceAmount : +states.BalanceAmt) : 0,
          "upi_amount": states.refundUPI ? (states?.Refund_Status === "advance" ? +states.AdvanceAmount : +states.BalanceAmt) : 0,
          "bank_transfer_amount": states.refundBankTranfer ? (states?.Refund_Status === "advance" ? +states.AdvanceAmount : +states.BalanceAmt) : 0,
          "payment_date": states["refunDate"] ? DateTime.fromJSDate(states["refunDate"]).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(today).toFormat('yyyy-MM-dd'),
          "payment_made_by": states["LogedUserName"] ? states["LogedUserName"] : "",
          "payment_mode": paymentMode.length > 0 ? paymentMode : [],
          "upi_ref_no": states["refupiNo"] ? states["refupiNo"] : null,
          "upi_id": states["refupiId"] ? states["refupiId"] : null,
          "bank_acc_no": states["refBankAccNo"] ? states["refBankAccNo"] : null,
          "bank_name": states["refbankName"] ? states["refbankName"] : null,
          "ifsc_code": states["refbankIFSCNo"] ? states["refbankIFSCNo"] : null,
          "advance_refund": states["Refund_Status"] === "advance" ? true : false
        }
        this.LoaderFunction(true)
        RestAPIService.create(datas, Serviceurls.IP_REFUND_PAYMENT_POST).
          then((response) => {
            if (response.data.status === "success") {
              if (this.state.isFromCheckOut && this.state.CheckoutThePatient) {
                this.LoaderFunction(false)
                this.responseSuccessErrorMessage(response.data.message, true)
                this.CheckOutPatientPost()
              } else {
                this.setState({
                  receiptID: response.data?.receipt_id?.fo_receipt_id ? response.data?.receipt_id?.fo_receipt_id : response.data?.data?.receipt_id,
                  pharmareceipt: response.data?.receipt_id?.pharmacy_receipt_id ? response.data?.receipt_id?.pharmacy_receipt_id : response.data?.data?.pharmacy_receipt_id,
                })
                this.handleClear();
                this._getPaymentDetails();
                this.getPatientData();
                this.LoaderFunction(false)
                this.responseSuccessErrorMessage(response.data.message, true)
              }
              this.setState({ disableBtn: false })
            } else {
              this.LoaderFunction(false)
              this.setState({ disableBtn: false })
              this.responseSuccessErrorMessage(response.data.message, false)
            }
          }).catch((error) => {
            this.LoaderFunction(false)
            this.setState({ disableBtn: false })
            this.responseSuccessErrorMessage(error?.response?.data?.message, false)
          })
      }
      else {
        this.setState({ disableBtn: false })
        if (!(payment_type)) {
          this.responseSuccessErrorMessage(`${t("Please")} ${t("Select")} ${t("any")} ${t("PaymentMethod")} `, false)
        } else if (states.refundBankTranfer && (states.refBankAccNo !== states.refConbankAccNo)) {
          this.responseSuccessErrorMessage(`${t("Account")} ${t("Number")} ${t("not")} ${t("Matched")} `, false)
        } else if (states.refundBankTranfer && ((states.refbankName == "" || states.refbankName == null) || (states.refBankAccNo == null || states.refBankAccNo == "") || (states.refConbankAccNo == null || states.refConbankAccNo == ""))) {
          this.responseSuccessErrorMessage(`${t("All")} ${t("Fields")} ${t("are")} ${t("Mandotory")} `, false)
        } else if (states.refundUPI && ((states.refupiNo == "" || states.refupiNo == null) || (states.refupiId == "" || states.refupiId == null))) {
          this.responseSuccessErrorMessage(`${t("All")} ${t("Fields")} ${t("are")} ${t("Mandotory")} `, false)
        } else if (states.refundUPI && !UPI_Id) {
          this.responseSuccessErrorMessage(`${t("Please")} ${t("Enter")} ${t("Correct")} ${t("UPI")} ${t("ID")} `, false)
        } else if (states.refundBankTranfer && !IFSC) {
          this.responseSuccessErrorMessage(`${t("Please")} ${t("Enter")} ${t("Correct")} ${t("IFSC")} ${t("Code")} `, false)
        }
        // else if (states.receiptDiscription !== ""){
        //   this.responseSuccessErrorMessage(`${t("Please")} ${t("Enter")} ${t("Receipt")} ${t("Description")}`, false)
        // }
      }
    }
    catch (e) {
      this.errorMessage(e.message)
      this.setState({ disableBtn: false })
    }
  }

  handleCheckBox = (e, key) => {
    var states = this.state
    states[key] = e.target.checked
    this.setState({ states }, () => {
      if (!states.isCash) { this.setState({ cashAmt: null }) }
      if (!states.isCard) { this.setState({ cardAmt: null, cardNo: null }) }
      if (!states.isUPI) { this.setState({ upiAmt: null, upiNo: null }) }
      if (!states.isCheque) { this.setState({ chequeAmt: null, chequeNo: null }) }
      if (!states.isBankTranfer) { this.setState({ bankTfrAmt: null, bankTfrNo: null }) }
      if (!states.isInsurance) {
        this.setState({
          insuranceAmt: null,
          TransactionNumber: "",
          corporatePay: this.state.corporatePay === "Insurance" || this.state.corporatePay === "Insurance credit" ? "" : this.state.corporatePay,
          CompanyName: this.state.patientData?.primary_payment?.insurance_detail?.patient_insurance_company ? this.state.patientData?.primary_payment?.insurance_detail?.patient_insurance_company : "",
          PolicyNumber: this.state.patientData?.primary_payment?.insurance_detail?.policy_number ? this.state.patientData?.primary_payment?.insurance_detail?.policy_number : "",
        })
      }
      if (states.isInsurance) { this.setState({ corporatePay: "Insurance" }) }
      if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
        this.setState({ cashAmt: this.state.payable_amt, })  // Fetch Full amount in Cash Amount, when only the cash is selected
      }
    })
  }

  handleCheckBoxes = (e, key) => {
    let states = this.state;
    states[key] = e.target.checked
    this.setState({ states }, () => {
      if (key === "corporate") {
        this.setState({ Insurance: false, corporatePay: "Corporate", PatientCredit: false })
      }
      else if (key === "Insurance") {
        this.setState({ corporate: false, corporatePay: "Insurance", PatientCredit: false })
      }
      else if (key === "PatientCredit") {
        this.setState({ corporate: false, Insurance: false, corporatePay: "" })
      }
    })
  }

  RefhandleCheckBox = (e, key) => {
    var states = this.state
    states[key] = e.target.checked
    this.setState({ states }, () => {
      if (!states.refundCash) { this.setState({ cashAmt: null }) }
      if (!states.refundUPI) { this.setState({ upiAmt: null, upiNo: null }) }
      if (!states.refundBankTranfer) { this.setState({ bankTfrAmt: null, bankTfrNo: null }) }
      if (key === "refundCash") { this.setState({ refundUPI: false, refundBankTranfer: false }) }
      if (key === "refundUPI") { this.setState({ refundCash: false, refundBankTranfer: false }) }
      if (key === "refundBankTranfer") { this.setState({ refundUPI: false, refundCash: false }) }
      this.handleClearDetails();
    })
  }

  handleClearDetails = () => {
    this.setState({
      refupiNo: null,
      refupiId: null,
      refBankAccNo: null,
      refConbankAccNo: null,
      refbankIFSCNo: null,
      refbankName: null,
    })
  }

  // handleTableCheckbox = (id) => {
  //   let states = this.state;
  //   let selectedIDs = new Set(id);
  //   let selectedRows = states["paymentDetails"].filter((row) => selectedIDs.has(row.bill_summary_id));
  //   let creditAmount = selectedRows.reduce((acc, cur) => acc += cur["outstanding_amount"], 0);
  //   this.setState({ payable_amt: creditAmount, selectionModel: id, creditAmount: creditAmount });
  // }

  getService_name = (params) => {
    let service_name = "";
    let Datas = params?.length > 0 ? params : [];
    Datas.forEach(element => {
      service_name = service_name ? (`${service_name},  ${element.service_name}`) : `${element.service_name ? element.service_name : "-"}`
    });
    return (
      <div>{service_name?.length > 25 ? <Tooltip placement="top" title={service_name} arrow><div>{service_name.slice(0, 30) + "..."}</div></Tooltip> : service_name ? service_name : "-"}</div>
    )
  }

  getFinalDiscountServiceName = (params) => {
    let service_name = "";
    let Datas = params?.length > 0 ? params : [];
    Datas.forEach(element => {
      service_name = service_name ? (`${service_name},  ${element.service_name}`) : `${element.service_name ? element.service_name : "-"}`
    });
    return (
      <div>{service_name?.length > 15 ? <Tooltip placement="top" title={service_name} arrow><div>{service_name.slice(0, 15) + "..."}</div></Tooltip> : service_name ? service_name : "-"}</div>
    )
  }

  Capitalize = (sentence) => {
    const wordArr = sentence.split(" ");
    for (let i = 0; i < wordArr.length; i++) {
      wordArr[i] = wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
    }
    const capitalized = wordArr.join(" ");
    return capitalized;
  }

  msgClose() {
    this.setState({
      showSuccessResponse: false,
      showErrorResponse: false,
      responseMessage: ''
    })
  }

  getIPBillPrint = () => {
    let admission_id = this.state.patientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      let API_URL = Serviceurls.IP_RECEIVE_PAYMENT_PRINT + "?ip_admission_id=" + admission_id
      RestAPIService.getAll(API_URL).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.response?.data?.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  SplitPayable = (label, PendingAmt, CheckBoxKey, PayableAmtKey) => {
    let states = this.state;
    return (
      <Box className="eMed_split_Wrap">
        <Checkbox
          disabled={PendingAmt === 0}
          checked={states[CheckBoxKey]}
          onChange={(e) => {
            states[CheckBoxKey] = e.target.checked
            if (!states[CheckBoxKey]) {
              states[PayableAmtKey] = ""
            }
            this.setState({ states })
          }}
          size='small' />
        <Box className="eMed_split_Label_Amount">
          <Typography fontSize={'0.8vw'}>{label}</Typography>
          <Typography fontSize={'0.95vw'} fontWeight={600}>{`${CurrencySymbol} ${PendingAmt ? PendingAmt?.toFixed(2) : 0}`}</Typography>
        </Box>
        {states[CheckBoxKey] ?
          <TextField
            inputProps={{ style: { height: "1vw" } }}
            size='small'
            value={states[PayableAmtKey]}
            sx={{ width: "8vw", marginLeft: '1vw' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{CurrencySymbol}</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              let number = CommonValidation.NumberWithDot(e.target.value);
              if ((number && e.target.value > 0 && e.target.value <= +PendingAmt) || e.target.value === "") {
                states[PayableAmtKey] = e.target.value
                this.setState({ states }, () => {
                  let OpAmt = states?.OPpayingAmount ? +states?.OPpayingAmount : 0
                  let IpAMt = states?.IPPayingAmount ? +states?.IPPayingAmount : 0
                  let PharmaAmt = states?.PharmaPayingAmount ? +states?.PharmaPayingAmount : 0
                  this.setState({
                    payable_amt: OpAmt + IpAMt + PharmaAmt,
                    cashAmt: OpAmt + IpAMt + PharmaAmt
                  })
                })
              }
            }}
          /> : null}

      </Box>
    )
  }

  renderAmountTextFields = (label, key, Icon) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        sx={{ padding: 0, margin: 0, width: "10vw" }}
        className='eMed_CrdPop_Amount_TextFields'
        size='small'
        label={t(label) + "(₹)"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={Icon} alt={label} className='eMed_action_img_small' />
            </InputAdornment>
          ),
        }}
        value={states[key]}
        onChange={(e) => {
          let number = CommonValidation.NumberWithDot(e.target.value);
          if ((number && e.target.value > 0) || e.target.value === "") {
            states[key] = e.target.value
            this.setState({
              states
            })
          }
        }}
      />
    )
  }

  renderNumberTextFields = (label, key, Right = false) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        className='eMed_CrdPop_Amount_TextFields'
        sx={{ marginLeft: Right ? "1vw" : 0, width: '10vw' }}
        inputProps={{ maxLength: 20 }}
        size='small'
        label={t(label)}
        value={states[key]}
        onChange={(e) => {
          let number = CommonValidation.gstnumberValidation(e.target.value);
          if (number || e.target.value === "") {
            states[key] = e.target.value.toUpperCase()
            this.setState({
              states
            })
          }
        }}
      />
    )
  }

  ReceivePaymentTab = () => {
    const { t } = this.props
    let states = this.state;
    let AdvanceAmt = states["amountData"]["advance_amount"] !== 0 && AmountFormat(states["amountData"]["advance_amount"]);
    let corporate_name = states["corporate_name"] ? states["corporate_name"] : "-";
    let isFromAdvace = (states["payAdv"] === "PayFromAdvance" && AdvanceAmt);
    let denomeBalance = (isFromAdvace && states.pendingAmount < states.payable_amt) ? (+states["payable_amt"] - +states.pendingAmount) : +states["payable_amt"];
    return (
      <div>
        <div className="eMed_recPayment_subDiv" style={{ height: this.state.isFromCheckOut ? '50vh' : "54vh", overflow: 'auto' }}>
          {this.state.isRegular === "Credit" || (states.value === "four" && !this.state.isFromCheckOut) ? null :
            <Stack className="emedhub_recPayment_RightContentBox" direction="column" justifyContent="center" alignItems="center" spacing={2.5} mb={1.8}>
              {isFromAdvace ? <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "27.5vw" }}><Typography id='eMed_recPayment_advTxt'>{`${t("AdvanceAmount")}`}</Typography><Typography>{`${AdvanceAmt}`}</Typography></Stack> : null}
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
                {this.renderTextBox("PayableAmount", "payable_amt", states["payable_amt"], "13.4vw")}
                {this.renderTextBox("PaymentDate", "paymentDate", states["paymentDate"], "13.2vw")}
              </Stack>
            </Stack>}
          {/* <div className='eMed_recPayment_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
            <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" mt={1}>
              <RadioGroup row value={this.state.isRegular ? this.state.isRegular : "regular"} onClick={(e) => {
                this.setState({
                  isRegular: this.state.isFromCheckOut ? this.state.isRegular : e.target.value
                })
                if (e.target.value === "Credit" && this.state.pendingAmount < 0) {
                  this.setState({
                    isCash: false,
                    isCard: false,
                    isCheque: false,
                    isUPI: false,
                    isBankTranfer: false,
                    corporate_amt: -(this.state.pendingAmount),
                    Insurance_amt: -(this.state.pendingAmount),
                    patientCreditAmount: -(this.state.pendingAmount),
                  })
                }
              }}>
                {this.state.isFromCheckOut ? null : <FormControlLabel value={"regular"} control={<Radio size="small" disabled={isFromAdvace ? true : false} />} label={<Typography id="eMed_recPayment_radioTxt">{t("Regular")}</Typography>} />}
                <FormControlLabel value={"Credit"} control={<Radio size="small" disabled={isFromAdvace || this.state.pendingAmount >= 0 || (this.state.pendingAmount < 0 && !this.state.isFromCheckOut) ? true : false} />} label={<Typography id="eMed_recPayment_radioTxt">{t("Credit")}</Typography>} />
              </RadioGroup>
            </Stack>
          </div> */}
          {states.isRegular === "Credit" || (states.value === "four" && !this.state.isFromCheckOut) ?
            <div style={{ width: "27.5vw", height: "17vh", marginLeft: "0.95vw" }}>
              {this.state.value === "one" || (states.value === "four" && !this.state.isFromCheckOut) ?
                <Box>
                  <Typography sx={{ marginTop: "0.5vw", marginLeft: "1vw" }} fontSize={"0.9vw"}>Payment Summary</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>Total Bill Amount</Typography>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.serviceAmount ? this.state.serviceAmount : 0}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>Total Received Amount</Typography>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.paidAmount ? this.state.paidAmount : 0}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>Approved Insurance Amount</Typography>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.ApprovedAmt ? this.state.ApprovedAmt : 0}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>Total Receivable Amount</Typography>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.TotalPayableAmount ? Math.round(this.state.TotalPayableAmount) : 0}</Typography>
                  </Box>
                  <Box display={this.state.AdvanceAmount ? "inline" : "none"} paddingLeft={"1vw"}>
                    <FormControlLabel control={
                      <Checkbox
                        checked={this.state.PayFromAdvance}
                        disabled={!this.state.TotalPayableAmount}
                        onChange={(e) => {
                          this.setState({
                            PayFromAdvance: e.target.checked,
                          }, () => {
                            if (this.state.PayFromAdvance) {
                              if (this.state.AdvanceAmount < this.state.TotalPayableAmount) {
                                this.setState({ PayableAmount: this.state.AdvanceAmount }, () => { this.CalculateDeductAmt() })
                              } else {
                                this.setState({ PayableAmount: this.state.TotalPayableAmount }, () => { this.CalculateDeductAmt() })
                              }
                            } else {
                              this.setState({ PayableAmount: (this.state.TotalPayableAmount) }, () => { this.CalculateDeductAmt() })
                            }
                          })
                        }}
                        size='small' />}
                      label={`Pay from Advance ( ${AmountFormat(this.state.AdvanceAmount)} )`} sx={{ fontSize: "1vw" }} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingX: "1vw", paddingBottom: '0.5vw', marginTop: "0.5vw" }}>
                    <Typography fontSize={"0.9vw"} fontWeight={600}>{this.state.PayFromAdvance ? "Deductable Advance Amount" : "Receivable Amount"}</Typography>
                    <OutlinedInput
                      inputProps={{ emed_tid: "cb_total_discount_amount" }}
                      value={this.state.PayableAmount ? Math.round(this.state.PayableAmount) : ""}
                      disabled={!this.state.TotalPayableAmount || this.state.PayFromAdvance}
                      startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                      sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw" }}
                      onChange={(e) => {
                        const { value } = e.target
                        if ((CommonValidation.NumberWithDot(value) && value <= this.state.TotalPayableAmount) || value === "") {
                          this.setState({
                            PayableAmount: value
                          }, () => { this.CalculateDeductAmt() })
                        }
                      }} />
                  </Box>
                  <Divider />
                  <Box sx={{ overflowY: "scroll", paddingLeft: '1vw' }}>
                    <Box className="eMed_CrdPop_Check_Group">
                      <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCashPayment} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.BillshandleCheckBox(e, "isCashPayment") }} size='small' />} label={t("Cash")} />
                      <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isCardPayment} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.BillshandleCheckBox(e, "isCardPayment") }} size='small' />} label={t("Card")} />
                      <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isUPIPayment} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.BillshandleCheckBox(e, "isUPIPayment") }} size='small' />} label={t("UPI")} />
                      <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox checked={this.state.isChequePayment} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.BillshandleCheckBox(e, "isChequePayment") }} size='small' />} label={t("Cheque")} />
                      <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox checked={this.state.isBankTranferPayment} disabled={!this.state.PayableAmount || this.state.PayFromAdvance} onChange={(e) => { this.BillshandleCheckBox(e, "isBankTranferPayment") }} size='small' />} label={t("BankTransfer")} />
                    </Box>

                    <Box component={'div'}>
                      {
                        this.state.isCashPayment ? this.renderAmountTextFields("CashAmount", "CashAmountPayment", ImagePaths.CashIcon.default) : null
                      }
                      {
                        this.state.isCardPayment ?
                          <Box component={"div"} display={"flex"} flexDirection={"row"}>
                            {this.renderAmountTextFields("CardAmount", "CardAmountPayment", ImagePaths.CardIcon.default)}
                            {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumberPayment", true)}
                          </Box>
                          : null
                      }
                      {
                        this.state.isUPIPayment ?
                          <Box component={"div"} display={"flex"} flexDirection={"row"}>
                            {this.renderAmountTextFields("UPIAmount", "UPIAmountPayment", ImagePaths.UPIIcon.default)}
                            {this.renderNumberTextFields("UPITransactionNumber", "UPINumberPayment", true)}
                          </Box>
                          : null
                      }
                      {
                        this.state.isBankTranferPayment ?
                          <Box component={"div"} display={"flex"} flexDirection={"row"}>
                            {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmountPayment", ImagePaths.BankTransferIcon.default)}
                            {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumberPayment", true)}
                          </Box>
                          : null
                      }
                      {
                        this.state.isChequePayment ?
                          <Box component={"div"} display={"flex"} flexDirection={"row"}>
                            {this.renderAmountTextFields("ChequeAmount", "ChequeAmountPayment", ImagePaths.ChequeIcon.default)}
                            {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumberPayment", true)}
                          </Box>
                          : null
                      }

                    </Box>
                    {this.state.isCashPayment ?
                      <Accordion sx={{ marginTop: '0.5vw' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Denomination</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize' }} onClick={() => {
                              this.setState({
                                TwoThounsandCountsPayment: "",
                                FiftyCountsPayment: "",
                                FiveHundredCountsPayment: "",
                                TwentyCountsPayment: "",
                                TwoHundredCountsPayment: "",
                                TenCountsPayment: "",
                                HundredCountsPayment: "",
                                CoinsCountsPayment: "",
                                FiveCountPayment: "",
                              })
                            }}>Reset</Button>
                          </Box>
                          <Box component={"div"} className="eMed_CrdPop_Denomi_div_New">
                            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                              {this.renderCountsText("2000", "TwoThounsandCountsPayment")}
                              {this.renderCountsText("20", "TwentyCountsPayment", true)}
                            </Box>
                            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                              {this.renderCountsText("500", "FiveHundredCountsPayment")}
                              {this.renderCountsText("10", "TenCountsPayment", true)}
                            </Box>
                            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                              {this.renderCountsText("200", "TwoHundredCountsPayment")}
                              {this.renderCountsText("5", "FiveCountPayment", true)}
                            </Box>
                            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                              {this.renderCountsText("100", "HundredCountsPayment")}
                              {this.renderCountsText("1", "CoinsCountsPayment", true)}
                            </Box>
                            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                              {this.renderCountsText("50", "FiftyCountsPayment")}
                            </Box>
                            <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"} justifyContent={'center'}>
                              <Typography>{t("Total")}<b style={{ color: (this.returnTotal() === +this.state.PayableAmount && this.state.PayableAmount !== "") ? Colors.themeDark : Colors.black, marginLeft: '1vw' }}>{`₹ ${this.returnTotal()}`}</b></Typography>
                              <Typography marginLeft={"3vw"}>{t("Balance")}<b style={{ color: (this.state.PayableAmount != "" ? +this.state.PayableAmount - this.returnTotal() : 0) < 0 ? Colors.red : Colors.black, marginLeft: '1vw' }}>₹ {this.state.PayableAmount != "" ? +this.state.PayableAmount - this.returnTotal() : 0}</b></Typography>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      : null}
                  </Box>
                </Box> :
                <Box>
                  <FormGroup sx={{ paddingX: "0.5vw" }}>
                    <FormControlLabel control={<Checkbox checked={states.corporatePay == "Corporate" || states.corporatePay == "Corprate credit" || states.corporate} onChange={(e) => { this.handleCheckBoxes(e, "corporate") }} disabled={isFromAdvace ? true : false} size='small' />} label={<Typography id="eMed_recPayment_radioCorpTxt"><span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Corporate")}: `}</span>{`${t("PatientUnder")} ${corporate_name ? corporate_name : "-"} ${t("CorporatePlan")} (${t("ID.No.")}${(states["employee_id"] ? states["employee_id"] : "-")})  ${(states["discount_percent"] ? states["discount_percent"] : "0")}% ${t("corporateTxt")}`}</Typography>} />
                    {states.corporate || states.corporatePay == "Corporate" || states.corporatePay == "Corprate credit" ? <Stack display={"flex"} flexDirection={"row"} flexWrap={"wrap"} justifyContent="space-between" spacing={1.5} marginTop={".5vw"} marginBottom={".5vw"}>
                      {this.renderTextBox("Corporate Name *", "corporate_name", states["corporate_name"], "13.4vw")}
                      {this.renderTextBox("Employee Id", "employee_id", states["employee_id"], "13.4vw")}
                      {this.renderTextBox("Amount", "corporate_amt", states["corporate_amt"], "13.4vw")}
                      {/* {this.renderTextBox("Approval Details", "cor_approval_details", states["cor_approval_details"], "13.2vw")} */}
                    </Stack> : null}
                    <FormControlLabel control={<Checkbox checked={states.corporatePay == "Insurance" || states.Insurance || states.corporatePay == "Insurance credit"} onChange={(e) => { this.handleCheckBoxes(e, "Insurance") }} disabled={isFromAdvace ? true : false} size='small' />} label={<Typography id="eMed_recPayment_radioCorpTxt"><span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Insurance")}: `}</span>{`${t("PatientRegisteredUnder")} ${states.CompanyName ? states.CompanyName : "-"} (${t("RegNo")} ${states["PolicyNumber"] ? states["PolicyNumber"] : "-"})`}</Typography>} />
                    {states.Insurance || states.corporatePay == "Insurance credit" || states.corporatePay == "Insurance" ? <Stack display={"flex"} flexDirection={"row"} flexWrap={"wrap"} justifyContent="space-between" spacing={1.5} marginTop={".5vw"} marginBottom={".5vw"}>
                      {this.renderTextBox("Insurance Company *", "CompanyName", states["CompanyName"], "13.4vw")}
                      {this.renderTextBox("Policy Number", "PolicyNumber", states["PolicyNumber"], "13.4vw")}
                      {this.renderTextBox("Amount", "Insurance_amt", states["Insurance_amt"], "13.4vw")}
                      {/* {this.renderTextBox("Approval Details", "Ins_approval_details", states["Ins_approval_details"], "13.2vw")} */}
                    </Stack> : null}
                    {/* <FormControlLabel control={<Checkbox checked={states.credit} onChange={(e) => { this.handleCheckBoxes(e, "credit") }} disabled={isFromAdvace ? true : false} />} label={"Patient Credit"} />
                {states.credit ? <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5} marginTop={".5vw"} marginBottom={".5vw"}>
                  {this.renderTextBox("Amount", "corporate_amt", states["patient_credit_amt"], "13.4vw")}
                  {this.renderTextBox("Approval Details", "credit_approval_details", states["credit_approval_details"], "13.2vw")}
                </Stack> : null} */}
                    <FormControlLabel control={<Checkbox checked={states.PatientCredit} onChange={(e) => { this.handleCheckBoxes(e, "PatientCredit") }} disabled={isFromAdvace ? true : false} size='small' />} label={<Typography id="eMed_recPayment_radioCorpTxt"><span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Patient Credit")} `}</span></Typography>} />
                    {states.PatientCredit ? <Stack display={"flex"} flexDirection={"row"} flexWrap={"wrap"} justifyContent="space-between" spacing={1.5} marginTop={".5vw"} marginBottom={".5vw"}>
                      {this.renderTextBox("Amount", "patientCreditAmount", states["patientCreditAmount"], "13.4vw")}
                      {/* {this.renderTextBox("Approval Details", "Ins_approval_details", states["Ins_approval_details"], "13.2vw")} */}
                    </Stack> : null}
                  </FormGroup>
                </Box>}
            </div> : null}
          {states.isRegular === "regular" && states.value !== "four" ?
            <Stack className="emedhub_recPayment_RightContentBox" direction="column" justifyContent="center" alignItems="center" spacing={1}>
              <FormGroup row>
                <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox size="small" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cash")}</Typography>} />
                <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox size="small" checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Card")}</Typography>} />
                <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox size="small" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("UPI")}</Typography>} />
                <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox size="small" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("BankTransfer")}</Typography>} />
                <FormControlLabel sx={{ width: "8.5vw" }} control={<Checkbox size="small" checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cheque")}</Typography>} />
                {/* <FormControlLabel sx={{width:"8.5vw"}} control={<Checkbox size="small" checked={this.state.isInsurance} onChange={(e) => { this.handleCheckBox(e, "isInsurance") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Insurance")}</Typography>} /> */}
              </FormGroup>

              <Box component={'div'} display={"flex"} justifyContent={"flex-start"} flexDirection={"column"} sx={{ width: "27.5vw" }}>
                {/* {this.state.isRegular === "Insurance" ?
                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{ marginLeft: "-13vw" }}>
                  {this.renderTextBox("", "insuranceNo", states["insuranceNo"], "", "", "ReferenceNo")}
                </Stack>
                : null} */}
                {this.state.isCash ?
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                    {this.renderTextBox("", "cashAmt", states["cashAmt"], "13.5vw", CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default, "CashAmount", !states["isCash"])}
                  </Stack> : null}
                {this.state.isCard ?
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                    {this.renderTextBox("", "cardAmt", states["cardAmt"], "13.5vw", ImagePaths.CardIcon.default, "CardAmount", !states["isCard"])}
                    {this.renderTextBox("", "cardNo", states["cardNo"], "13.5vw", "", "ReferenceNo", !states["isCard"])}
                  </Stack> : null}
                {this.state.isUPI ?
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                    {this.renderTextBox("", "upiAmt", states["upiAmt"], "13.5vw", ImagePaths.UPIIcon.default, "UPIAmount", !states["isUPI"])}
                    {this.renderTextBox("", "upiNo", states["upiNo"], "13.5vw", "", "ReferenceNo", !states["isUPI"])}
                  </Stack> : null}
                {this.state.isBankTranfer ?
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                    {this.renderTextBox("", "bankTfrAmt", states["bankTfrAmt"], "13.5vw", ImagePaths.BankTransferIcon.default, "BankTransferAmount", !states["isBankTranfer"])}
                    {this.renderTextBox("", "bankTfrNo", states["bankTfrNo"], "13.5vw", "", "ReferenceNo", !states["isBankTranfer"])}
                  </Stack> : null}
                {this.state.isCheque ?
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                    {this.renderTextBox("", "chequeAmt", states["chequeAmt"], "13.5vw", ImagePaths.ChequeIcon.default, "ChequeAmount", !states["isCheque"])}
                    {this.renderTextBox("", "chequeNo", states["chequeNo"], "13.5vw", "", "ReferenceNo", !states["isCheque"])}
                  </Stack>
                  : null
                }
                {this.state.isInsurance ?
                  <Box>
                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                      {this.renderTextBox("", "insuranceAmt", states["insuranceAmt"], "13.5vw", CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default, "InsuranceAmount", !states["isInsurance"])}
                      {this.renderTextBox("", "TransactionNumber", states["TransactionNumber"], "13.5vw", "", "TransactionNumber", !states["isInsurance"])}
                    </Stack>
                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                      {this.renderTextBox("", "CompanyName", states["CompanyName"], "13.5vw", "", "CompanyName", !states["isInsurance"])}
                      {this.renderTextBox("", "PolicyNumber", states["PolicyNumber"], "13.5vw", "", "PolicyNumber", !states["isInsurance"])}
                    </Stack>
                  </Box>
                  : null
                }
              </Box>
              {
                this.state.isCash ?
                  <Box component={"div"} className="eMed_recPayment_Denomi_div" sx={{ display: this.state.isCash ? "block" : "none" }}>
                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                      {this.renderCountsText("2000", "TwoThounsandCounts")}
                      {this.renderCountsText("50", "FiftyCounts", true)}
                    </Box>
                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                      {this.renderCountsText("500", "FiveHundredCounts")}
                      {this.renderCountsText("20", "TwentyCounts", true)}
                    </Box>
                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                      {this.renderCountsText("200", "TwoHundredCounts")}
                      {this.renderCountsText("10", "TenCounts", true)}
                    </Box>
                    <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
                      {this.renderCountsText("100", "HundredCounts")}
                    </Box>
                    <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"} justifyContent={'center'}>
                      <Typography>{t("Total")}<b style={{ color: (this.returnTotal() === +denomeBalance && denomeBalance !== "") ? Colors.themeDark : Colors.black, marginLeft: '1vw' }}>{`${CurrencySymbol} ${this.returnTotal()}`}</b></Typography>
                      <Typography marginLeft={"3vw"}>{t("Balance")}<b style={{ color: (denomeBalance != "" ? +denomeBalance - this.returnTotal() : 0) < 0 ? Colors.red : Colors.black, marginLeft: '1vw' }}>{CurrencySymbol} {denomeBalance != "" ? +denomeBalance - this.returnTotal() : 0}</b></Typography>
                    </Box>
                  </Box> : null
              }
            </Stack> : null}
        </div>
      </div>
    )
  }

  RefundPaymentTab = () => {
    const { t } = this.props;
    let states = this.state;
    // let BalanceAmount = states?.CardPendingAmount > 0 ? ((+states?.CardPendingAmount + +states?.paidAmount) - (+states.AdvanceAmount)) : 0
    let BalanceAmount = states?.CardPendingAmount > 0 ? (states?.CardPendingAmount - states.AdvanceAmount) : 0
    return (
      <div style={{ paddingTop: "0.5vw" }}>
        <Box component={'div'} style={{ marginLeft: "1.5vw", marginBottom: "0.5vw" }}>
          <RadioGroup value={this.state.Refund_Status}
            sx={{ display: 'flex', flexDirection: 'row', width: '18vw' }}
            onClick={(e) => {
              if (states?.isClimed) {
                this.setState({ Refund_Status: e.target.value, BalanceAmt: BalanceAmount })
              }
            }}
          >
            <FormControlLabel value={"advance"} control={<Radio size='small' />} label='Advance' />
            <FormControlLabel value={"balance"} disabled={!states?.isClimed} control={<Radio size='small' />} label='Balance' />
          </RadioGroup>
        </Box>
        <Box sx={{ paddingLeft: "0.75vw", paddingRight: "2vw" }}>
          <Typography sx={{ marginTop: "0.5vw", marginLeft: "1vw" }} fontSize={"0.9vw"}>Payment Summary</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
            <Typography fontSize={"0.9vw"} fontWeight={600}>Total Bill Amount</Typography>
            <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.serviceAmount ? this.state.serviceAmount : 0}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
            <Typography fontSize={"0.9vw"} fontWeight={600}>Total Received Amount</Typography>
            <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.paidAmount ? this.state.paidAmount : 0}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: "1vw", paddingBottom: '0.4vw' }}>
            <Typography fontSize={"0.9vw"} fontWeight={600}>Approved Insurance Amount</Typography>
            <Typography fontSize={"0.9vw"} fontWeight={600}>₹  {this.state.ApprovedAmt ? this.state.ApprovedAmt : 0}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingX: "1vw", paddingBottom: '0.5vw', marginTop: "0.5vw" }}>
            <Typography fontSize={"0.9vw"} fontWeight={600}>{"Total Refundable Amount"}</Typography>
            <OutlinedInput
              value={this.state.Refund_Status === "advance" ? states.AdvanceAmount : BalanceAmount}
              disabled={true}
              startAdornment={<InputAdornment position="start">₹</InputAdornment>}
              sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw" }}
            />
          </Box>
        </Box>
        {/* <Stack className="emedhub_recPayment_RightContentBox" direction="column" justifyContent="center" alignItems="center" spacing={2.5} mb={1.8}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1.5}>
            {this.renderTextBox("Refund Amount", "refund_amt", (this.state.Refund_Status === "advance" ? states.AdvanceAmount : BalanceAmount), "13.4vw")}
            {this.renderTextBox("Refund Date", "refunDate", states["refunDate"], "13.2vw")}
          </Stack>
          {this.renderTextBox("PaymentMadeBy", "refundPaymentmadeBy", states["LogedUserName"], "27.5vw")}
        </Stack> */}
        <div className='eMed_recPayment_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
          <p className='eMed_recPayment_rightHead_txt'>{t("PaymentMethod")}</p>
        </div>
        <div className="eMed_refundPayment_subDiv" style={{ height: this.state.isFromCheckOut ? "11vw" : "15vw" }}>
          <Stack className="emedhub_recPayment_RightContentBox" direction="column" display={'flex'} marginTop={"-0.75vw"} justifyContent={'flex-start'} spacing={1}>
            <FormGroup row>
              <FormControlLabel control={<Checkbox size="small" checked={states.refundCash} onChange={(e) => { this.RefhandleCheckBox(e, "refundCash") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("Cash")}</Typography>} />
              <FormControlLabel control={<Checkbox size="small" checked={states.refundUPI} onChange={(e) => { this.RefhandleCheckBox(e, "refundUPI") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("UPI")}</Typography>} />
              <FormControlLabel control={<Checkbox size="small" checked={states.refundBankTranfer} onChange={(e) => { this.RefhandleCheckBox(e, "refundBankTranfer") }} />} label={<Typography id="eMed_recPayment_checkTxt">{t("BankTransfer")}</Typography>} />
            </FormGroup>
          </Stack>
          <Box component={'div'} display={"flex"} justifyContent={"flex-start"} flexDirection={"column"} sx={{ width: "29vw", paddingLeft: "1vw" }}>
            {this.state.refundCash ?
              <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                {this.renderTextBox("", "refcashAmt", (this.state.Refund_Status === "advance" ? (states.AdvanceAmount < 0 ? 0 : states.AdvanceAmount) : (BalanceAmount < 0 ? 0 : BalanceAmount)), "13.5vw", CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.RupeeOff.default, "CashAmount", !states["refundCash"])}
              </Stack> : null}
            {this.state.refundUPI ?
              <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
                {this.renderTextBox("", "refupiAmt", (this.state.Refund_Status === "advance" ? (states.AdvanceAmount < 0 ? 0 : states.AdvanceAmount) : (BalanceAmount < 0 ? 0 : BalanceAmount)), "13.5vw", ImagePaths.UPIIcon.default, "UPIAmount", !states["refundUPI"])}
                {this.renderTextBox("", "refupiNo", states["refupiNo"], "13.5vw", "", "ReferenceNo", !states["refundUPI"])}
                {this.renderTextBox("", "refupiId", states["refupiId"], "13.5vw", "", "UPI ID", !states["refundUPI"])}
              </Stack> : null}
            {this.state.refundBankTranfer ?
              <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
                {this.renderTextBox("", "refbankTfrAmt", (this.state.Refund_Status === "advance" ? (states.AdvanceAmount < 0 ? 0 : states.AdvanceAmount) : (BalanceAmount < 0 ? 0 : BalanceAmount)), "13.5vw", ImagePaths.BankTransferIcon.default, "BankTransferAmount", !states["refundBankTranfer"])}
                {this.renderTextBox("", "refbankName", states["refbankName"], "13.5vw", "", "Bank Name", !states["refundBankTranfer"])}
                {this.renderTextBox("", "refBankAccNo", states["refBankAccNo"], "13.5vw", "", "Account Number", !states["refundBankTranfer"], true)}
                {this.renderTextBox("", "refConbankAccNo", states["refConbankAccNo"], "13.5vw", "", "Confirm account number", !states["refundBankTranfer"])}
                {this.renderTextBox("", "refbankIFSCNo", states["refbankIFSCNo"], "13.5vw", "", "IFSC Number", !states["refundBankTranfer"])}
              </Stack> : null}
          </Box>
          <Box component={"div"} className="eMed_recPayment_Denomi_div" sx={{ display: this.state.refundCash ? "block" : "none", marginLeft: "1vw" }}>
            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
              {this.renderCountsText("2000", "TwoThounsandCounts")}
              {this.renderCountsText("50", "FiftyCounts", true)}
            </Box>
            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
              {this.renderCountsText("500", "FiveHundredCounts")}
              {this.renderCountsText("20", "TwentyCounts", true)}
            </Box>
            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
              {this.renderCountsText("200", "TwoHundredCounts")}
              {this.renderCountsText("10", "TenCounts", true)}
            </Box>
            <Box component={"div"} className="eMed_CrdPop_Denomi_MainGrid_div">
              {this.renderCountsText("100", "HundredCounts")}
            </Box>
            <Box component={'div'} display={"flex"} flexDirection={"row"} margin={"1vw"} justifyContent={'center'}>
              <Typography>{t("Total")}<b style={{ color: (this.returnTotal() === +this.state.payable_amt && this.state.payable_amt !== "") ? Colors.themeDark : Colors.black, marginLeft: '1vw' }}>{`${CurrencySymbol} ${this.returnTotal()}`}</b></Typography>
              <Typography marginLeft={"3vw"}>{t("Balance")}<b style={{ color: (this.state.payable_amt != "" ? +this.state.payable_amt - this.returnTotal() : 0) < 0 ? Colors.red : Colors.black, marginLeft: '1vw' }}>{CurrencySymbol} {states.AdvanceAmount < 0 ? 0 : states.AdvanceAmount - this.returnTotal()}</b></Typography>
            </Box>
          </Box>
        </div>
      </div>
    )
  }

  ClosesuccessPopUp = () => {
    this.setState({
      receiptID: null,
      pharmareceipt: null
    })
  }

  getCustomConfigurationData = () => {
    try {
      RestAPIService.getAllTwo(Serviceurls.CUSTOM_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({ customConfigData: response.data.data })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.responseSuccessErrorMessage(error?.response?.data?.message, false)
          } else {
            this.responseSuccessErrorMessage(error.message, false)
          }
        })
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  postOverAllDiscount = () => {
    try {
      let states = this.state
      let paymentDetails = []; let paymentHash = {}; let newPayment = []; let discountAmt = 0
      let dupPaymentData = JSON.parse(this.state.dupliPaymentDetails)
      states.paymentDetails?.forEach(item => {
        paymentHash[item.bill_no] = item
        newPayment?.push(item)
      })
      dupPaymentData?.filter(element => !paymentHash[element.bill_no] ? newPayment?.push(element) : [])
      newPayment?.forEach(element => {
        paymentDetails?.push({
          is_change: element?.is_change ? element?.is_change : false,
          discount_amount: element?.discount_amount ? element?.discount_amount : 0,
          discount_percentage: element?.discount_percentage ? element?.discount_percentage : 0,
          total_amount: element?.payment_status === 'not paid' ? ((element?.sub_total ? Math.round(element?.sub_total) : 0) - (element?.discount_amount ? element?.discount_amount : 0)) : (element?.outstanding_amount ? element?.outstanding_amount : 0),
          lab_bill_summary_id: element?.lab_bill_summary_id ? element?.lab_bill_summary_id : null,
          ph_bill_summary_id: element?.ph_bill_summary_id ? element?.ph_bill_summary_id : null,
          bill_summary_id: element?.bill_summary_id ? element?.bill_summary_id : null,
          module: element?.module ? element?.module : null,
          is_overall_concession: (this.state.overallConcession !== '' || this.state.overallConcession !== 0) ? element.is_overall_concession : false
        })
        if (element.bill_type !== 'Return' && element.payment_status !== 'partial') {
          discountAmt = discountAmt + element.discount_amount
        }
      })
      let data = {
        ip_admission_id: this.state.patientData?.id,
        data: paymentDetails,
        total_discount_amount: Math.round(+discountAmt - this.state.dupliDiscount),
        remarks: this.state.overallDiscountReason ? this.state.overallDiscountReason : '',
        over_all_concession: this.state.overallConcession ? Math.round(+this.state.overallConcession) : 0,
        over_all_concession_percentage: this.state.overAllPercent ? +this.state.overAllPercent : 0
      }
      let checkData = this.state.paymentDetails?.some(item => item?.discount_amount !== 0)
      if ((checkData && this.state.overallDiscountReason === '')) {
        this.responseSuccessErrorMessage('Enter Discount Reason', false)
        this.setState({ disableBtn: false })
      } else {
        RestAPIService.create(data, Serviceurls.IP_OVERALL_DISCOUNT)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.responseSuccessErrorMessage(response.data.message, true)
              this.setState({
                openFinalDiscount: false,
                dupliDiscount: 0,
                disableBtn: false
              })
              this.getApproxCharges()
              this.getPatientData()
              this._getPaymentDetails()
              this.GetBillDataList()
            }
          }).catch((error) => {
            this.setState({ disableBtn: false })
            if (error?.response?.data?.message) {
              this.responseSuccessErrorMessage(error?.response?.data?.message, false)
            } else {
              this.responseSuccessErrorMessage(error?.message, false)
            }
          })
      }
    } catch (error) {
      this.setState({ disableBtn: false })
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  calculateTotalDiscountAmount = (value, index) => {
    try {
      let { paymentDetails } = this.state
      paymentDetails[index]['total_discount_amount'] = paymentDetails[index]['sub_total'] - value
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  onChangeHandler = (e, names, index) => {
    try {
      let value = e.target.value
      let { paymentDetails } = this.state
      // let outStandingAmt = paymentDetails[index]['outstanding_amount'] !== 0 ? paymentDetails[index]['outstanding_amount'] : paymentDetails[index]['sub_total']
      let amt = 0;
      // let totalAmt = paymentDetails[index]['return_amount'] ? (Math.round(paymentDetails[index]['sub_total']) - paymentDetails[index]['return_amount']) : Math.round(paymentDetails[index]['sub_total'])
      let totalAmt = paymentDetails[index]['bill_amt_const']
      let discountRestrict = +this.state.billingConcessionType === 1 ? totalAmt : (paymentDetails[index]['hospital_share'] ? paymentDetails[index]['hospital_share'] : totalAmt)
      let percentRestrict = +this.state.billingConcessionType === 1 ? 100 : CommonValidation.amountPercentage((paymentDetails[index]['hospital_share'] ? paymentDetails[index]['hospital_share'] : totalAmt), totalAmt)
      switch (names) {
        case 'discount':
          if ((CommonValidation.NumberWithDot(value) && (+value <= discountRestrict)) || value === '') {
            paymentDetails[index]['discount_amount'] = +value
            paymentDetails[index]['discount_percentage'] = +value ? CommonValidation.amountPercentage(+value, totalAmt) : 0
            paymentDetails[index]['is_change'] = true
            paymentDetails[index]['total_discount_amount'] = totalAmt - value
            paymentDetails[index]['payable_amount'] = totalAmt - value
            paymentDetails[index]['discount_man'] = paymentDetails[index]['discount_amount'] !== 0 ? true : false
            this.setState({ paymentDetails }, () => this.setState({ changed: true }))
          }
          break;
        case 'percentage':
          if ((CommonValidation.NumberWithDot(value) && (+value <= +percentRestrict)) || value === '') {
            paymentDetails[index]['discount_percentage'] = +value
            paymentDetails[index]['discount_amount'] = +value ? CommonValidation.calculatePercentageWithoutRoundoff(totalAmt, +value) : 0
            paymentDetails[index]['is_change'] = true
            paymentDetails[index]['payable_amount'] = totalAmt - paymentDetails[index]['discount_amount']
            paymentDetails[index]['discount_man'] = paymentDetails[index]['discount_amount'] !== 0 ? true : false
            this.setState({ paymentDetails }, () => {
              this.calculateTotalDiscountAmount(paymentDetails[index]['discount_amount'], index)
              this.setState({ changed: true })
            })
          }
          break;
        default: break;
      }
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  inputTextBox = (names, value, index, label, disabled) => {
    try {
      return (
        <TextField
          label={label}
          autoComplete="off"
          sx={{ width: '6vw', mr: '0.5vw' }}
          disabled={disabled || this.state.concessionChecked}
          InputProps={{ style: { height: '4vh' } }}
          value={value}
          name={names}
          onChange={(e) => this.onChangeHandler(e, names, index)}
        />
      )
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  filterTableData = () => {
    try {
      let states = this.state
      let filterData = [];
      if (states.checkAll) {
        filterData?.push('Pharmacy', 'Laboratory', 'Inpatient', 'Radiology')
      } else {
        if (states.checkIp) {
          filterData?.push('Inpatient')
        }
        if (states.checkLab) {
          filterData?.push('Laboratory')
        }
        if (states.checkPharmacy) {
          filterData?.push("Pharmacy")
        }
        if (states.checkRadiology) {
          filterData?.push("Radiology")
        }
      }
      let filterHash = {}; let data = [];
      filterData?.forEach(element => filterHash[element] = element)
      let dupliPaymentDetails = JSON.parse(this.state.dupliPaymentDetails)
      dupliPaymentDetails?.forEach(element => {
        if (filterHash[element?.module] === element?.module) {
          data?.push(element)
        }
      })
      if (states.checkAll) {
        if (states.overallConcession === '') {
          dupliPaymentDetails?.forEach(item => {
            if (item.is_overall_concession) {
              item.discount_amount = 0
              item.discount_percentage = 0
              item.is_change = true
              item.is_overall_concession = false
              item.from_over_discount = true
              // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total)
              item.payable_amount = item.bill_amt_const
              item.total_discount_amount = 0
            }
          })
        }
        this.setState({
          paymentDetails: dupliPaymentDetails,
          finalOverAllAmt: JSON.parse(this.state.dupFinalAmt)
        })
      } else {
        let totalAmount = 0;
        data?.forEach(element => {
          if (element.is_overall_concession || (element.payment_status === 'not paid' && !element.discount_amount && element.bill_type !== 'Return' && !element.pharmacy_return)) {
            if (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && element.module === 'Pharmacy') {
              totalAmount = totalAmount + 0
            } else {
              if (+this.state.billingConcessionType === 1) {
                // totalAmount = totalAmount + (element.return_amount ? (Math.round(element.sub_total) - element.return_amount) : Math.round(element.sub_total))
                totalAmount = totalAmount + element.bill_amt_const
              } else {
                // totalAmount = totalAmount + (element.hospital_share ? element.hospital_share : (element.return_amount ? (Math.round(element.sub_total) - element.return_amount) : Math.round(element.sub_total)))
                totalAmount = totalAmount + (element.hospital_share ? element.hospital_share : element.bill_amt_const)
              }
            }
            element.discount_amount = 0
            element.discount_percentage = 0
            element.is_change = true
            element.is_overall_concession = false
            element.from_over_discount = true
            // element.payable_amount = element.return_amount ? (Math.round(element?.sub_total) - (element?.return_amount + element?.discount_amount)) : Math.round(element.sub_total)
            element.payable_amount = element.bill_amt_const
            element.total_discount_amount = 0
          }
        })
        this.setState({
          paymentDetails: data,
        }, () => {
          this.setState({ finalOverAllAmt: totalAmount })
        })
      }
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  onChangeCheckBoxHandler = (e, key) => {
    try {
      let value = e.target.checked
      let states = this.state

      switch (key) {
        case 'checkAll':
          this.setState({
            checkAll: value
          }, () => {
            if (this.state.checkAll) {
              this.setState({ checkIp: true, checkLab: true, checkPharmacy: true, checkRadiology: true, overallConcession: '', overAllPercent: '' }, () => {

                this.filterTableData()
              })
            } else {
              this.setState({ checkIp: false, checkLab: false, checkPharmacy: false, checkRadiology: false, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            }
          })
          break;
        case 'checkIp':
          this.setState({
            checkIp: value
          }, () => {
            if (value && states.checkLab && states.checkPharmacy && states.checkRadiology) {
              this.setState({ checkAll: value, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            } else {
              this.setState({ checkAll: false, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            }
          })
          break;
        case 'checkLab':
          this.setState({
            checkLab: value
          }, () => {
            this.filterTableData()
            if (states.checkIp && value && states.checkPharmacy && states.checkRadiology) {
              this.setState({ checkAll: value, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            } else {
              this.setState({ checkAll: false, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            }
          })
          break;
        case 'checkPharmacy':
          this.setState({
            checkPharmacy: value
          }, () => {
            this.filterTableData()
            if (states.checkIp && states.checkLab && value && states.checkRadiology) {
              this.setState({ checkAll: value, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            } else {
              this.setState({ checkAll: false, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            }
          })
          break;
        case 'checkRadiology':
          this.setState({
            checkRadiology: value
          }, () => {
            this.filterTableData()
            if (states.checkIp && states.checkLab && value && states.checkPharmacy) {
              this.setState({ checkAll: value, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            } else {
              this.setState({ checkAll: false, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
            }
          })
          break;
        default:
          this.filterTableData()
          if (states.checkIp && states.checkLab && states.checkPharmacy) {
            this.setState({ checkAll: true, overallConcession: '', overAllPercent: '' }, () => this.filterTableData())
          }
          break;
      }

    } catch (error) {
      this.responseSuccessErrorMessage((error.message, false))
    }
  }

  billingCalculation = () => {
    try {
      let subAmount = 0; let discountAmount = 0; let finalTotalAmount = 0; let returnAmount = 0;
      let payableAmount = 0;
      this.state.paymentDetails?.forEach(element => {
        if (element.bill_type !== 'Return') {
          subAmount = subAmount + Math.round(element?.sub_total)
          discountAmount = discountAmount + element?.discount_amount
          // finalTotalAmount = element?.payment_status === 'not paid' && element?.partial_return ? finalTotalAmount + (Math.round(element?.total_after_rtn) - element?.discount_amount) : finalTotalAmount + element?.outstanding_amount
          finalTotalAmount = finalTotalAmount + element?.payable_amount
        } else {
          returnAmount = returnAmount + element.sub_total
        }
      })
      let SubTotal = Math.round(subAmount) - Math.round(returnAmount)
      this.state.finalDiscount = discountAmount
      this.state.finalSubTotalAmount = SubTotal
      this.state.finalTotalAmount = finalTotalAmount
      return { SubTotal, discountAmount, finalTotalAmount }
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  calculatePercentage = (discount, total) => {
    if (+discount == 0 || +total == 0) return ""
    return +(((+discount / 100) * +total).toFixed(4))
  }

  onchangeOverDiscount = (e, type) => {
    try {
      let value = e.target.value
      let states = this.state
      switch (type) {
        case 'disAmount':
          if ((CommonValidation.numberOnly(value) && value <= Math.round(states.finalOverAllAmt) && value > 0) || value === '') {
            this.setState({
              overallConcession: value,
              overAllPercent: CommonValidation.amountPercentage(value, states.finalOverAllAmt),
              changed: true
            }, () => {
              let states = this.state
              let disPercent = ((states.overallConcession / states.finalOverAllAmt) * 100)
              let totDisAmt = 0
              let diffAmt = 0
              states.paymentDetails?.forEach((item, index, array) => {
                if (item.from_over_discount) {
                  let lastIndex = array.findLastIndex(list => list.from_over_discount === true)
                  // let tltAmt = +this.state.billingConcessionType === 1 ? (item.return_amount ? ((item?.sub_total) - (item?.return_amount)) : (item.sub_total)) : (item.hospital_share ? item.hospital_share : (item.return_amount ? ((item?.sub_total) - (item?.return_amount)) : (item.sub_total)))
                  let tltAmt = +this.state.billingConcessionType === 1 ? item.bill_amt_const : (item.hospital_share ? item.hospital_share : item.bill_amt_const)
                  if (states.overallConcession !== '') {
                    if (!item?.pharmacy_return) {
                      let discountAmount = this.calculatePercentage(disPercent, tltAmt)
                      item.discount_percentage = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (+this.state.billingConcessionType === 1 ? +Math.round(+disPercent) : CommonValidation.amountPercentage(discountAmount, item.bill_amt_const))
                      item.is_change = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? false : true
                      item.is_overall_concession = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? false : true
                      totDisAmt += (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (item.discount_percentage ? +Math.round(discountAmount) : 0)
                      diffAmt = (+states.overallConcession) - (+totDisAmt)
                      if (index != lastIndex) {
                        item.discount_amount = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (item.discount_percentage ? +Math.round(discountAmount) : 0)
                        // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total) - item.discount_amount
                        item.payable_amount = item.bill_amt_const - item.discount_amount
                      }
                      else if (index === (lastIndex) && (Math.abs(diffAmt) >= 0)) {
                        item.discount_amount = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (item.discount_percentage ? (+Math.round(discountAmount) + diffAmt) : 0)
                        // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total) - item.discount_amount
                        item.payable_amount = item.bill_amt_const - item.discount_amount
                      }
                    }
                  } else {
                    item.discount_amount = 0
                    item.discount_percentage = 0
                    item.is_change = true
                    item.is_overall_concession = false
                    item.from_over_discount = true
                    // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total)
                    item.payable_amount = item.bill_amt_const - item.discount_amount
                    item.total_discount_amount = 0
                  }
                }
              })
              this.setState({ paymentDetails: this.state.paymentDetails })
            })
          }
          break;
        case 'disPercent':
          if ((CommonValidation.numberOnly(value) && value <= 100 && value > 0) || value === '') {
            this.setState({
              overAllPercent: value,
              overallConcession: CommonValidation.calculatePercentage(value, states.finalOverAllAmt),
              changed: true
            }, () => {
              let states = this.state
              let disPercent = ((states.overallConcession / states.finalOverAllAmt) * 100)
              let totDisAmt = 0
              let diffAmt = 0
              states.paymentDetails?.forEach((item, index, array) => {
                if (item.from_over_discount) {
                  let lastIndex = array.findLastIndex(list => list.from_over_discount === true)
                  // let tltAmt = +this.state.billingConcessionType === 1 ? (item.return_amount ? ((item?.sub_total) - (item?.return_amount)) : (item.sub_total)) : (item.hospital_share ? item.hospital_share : (item.return_amount ? ((item?.sub_total) - (item?.return_amount)) : (item.sub_total)))
                  let tltAmt = +this.state.billingConcessionType === 1 ? item.bill_amt_const : (item.hospital_share ? item.hospital_share : item.bill_amt_const)
                  if (states.overallConcession !== '') {
                    if (!item?.pharmacy_return) {
                      let discountAmount = this.calculatePercentage(disPercent, tltAmt)
                      item.discount_percentage = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (+this.state.billingConcessionType === 1 ? +Math.round(+disPercent) : CommonValidation.amountPercentage(discountAmount, item.bill_amt_const))
                      item.is_change = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? false : true
                      item.is_overall_concession = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? false : true
                      totDisAmt += (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (item.discount_percentage ? +Math.round(discountAmount) : 0)
                      diffAmt = (+states.overallConcession) - (+totDisAmt)
                      if (index != lastIndex) {
                        item.discount_amount = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (item.discount_percentage ? +Math.round(discountAmount) : 0)
                        // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total) - item.discount_amount
                        item.payable_amount = item.bill_amt_const - item.discount_amount
                      }
                      else if (index === (lastIndex) && (Math.abs(diffAmt) >= 0)) {
                        item.discount_amount = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && item?.module === 'Pharmacy') ? 0 : (item.discount_percentage ? (+Math.round(discountAmount) + diffAmt) : 0)
                        // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total) - item.discount_amount
                        item.payable_amount = item.bill_amt_const - item.discount_amount
                      }
                    }
                  } else {
                    item.discount_amount = 0
                    item.discount_percentage = 0
                    item.is_change = true
                    item.is_overall_concession = false
                    item.from_over_discount = true
                    // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total)
                    item.payable_amount = item.bill_amt_const - item.discount_amount
                    item.total_discount_amount = 0
                  }
                }
              })
              this.setState({ paymentDetails: this.state.paymentDetails })
            })
          }
          break;
        default: break;
      }
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  renderFinalDiscountScreen = () => {
    const { t } = this.props;
    try {
      let payment = this.billingCalculation()
      let totalAmount = 0;
      this.state.paymentDetails?.forEach(element => {
        if ((element?.payment_status === 'not paid' && !element?.is_discount && element.bill_type !== 'Return')) {
          totalAmount = totalAmount + element?.payable_amount
        }
      })
      return (
        <Box component={'div'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
          <Box component={'div'} height={'2.5vw'} pl={'0.5vw'} border={'1px solid lightgray'} display={'flex'}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={this.state.checkAll} onChange={(e) => this.onChangeCheckBoxHandler(e, 'checkAll')} size="small" />} label="All" />
              <FormControlLabel control={<Checkbox checked={this.state.checkIp} onChange={(e) => this.onChangeCheckBoxHandler(e, 'checkIp')} size="small" />} label="IP" />
              {!this.state.customConfigData?.show_pharmacy_bills_in_fo && !this.state.customConfigData?.allow_fo_pharmacy_bill_complete ? null :
                <FormControlLabel control={<Checkbox checked={this.state.checkPharmacy} onChange={(e) => this.onChangeCheckBoxHandler(e, 'checkPharmacy')} size="small" />} label="Pharmacy" />
              }
              {this.state.customConfigData?.block_radiology_bills_in_fo ?
                <FormControlLabel control={<Checkbox checked={this.state.checkRadiology} onChange={(e) => this.onChangeCheckBoxHandler(e, 'checkRadiology')} size="small" />} label="Radiology" /> : null}
              <FormControlLabel control={<Checkbox checked={this.state.checkLab} onChange={(e) => this.onChangeCheckBoxHandler(e, 'checkLab')} size="small" />} label="Laboratory" />
            </FormGroup>
          </Box>
          <Box component={'div'} border={'1px soild lightgray'} height={'40vh'} overflow={'auto'}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width={'5%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>S.No</TableCell>
                  <TableCell width={'12.2%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>Bill No</TableCell>
                  <TableCell width={'12.2%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>Modules</TableCell>
                  <TableCell width={'12.2%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>Description</TableCell>
                  <TableCell width={'21.4%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>{t("ConcessionAmount")}</TableCell>
                  <TableCell width={'12.2%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>Sub Amount</TableCell>
                  <TableCell width={'12.2%'} sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>Bill Amount</TableCell>
                  <TableCell width={'14%'} align="right" sx={{ backgroundColor: Colors.Background, fontSize: '0.9vw', fontWeight: '600', color: Colors.SecondaryText }}>Payable Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.paymentDetails?.length > 0 ? this.state.paymentDetails?.map((item, index) => {
                  let disabled = (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && (item?.module === 'Pharmacy') || item?.bill_type === 'Return' || item?.payment_status !== 'not paid' || (item?.payment_status === 'not paid' && item?.pharmacy_return)) ? true : false
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item?.bill_no ? item?.bill_no?.length > 22 ?
                        <Tooltip placement="top" title={item?.bill_no}><div style={{ fontSize: '0.9vw' }}>{item?.bill_no?.slice(0, 22) + '...'}</div></Tooltip> :
                        item?.bill_no : '-'}</TableCell>
                      <TableCell>{item?.module}</TableCell>
                      <TableCell>{this.getFinalDiscountServiceName(item?.service_description)}</TableCell>
                      <TableCell>
                        {item?.payment_status !== 'not paid' || (item?.payment_status === 'not paid' && item?.partial_return) ?
                          <Tooltip placement="top" title={'Discount is Not Applicable for Paid/Return bills'}>
                            <Box component={'div'}>
                              {this.inputTextBox('discount', item?.discount_amount, index, 'Amount', disabled)}
                              {this.inputTextBox('percentage', item?.discount_percentage, index, 'Percentage', disabled)}
                            </Box>
                          </Tooltip> :
                          <Box component={'div'}>
                            {this.inputTextBox('discount', item?.discount_amount, index, 'Amount', disabled)}
                            {this.inputTextBox('percentage', item?.discount_percentage, index, 'Percentage', disabled)}
                          </Box>}
                      </TableCell>
                      <TableCell>{Math.round(item?.sub_total)?.toFixed(2)}</TableCell>
                      <TableCell sx={{ color: item?.bill_type === 'Return' ? '#FF474C' : 'black' }}>{Math.round(item?.bill_amt)?.toFixed(2)}</TableCell>
                      {/* <TableCell align="right" sx={{ color: item?.bill_type === 'Return' ? '#FF474C' : 'black' }}>{item?.payment_status === 'not paid' && item?.partial_return ? (Math.round(item?.total_after_rtn) - item?.discount_amount).toFixed(2) : item?.payment_status === 'not paid' ? (Math.round(item?.sub_total) - item?.discount_amount).toFixed(2) : (item?.outstanding_amount).toFixed(2)}</TableCell> */}
                      <TableCell align="right" sx={{ color: item?.bill_type === 'Return' ? '#FF474C' : 'black' }}>{(+item.payable_amount)?.toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }) :
                  <TableRow>
                    <TableCell colSpan={6} sx={{ height: '18vw' }} align="center">No Records To Be Shown</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </Box>
          <Box component={'div'} p={'0vw 0.5vw'} border={'1px solid lightgray'} height={'7vw'} display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
            <Box component={'div'} width={'30vw'}>
              <Box component={'div'} mb={'0.2vw'} display={'flex'}>
                <FormControlLabel control={<Checkbox size="small"
                  disabled={(this.state.overallConcession !== '' ? true : false) || (this.state.overallConcession === '' && this.state.paymentDetails?.some(element => element.is_change && element.discount_amount !== 0))}
                  checked={this.state.concessionChecked} onChange={(e) => {
                    this.setState({
                      concessionChecked: !this.state.concessionChecked,
                      overallConcession: '',
                      // paymentDetails: JSON.parse(this.state.dupliPaymentDetails),
                      changed: false
                    }, () => {
                      if (!this.state.concessionChecked) {
                        this.state.paymentDetails?.forEach(item => {
                          if (item.is_overall_concession) {
                            item.discount_amount = 0
                            item.discount_percentage = 0
                            item.is_change = true
                            item.is_overall_concession = false
                            item.from_over_discount = true
                            // item.payable_amount = item.return_amount ? (Math.round(item?.sub_total) - (item?.return_amount + item?.discount_amount)) : Math.round(item.sub_total)
                            // item.payable_amount = item.outstanding_amount ?  item?.outstanding_amount : 0
                            item.payable_amount = item.bill_amt_const ? item?.bill_amt_const : 0
                            item.total_discount_amount = 0
                          }
                        })
                        this.setState({
                          paymentDetails: this.state.paymentDetails,
                          changed: true,
                          checkPharmacy: true,
                          checkLab: true,
                          checkAll: true,
                          checkIp: true,
                          checkRadiology: true
                        }, () => this.filterTableData())
                      } else {
                        this.setState({
                          checkPharmacy: false,
                          checkLab: false,
                          checkAll: false,
                          checkRadiology: false
                        }, () => this.filterTableData())
                      }
                    })
                  }} />} label="Overall Concession" />
                <TextField
                  size="small"
                  disabled={this.state.concessionChecked ? false : true}
                  sx={{ width: '8.5vw', mr: '1vw' }}
                  autoComplete="off"
                  value={this.state.overallConcession}
                  onChange={(e) => { this.onchangeOverDiscount(e, 'disAmount') }}
                  InputProps={{
                    endAdornment: <Box><Typography fontWeight={600}>{CurrencySymbol}</Typography></Box>
                  }}
                />
                <TextField
                  size="small"
                  disabled={this.state.concessionChecked ? false : true}
                  sx={{ width: '8.5vw' }}
                  autoComplete="off"
                  value={this.state.overAllPercent}
                  onChange={(e) => { this.onchangeOverDiscount(e, 'disPercent') }}
                  InputProps={{
                    endAdornment: <Box><Typography fontWeight={600}>{"%"}</Typography></Box>
                  }}
                />
              </Box>
              <Box component={'div'}><FormHelperText>{`Maximum Amount shall be given ${CurrencySymbol} ${this.state.finalOverAllAmt ? this.state.finalOverAllAmt : 0}`}</FormHelperText></Box>
              <Box component={'div'} mb={'0.2vw'}>
                <TextField
                  multiline
                  fullWidth
                  rows={1}
                  size="small"
                  autoComplete="off"
                  placeholder="Overall Final Concession Reason"
                  value={this.state.overallDiscountReason}
                  onChange={(e) => this.setState({ overallDiscountReason: e.target.value })}
                />
              </Box>
            </Box>
            <Box component={'div'} width={'18vw'}>
              <Typography fontSize={'0.9vw'} fontWeight={'600'}>Payment Summary</Typography>
              <Box component={'div'} display={'flex'}>
                <Box component={'div'} flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  <Typography fontSize={'0.9vw'} color={'gray'}>Sub Total</Typography>
                </Box>
                <Box flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  <Typography fontSize={'0.9vw'} color={'gray'} pr={'0.5vw'}>{Math.round(payment?.SubTotal)?.toFixed(2)}</Typography>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'}>
                <Box flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  <Typography fontSize={'0.9vw'} color={'gray'} >{t("concession")}</Typography>
                </Box>
                {/* Commented for future use */}
                {/* <Box flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  {this.inputTextBox('finalDiscount',this.state.finalDiscount)}
                  {this.inputTextBox('finalPercentage',this.state.finalPercentage)}
                </Box> */}
                <Box component={'div'} flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  <Typography fontSize={'0.9vw'} color={'gray'} pr={'0.5vw'}>{Math.round(payment?.discountAmount)?.toFixed(2)}</Typography>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'}>
                <Box flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600}>Payable Amount</Typography>
                </Box>
                <Box flex={'0.5'} display={'flex'} justifyContent={'flex-end'}>
                  {/* <Typography fontSize={'0.9vw'} fontWeight={600} pr={'0.5vw'}>{Math.round(payment?.finalTotalAmount)?.toFixed(2)}</Typography> */}
                  <Typography fontSize={'0.9vw'} fontWeight={600} pr={'0.5vw'}>{Math.round(payment.finalTotalAmount)?.toFixed(2)}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component={'div'} border={'1px solid lightgray'} height={'3vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Button variant="outlined" sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
              onClick={() => this.setState({ openFinalDiscount: false, paymentDetails: JSON.parse(this.state.dupliPaymentDetails) })}
            >Cancel</Button>
            <Button variant="contained" sx={{ height: '2vw', textTransform: 'capitalize' }}
              disabled={((this.state.changed && this.state.paymentDetails?.length > 0) ? false : true) || this.state.disableBtn}
              onClick={() => {
                this.setState({ disableBtn: true }, () => {
                  if (this.state.concessionChecked && this.state.overallConcession === '') {
                    this.responseSuccessErrorMessage('Please enter value in Overall Concession', false)
                    this.setState({ disableBtn: false })
                  } else {
                    this.postOverAllDiscount()
                  }
                })
              }}
            >Confirm</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.responseSuccessErrorMessage(error.message, false)
    }
  }

  ReturnCollectedIn(BillList = []) {
    let OnlyPharmacyBills = BillList.every((list) => (list?.module === "Pharmacy"))
    let OnlyLabBills = BillList.every((list) => (list?.module === "Laboratory"))
    if (OnlyPharmacyBills) {
      return "FO PH"
    } else if (OnlyLabBills) {
      return "FO LAB"
    } else {
      return "IP"
    }
  }

  PaymentReceivedPost(FromAdvance = false) {
    try {
      var states = this.state
      var LoggedUserData = JSON.parse(localGetItem("loggedInUserInfo"))
      let payment = []
      let BillList = this.state.BillDataList.filter((item) => (item?.is_checked && item?.deduct_amount > 0))
      if (this.state.isCashPayment) {
        payment.push(1)
      }
      if (this.state.isCardPayment) {
        payment.push(2)
      }
      if (this.state.isUPIPayment) {
        payment.push(3)
      }
      if (this.state.isChequePayment) {
        payment.push(4)
      }
      if (this.state.isBankTranferPayment) {
        payment.push(5)
      }
      if (
        ((states.PayableAmount !== "" &&
          (states.isCashPayment || states.isCardPayment || states.isBankTranferPayment || states.isUPIPayment || states.isChequePayment) &&
          this.CalculateAmountPayment()) || FromAdvance)
      ) {
        if ((this.state.CardAmountPayment !== "" && this.state.CardTransactionNumberPayment === "" ||
          this.state.UPIAmountPayment !== "" && this.state.UPINumberPayment === "" ||
          this.state.ChequeAmountPayment !== "" && this.state.ChequeNumberPayment === "" ||
          this.state.BankTfrAmountPayment !== "" && this.state.BankTfrNumberPayment === "") && !FromAdvance) {
          this.responseSuccessErrorMessage("Please,Enter Reference Number", false)
          this.setState({ disableBtn: false })
        } else {
          let data = {
            "amount_cash": +states.CashAmountPayment,
            "amount_card": +states.CardAmountPayment,
            "upi_amount": +states.UPIAmountPayment,
            "paid_amount": Math.round(+states.PayableAmount),
            "bank_transfer_amount": +states.BankTfrAmountPayment,
            "cheque_amount": +states.ChequeAmountPayment,
            "cheque_ref_no": states.ChequeNumberPayment,
            "bank_ref_no": states.BankTfrNumberPayment,
            "upi_ref_no": states.UPINumberPayment,
            "card_ref_no": states.CardTransactionNumberPayment,
            "payment_mode": payment,
            "patient_id": this.state?.patientData?.patient_id,
            "bill_list": BillList,
            "branch_id": localGetItem("BranchId") ? +localGetItem("BranchId") : null,
            "clinic_id": LoggedUserData?.clinic_id ? LoggedUserData?.clinic_id : null,
            "module": ['ip', 'lab', 'pharmacy', 'ot'],
            "add_to_advance": states.PayFromAdvance ? true : false,
            "collected_in": this.ReturnCollectedIn(BillList),
            "ip_admission_id": this.state.patientData?.id
          }
          this.LoaderFunction(true)
          RestAPIService.create(data, Serviceurls.FO_CREDIT_BILLS_GET).
            then((response) => {
              this.LoaderFunction(false)
              if (response.data.status === "success") {
                if (this.state.isFromCheckOut && this.state.CheckoutThePatient) {
                  this.LoaderFunction(false)
                  this.responseSuccessErrorMessage(response.data.message, true);
                  this.CheckOutPatientPost()
                } else {
                  this.GetBillDataList()
                  this._getPaymentDetails();
                  this.getApproxCharges()
                  this.getPatientData()
                  this.ClearPaymentDetails()
                }
                this.setState({ disableBtn: false })
              }
              else {
                this.LoaderFunction(false)
                this.setState({ disableBtn: false })
                this.responseSuccessErrorMessage(response.data.message, false)
              }
            }).catch((error) => {
              this.LoaderFunction(false)
              this.setState({ disableBtn: false })
              if (error?.response?.data?.message) {
                this.responseSuccessErrorMessage(error.response.data.message, false)
              } else {
                this.responseSuccessErrorMessage(error.message, false)
              }
            })
        }
      }
      else {
        this.setState({ disableBtn: false })
        if (states.PayableAmount === "" || states.PayableAmount === "0") {
          this.responseSuccessErrorMessage("Please Enter the Payable Amount", false)
        }
        else if (!(states.isCashPayment || states.isCardPayment || states.isBankTranferPayment || states.isUPIPayment || states.isChequePayment) && !FromAdvance) {
          this.responseSuccessErrorMessage("Please Select any Payment Methods", false)
        }
        else if (!this.CalculateAmountPayment() && !FromAdvance) {
          this.responseSuccessErrorMessage("Amount Not Matched ", false)
        }
      }
    }
    catch (e) {
      this.setState({ disableBtn: false })
      this.responseSuccessErrorMessage(e.message)
    }
  }


  BillshandleCheckBox = (e, key, index = null) => {
    var states = this.state
    if (key === "bill_Check") {
      if (index !== null) {
        states.PayableAmount = ""
        states.PayFromAdvance = false
        states.BillDataList[index].is_checked = e.target.checked
        states.BillDataList[index].deduct_amount = e.target.checked ? +states.BillDataList[index].deduct_amount : 0
      } else {
        states.PayableAmount = ""
        states.PayFromAdvance = false
        states.BillDataList.map((item) => (
          item.is_checked = e.target.checked,
          item.deduct_amount = e.target.checked ? +item.deduct_amount : 0
        ))
      }
    } else if (key === "PharmacyData") {
      states["PharmacyData"][index]["isChecked"] = e.target.checked
      states?.PharmacyData?.forEach((list, i) => {
        if (i !== index && e.target.checked) {
          list["disableCheck"] = true
        } else {
          list["disableCheck"] = false
        }
      })
    } else if (key === "LabData") {
      states["LabData"][index]["isChecked"] = e.target.checked
      states?.LabData?.forEach((list, i) => {
        if (i !== index && e.target.checked) {
          list["disableCheck"] = true
        } else {
          list["disableCheck"] = false
        }
      })
    } else {
      states[key] = e.target.checked
    }
    this.setState({ states }, () => {
      if (key === "bill_Check") {
        this.CalculateTotalPayAmt()
        this.CalculateDeductAmt()
      } else if (key === "ShowOpBills" || key === "ShowIpBills" || key === "ShowPharmaBills" || key === "ShowLabBills" || key === "ShowOtBills" || key === "ShowRadiologyBills" || key === "PharmacyData" || key === "LabData") {
        this.FilterBillList()
      } else {
        if (!states.isCashPayment) { this.setState({ CashAmountPayment: "" }) }
        if (!states.isCardPayment) { this.setState({ CardAmountPayment: "", CardNumberPayment: "" }) }
        if (!states.isUPIPayment) { this.setState({ UPIAmountPayment: "", UPINumberPayment: "" }) }
        if (!states.isChequePayment) { this.setState({ ChequeAmountPayment: "", ChequeNumberPayment: "" }) }
        if (!states.isBankTranferPayment) { this.setState({ BankTfrAmountPayment: "", BankTfrNumberPayment: "" }) }
        if (states.isCashPayment && !states.isCardPayment && !states.isUPIPayment && !states.isBankTranferPayment) {
          this.setState({ CashAmountPayment: Math.round(this.state.PayableAmount) })  // Fetch Full amount in Cash Amount, when only the cash is selected
        }
      }
    })
  }

  renderLeftTable = () => {
    let isAllBillChecked = this.state.BillDataList?.every((item) => (item.is_checked === true))
    let disableChecked = this.state.BillDataList?.some(item => item?.module === 'Pharmacy' && !this.state.customConfigData?.allow_fo_pharmacy_bill_complete)
    return (
      <TableContainer sx={{ height: "51vh", overflow: 'auto' }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead sx={{ backgroundColor: Colors.Background, height: '5vh' }}>
            <TableRow sx={{ paddingY: "0.5vw" }}>
              <TableCell sx={{ flex: 0.06 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center"><Checkbox size='small' disabled={disableChecked} checked={isAllBillChecked} onChange={(e) => { this.BillshandleCheckBox(e, "bill_Check") }} /></TableCell>
              <TableCell sx={{ flex: 0.17 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Bill Details</TableCell>
              <TableCell sx={{ flex: 0.14 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Module</TableCell>
              <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Bill Amt (₹)`}</TableCell>
              <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Pending Amt (₹)`}</TableCell>
              <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`Deduct Amt (₹)`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.BillDataList?.length > 0 ? this.state.BillDataList?.map((row, index) => (
              <TableRow
                key={row?.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ flex: 0.06 }} align="center" className='eMed_Credit_bill_pop_Tbl_Body'>
                  <Checkbox size='small' disabled={row?.module === 'Pharmacy' && !this.state.customConfigData?.allow_fo_pharmacy_bill_complete} checked={row?.is_checked} onChange={(e) => { this.BillshandleCheckBox(e, "bill_Check", index) }} />
                </TableCell>
                <TableCell sx={{ flex: 0.17 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">
                  <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                    <Typography fontSize={"0.9vw"} fontWeight={600} >{row?.invoice_number ? row.invoice_number : "-"}</Typography>
                    <Typography fontSize={"0.8vw"}>{row?.invoice_date ? convertDateTime(row?.invoice_date) : "-"}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ flex: 0.14 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{row?.module ? row.module : "-"}</TableCell>
                <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.net_amount ? (+row.net_amount)?.toFixed(2) : 0}</TableCell>
                <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.pending_amount ? (+row.pending_amount)?.toFixed(2) : 0}</TableCell>
                <TableCell sx={{ flex: 0.10, fontWeight: 600 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.deduct_amount ? (+row.deduct_amount)?.toFixed(2) : 0}</TableCell>
              </TableRow>
            )) : <TableRow>
              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="right">
                <Typography paddingTop={"2vw"}>No Records Found</Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  ClosePopUp() {
    this.setState({
      Makepayment: false,
    })
  }

  creditBillPost(message = "", receiptNo = null, phrReceipt = null, error = false) {
    this.setState({
      Makepayment: false,
      receiptID: receiptNo,
      pharmacReceipt: phrReceipt
    }, () => {
      if (error) {
        this.responseSuccessErrorMessage(message, false)
      } else {
        this.responseSuccessErrorMessage(message, true)
      }
      this._getPaymentDetails();
      this.getApproxCharges()
      this.getPatientData()
    })
  }

  render() {
    let fromFinalBill = this.props?.history?.location?.state?.fromFinalBill
    const { t } = this.props;
    let states = this.state;

    let serviceAmt = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t("BillAmount"),
      lineTwo: `${this.state.serviceAmount ? AmountFormat(this.state.serviceAmount) : `${CurrencySymbol} 0`}`,
    }
    let PaidDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t("Paid Amount"),
      lineTwo: `${this.state.paidAmount ? AmountFormat(this.state.paidAmount) : `${CurrencySymbol} 0`}`,
    }

    let PendingDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t("BalAmt"),
      lineTwo: `${this.state.CardPendingAmount ? AmountFormat(this.state.CardPendingAmount) : `${CurrencySymbol} 0`}`,
      colorCode: this.state.CardPendingAmount > 0 ? 'green' : this.state.CardPendingAmount == 0 ? "balck" : 'red'
    }

    let ApproxDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t('ApproxAmount'),
      lineTwo: `${states.ApproxAmount ? AmountFormat(states.ApproxAmount) : `${CurrencySymbol} 0`}`
    }
    let AdvanceDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t('AdvanceAmount'),
      lineTwo: `${states.AdvanceAmount ? AmountFormat(states.AdvanceAmount) : `${CurrencySymbol} 0`}`
    }
    let ClaimDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t('Claimed Amount'),
      lineTwo: `${states.ApprovedAmt ? AmountFormat(states.ApprovedAmt) : `${CurrencySymbol} 0`}`
    }
    return (
      <Box id="eMed_recPayment_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
        <Box className="eMed_recPayment_Box">
          <Stack className="eMed_recCard_header" direction="row" justifyContent='flex-start' alignItems="center" spacing={1} marginLeft={"0.2vw"}>
            <Stack className="eMed_recCard_div" sx={{ width: "22.8vw", backgroundColor: Colors.white }}>
              <CommonPatientDetails data={this.state.patientData} showDetailed={true} />
            </Stack>
            <CommonPatientCard details={AdvanceDetails} />
            <CommonPatientCard details={serviceAmt} smallWidth={true} />
            <CommonPatientCard details={PaidDetails} smallWidth={true} />
            <CommonPatientCard details={PendingDetails} smallWidth={true} />
            <CommonPatientCard details={ApproxDetails} smallWidth={true} />
            <CommonPatientCard details={ClaimDetails} smallWidth={true} />
          </Stack>
          <Box className="eMed_recPayment_mainDiv" mt={this.state.isFromCheckOut ? 0 : "1vh"}>
            {this.state.openFinalDiscount ?
              <Box mt={'0.65vw'} width={'92vw'}>
                {this.renderFinalDiscountScreen()}
              </Box>
              :
              <Box flex={0.66} padding={0}>
                {/* {this.state.isFromCheckOut ? */}
                <Tabs
                  value={this.state.value === "three" || this.state.value === "four" ? "one" : (this.state.value === "one" && !this.state.isFromCheckOut) ? "two" : this.state.value}
                  onChange={(e, newValue) => this.setState({
                    value: newValue
                  })}
                >
                  <Tab value="one" label={"Pending Bills"} className='eMed_tab_header' />
                  <Tab value="two" label={"All Bills"} className='eMed_tab_header' />
                </Tabs>
                {/* : null} */}
                <Paper className="eMed_IP_recPayment_Div" sx={{ height: "64vh" }} elevation={1}>
                  {
                    ((this.state.isFromCheckOut && (this.state.value === "one" || this.state.value === "three")) || (!this.state.isFromCheckOut && (this.state.value === "four"))) ?
                      <>
                        <Box ml={"0.5vw"}>
                          {(this.state.AllBillDataList.some((item) => (item?.module === "Out Patient"))) ?
                            <FormControlLabel control={<Checkbox checked={this.state.ShowOpBills} onChange={(e) => { this.BillshandleCheckBox(e, "ShowOpBills") }} size='small' />} label={"OP"} /> : null}
                          {(this.state.AllBillDataList.some((item) => (item?.module === "In Patient"))) ?
                            <FormControlLabel control={<Checkbox checked={this.state.ShowIpBills} onChange={(e) => { this.BillshandleCheckBox(e, "ShowIpBills") }} size='small' />} label={"IP"} /> : null}
                          {((localGetItem("Allow_Pharma_CreditBill_In_FO") === "true") && this.state.AllBillDataList.some((item) => (item?.module === "Pharmacy"))) ?
                            (localGetItem('multiPharmacy') === "true") ?
                              this.state.PharmacyData?.map((item, i) => (
                                <FormControlLabel control={<Checkbox disabled={item?.disableCheck} checked={item?.isChecked} onChange={(e) => { this.BillshandleCheckBox(e, "PharmacyData", i) }} size='small' />} label={item?.pharmacy_name} />
                              ))
                              :
                              <FormControlLabel control={<Checkbox checked={this.state.ShowPharmaBills} onChange={(e) => { this.BillshandleCheckBox(e, "ShowPharmaBills") }} size='small' />} label={"Pharmacy"} /> : null}
                          {(this.state.AllBillDataList.some((item) => (item?.module === "Laboratory"))) ?
                            (localGetItem('multiLaboratory') === "true") ?
                              this.state.LabData?.map((item, i) => (
                                <FormControlLabel control={<Checkbox disabled={item?.disableCheck} checked={item?.isChecked} onChange={(e) => { this.BillshandleCheckBox(e, "LabData", i) }} size='small' />} label={item?.laboratory_name} />
                              ))
                              :
                              <FormControlLabel control={<Checkbox checked={this.state.ShowLabBills} onChange={(e) => { this.BillshandleCheckBox(e, "ShowLabBills") }} size='small' />} label={"Lab"} /> : null}
                          {(this.state.AllBillDataList.some((item) => (item?.module === "Radiology"))) ?
                            <FormControlLabel control={<Checkbox checked={this.state.ShowRadiologyBills} onChange={(e) => { this.BillshandleCheckBox(e, "ShowRadiologyBills") }} size='small' />} label={"Radiology"} /> : null}
                          {(this.state.AllBillDataList.some((item) => (item?.module === "OT"))) ?
                            <FormControlLabel control={<Checkbox checked={this.state.ShowOtBills} onChange={(e) => { this.BillshandleCheckBox(e, "ShowOtBills") }} size='small' />} label={"OT"} /> : null}
                        </Box>
                        {this.renderLeftTable()}
                      </>

                      :
                      <TableContainer sx={{ height: "55vh", overflow: 'auto' }}>
                        <Table aria-label="simple table" stickyHeader>
                          <TableHead sx={{ backgroundColor: Colors.Background, height: '5vh' }}>
                            <TableRow sx={{ paddingY: "0.5vw" }}>
                              <TableCell sx={{ flex: 0.17 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">S No</TableCell>
                              <TableCell sx={{ flex: 0.17 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Bill No</TableCell>
                              <TableCell sx={{ flex: 0.14 }} className='eMed_Credit_bill_pop_Tbl_Header' align="center">Description</TableCell>
                              <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{`${t('ConcessionAmount')}(₹)`}</TableCell>
                              <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Header' align="right">{t('BillAmount') + `(${CurrencySymbol})`}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.paymentDetails?.length > 0 ? this.state.paymentDetails?.map((row, index) => (
                              <TableRow
                                key={row?.name}
                                sx={{ height: "7vh" }}
                              >
                                <TableCell sx={{ flex: 0.14 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{index + 1}</TableCell>
                                <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{row?.bill_no ? row.bill_no : 0}</TableCell>
                                <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="center">{row?.service_description ? this.getService_name(row.service_description) : 0}</TableCell>
                                <TableCell sx={{ flex: 0.10 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.discount_amount ? row.discount_amount : 0}</TableCell>
                                <TableCell sx={{ flex: 0.10, fontWeight: 600 }} className='eMed_Credit_bill_pop_Tbl_Body' align="right">{row?.total_amount ? AmountFormat(row?.total_amount).replace(`${CurrencySymbol}`, "") : 0}</TableCell>
                              </TableRow>
                            )) : <TableRow>
                              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                              <TableCell sx={{ borderBottom: "none" }} align="right">
                                <Typography paddingTop={"2vw"}>No Records Found</Typography>
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                              <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                            </TableRow>}
                          </TableBody>
                        </Table>
                      </TableContainer>}
                </Paper>
                <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ marginTop: "-3vw" }} alignItems="center" justifyContent="center">
                  <Button variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.getIPBillPrint() }}>{t("Print")}</Button>
                  {fromFinalBill ?
                    <Button variant='contained' size="small" id="eMed_recPayment_btn" disabled={((this.state.patientData?.status === 'Checked Out')) ? true : false}
                      onClick={() => {
                        let concession = 0; let percentConcession = 0;
                        this.state.paymentDetails?.forEach(element => {
                          if (element.is_overall_concession || (element.payment_status === 'not paid' && !element.discount_amount && element.bill_type !== 'Return' && !element.pharmacy_return)) {
                            if (!this.state.customConfigData?.allow_fo_pharmacy_bill_complete && element.module === 'Pharmacy') {
                              concession = concession + 0
                            } else {
                              if (+this.state.billingConcessionType === 1) {
                                // concession = concession + (element.return_amount ? (Math.round(element.sub_total) - element.return_amount) : Math.round(element.sub_total))
                                concession = concession + element.bill_amt_const
                              } else {
                                // concession = concession + (element.hospital_share ? element.hospital_share : (element.return_amount ? (Math.round(element.sub_total) - element.return_amount) : Math.round(element.sub_total)))
                                concession = concession + (element.hospital_share ? element.hospital_share : element.bill_amt_const)
                              }
                            }
                          }
                        })
                        this.setState({
                          finalOverAllAmt: concession,
                          dupFinalAmt: JSON.stringify(concession),
                        }, () => {
                          this.setState({
                            openFinalDiscount: true,
                            overallConcession: this.state.dupOverAllConcession === '' ? '' : JSON.parse(this.state.dupOverAllConcession),
                            concessionChecked: this.state.dupOverAllConcession === '' ? false : true,
                            overAllPercent: this.state.dupOverAllPercent === '' ? '' : JSON.parse(this.state.dupOverAllPercent)
                          }, () => {
                            // this.filterTableData()
                            let states = this.state
                            let filterData = [];
                            if (states.checkAll) {
                              filterData?.push('Pharmacy', 'Laboratory', 'Inpatient', "Radiology")
                            } else {
                              if (states.checkIp) {
                                filterData?.push('Inpatient')
                              }
                              if (states.checkLab) {
                                filterData?.push('Laboratory')
                              }
                              if (states.checkPharmacy) {
                                filterData?.push("Pharmacy")
                              }
                              if (states.checkRadiology) {
                                filterData?.push("Radiology")
                              }
                            }
                            let filterHash = {}; let data = [];
                            filterData?.forEach(element => filterHash[element] = element)
                            let dupliPaymentDetails = JSON.parse(this.state.dupliPaymentDetails)
                            dupliPaymentDetails?.forEach(element => {
                              if (filterHash[element?.module] === element?.module) {
                                data?.push(element)
                              }
                            })
                            this.setState({ paymentDetails: data })
                          })
                        })
                      }}>{t("Final Concession")}</Button>
                    : null}
                </Stack>
              </Box>
            }
            {this.state.openFinalDiscount ? null :
              <Box item flex={0.33} padding={0}>
                {this.state.isFromCheckOut ? <FormControlLabel sx={{ marginTop: "1.5vh" }} control={<Checkbox size="small" checked={this.state.CheckoutThePatient} onChange={(e) => { this.setState({ CheckoutThePatient: e.target.checked }) }} />} label={<Typography fontWeight={600} id="eMed_recPayment_checkTxt">{t("Checkout the Patient")}</Typography>} /> : null}
                <Paper className="eMed_IP_recPayment_Div" sx={{ height: this.state.isFromCheckOut ? "64vh" : "69vh" }} elevation={1}>
                  <Grid xs={12} item className='eMed_recPayment_gridDiv' sx={{ height: this.state.isFromCheckOut ? "57.3vh" : "63.3vh", overflow: 'auto' }}>
                    <Tabs
                      value={this.state.value}
                      onChange={(e, newValue) => this.setState({
                        value: newValue
                      }, () => {
                        if (this.state.isRegular === "Credit" && this.state.pendingAmount < 0) {
                          this.setState({
                            isCash: false,
                            isCard: false,
                            isCheque: false,
                            isUPI: false,
                            isBankTranfer: false,
                            corporate_amt: +this.state.serviceAmount,
                            Insurance_amt: +this.state.serviceAmount,
                            patientCreditAmount: -(this.state.pendingAmount),
                          })
                        }
                      })}
                      sx={{ backgroundColor: Colors.DataTblHeaderbg }}
                    >
                      <Tab value="one" label={this.state.isFromCheckOut ? "Receive Payment" : "Receive Advance"} className='eMed_tab_header' />
                      {this.state.isFromCheckOut ? <Tab value="three" label="Credit" className='eMed_tab_header' /> : null}
                      <Tab value="two" label="Refund Payment" className='eMed_tab_header' />
                      {this.state.isFromCheckOut ? null : <Tab value="four" label="Receive Payment" className='eMed_tab_header' />}
                      {/* {
                        this.state.isFromCheckOut ? null : <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5vw' }}>
                          <Button variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => { this.setState({ Makepayment: true }) }} size="small">Receive Payment</Button>
                        </Box>
                      } */}
                    </Tabs>
                    {(this.state.value === "one" || this.state.value === "three" || (this.state.value === "four" && !this.state.isFromCheckOut)) ? this.ReceivePaymentTab() : this.state.value === "two" ? this.RefundPaymentTab() : ""}
                  </Grid>
                  <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg, marginTop: "0.3vw" }} alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" id="eMed_recPayment_btn" disabled={this.state.withOutAppointment?.is_ip} onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                    <Button disabled={(this.state.disableBtn || (this.state.isLoader ? true : this.state.value === "two" && states.AdvanceAmount < 0) || (this.state.value === "four" && !this.state.PayableAmount) ) ? true : CheckAccessFunc("front_office", "In Patients", "Receive Payment", null, "Tab")?.editAccess ? states.isButtonDisabled ? true : this.state.withOutAppointment?.is_ip : true} variant='contained' size="small" id="eMed_recPayment_btn" emed_tid="ReceiveAmt_Receive_Btn"
                      onClick={() => {
                        this.setState({ disableBtn: true }, () => {
                          (this.state.value === "one" && this.state.isRegular != "Credit") ? this.handleReceive() :
                            ((this.state.value === "one" && this.state.isRegular === "Credit") || (this.state.value === "four")) ? (this.state.PayFromAdvance ? this.PaymentReceivedPost(true) : this.PaymentReceivedPost()) :
                              (this.state.value === "two") ? this.handleRefund() : this.handleReceive("isCredit")
                        })
                      }}>
                      {this.state.value === "one" && this.state.isRegular != "Credit" ? t("Receive") :
                        ((this.state.value === "one" && this.state.isRegular === "Credit") || (this.state.value === "four")) ? (this.state.PayFromAdvance ? t("Deduct From Advance") : t("Receive Payment")) :
                          this.state.value === "two" ? t("Refund") : t("Add to Credit")}</Button>
                  </Stack>
                </Paper>
              </Box>}
          </Box>
        </Box>
        {
          this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        {
          this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
        }
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.receiptID !== null || this.state.pharmareceipt !== null ?
          <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmareceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null}
        {this.state.CheckoutPrintPoP ?
          <ChecKoutSuceessPop ip_admission_id={this.state.patientData?.id ? this.state.patientData?.id : null} history={this.props.history} /> : null}
        {this.state.Makepayment ?
          <CreditBillCompletePoP
            Module_ID_Key_Name={"ip_admission_id"}
            Module_ID_Key_Value={this.state.patientData?.id}
            modules={['ip', 'lab', 'pharmacy', 'ot', 'radiology']}
            CrebillPosted={this.creditBillPost.bind(this)}
            BillData={this.state.patientData}
            ClosePopUp={this.ClosePopUp.bind(this)}
            CollectedIn={"IP"}
            fromReceivePayment={true}
            balanceAmount={this.state.CardPendingAmount}
            dueAmount={this.state.AdvanceAmount}
            paidAmount={this.state.paidAmount}
            billAmount={this.state.serviceAmount}
            isFromCheckOut={this.state.isFromCheckOut}
          /> : null}
      </Box>
    )
  }
}
export default withTranslation()(ReceivePayment);