import {
    Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Modal,
    Radio, RadioGroup, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs,
    TextField, Tooltip, Typography, ListItem, ListItemText, ListItemButton, List, Divider,
    Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import React, { Component } from "react";
import { Colors } from "../../../../Styles/Colors";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { ADD, AXIS_OPTIONS, BCDVA_OPTIONS, BCNVA, CYL_OPTIONS, DEFAULT_CHARTUSED_SR, DEFAULT_MUSCLES_SR, DEFAULT_OCCUPATIONS_SR, SPH_OPTIONS, UCVA_OPTIONS } from "../../../../Utility/Constants";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import ShowComponents from "./ShowComponent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';

export default class DocRetinoscopicRefraction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openPopUp: false,
            patientDetails: {},
            clinicId: this.props.clinicId,
            patientId: this.props.patientId,
            appointmentId: this.props.appointmentId,
            isLocked: this.props.isLocked,
            appointmentDate: this.props.appointmentDate,
            templateData: [],
            value: "Two",
            LeftEye: {
                UCVA: "",
                SPH: "",
                CYL: "",
                AXIS: "",
                BCDVA: "",
                ADD: "",
                BCNVA: "",
                comments: ""
            },
            RightEye: {
                UCVA: "",
                SPH: "",
                CYL: "",
                AXIS: "",
                BCDVA: "",
                ADD: "",
                BCNVA: "",
                comments: ""
            },
            Leftchecked: false,
            Rightchecked: false,
            postLoad: false,
            templateDataLeft: [],
            templateDataRight: [],
            Occupation: "",
            Chart: "",
            Muscle: "",
            NewText: "",
            PreviousData: [],
            currentData: [],
            comparePopup: false,
            isEdit: false,
            SPHselectedValues: "Minus",
            CYLselectedValues: "Minus",
            AXISselectedValues: "All",
            ADDselectedValues: "All",
            BCDVAselectedValues: "All",
            BCNVAselectedValues: "All",
            occupationList: [],
            isOccupationadd: false,
            occupationName: '',
            addPostLoad: false,
        }
        this.UCVA_REF = React.createRef();
        this.SPH_REF = React.createRef();
        this.CYL_REF = React.createRef();
        this.AXIS_REF = React.createRef();
        this.ADD_REF = React.createRef();
        this.BCDVA_REF = React.createRef();
        this.BCNVA_REF = React.createRef();
    }

    apiCalls = () => {
        this.getRetinoscopicRefraction()
        this.getPreviousRetinoRefraction()
        this.getOccupationList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getRetinoscopicRefraction = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_RETINOSCOPICREFRACTION + `?appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []
                        data = response.data.data.additional_subrefraction ? response.data.data.additional_subrefraction : [];

                        let temp_occupation = "";
                        let temp_chart_used = "";
                        let temp_muscle = "";

                        for (var i = 0; i < data.length; i++) {
                            if (i == 0) {
                                temp_occupation = data[i].occupation
                                temp_chart_used = data[i].chart_used
                                temp_muscle = data[i].muscle
                            } else {
                                data[i].occupation = temp_occupation
                                data[i].chart_used = temp_chart_used
                                data[i].muscle = temp_muscle
                            }
                        }

                        this.setState({
                            Occupation: temp_occupation,
                            Chart: temp_chart_used,
                            Muscle: temp_muscle,
                            disableLeft: false,
                            disableRight: false,
                            NewText: "",
                            value: "Two",
                            Leftchecked: false,
                            Rightchecked: false,
                            LeftEye: {
                                UCVA: "",
                                SPH: "",
                                CYL: "",
                                AXIS: "",
                                BCDVA: "",
                                ADD: "",
                                BCNVA: "",
                                PHVA: "",
                                comments: ""
                            },
                            RightEye: {
                                UCVA: "",
                                SPH: "",
                                CYL: "",
                                AXIS: "",
                                BCDVA: "",
                                ADD: "",
                                BCNVA: "",
                                PHVA: "",
                                comments: ""
                            }
                        }, () => {
                            var left = []; var Right = [];
                            if (data[0] && data[1]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                                if (data[1]?.eye_type == "RE") {
                                    Right.push(data[1])
                                } else {
                                    left.push(data[1])
                                }
                            } else if (data[0]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                            }
                            this.setState({
                                templateDataLeft: left,
                                templateDataRight: Right,
                                currentData: data
                            })
                            if (left.length > 0) {
                                let Left = left[0]
                                states.LeftEye["comments"] = Left.comment
                                this.setState({
                                    states
                                })
                            }
                            if (Right.length > 0) {
                                let right = Right[0]
                                states.RightEye["comments"] = right.comment
                                this.setState({
                                    states
                                })
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPreviousRetinoRefraction = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_RETINO_REFRACTION_PREVIOUS + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}&appointment_date=${this.state.appointmentDate}&doctor_id=${this.props.doctorID}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let data = []
                        data = response.data.data ? response.data.data : [];
                        this.setState({
                            PreviousData: data
                        }, () => {
                            var left = []; var Right = []; var data = this.state.PreviousData
                            if (data[0] && data[1]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                                if (data[1]?.eye_type == "RE") {
                                    Right.push(data[1])
                                } else {
                                    left.push(data[1])
                                }
                            } else if (data[0]) {
                                if (data[0]?.eye_type == "LE") {
                                    left.push(data[0])
                                } else {
                                    Right.push(data[0])
                                }
                            }
                            this.setState({
                                oldDataLeft: left,
                                oldDataRight: Right
                            })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getOccupationList = () => {
        try {
            let url = Serviceurls.DOC_OCCUPATION_GET;
            if (this.props.isOpthometry && this.props.doctorID) {
                url = `${url}?doctor_id=${this.props.doctorID}`
            }
            RestAPIService.getAll(url)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            occupationList: response.data.data ? response.data.data : []
                        })
                    }
                })).catch(e => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }

                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderNewText = (Heading, values, Tab) => {
        let states = this.state
        return (
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw" }}>
                <TextField
                    label={(`Add ${Heading}`)}
                    size="small"
                    style={{ marginTop: "0.5vw", width: "20vw" }}
                    variant="outlined"
                    value={states.NewText}
                    onChange={(event) => {
                        let value = event.target.value
                        this.setState({ NewText: value })
                    }}
                />
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        if (this.state.NewText) {
                            let data = { label: CommonValidation.removeSpace(this.state.NewText), value: CommonValidation.removeSpace(this.state.NewText) }
                            values.push(data)
                            states[Tab][Heading] = this.state.NewText
                            this.setState({ states }, () => { this.setState({ selectedText: "", NewText: "" }) })
                        }
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                </div>
                <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                    onClick={() => {
                        this.setState({ selectedText: "", NewText: "" })
                    }}>
                    <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                </div>
            </div>
        )
    }

    onEditHandler = (data) => {
        if (data.eye_type === "RE") {
            if (this.state.templateDataLeft?.length > 0) {
                let item = this.state.templateDataLeft[0]
                this.setState({
                    LeftEye: {
                        UCVA: item.UCVA,
                        SPH: item?.SPH,
                        CYL: item?.CYL,
                        AXIS: item?.AXIS,
                        BCDVA: item?.BCDVA,
                        ADD: item?.add,
                        BCNVA: item?.BCNVA,
                        id: item?.id,
                        comments: item.comment
                    }
                })
            }
            this.setState({
                value: "Two",
                openPopUp: true,
                Occupation: data.occupation,
                Chart: data.chart_used,
                Muscle: data.muscle,
                RightEye: {
                    UCVA: data.UCVA,
                    SPH: data?.SPH,
                    CYL: data?.CYL,
                    AXIS: data?.AXIS,
                    BCDVA: data?.BCDVA,
                    ADD: data?.add,
                    BCNVA: data?.BCNVA,
                    id: data?.id,
                    comments: data.comment
                },
                disableLeft: true
            })
        } else {
            if (this.state.templateDataRight?.length > 0) {
                let item = this.state.templateDataRight[0]
                this.setState({
                    RightEye: {
                        UCVA: item.UCVA,
                        SPH: item?.SPH,
                        CYL: item?.CYL,
                        AXIS: item?.AXIS,
                        BCDVA: item?.BCDVA,
                        ADD: item?.add,
                        BCNVA: item?.BCNVA,
                        id: item?.id,
                        comments: item.comment
                    }
                })
            }
            this.setState({
                value: "One",
                openPopUp: true,
                Occupation: data.occupation,
                Chart: data.chart_used,
                Muscle: data.muscle,
                LeftEye: {
                    UCVA: data.UCVA,
                    SPH: data?.SPH,
                    CYL: data?.CYL,
                    AXIS: data?.AXIS,
                    BCDVA: data?.BCDVA,
                    ADD: data?.add,
                    BCNVA: data?.BCNVA,
                    id: data?.id,
                    comments: data.comment
                },
                disableRight: true
            })
        }
    }

    renderOldGlassPowerTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>UCVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCDVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>ADD</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCNVA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.oldDataRight?.length > 0 ? this.state.oldDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                    </TableRow>
                            )) : null}
                            {this.state.oldDataLeft?.length > 0 ? this.state.oldDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderRetinoscopicRefractionTable = () => {
        try {
            return (
                <Box sx={{ maxHeight: '15vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Eye</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>UCVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>SPH</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>CYL</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>AXIS</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCDVA</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>ADD</TableCell>
                                <TableCell width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>BCNVA</TableCell>
                                {this.state.comparePopup ? null : <TableCell align='center' width={'12.5%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.templateDataRight?.length > 0 ? this.state.templateDataRight?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Right Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                            {this.state.templateDataLeft?.length > 0 ? this.state.templateDataLeft?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>Left Eye</TableCell>
                                    <TableCell>
                                        {item?.UCVA ? item?.UCVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.UCVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.UCVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.SPH ? item?.SPH : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.CYL ? item?.CYL : '-'}</TableCell>
                                    <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>                                        
                                        {item?.AXIS ? item?.AXIS?.length > 10 ?
                                        <Tooltip placement='top' title={item?.AXIS}>
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS?.slice(0, 10) + '...'}</Typography>
                                        </Tooltip> :
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.AXIS}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCDVA ? item?.BCDVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCDVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCDVA}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.add ? item?.add?.length > 10 ?
                                            <Tooltip placement='top' title={item?.add}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.add}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.BCNVA ? item?.BCNVA?.length > 10 ?
                                            <Tooltip placement='top' title={item?.BCNVA}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA?.slice(0, 10) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.BCNVA}</Typography> : '-'}
                                    </TableCell>
                                    {this.state.comparePopup ? null : <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button
                                                className='Common_Btn_Min_Width'
                                                onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                {this.state.isLocked || this.props.isLocked ?
                                                    <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                    :
                                                    <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                }
                                            </Button>
                                        </Box>
                                    </TableCell>}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        var { LeftEye, RightEye } = this.state
        if ((LeftEye.UCVA == "" && LeftEye.SPH == "" && LeftEye.CYL == "" && LeftEye.AXIS == "" && LeftEye.BCDVA == "" && LeftEye.ADD == "" && LeftEye.BCNVA == "") &&
            (RightEye.UCVA == "" && RightEye.SPH == "" && RightEye.CYL == "" && RightEye.AXIS == "" && RightEye.BCDVA == "" && RightEye.ADD == "" && RightEye.BCNVA == "")) {
            return "Select Any Eye Value"
        } else {
            return null
        }
    }

    PostRetinoscopicRefraction = () => {
        var { LeftEye, RightEye, appointmentId, Occupation, Chart, Muscle } = this.state
        let data = {}
        try {
            if (
                (LeftEye.UCVA != "" || LeftEye.SPH != "" || LeftEye.CYL != "" || LeftEye.AXIS != "" || LeftEye.BCDVA != "" || LeftEye.ADD != "" || LeftEye.BCNVA != "") &&
                (RightEye.UCVA != "" || RightEye.SPH != "" || RightEye.CYL != "" || RightEye.AXIS != "" || RightEye.BCDVA != "" || RightEye.ADD != "" || RightEye.BCNVA != "")
            ) {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        UCVA: LeftEye.UCVA ? LeftEye.UCVA : "",
                        SPH: LeftEye.SPH ? LeftEye.SPH : "",
                        CYL: LeftEye.CYL ? LeftEye.CYL : "",
                        AXIS: LeftEye.AXIS ? LeftEye.AXIS : "",
                        BCDVA: LeftEye.BCDVA ? LeftEye.BCDVA : "",
                        add: LeftEye.ADD ? LeftEye.ADD : "",
                        BCNVA: LeftEye.BCNVA ? LeftEye.BCNVA : "",
                        occupation: Occupation ? Occupation : "",
                        chart_used: Chart ? Chart : "",
                        muscle: Muscle ? Muscle : "",
                        comment: LeftEye.comments ? LeftEye.comments : ""
                    },
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        UCVA: RightEye.UCVA ? RightEye.UCVA : "",
                        SPH: RightEye.SPH ? RightEye.SPH : "",
                        CYL: RightEye.CYL ? RightEye.CYL : "",
                        AXIS: RightEye.AXIS ? RightEye.AXIS : "",
                        BCDVA: RightEye.BCDVA ? RightEye.BCDVA : "",
                        add: RightEye.ADD ? RightEye.ADD : "",
                        BCNVA: RightEye.BCNVA ? RightEye.BCNVA : "",
                        occupation: Occupation ? Occupation : "",
                        chart_used: Chart ? Chart : "",
                        muscle: Muscle ? Muscle : "",
                        comment: RightEye.comments ? RightEye.comments : ""
                    }
                ]

                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
                if (RightEye.id) { data[1]['id'] = RightEye.id }
            }
            else if (LeftEye.UCVA != "" || LeftEye.SPH != "" || LeftEye.CYL != "" || LeftEye.AXIS != "" || LeftEye.BCDVA != "" || LeftEye.ADD != "" || LeftEye.BCNVA != "") {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "LE",
                        UCVA: LeftEye.UCVA ? LeftEye.UCVA : "",
                        SPH: LeftEye.SPH ? LeftEye.SPH : "",
                        CYL: LeftEye.CYL ? LeftEye.CYL : "",
                        AXIS: LeftEye.AXIS ? LeftEye.AXIS : "",
                        BCDVA: LeftEye.BCDVA ? LeftEye.BCDVA : "",
                        add: LeftEye.ADD ? LeftEye.ADD : "",
                        BCNVA: LeftEye.BCNVA ? LeftEye.BCNVA : "",
                        occupation: Occupation ? Occupation : "",
                        chart_used: Chart ? Chart : "",
                        muscle: Muscle ? Muscle : "",
                        comment: LeftEye.comments ? LeftEye.comments : ""

                    }
                ]
                if (LeftEye.id) { data[0]['id'] = LeftEye.id }
            }
            else if (RightEye.UCVA != "" || RightEye.SPH != "" || RightEye.CYL != "" || RightEye.AXIS != "" || RightEye.BCDVA != "" || RightEye.ADD != "" || RightEye.BCNVA != "") {
                data = [
                    {
                        appointment_id: appointmentId,
                        eye_type: "RE",
                        UCVA: RightEye.UCVA ? RightEye.UCVA : "",
                        SPH: RightEye.SPH ? RightEye.SPH : "",
                        CYL: RightEye.CYL ? RightEye.CYL : "",
                        AXIS: RightEye.AXIS ? RightEye.AXIS : "",
                        BCDVA: RightEye.BCDVA ? RightEye.BCDVA : "",
                        add: RightEye.ADD ? RightEye.ADD : "",
                        BCNVA: RightEye.BCNVA ? RightEye.BCNVA : "",
                        occupation: Occupation ? Occupation : "",
                        chart_used: Chart ? Chart : "",
                        muscle: Muscle ? Muscle : "",
                        comment: RightEye.comments ? RightEye.comments : ""
                    }
                ]
                if (RightEye.id) { data[0]['id'] = RightEye.id }
            }
            let validate = this.checkValidation()
            if (validate == null) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_RETINOSCOPICREFRACTION)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.successMessage(response.data.message)
                            this.handleClear()
                            this.setState({ postLoad: false, openPopUp: false, comparePopup: false, isEdit: false }, () => {
                                this.getRetinoscopicRefraction()
                            })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else { this.errorMessage(validate) }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    CopyPreviousData = () => {
        var left = []; var Right = []; var data = this.state.PreviousData
        if (data[0] && data[1]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
            if (data[1]?.eye_type == "RE") {
                Right.push(data[1])
            } else {
                left.push(data[1])
            }
        } else if (data[0]) {
            if (data[0]?.eye_type == "LE") {
                left.push(data[0])
            } else {
                Right.push(data[0])
            }
        }

        this.setState({
            LeftEye: {
                UCVA: left[0]?.UCVA ? left[0]?.UCVA : "",
                SPH: left[0]?.SPH ? left[0]?.SPH : "",
                CYL: left[0]?.CYL ? left[0]?.CYL : "",
                AXIS: left[0]?.AXIS ? left[0]?.AXIS : "",
                BCDVA: left[0]?.BCDVA ? left[0]?.BCDVA : "",
                ADD: left[0]?.add ? left[0]?.add : "",
                BCNVA: left[0]?.BCNVA ? left[0]?.BCNVA : "",
                comments: left[0]?.comment ? left[0]?.comment : "",
                id: this.state?.templateDataLeft[0]?.id
            },
            RightEye: {
                UCVA: Right[0]?.UCVA ? Right[0]?.UCVA : "",
                SPH: Right[0]?.SPH ? Right[0]?.SPH : "",
                CYL: Right[0]?.CYL ? Right[0]?.CYL : "",
                AXIS: Right[0]?.AXIS ? Right[0]?.AXIS : "",
                BCDVA: Right[0]?.BCDVA ? Right[0]?.BCDVA : "",
                ADD: Right[0]?.add ? Right[0]?.add : "",
                BCNVA: Right[0]?.BCNVA ? Right[0]?.BCNVA : "",
                comments: Right[0]?.comment ? Right[0]?.comment : "",
                id: this.state?.templateDataRight[0]?.id
            },
            Occupation: this.state.PreviousData[0]?.occupation,
            Chart: this.state.PreviousData[0]?.chart_used,
            Muscle: this.state.PreviousData[0]?.muscle,
        }, () => { this.PostRetinoscopicRefraction() })
    }
   
    renderGlassPower = (Heading, values, key, Tab, AddButton, ref, scrollTo) => {
        let states = this.state
        let sortedValue = values
        let { SPHselectedValues, CYLselectedValues,AXISselectedValues,ADDselectedValues,BCDVAselectedValues,BCNVAselectedValues } = this.state
        if(Heading == "SPH"){
            if(SPHselectedValues != "All"){
                sortedValue = sortedValue.filter( (item) => SPHselectedValues === "plus"  ? item?.value >= 0 : SPHselectedValues === "Minus" ? item?.value <= 0 : sortedValue    )
            }
        }
        else if(Heading == "CYL"){
            if(CYLselectedValues != "All"){
                sortedValue = sortedValue.filter((item) => CYLselectedValues === "plus"  ? item?.value >= 0 : CYLselectedValues === "Minus" ? item?.value <= 0 : sortedValue   )
            }
        }
            else if(Heading == "AXIS"){
                if(AXISselectedValues != "All"){
                    sortedValue = sortedValue.filter((item) => AXISselectedValues === "plus"  ? item?.value > 0 :AXISselectedValues === "Minus" ? item?.value < 0 : sortedValue   )
                }
            }
            else if(Heading == "ADD"){
                if(ADDselectedValues != "All"){
                    sortedValue = sortedValue.filter((item) => ADDselectedValues === "plus"  ? item?.value > 0 :ADDselectedValues=== "Minus" ? item?.value < 0 : sortedValue    )
                }
            }
            else if(Heading == "BCDVA"){
                if(BCDVAselectedValues != "All"){
                    sortedValue = sortedValue.filter((item) => BCDVAselectedValues === "plus"  ? item?.value > 0  : BCDVAselectedValues === "Minus" ? item?.value < 0 : sortedValue )
                }
            }
            else if(Heading == "BCNVA"){
                if(BCNVAselectedValues != "All"){
                    sortedValue = sortedValue.filter((item) => BCNVAselectedValues === "plus"  ? item?.value > 0  : BCNVAselectedValues=== "Minus" ? item?.value < 0 : sortedValue  )
                }
            } 
           
        return (
            <div>
                <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{Heading}</div>
                <Box component={'div'} sx={{ display: "flex", flexWrap: "wrap", marginLeft: "1vw" }}>
                        {
                           ( Heading === "SPH" ||   Heading === "CYL"|| Heading === "AXIS" ||   Heading === "ADD" ||  Heading === "BCDVA"||Heading === "BCNVA" ) ?
                                <RadioGroup
                                    value={ Heading === "SPH"  ? this.state.SPHselectedValues: Heading === "CYL"  ? this.state.CYLselectedValues: Heading === "ADD"  ? this.state.ADDselectedValues : Heading === "AXIS"  ? this.state.AXISselectedValues: Heading === "BCDVA"  ? this.state.BCDVAselectedValues: this.state.BCNVAselectedValues  }
                                    sx={{ display: 'flex', flexDirection: 'row' ,  marginTop :"-4vh", marginLeft :"3vw" }}
                                    onChange={(e, values) => {
                                        if(Heading === "SPH"){
                                            this.setState({ SPHselectedValues: e.target.value })
                                        }
                                        else if(Heading === "CYL"){
                                            this.setState({ CYLselectedValues: e.target.value })
                                        }
                                        else if(Heading === "AXIS"){
                                            this.setState({ AXISselectedValues: e.target.value })
                                        }
                                        else if(Heading === "ADD"){
                                            this.setState({ ADDselectedValues: e.target.value })
                                        }
                                        else if(Heading === "BCDVA"){
                                            this.setState({ BCDVAselectedValues: e.target.value })
                                        }
                                        else if(Heading === "BCNVA"){
                                            this.setState({ BCNVAselectedValues: e.target.value })
                                        }
                                    }}
                                >
                                    
                                    {Heading === "SPH" || Heading === "CYL" ? null : <FormControlLabel control={<Radio inputProps = {{emed_tid : "All_testID"}} size='small' value={"All"}  />} label="All" />}
                                    <FormControlLabel control={<Radio inputProps = {{emed_tid : "plus_testID"}} size='small' value={"plus"}  />} label="+" />
                                    <FormControlLabel control={<Radio  inputProps = {{emed_tid : "Minus_testID"}} size='small' value={"Minus"} />} label="-" />
                                </RadioGroup> : 
                               null
                        }
                    </Box>
                <div  ref={ref} style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        sortedValue && sortedValue.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${item.label === key ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: item?.label?.length > 6 ? "5vw" : "4vw" }}
                                label={item?.label?.length > 6 ? <Tooltip placement='top' title={item?.label}><div style={{ fontSize: '0.7vw' }}>{item?.label?.slice(0, 5)+ '...'}</div></Tooltip> : item?.label}
                                variant={item.label === key ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (Tab == "LeftEye" && this.state.Leftchecked) {
                                        this.CopyLefttoRight(!this.state.Leftchecked)
                                    } else if (Tab == "RightEye" && this.state.Rightchecked) {
                                        this.CopyRighttoLeft(!this.state.Rightchecked)
                                    }
                                    if (item.label === key) {
                                        states[Tab][Heading] = ""
                                        this.setState({ states })
                                    } else {
                                        states[Tab][Heading] = item?.value
                                        this.setState({ states })
                                    }
                                    this.scrollToRef(scrollTo)
                                }}
                                clickable
                            />
                        ))
                    }
                </div>
                <div>
                    {AddButton && this.state.selectedText != Heading ?
                        <Button
                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                            variant='contained'
                            onClick={() => { this.setState({ selectedText: Heading, NewText: "" }) }}
                        >Add</Button>
                        : null}
                </div>
            </div>
        )
    }

    renderRightSideValues = (Heading, value) => {
        return (
            <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
                <div>{Heading}</div>
                <div className="eMed_BoxShadow">
                    {value?.length > 13 ? <Tooltip title={value} placement='top' arrow><div>{value?.slice(0, 10) + "..."}</div></Tooltip>: value}
                </div>
            </div>
        )
    }

    CopyLefttoRight = (value) => {
        let { LeftEye } = this.state
        let states = this.state
        if (value) {
            states.RightEye["UCVA"] = LeftEye.UCVA
            states.RightEye["SPH"] = LeftEye.SPH
            states.RightEye["CYL"] = LeftEye.CYL
            states.RightEye["AXIS"] = LeftEye.AXIS
            states.RightEye["ADD"] = LeftEye.ADD
            states.RightEye["BCDVA"] = LeftEye.BCDVA
            states.RightEye["BCNVA"] = LeftEye.BCNVA
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                value: this.state.isEdit ? "One" : "Two",
                states
            })
        } else {
            states.RightEye["UCVA"] = ""
            states.RightEye["SPH"] = ""
            states.RightEye["CYL"] = ""
            states.RightEye["AXIS"] = ""
            states.RightEye["ADD"] = ""
            states.RightEye["BCDVA"] = ""
            states.RightEye["BCNVA"] = ""
            this.setState({
                Leftchecked: value,
                Rightchecked: false,
                states
            })
        }
    }

    CopyRighttoLeft = (value) => {
        let { RightEye } = this.state
        let states = this.state
        if (value) {
            states.LeftEye["UCVA"] = RightEye.UCVA
            states.LeftEye["SPH"] = RightEye.SPH
            states.LeftEye["CYL"] = RightEye.CYL
            states.LeftEye["AXIS"] = RightEye.AXIS
            states.LeftEye["ADD"] = RightEye.ADD
            states.LeftEye["BCDVA"] = RightEye.BCDVA
            states.LeftEye["BCNVA"] = RightEye.BCNVA
            states.LeftEye["PHVA"] = RightEye.PHVA
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                value: this.state.isEdit ? "Two" : "One",
                states
            })
        } else {
            states.LeftEye["UCVA"] = ""
            states.LeftEye["SPH"] = ""
            states.LeftEye["CYL"] = ""
            states.LeftEye["AXIS"] = ""
            states.LeftEye["ADD"] = ""
            states.LeftEye["BCDVA"] = ""
            states.LeftEye["BCNVA"] = ""
            states.LeftEye["PHVA"] = ""
            this.setState({
                Rightchecked: value,
                Leftchecked: false,
                states
            })
        }
    }

    handleClear = () => {
        if (this.state.isEdit) {
            this.setState({
                Leftchecked: false,
                Rightchecked: false,
                Occupation: "",
                Chart: "",
                Muscle: "",
                LeftEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    PHVA: "",
                    comments: "",
                    id: this.state.LeftEye.id
                },
                RightEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    PHVA: "",
                    comments: "",
                    id: this.state.RightEye.id
                }
            })
        } else {
            this.setState({
                LeftEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    comments: ""
                },
                RightEye: {
                    UCVA: "",
                    SPH: "",
                    CYL: "",
                    AXIS: "",
                    BCDVA: "",
                    ADD: "",
                    BCNVA: "",
                    comments: ""
                },
                Leftchecked: false,
                Rightchecked: false,
                postLoad: false,
                value: "Two",
                disableLeft: false,
                disableRight: false,
                Occupation: "",
                Chart: "",
                Muscle: "",
                isEdit: false
            })
        }
    }

    renderBottomArea = (Title, value) => {
        return (
            <div style={{ marginRight: "0.5vw", width: "12vw", marginLeft: "0.5vw" }}>
                <div>{Title}</div>
                {value && value?.length > 15 ?
                    <Tooltip placement='top' title={value}><div style={{ fontWeight: "600", color: "black" }}>{value?.slice(0, 15) + '...'}</div></Tooltip> :
                    <div style={{ fontWeight: "600", color: "black", fontSize: "0.9vw" }}>{value ? value : "-"}</div>
                }
            </div>
        )
    }

    PreviousPowerPopup = () => {
        return (
            <div>
                <Modal open={this.state.comparePopup}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "65vw", height: "55vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
                            <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>View Retinoscopic Refraction</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ comparePopup: false })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'} style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
                            <Box component={'div'}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5vw" }}>
                                        <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>Current Retinoscopic Refraction</Typography>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: Colors.ThemeColor, marginLeft: "0.65vw", marginTop: "-0.2vw" }} onClick={() => { this.CopyPreviousData() }}>
                                            <Tooltip placement='top' title='Copy'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>{this.renderOldGlassPowerTable()}</div>
                                </div>
                            </Box>
                            <Box component={'div'} sx={{ marginTop: "0.65vw" }}>
                                <div style={{ marginTop: "0.65vw" }}>
                                    <Typography fontSize={'0.95vw'} fontWeight={600} style={{ marginBottom: "0.5vw" }} color={Colors.SecondaryText}>New Retinoscopic Refraction</Typography>
                                    {this.renderRetinoscopicRefractionTable()}
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    scrollToRef = (ref) => {
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

     // occupation add popup start   
     occupationPopUpCls = () => {
        this.setState({
            isOccupationadd: false,
            occupationName: '',
            addPostLoad: false
        })
    }

    handleAdd = () => {
        const { occupationName, occupationList } = this.state;

        const normalizeString = (str) => str.trim().toLowerCase();
        const isDuplicate = occupationList?.some(item => normalizeString(item) === normalizeString(occupationName));

        if (isDuplicate) {
            this.errorMessage(`Occupation ${occupationName} is already exists!`);
            return;
        }

        this.setState((prevState) => ({
            addPostLoad: true,
            occupationList: [...prevState.occupationList, prevState.occupationName]
        }), () => {
            this.occupationPopUpCls();
        });
    }

     renderOccupationAdd = () => {
         return (
             <Dialog open={true} >
                 <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
                     <Typography marginLeft={"1dvw"}>{"Add Occupation Name"}</Typography>
                     <Button onClick={() => { this.occupationPopUpCls() }} >
                         <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                     </Button>
                 </DialogTitle>
                 <DialogContent sx={{ padding: "2dvw", width: '25dvw' }}>
                     <TextField
                         inputProps={{ maxLength: 30 }}
                         sx={{ width: "20dvw", marginTop: "2dvw" }}
                         size='small'
                         autoComplete="off"
                         label={"Occupation Name"}
                         value={this.state.occupationName}
                         onChange={(event) => {
                             let alphanum = CommonValidation.alphanumValidation(event.target.value);
                             if (alphanum || event.target.value === '') {
                                 this.setState({
                                     occupationName: event.target.value
                                 })
                             }
                         }}
                     />
                 </DialogContent>
                 <DialogActions>
                     <Button variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({ occupationName: '' }) }}>{"Clear"}</Button>
                     <Button variant='contained' size='small' disabled = {!this.state.occupationName || this.state.addPostLoad} className='eMed_Filter_Btns' onClick={() => { this.handleAdd() }}>{"Add"}</Button>
                 </DialogActions>
             </Dialog>
         )
     }
     // occupation add popup end

    RetinoscopicRefractionPopup = () => {
        let { LeftEye, RightEye, selectedText } = this.state
        return (
            <div>
                <Modal open={this.state.openPopUp}>
                    <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "78vw", height: "90vh", maxHeight: "100%" }}>
                        <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Retinoscopic Refraction</Typography>
                            <Button
                                className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ openPopUp: false }, () => { this.getRetinoscopicRefraction() })}
                            >
                                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                            </Button>
                        </Box>
                        <Box component={'div'}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "60%" }}>
                                    <Tabs
                                        value={this.state.value}
                                        onChange={(e, newValue) => this.setState({
                                            value: newValue
                                        }, () => this.scrollToRef(this.UCVA_REF))}
                                    >
                                        <Tab disabled={this.state.disableRight} value="Two" label={"Right Eye"} className='eMed_tab_header' />
                                        <Tab disabled={this.state.disableLeft} value="One" label={"Left Eye"} className='eMed_tab_header' />
                                    </Tabs>
                                    {
                                        this.state.value === "One" ?
                                            <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                    <div>
                                                        {this.renderGlassPower("UCVA", UCVA_OPTIONS, LeftEye.UCVA, "LeftEye", true, this.UCVA_REF, this.SPH_REF)}
                                                        {selectedText == "UCVA" ? this.renderNewText("UCVA", UCVA_OPTIONS, "LeftEye") : null}
                                                        {this.renderGlassPower("SPH",SPH_OPTIONS, LeftEye.SPH, "LeftEye", null, this.SPH_REF, this.CYL_REF)}
                                                        {this.renderGlassPower("CYL", CYL_OPTIONS, LeftEye.CYL, "LeftEye", null, this.CYL_REF, this.AXIS_REF)}
                                                        {this.renderGlassPower("AXIS", AXIS_OPTIONS, LeftEye.AXIS, "LeftEye", true, this.AXIS_REF, this.ADD_REF)}
                                                        {selectedText == "AXIS" ? this.renderNewText("AXIS", AXIS_OPTIONS, "LeftEye") : null}
                                                        {this.renderGlassPower("ADD",ADD, LeftEye.ADD, "LeftEye", true, this.ADD_REF, this.BCDVA_REF)}
                                                        {selectedText == "ADD" ? this.renderNewText("ADD", ADD, "LeftEye") : null}
                                                        {this.renderGlassPower("BCDVA",BCDVA_OPTIONS, LeftEye.BCDVA, "LeftEye", true, this.BCDVA_REF, this.BCNVA_REF)}
                                                        {selectedText == "BCDVA" ? this.renderNewText("BCDVA", BCDVA_OPTIONS, "LeftEye") : null}
                                                        {this.renderGlassPower("BCNVA", BCNVA, LeftEye.BCNVA, "LeftEye", true, this.BCNVA_REF, this.BCNVA_REF)}
                                                        {selectedText == "BCNVA" ? this.renderNewText("BCNVA", BCNVA, "LeftEye") : null}
                                                    </div>
                                                </Box>
                                            </Box>
                                            : this.state.value === "Two" ?
                                                <Box component={"div"} style={{ padding: "0.75vw" }}>
                                                    <Box className="eMedhub_Shadow_Properties" sx={{ width: "100%", height: "70vh", paddingLeft: "0.5vw", overflow: "scroll" }}>
                                                        <div>
                                                            {this.renderGlassPower("UCVA", UCVA_OPTIONS, RightEye.UCVA, "RightEye", true, this.UCVA_REF, this.SPH_REF)}
                                                            {selectedText == "UCVA" ? this.renderNewText("UCVA", UCVA_OPTIONS, "RightEye") : null}
                                                            {this.renderGlassPower("SPH", SPH_OPTIONS, RightEye.SPH, "RightEye", null, this.SPH_REF, this.CYL_REF)}
                                                            {this.renderGlassPower("CYL", CYL_OPTIONS, RightEye.CYL, "RightEye", null, this.CYL_REF, this.AXIS_REF)}
                                                            {this.renderGlassPower("AXIS", AXIS_OPTIONS, RightEye.AXIS, "RightEye", true, this.AXIS_REF, this.ADD_REF)}
                                                            {selectedText == "AXIS" ? this.renderNewText("AXIS", AXIS_OPTIONS, "RightEye") : null}
                                                            {this.renderGlassPower("ADD",ADD, RightEye.ADD, "RightEye", true, this.ADD_REF, this.BCDVA_REF)}
                                                            {selectedText == "ADD" ? this.renderNewText("ADD", ADD, "RightEye") : null}
                                                            {this.renderGlassPower("BCDVA", BCDVA_OPTIONS, RightEye.BCDVA, "RightEye", true, this.BCDVA_REF, this.BCNVA_REF)}
                                                            {selectedText == "BCDVA" ? this.renderNewText("BCDVA", BCDVA_OPTIONS, "RightEye") : null}
                                                            {this.renderGlassPower("BCNVA", BCNVA , RightEye.BCNVA, "RightEye", true, this.BCNVA_REF, this.BCNVA_REF)}
                                                            {selectedText == "BCNVA" ? this.renderNewText("BCNVA", BCNVA, "RightEye") : null}
                                                        </div>
                                                    </Box>
                                                </Box>
                                                : null
                                    }
                                </div>
                                <div style={{ width: "40%" }}>
                                    <Box component={'div'} style={{ marginTop: "4.4vw", marginLeft: "1vw", height: "67vh", overflow: "scroll" }}>
                                        <div style={{ fontWeight: "600", marginBottom: "0.75vw" }}>{"Retinoscopic Refraction"}</div>
                                        <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "0.5vw" }}>
                                            <FormControl size='small' style={{ width: '90%' }}>
                                                <InputLabel id="Dis_lay_Diet">{'Select Occupation'}</InputLabel>
                                                <Select
                                                    value={this.state.Occupation ? this.state.Occupation : ""}
                                                    label={'Select Occupation'}
                                                    onChange={(event) => {
                                                        let values = event.target.value;
                                                        this.setState({
                                                            Occupation: values
                                                        })
                                                    }}
                                                    MenuProps={{
                                                        style: { maxHeight: 350 },
                                                    }}
                                                >
                                                    {/* <MenuItem value={""}>{"Select Occupation"}</MenuItem> */}
                                                    {/* {DEFAULT_OCCUPATIONS_SR.map((item, index) => {
                                                        return <MenuItem key={index} value={item.value}>{(item.label ? item.label : "")}</MenuItem>
                                                    })} */}
                                                    {this.state.occupationList?.length > 0 ? this.state.occupationList?.map((item) => (
                                                        <MenuItem value={item}>{item}</MenuItem>
                                                    )) : <Typography align='center'>{"No Data Found..."}</Typography>}
                                                    <Divider />
                                                    <List>
                                                        <ListItem disablePadding>
                                                            <ListItemButton onClick={() => {
                                                                this.setState({
                                                                    isOccupationadd: true,
                                                                    occupationName: '',
                                                                })
                                                            }
                                                            }>
                                                                <ListItemText primary={'Add New Occupation'} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </List>
                                                </Select>
                                            </FormControl>
                                            <FormControl size='small' style={{ width: '44%', marginTop: "0.75vw" }}>
                                                <InputLabel id="Dis_lay_Diet">{'Select Chart Used'}</InputLabel>
                                                <Select
                                                    value={this.state.Chart ? this.state.Chart : ""}
                                                    label={'Select Chart Used'}
                                                    onChange={(event) => {
                                                        let values = event.target.value;
                                                        this.setState({
                                                            Chart: values
                                                        })
                                                    }}
                                                    MenuProps={{
                                                        style: { maxHeight: 350 },
                                                    }}
                                                >
                                                    <MenuItem value={""}>{"Select Chart Used"}</MenuItem>
                                                    {DEFAULT_CHARTUSED_SR.map((item, index) => {
                                                        return <MenuItem key={index} value={item.value}>{(item.label ? item.label : "")}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl size='small' style={{ width: '44%', marginTop: "0.75vw", marginLeft: "0.5vw" }}>
                                                <InputLabel id="Dis_lay_Diet">{'Select Muscle'}</InputLabel>
                                                <Select
                                                    value={this.state.Muscle ? this.state.Muscle : ""}
                                                    label={'Select Muscle'}
                                                    onChange={(event) => {
                                                        let values = event.target.value;
                                                        this.setState({
                                                            Muscle: values
                                                        })
                                                    }}
                                                    MenuProps={{
                                                        style: { maxHeight: 350 },
                                                    }}
                                                >
                                                    <MenuItem value={""}>{"Select Muscle"}</MenuItem>
                                                    {DEFAULT_MUSCLES_SR.map((item, index) => {
                                                        return <MenuItem key={index} value={item.value}>{(item.label ? item.label : "")}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div style={{ fontWeight: "600", marginBottom: "0.75vw", marginTop: "0.5vw" }}>{this.state.value === "One" ? "Left Eye" : "Right Eye"}</div>
                                        {this.state.value === "One" ?
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                    {this.renderRightSideValues("UCVA", LeftEye.UCVA)}
                                                    {this.renderRightSideValues("SPH", LeftEye.SPH)}
                                                    {this.renderRightSideValues("CYL", LeftEye.CYL)}
                                                    {this.renderRightSideValues("AXIS", LeftEye.AXIS)}
                                                    {this.renderRightSideValues("ADD", LeftEye.ADD)}
                                                    {this.renderRightSideValues("BCDVA", LeftEye.BCDVA)}
                                                    {this.renderRightSideValues("BCNVA", LeftEye.BCNVA)}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Leftchecked} control={<Checkbox checked={this.state.Leftchecked} onClick={(e) => { this.CopyLefttoRight(!this.state.Leftchecked) }} />} label="Copy to Right Eye (RE)" />
                                                </Box>
                                            </div> :
                                            <div>
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginLeft: "-0.5vw" }}>
                                                    {this.renderRightSideValues("UCVA", RightEye.UCVA)}
                                                    {this.renderRightSideValues("SPH", RightEye.SPH)}
                                                    {this.renderRightSideValues("CYL", RightEye.CYL)}
                                                    {this.renderRightSideValues("AXIS", RightEye.AXIS)}
                                                    {this.renderRightSideValues("ADD", RightEye.ADD)}
                                                    {this.renderRightSideValues("BCDVA", RightEye.BCDVA)}
                                                    {this.renderRightSideValues("BCNVA", RightEye.BCNVA)}
                                                </div>
                                                <Box sx={{ marginTop: "1vw" }}>
                                                    <FormControlLabel checked={this.state.Rightchecked} control={<Checkbox checked={this.state.Rightchecked} onClick={(e) => { this.CopyRighttoLeft(!this.state.Rightchecked) }} />} label="Copy to Left Eye (LE)" />
                                                </Box>
                                            </div>}
                                        <div style={{ marginTop: "1vw" }}>
                                            <div style={{ marginTop: "0.5vw", fontWeight: "600" }}>{"Comments"}</div>
                                            <div >
                                                <TextField
                                                    label={("Enter Comments")}
                                                    style={{ height: "5vw", marginTop: "0.5vw", width: "95%" }}
                                                    multiline={true}
                                                    rows={3}
                                                    variant="outlined"
                                                    value={this.state.value === "One" ? LeftEye.comments : RightEye.comments}
                                                    onChange={(event) => {
                                                        var value = event.target.value
                                                        if (this.state.value === "One") {
                                                            if (value?.length <= 250) {
                                                                LeftEye.comments = value
                                                                this.setState({
                                                                    LeftEye
                                                                })
                                                            } else { this.errorMessage('Allowed only 250 characters') }
                                                        } else {
                                                            if (value?.length <= 250) {
                                                                RightEye.comments = event.target.value
                                                                this.setState({
                                                                    RightEye
                                                                })
                                                            }
                                                            else { this.errorMessage('Allowed only 250 characters') }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-end", marginTop: "-0.75vw", marginRight: "0.75vw" }}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.handleClear() }}
                                >Clear</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    disabled={this.state.postLoad}
                                    variant='contained'
                                    onClick={() => { this.PostRetinoscopicRefraction() }}
                                >Add</Button>
                            </div>
                        </Box>
                    </Box>
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ backgroundColor: Colors.Background, height: "3.5vw" }}
                        >
                            <Box component={'div'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'} sx={{ backgroundColor: Colors.Background }} width={"100%"}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>Retinoscopic Refraction</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box component={'div'} p={'0.5vw'}>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mb: "0.65vw" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: this.state.PreviousData?.length > 0 && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && !this.props.isLocked) { this.CopyPreviousData() } }}>
                                            <Tooltip placement='top' title='Copy Previous Retinoscopic Refraction'>
                                                <Box component={'img'} src={ImagePaths.copy.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                        <div className="eMed_icon_styles" style={{ backgroundColor: (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0) && !this.props.isLocked ? Colors.ThemeColor : Colors.disableComponentColor }} onClick={() => { if (this.state.PreviousData?.length > 0 && this.state.currentData?.length > 0 && !this.props.isLocked) { this.setState({ comparePopup: true }) } }}>
                                            <Tooltip placement='top' title='Compare'>
                                                <Box component={'img'} src={ImagePaths.EyeView.default} height={'1.5vw'} width={'1.5vw'} />
                                            </Tooltip>
                                        </div>
                                        <Button
                                            disabled={this.state.isLocked || this.props.isLocked || this.state?.currentData?.length > 1}
                                            variant='contained'
                                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw' }}
                                            onClick={() => {
                                                this.setState({
                                                     openPopUp: true,
                                                     SPHselectedValues : "Minus",
                                                     CYLselectedValues : "Minus",
                                                     AXISselectedValues : "All",
                                                     ADDselectedValues : "All",
                                                     BCDVAselectedValues : "All",
                                                     BCNVAselectedValues : "All",
                                                     })
                                            }}
                                        >Add</Button>
                                    </div>
                                </Box>
                                <Box component={'div'}>
                                    {this.renderRetinoscopicRefractionTable()}
                                </Box>
                            </Box>
                            <Box component={'div'} p={'0.5vw'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                {this.state.Occupation ? this.renderBottomArea("Occupation", this.state.Occupation) : null}
                                {this.state.Chart ? this.renderBottomArea("Chart Used", this.state.Chart) : null}
                                {this.state.Muscle ? this.renderBottomArea("Muscle", this.state.Muscle) : null}
                                {this.state.RightEye.comments ? this.renderBottomArea("RE - Comments", this.state.RightEye.comments) : null}
                                {this.state.LeftEye.comments ? this.renderBottomArea("LE - Comments", this.state.LeftEye.comments) : null}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {this.RetinoscopicRefractionPopup()}
                    {this.PreviousPowerPopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {(this.state.isOccupationadd) ? this.renderOccupationAdd() : null}
                </Box>
            </ShowComponents >
        )
    }

}